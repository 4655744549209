import React from "react";
import { Element } from "react-scroll";
import HeroSection from "./components/HeroSection";
import Policy from "./components/Policy";
import Section3 from "./components/Section3";
import SearchAndContactUsSection from "../../components/SearchAndContact";
import withScrollToHash from '../../hooks/useScrollToHash';
import { Helmet } from "react-helmet";

function Link4() {
  return (
    <>
      <Helmet>
        <title>Protean TIN PAN Privacy Policy - Data Privacy and Security</title>
        <meta name="description" content="Discover how Protean upholds data protection and information security. Ensuring your data privacy is our top priority." />
        <meta name="keywords" content="tin facilitation centre near me, income tax payment online, nsdl tax information network website, e tax payment system, pan card online application" />
      </Helmet>
      <div className="flex flex-col gap-8">
        <HeroSection />
        <SearchAndContactUsSection />
        <Element name="policy">
          <Policy />
        </Element>
        <Element name="section3">
          <Section3 />
        </Element>
      </div>
    </>
  );
}

export default withScrollToHash(Link4); 
