import React from 'react';

const Table = ({ data }) => {
  return (
    <div className="mt-4 w-full">
      {/* Desktop Table */}
      <div className="hidden md:block">
        <table className="w-full table-auto border-collapse">
          <thead>
            <tr className="bg-yellow-200">
              <th className="p-2 border">State</th>
              <th className="p-2 border">City</th>
              <th className="p-2 border">Pin Code</th>
              <th className="p-2 border">Facilitator</th>
              <th className="p-2 border">Branch</th>
              <th className="p-2 border">Contact Person</th>
              <th className="p-2 border">Contact Address</th>
              <th className="p-2 border">Contact Number</th>
              <th className="p-2 border">Email Address</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index} className="border-t">
                <td className="p-2 border">{row.State || row.STATE}</td>
                <td className="p-2 border">{row.City || row.CITY}</td>
                <td className="p-2 border">{row.PinCode || row.PINCODE}</td>
                <td className="p-2 border">{row.Facilitator || row.FCNAME}</td>
                <td className="p-2 border">{row.BRANCHID}</td>
                <td className="p-2 border">{(row.ContactPerson || row.CONTACTPERSON1).replaceAll('^','')}</td>
                <td className="p-2 border">
                  {row.Address1 || row.ADDRESS1}, {row.Address2 || row.ADDRESS2}, {row.Address3 || row.ADDRESS3}
                </td>
                <td className="p-2 border">{(row.Telephone || row.TELEPHONE).replace(/\/+$/, '')}</td>
                <td className="p-2 border">{(row.Email || row.EMAIL).replaceAll(';','')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Mobile Cards */}
      <div className="md:hidden">
        {data.map((row, index) => (
          <div key={index} className="border rounded-lg p-4 mb-4 bg-white shadow">
            <div className="mb-2">
              <strong>State:</strong> {row.State || row.STATE}
            </div>
            <div className="mb-2">
              <strong>City:</strong> {row.City || row.CITY}
            </div>
            <div className="mb-2">
              <strong>Pin Code:</strong> {row.PinCode || row.PINCODE}
            </div>
            <div className="mb-2">
              <strong>Facilitator:</strong> {row.Facilitator || row.FCNAME}
            </div>
            <div className="mb-2">
              <strong>Branch:</strong> {row.BRANCHID}
            </div>
            <div className="mb-2">
              <strong>Contact Person:</strong> {row.ContactPerson || row.CONTACTPERSON1}
            </div>
            <div className="mb-2">
              <strong>Contact Address:</strong> {row.Address1 || row.ADDRESS1}, {row.Address2 || row.ADDRESS2}, {row.Address3 || row.ADDRESS3}
            </div>
            <div className="mb-2">
              <strong>Email Address:</strong> {row.Telephone || row.TELEPHONE}.replaceAll('/','')
            </div>
            <div className="mb-2">
              <strong>Email Address:</strong> {row.Email || row.EMAIL}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Table;
