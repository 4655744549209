import React from 'react';
import { Element } from 'react-scroll';
import SearchHeroSection from '../../components/SearchHerosection';
import SearchForms from './components/SearchForm';
import withScrollToHash from '../../hooks/useScrollToHash'; // Correctly import the HOC
import { Helmet } from 'react-helmet';

function Link16() {
  return (
    <>
      <Helmet>
        <title>TIN Facilities: Services, Registration, and Application</title>
        <meta name="description" content="Explore TIN facilities including registration, application, and services. Access comprehensive Tax Information Network support and resources." />
      </Helmet>
      <section className='flex flex-col gap-8'>
        <SearchHeroSection isPage2={true} />
        <Element name="search-forms">
          <SearchForms />
        </Element>
      </section>
    </>
  );
}

export default withScrollToHash(Link16); // Use the HOC
