import React from 'react';
import { Element } from 'react-scroll';
import CommonBanner from '../../components/CommonBanner';
import ThirdGrid from './components/ThirdGrid';
import ApplyOnline from './components/ApplyOnline';
import AoCodes from './components/AoCodes';
import DownloadTan from './components/DownloadTan';
import KnowStatus from './components/KnowStatus';
import withScrollToHash from '../../hooks/useScrollToHash'; // Correctly import the HOC
import { Helmet } from 'react-helmet';

function Link24() {
    return (
        <>
            <Helmet>
                <title>TAN Downloads - Protean Pan & Tan Services</title>
                <meta name="description" content="Download TAN-related forms and documents. Access resources from Protean Tinpan for TAN registration. Get downloads now!" />
                <meta name="keywords" content="form 49 b, correction in tan data, tan application form online, pan tan application status, tan application form online, tan application form download, tax deduction account number tan, tan 49b" />
            </Helmet>
            <div className="flex flex-col gap-8">
                <div className="lg:px-[70px]">
                    <CommonBanner
                        title={"Tax Deduction / Collection Account Number (TAN)"}
                        className={"min-h-[60vh] md:min-h-[50vh] mx-auto"}
                        isTaxDeductions
                        desktopWidth="466px"
                        desktopHeight="466px"
                        shiftDotGrid='bottom-10'
                        isTAN={true}
                        isFirstButton={true}
                        firstButtonTitle="New TAN"
                        firstButtonLink="https://tin.tin.nsdl.com/tan/form49B.html"
                        isSecondButton={true}
                        secondButtonTitle="Change / Correction"
                        secondButtonLink="https://tin.tin.nsdl.com/tan/ChangeRequest.html"
                    />
                </div>
                <Element name="third-grid">
                    <ThirdGrid />
                </Element>
                <Element name="apply-online">
                    <ApplyOnline />
                </Element>
                <Element name="ao-codes">
                    <AoCodes />
                </Element>
                <Element name="download-tan">
                    <DownloadTan />
                </Element>
                <Element name="know-status">
                    <KnowStatus />
                </Element>
            </div>
        </>
    );
}

export default withScrollToHash(Link24); // Use the HOC
