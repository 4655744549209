// src/components/TabsDropdown.jsx
import React, { useState } from "react";

function TabsDropdown({ tabs, activeTab, setActiveTab, activeTabColor = "#EEF7ED" }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (index) => {
    setActiveTab(index);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block w-full">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full text-left p-4 border rounded flex justify-between items-center focus:outline-none"
        style={{
          backgroundColor: activeTabColor,
        }}
      >
        {tabs[activeTab].label}
        <span className="float-right">
          <svg
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L7 7L13 1"
              stroke="#1D1D1B"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        {/* Dropdown arrow */}
      </button>
      {isOpen && (
        <div className="absolute z-10 w-full mt-2 bg-white border rounded shadow-lg">
          {tabs.map((tab, index) => (
            <button
              key={index}
              onClick={() => handleSelect(index)}
              className={`block w-full text-left px-4 py-2`}
              style={{
                backgroundColor: activeTab === index ? activeTabColor : "transparent",
              }}
            >
              {tab.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default TabsDropdown;
