import React, { useState } from 'react'
import { ArrowUpSvg, DropDownArrowSvg, ThreeDotsBulletSvg } from '../../../assets/svgs'

function Communication({ language }) {
    const isMobile = window.innerWidth <= 768;
    const [isOverviewOpen, setIsOverviewOpen] = useState(isMobile || true)

    const toggleOverview = () => {
        setIsOverviewOpen(!isOverviewOpen)
    }

    const textContent = {
        en: {
            overviewTitle: "Communication",
            overviewDescription1: "Protean forwards the application details to ITD after digitization of the form submitted.",
            overviewDescription2: "In case of application for allotment of PAN (Form 49A), a new PAN is allotted by ITD. Protean prints the PAN card after allotment of PAN by ITD and dispatches the same along with an allotment letter to the respective applicant.",
            overviewDescription3: "In case of 'Request for New PAN Card or/and Changes or Correction in PAN data' the application request is forwarded to ITD for update of the database and after confirmation from ITD, a new PAN card is printed and dispatched to the applicant.",
            overviewDescription4: "All the communications are sent at the address mentioned as 'address for communication' in the form. Applicants, specifying valid e-mail ID in the application, are informed about the new PAN through e-mail in addition to Physical PAN card and the PAN allotment letter. This ensures faster communication in case of any discrepancy in the application.",
        },
        hi: {
            overviewTitle: "संचार",
            overviewDescription1: "सबमिट किए गए फॉर्म के डिजिटलीकरण के बाद प्रोटीन आवेदन विवरण आईटीडी को भेजता है।",
            overviewDescription2: "पैन (फॉर्म 49ए) के आवंटन के लिए आवेदन के मामले में, आईटीडी द्वारा एक नया पैन आवंटित किया जाता है। प्रोटीन आईटीडी द्वारा पैन के आवंटन के बाद पैन कार्ड प्रिंट करता है और इसे संबंधित आवेदक को आवंटन पत्र के साथ भेजता है।",
            overviewDescription3: "'नए पैन कार्ड के लिए अनुरोध या/और पैन डेटा में परिवर्तन या सुधार' के मामले में, आवेदन अनुरोध को डेटाबेस के अद्यतन के लिए आईटीडी को भेज दिया जाता है और आईटीडी से पुष्टि के बाद, एक नया पैन कार्ड मुद्रित किया जाता है और आवेदक को भेज दिया जाता है।",
            overviewDescription4: "सभी संचार फॉर्म में 'संचार के लिए पता' के रूप में उल्लिखित पते पर भेजे जाते हैं। आवेदन में वैध ई-मेल आईडी निर्दिष्ट करने वाले आवेदकों को भौतिक पैन कार्ड और पैन आवंटन पत्र के अतिरिक्त ई-मेल के माध्यम से नए पैन के बारे में सूचित किया जाता है। यह आवेदन में किसी भी विसंगति के मामले में तेजी से संचार सुनिश्चित करता है।",
        }
    };

    return (
        <section className="bg-[#3091D0] bg-opacity-10 mt-6 md:mt-0 p-8 mb-6">
            <div className='px-4 md:px-0 flex items-center justify-between pb-6'>
                <h1 className='flex flex-row text-[26px] font-semibold gap-2 leading-5'>
                    <ThreeDotsBulletSvg fillColor={'#3091D0'} className={'w-[34px] h-[17px]'} />
                    {textContent[language].overviewTitle}
                </h1>
                {isMobile && <button onClick={toggleOverview} className=''>
                    {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                </button>}
            </div>
            {isOverviewOpen && <p className='text-sm font-normal leading-6'>
                {textContent[language].overviewDescription1}
            </p>}
            {isOverviewOpen && <p className='text-sm font-normal leading-6'>
                {textContent[language].overviewDescription2}
            </p>}
            {isOverviewOpen && <p className='text-sm font-normal leading-6'>
                {textContent[language].overviewDescription3}
            </p>}
            {isOverviewOpen && <p className='text-sm font-normal leading-6'>
                {textContent[language].overviewDescription4}
            </p>}
        </section>
    )
}

export default Communication
