import React from 'react'
import DynamicLinks from '../../../components/DynamicLink'
import { ThreeDotsBulletSvg } from '../../../assets/svgs';

function RBI() {
    const cardDataArray = [
        {
            linkData: [
                {
                    text: "RBI clarification regarding Accounting Procedure - OLTAS",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                },
            ],
        },
    ];

    return (
        <div className='flex flex-col py-6 w-full'>
            <div className="bg-[#6F368B] bg-opacity-10 p-8">
                <h1 className='flex flex-row text-[26px] font-semibold gap-2 pb-6 leading-5'>
                    <ThreeDotsBulletSvg fillColor={'#6F368B'} className={'w-[34px] h-[17px]'} />
                    RBI Clarification on OLTAS
                </h1>
                <div className="flex flex-col md:flex-row pb-20 md:pb-0">
                    {cardDataArray.map((cardData, index) => (
                        <DynamicLinks key={index} linkData={cardData.linkData} listDir='flex-row' className={"text-black flex-col md:flex-row md:items-end gap-5 px-2 md:px-0 md:gap-6"} titleColor='black' />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default RBI