import React from "react";
import { Element } from "react-scroll";
import SearchTabForms from "./components/Search";
import CommonBanner from "../../components/CommonBanner";
import withScrollToHash from "../../hooks/useScrollToHash"; // Correctly import the HOC

function Link19() {
  return (
    <div className="flex flex-col gap-8">
      <CommonBanner
        title={"Search"}
        className={"min-h-[60vh] md:min-h-[50vh]"}
      />
      <Element name="search-tab-forms">
        <SearchTabForms />
      </Element>
    </div>
  );
}

export default withScrollToHash(Link19); // Use the HOC
