import React, { useState } from 'react'
import { ArrowUpSvg, DropDownArrowSvg, ThreeDotsBulletSvg } from '../../../assets/svgs'

function TANApplication() {
    const isMobile = window.innerWidth <= 768;
    const [isOverviewOpen, setIsOverviewOpen] = useState(isMobile || false)

    const toggleOverview = () => {
        setIsOverviewOpen(!isOverviewOpen)
    }

    return (
        <section className="md:px-[68px] mb-8 w-full">
            <div className='bg-[#3091D0] bg-opacity-10 p-8'>
                <div className='px-4 md:px-0 flex items-center justify-between'>
                    <h1 className='flex flex-row text-[26px] font-semibold gap-2 leading-5'>
                        <ThreeDotsBulletSvg fillColor={'#3091D0'} className={'w-[34px] h-[17px]'} />
                        TAN Application
                    </h1>
                    <button onClick={toggleOverview} className=''>
                        {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                    </button>
                </div>
                {isOverviewOpen && <div className='pt-6'>
                    <p className="text-lg font-semibold mb-2 md:pt-2">
                        Introduction
                    </p>
                    <p className='text-sm font-normal leading-6 md:pb-6'>
                        Every person who requires to deduct tax while making payment to any other person has to obtain TAN. Under Section 203A of the Income Tax Act, 1961, it is mandatory to quote Tax Deduction Account Number (TAN) allotted by the Income Tax Department (ITD) in all TDS returns and Challans while depositing the tax in designated Bank.
                    </p>
                    <p className="text-lg font-semibold mb-2 pt-6 md:pt-2">
                        Types of PAN Application
                    </p>
                    <ul className='text-sm font-normal leading-6 list-inside list-decimal'>
                        <li>
                            <span className='font-bold'>Application for allotment of TAN (Form 49 B)</span> In case you want Change or Correction in data associated with your reformatted 10 digit TAN, you can use the "form for Change or Correction in TAN data" prescribed by ITD.
                        </li>
                        <li>
                            <span className='font-bold'>Request for New TAN Card or/and Changes or Correction in TAN data</span> This application should be used when PAN has been allotted already to applicant but a new PAN card is required or when data associated with the PAN (e.g. name of applicant / date of birth / address) is required to be updated in the records of Income Tax Department.
                        </li>
                        <li>
                            You can submit a duly filled-in application to any of the TIN-FCs managed by Protean or can apply online.
                        </li>
                    </ul>
                </div>}
                {isOverviewOpen && <div className='md:w-[50vw] pt-6'>
                    <p className="text-lg font-semibold mb-2 md:pt-2">
                        How to Apply
                    </p>
                    <p className='text-sm font-normal leading-6 md:pb-6'>
                        Go through the instructions and guidelines provided in the application form before filling the form.
                        Submit the duly filled and signed form to any TIN-FC. Alternatively, submit an online application.
                        Ensure that the necessary supporting documents are submitted alongwith the application. Details of the documents required are provided in the application form.
                    </p>
                    <p className="text-lg font-semibold mb-2 pt-6 md:pt-2">
                        Where to get Application Forms
                    </p>
                    <p className='text-sm font-normal leading-6 md:pb-6'>Application forms can either be obtained from TIN-FCs, PAN centres, any other vendors providing such forms or can be freely downloaded from this website.</p>
                    <p className="text-lg font-semibold mb-2 pt-6 md:pt-2">
                        Fee
                    </p>
                    <p className='text-sm font-normal leading-6 md:pb-6'>The processing fee for the both the applications (49B and change request) is ₹55.00 (plus applicable taxes).</p>
                    <p className="text-lg font-semibold mb-2 pt-6 md:pt-2">
                        How soon a TAN intimation is dispatched
                    </p>
                    <p className='text-sm font-normal leading-6 md:pb-6'>Normally 15 days are required to process the application, provided the application is found to be in order.</p>
                </div>}
            </div>
        </section>
    )
}

export default TANApplication