import React, { useState } from 'react'
import { ArrowUpSvg, DropDownArrowSvg, ThreeDotsBulletSvg } from '../../../assets/svgs';

function FVU() {
    const isMobile = window.innerWidth <= 768;
    const [isOverviewOpen, setIsOverviewOpen] = useState(isMobile || true)

    const toggleOverview = () => {
        setIsOverviewOpen(!isOverviewOpen)
    }

    return (
        <section className="bg-[#EE7F25] bg-opacity-10 p-8">
            <div className='px-4 md:px-0 flex items-center justify-between pb-6'>
                <h1 className='flex flex-row text-[26px] font-semibold gap-2 leading-5'>
                    <ThreeDotsBulletSvg fillColor={'#3091D0'} className={'w-[34px] h-[17px]'} />
                    File Validation Utility (FVU)
                </h1>
                {isMobile && <button onClick={toggleOverview} className=''>
                    {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                </button>}
            </div>
            {isOverviewOpen && <p className='text-sm font-normal leading-6'>
                e-TDS / e-TCS returns prepared for FY 2005-06 and onwards (i.e. Forms 24Q, 26Q, 27Q and 27EQ) can be validated using this utility.
            </p>}
            {isOverviewOpen && <p className='text-sm font-normal leading-6'>
                The e-TDS/TCS FVU is a Java based utility. JRE (Java Run-time Environment) [versions: SUN JRE: 1.6 onwards] should be installed on the computer where the e-TDS/TCS FVU is being installed. Java is freely downloadable from http://java.sun.com andhttp://www.ibm.com/developerworks/java/jdk or you can ask your vendor providing computer facilities (hardware) to install the same for you.
            </p>}
            {isOverviewOpen && <p className='text-sm font-normal leading-6'>
                The e-TDS/TCS FVU setup file (e-TDS/TCS FVU.exe) comprises of three files namely:
            </p>}
            {isOverviewOpen && <ul className='text-sm font-normal leading-6'>
                <li>TDS FVU Readme.rtf: This file contains instructions for setup of the e-TDS FVU.</li>
                <li>e-TDS FVU Setup.exe: This is a setup program for installation of FVU.</li>
                <li>TDS_FVU_STANDALONE.jar: This is the FVU program file.</li>
            </ul>}
            {isOverviewOpen && <p className='text-sm font-normal leading-6'>
                “Please download and replace the existing folders with the latest version of FVU and RPU folders available at TIN website. Replacing only the FVU Jar file in the old folder may lead to the rejection of statement at the time of submission of the file.”
            </p>}
            {isOverviewOpen && <p className='text-sm font-normal leading-6'>
                These files are in an executable zip file. These files are required for installing the e-TDS/TCS FVU.
            </p>}
            {isOverviewOpen && <p className='text-sm font-normal leading-6'>
                FVU for quarterly e-TDS/TCS statement pertaining to FY 2010-11 onwards
            </p>}
            {isOverviewOpen && <ul className='list-disc list-inside text-sm font-normal leading-6'>
                <li>e-TDS/TCS FVU.exe (Version 8.4) New features/Validations</li>
            </ul>}
            {isOverviewOpen && <p className='text-sm font-normal leading-6'>
                FVU for quarterly e-TDS/TCS statement up to FY 2009-10
            </p>}
            {isOverviewOpen && <ul className='list-disc list-inside text-sm font-normal leading-6'>
                <li>e-TDS/TCS FVU.exe (Version 2.180) New features/Validations</li>
            </ul>}
            {isOverviewOpen && <p className='text-sm font-normal leading-6'>
                Instructions for extracting the files are given in:
            </p>}
            {isOverviewOpen && <ul className='list-disc list-inside text-sm font-normal leading-6'>
                <li>e-TDS FVU Extract</li>
            </ul>}
        </section>
    )
}

export default FVU
