import React, { useState } from 'react'
import { ArrowUpSvg, DropDownArrowSvg, ThreeDotsBulletSvg } from '../../../assets/svgs'

function Procedure() {
    const isMobile = window.innerWidth <= 768;
    const [isOverviewOpen, setIsOverviewOpen] = useState(isMobile || true)

    const toggleOverview = () => {
        setIsOverviewOpen(!isOverviewOpen)
    }

    return (
        <div className='md:px-[68px] pb-6'>
            <div className='bg-[#3091D0] bg-opacity-10 p-8'>
                <div className='px-4 md:px-0 flex items-center justify-between'>
                    <h1 className='flex flex-row text-lg md:text-[26px] font-semibold gap-2 leading-5'>
                        <ThreeDotsBulletSvg fillColor={'#3091D0'} className={'w-[34px] h-[17px]'} />
                        Procedure for Registration
                    </h1>
                    {isMobile && <button onClick={toggleOverview} className=''>
                        {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                    </button>}
                </div>
                <div className='pt-6'>
                    <p className='text-sm'>Entity desirous of availing the facility for online PAN verification has to <a href='https://onlineservices.tin.egov-nsdl.com/TIN/inputPageForPanInqDetails.do' className='underline'>register</a> with Protean. For this, the entity should forward the following documents to Protean:</p>
                    <p className='md:pl-10 text-sm font-bold'>(Only one copy of below Documents are needed duly signed and stamped by one and the same authorised signatory)</p>
                    <ul className='md:pl-10 text-sm font-normal list-disc list-outside'>
                        <li><a href='https://www.protean-tinpan.com/downloads/online-pan-verification/Terms%20and%20conditions.pdf' className='underline'>Terms & Conditions</a> on the letterhead of the Entity</li>
                        <li><a href='https://www.protean-tinpan.com/downloads/online-pan-verification/NDA_format.docx' className='underline'>Non-Disclosure Agreement/ Confidentiality Agreements (NDA)</a> on the letterhead of the Entity</li>
                        <li><a href='https://www.protean-tinpan.com/downloads/online-pan-verification/Authority%20letter_R.docx' className='underline'>Authorisation letter</a> (DSC holder should be different from authorised signatory)</li>
                        <li>Screenshots of DSC as per below details :</li>
                    </ul>
                    <p className='md:pl-10 text-sm font-normal'>Go to Internet explorer - Tools - Internet Options - Contents - Certificates - Personal Tab (and view certificate) then take below screen shots.</p>
                    <ol className='md:pl-10 text-sm font-normal list-decimal list-inside'>
                        <li>General Tab</li>
                        <li>Details Tab - Serial Number</li>
                        <li>Details Tab - Authority Key Identifier</li>
                    </ol>
                    <p className='text-sm md:pl-10 font-bold py-8'>
                        (Two copies of below Documents are needed Duly Signed and Stamped by same authorised signatory)
                    </p>
                    <ul className='md:pl-10 text-sm font-normal list-disc list-outside'>
                        <li>Incorporation Certificate.</li>
                        <li>PAN card.</li>
                        <li>license/certificate issued by regulatory body.</li>
                        <li><a href='https://www.protean-tinpan.com/downloads/online-pan-verification/Declaration-by-Entities_R.docx' className='underline'>Declaration by Entities</a></li>
                        <li>Balance sheet and Profit & Loss account statements financial of latest Assessment Year.</li>
                        <li>Additional Information on letterhead mentioning below details</li>
                    </ul>
                    <ol className='md:pl-6 text-sm font-normal py-8 list-outside'>
                        <li>a) Authorised Capital</li>
                        <li>b) Disbursed amount (Mention NA if not applicable)</li>
                        <li>c) Existing number of customers as on ________(date)</li>
                        <li>d) Expected number of customers __________ per year</li>
                        <li>ITR-V (acknowledgment) of latest Assessment Year</li>
                    </ol>
                    <p className='md:pl-6 text-sm font-bold'>Account details for payment will be shared to you once Virtual ID will be created</p>
                    <p className='py-8 text-sm font-bold'>List of document to provided as per category of entity are as below :</p>
                    <div className='flex flex-col md:flex-row gap-6'>
                        <div className='flex flex-col gap-10 md:w-1/2'>
                            <table className="min-w-full bg-white border overflow-x-scroll overflow-auto">
                                <thead>
                                    <tr className='bg-[#D7EAF6] text-center'>
                                        <th className="py-2 px-4">Sr No.</th>
                                        <th className="py-2 px-4">Category of Entity</th>
                                        <th className="py-2 px-4">Document</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='text-center align-top'>
                                        <td className="py-2 px-4">1</td>
                                        <td className="py-2 px-4 text-left">Banks / Payments Bank approved by RBI / Prepaid Payment Instrument Issuers approved by RBI/ Non-Banking Financial Companies approved by RBI / Credit card Companies / Institutions / Credit information companies approved by RBI/ Payment And Settlement System Operators authorized by RBI/ RBI approved Authorised Dealer – Category II</td>
                                        <td className="py-2 px-4 text-left">License/Certificate of Registration issued by RBI</td>
                                    </tr>
                                    <tr className='text-center align-top'>
                                        <td className="py-2 px-4">2</td>
                                        <td className="py-2 px-4 text-left">Mutual Funds / Depository Participants / Stock Exchanges / Commodity Exchanges /Clearing Corporations / Depositories / Investment Advisor approved by SEBI/ Register &Transfer agents approved by SEBI/KYC Registration Agency (KRA)/Central KYC Registry</td>
                                        <td className="py-2 px-4 text-left">License/Certificate of Registration issued by SEBI</td>
                                    </tr>
                                    <tr className='text-center align-top'>
                                        <td className="py-2 px-4">3</td>
                                        <td className="py-2 px-4 text-left">Companies (Required to furnish Annual Information Return (AIR)/Statement
                                            of Financial Transaction (SFT) /Any other entity required to furnish Annual Information
                                            Return (AIR)/Statement of Financial Transaction (SFT)</td>
                                        <td className="py-2 px-4 text-left">Latest provisional receipt of Annual Information Return filed/confirmation received from ITD for submission of SFT</td>
                                    </tr>
                                    <tr className='text-center align-top'>
                                        <td className="py-2 px-4">4</td>
                                        <td className="py-2 px-4 text-left">Latest provisional receipt of Annual Information Return filed/confirmation received
                                            from ITD for submission of SFT</td>
                                        <td className="py-2 px-4 text-left">Latest provisional receipt of TDS/TCS return filed</td>
                                    </tr>
                                    <tr className='text-center align-top'>
                                        <td className="py-2 px-4 w-1/3">5</td>
                                        <td className="py-2 px-4 text-left">Insurance Web Aggregators / Insurance Company / Insurance Repository/ Direct
                                            (Life & General) Brokers approved by IRDAI</td>
                                        <td className="py-2 px-4 text-left">License/Certificate of registration issued by IRDA</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div>
                                <h2 className='text-lg md:text-[22px] md:pl-6 leading-5 font-medium pb-4'>Payment Details</h2>
                                <ul className='list-disc text-sm md:pl-6'>
                                    <li>The registration charges are ₹ 12,000 p.a. plus Goods and Services Tax (GST).</li>
                                    <li>This facility is provided for one year and the same needs to be renewed on yearly basis.</li>
                                    <li>PANs verified through file upload under ‘File and Screen Based’ facility are chargeable.</li>
                                    <li>The entity availing ‘File and Screen Based’ or ‘API Based’ facility has to maintain sufficient deposit against its user ID in order to verify PANs which are more than the free limit of PANs per day.</li>
                                    <li>Goods and Services Tax (GST) will be applicable on all the above charges. </li>
                                    <li>Payment can be made only through Online Transfer. </li>
                                </ul>
                            </div>
                        </div>
                        <div className='flex flex-col justify-between gap-10 md:w-1/2'>
                            <div>
                                <table className="min-w-full bg-white border overflow-x-scroll overflow-auto">
                                    <thead>
                                        <tr className='bg-[#D7EAF6] text-center'>
                                            <th className="py-2 px-4">Sr No.</th>
                                            <th className="py-2 px-4">Category of Entity</th>
                                            <th className="py-2 px-4">Document</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='text-center align-top'>
                                            <td className="py-2 px-4">6</td>
                                            <td className="py-2 px-4 text-left">Housing Finance Companies</td>
                                            <td className="py-2 px-4 text-left">License/Certificate of registration issued by National housing Bank (NHB)/RBI</td>
                                        </tr>
                                        <tr className='text-center align-top'>
                                            <td className="py-2 px-4">7</td>
                                            <td className="py-2 px-4 text-left">Educational Institutions established by Regulatory Bodies</td>
                                            <td className="py-2 px-4 text-left">License/Certificate of registration issued by Regulatory bodyt</td>
                                        </tr>
                                        <tr className='text-center align-top'>
                                            <td className="py-2 px-4">8</td>
                                            <td className="py-2 px-4 text-left">Central Recordkeeping Agency of National Pension System/PFRDA approved
                                                Point of Presence (For Online Platform)</td>
                                            <td className="py-2 px-4 text-left">License/Certificate of registration issued by PFRDA</td>
                                        </tr>
                                        <tr className='text-center align-top'>
                                            <td className="py-2 px-4">9</td>
                                            <td className="py-2 px-4 text-left">Mutual Fund Advisor</td>
                                            <td className="py-2 px-4 text-left">License/Certificate of registration issued by AMFI</td>
                                        </tr>
                                        <tr className='text-center align-top'>
                                            <td className="py-2 px-4 w-1/3">10</td>
                                            <td className="py-2 px-4 text-left">DSC issuing Authorities</td>
                                            <td className="py-2 px-4 text-left">License/Certificate of registration issued by CCA (Controller of Certifying Authorities)</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className='py-8 flex flex-col gap-8'>
                                    <p className='text-sm font-bold'>Details required to be provided at the time of online registration are as
                                        mentioned below.</p>
                                    <ul className='text-sm font-normal list-disc list-inside'>
                                        <li>Organisational Details</li>
                                        <li>Reason for availing PAN verification facility</li>
                                        <li>Payment details</li>
                                        <li>Digital Signature Certificate (DSC)</li>
                                        <li>Details of DSC are optional at the time of online registration.</li>
                                        <li>However, DSC is mandatory for PAN verification. For details related to</li>
                                        <li>DSC, kindly refer <a href='https://www.protean-tinpan.com/services/online-pan-verification/pan-verification-prerequisite.html' className='underline'>Pre-requisite - Digital Signature Certificate (DSC)</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Procedure