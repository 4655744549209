// CorrectionInPAN.js
import React, { useState } from "react";
import StepsForApplication from "./StepsForApplication";
import PAMLayout from "./PAMLayout";

function CorrectionInPAN() {
    
  const [language, setLanguage] = useState("en"); // Default language is English
  const [dropdownOpen, setDropdownOpen] = useState(false); // Dropdown visibility state

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const changeLanguage = (lang) => {
    setLanguage(lang);
    setDropdownOpen(false); // Close dropdown after selection
  };

  return (
    <section className="lg:px-[70px] flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <div>
          <p className="text-[26px] text-black text-left flex items-center gap-2">
            <svg
              width="34"
              height="17"
              viewBox="0 0 34 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
                fill="#6F368B"
              />
              <path
                d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
                fill="#6F368B"
              />
              <circle cx="25.5" cy="8.5" r="8.5" fill="#6F368B" />
            </svg>
            Change/Correction in PAN Data Guidelines
          </p>
        </div>
        <div className="relative">
          <button
            onClick={toggleDropdown}
            className="border p-2 px-4 z-40 rounded flex justify-between items-center bg-[#6F368B] text-white focus:outline-none min-w-[111px]"
          >
            {language === "en" ? "English" : "Hindi"}
            <svg
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L7 7L13 1"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          {dropdownOpen && (
            <div className="absolute z-40 right-0 mt-2 w-32 bg-white border rounded shadow-lg">
              <button
                onClick={() => changeLanguage("en")}
                className="block px-4 py-2 text-left w-full hover:bg-gray-100"
              >
                English
              </button>
              <button
                onClick={() => changeLanguage("hi")}
                className="block px-4 py-2 text-left w-full hover:bg-gray-100"
              >
                Hindi
              </button>
            </div>
          )}
        </div>
      </div>

      <StepsForApplication language={language} />
      <PAMLayout language={language} />
    </section>
  );
}

export default CorrectionInPAN;
