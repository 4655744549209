import React from "react";
import { DotsGrid, LinkArrowSvg } from "../assets/svgs"; // Import DotGrid if not already imported
import SearchBanner from "../assets/images/SearchBanner.png";
import SearchBannerMob from "../assets/images/SearchBannerMob.png";
import CustomerCareBanner from "../assets/images/CustomerCareBanner.png";
import CustomerCareBannerMob from "../assets/images/CustomerCareMobile.png";
import GuidedToursBanner from "../assets/images/GuidedToursBanner.png";
import GuidedToursBannerMob from "../assets/images/GuidedToursBannerMob.png";
import TaxDeductionsBanner from "../assets/images/panHeroSection.png";
import TaxDeductionsBannerMob from "../assets/images/panHeroSectionSm.png";
import OnlineTaxBanner from "../assets/images/OnlineTaxBanner.png";
import OnlineTaxBannerMob from "../assets/images/OnlineTaxBannerMob.png";
import AboutTinBanner from "../assets/images/AboutTinBanner.png";
import AboutTinBannerMob from "../assets/images/AboutTinBannerMob.png";
import OnlinePanVerificationBanner from "../assets/images/PaNVerification.png";
import OnlinePanVerificationBannerMob from "../assets/images/panHeroSectionSm.png";
import OTLASDESK from "../assets/images/OTLAS.png";
import OLTASMOB from "../assets/images/OTLASMobile.png";

const CommonBanner = ({
  title,
  className,
  isCustomerCare,
  isGuidedTours,
  isTaxDeductions,
  isOnlineTax,
  isAboutTin,
  isOnlinePanVerification,
  isOltas,
  desktopWidth = "",
  desktopHeight = "517px",
  mobileWidth = "308px",
  mobileHeight = "auto",
  shiftDotGrid = "",
  isSquare = true,
  isFirstButton = false,
  isSecondButton = false,
  firstButtonLink = "",
  secondButtonLink = "",
  firstButtonTitle = "",
  secondButtonTitle = "",
  isPAN = false,
  isTAN = false,
}) => {
  let desktopImage = SearchBanner;
  let mobileImage = SearchBannerMob;

  if (isCustomerCare) {
    desktopImage = CustomerCareBanner;
    mobileImage = CustomerCareBannerMob;
  } else if (isGuidedTours) {
    desktopImage = GuidedToursBanner;
    mobileImage = GuidedToursBannerMob;
  } else if (isTaxDeductions) {
    desktopImage = TaxDeductionsBanner;
    mobileImage = TaxDeductionsBannerMob;
  } else if (isOnlineTax) {
    desktopImage = OnlineTaxBanner;
    mobileImage = OnlineTaxBannerMob;
  } else if (isAboutTin) {
    desktopImage = AboutTinBanner;
    mobileImage = AboutTinBannerMob;
  } else if (isOnlinePanVerification) {
    desktopImage = OnlinePanVerificationBanner;
    mobileImage = OnlinePanVerificationBannerMob;
  } else if (isOltas) {
    desktopImage = OTLASDESK;
    mobileImage = OLTASMOB;
  }

  return (
    <div
      className={`md:bg-[#F7F7F7] bg-white w-full  md:min-h-[60vh] gap-4 md:justify-between flex-col h-full flex md:flex-row relative z-10 ${className}`}
    >
      <DotsGrid
        className={`absolute hidden md:flex left-0 max-w-[100px] md:max-w-[400px]  ${shiftDotGrid || 'bottom-20'}`}
      />
      <DotsGrid
        className={`absolute flex md:hidden right-0 bottom-0 z-10 max-w-[200px]  ${shiftDotGrid}`}
      />
      {/* <DotGrid
        className={`max-w-[200px] md:max-w-[400px] absolute bottom-0 hidden md:flex ${shiftDotGrid}`}
      /> */}
      <div className="bg-[#F7F7F7] flex md:hidden justify-center">
        <img
          src={mobileImage}
          alt="Banner Mobile"
          className="flex md:hidden z-10"
          style={{ width: mobileWidth, height: mobileHeight }}
        />
      </div>

      <div className="relative  px-4 md:px-0 flex flex-col md:self-center z-40">
        <div className="md:pl-[68px] flex flex-col pb-[10px] md:pb-[87px]">
          <h1 className="font-semibold text-[#1D1D1B] text-[32px] leading-[43.58px] md:text-[56px] md:leading-[68px] md:max-w-[550px] md:pl-10 pt-[30px] md:pt-0">
            {title}
          </h1>
        </div>
        {/* <div className="self-start mb-[66px] flex md:hidden md:mb-0 md:mt-0">
          <SearchSvg2 />
        </div> */}
        <div className={`flex absolute ${isTAN && '-bottom-6 gap-10 md:-bottom-[86px] md:left-24'} ${isPAN ? '-bottom-24 gap-10 md:-bottom-28 left-24' : '-bottom-24 gap-2 md:gap-10 flex-row md:-bottom-36 md:left-24 whitespace-nowrap'}`}>
          {isFirstButton && (
            <button className={`bg-orange-500 text-white ${isTAN ? 'p-1' : 'p-2'} rounded-md`}>
              <a className="flex flex-row gap-2" href={firstButtonLink}>
                {firstButtonTitle}
                <LinkArrowSvg strokeColor={"white"} />
              </a>
            </button>
          )}
          {isSecondButton && (
            <button className={`bg-orange-500 text-white ${isTAN ? 'p-1' : 'p-2'} rounded-md`}>
              <a className="flex flex-row gap-2" href={secondButtonLink}>
                {secondButtonTitle}
                <LinkArrowSvg strokeColor={"white"} />
              </a>
            </button>
          )}
        </div>
      </div>
      <img
        src={desktopImage}
        alt="Banner Desktop"
        className={`${isSquare ? 'aspect-square' : ''} hidden md:flex z-10`}
        style={{ width: desktopWidth, height: desktopHeight }}
      />
    </div >
  );
};

export default CommonBanner;
