import React from "react";
import { Element } from "react-scroll";
import CommonBanner from "../../components/CommonBanner";
import SearchAndContactUsSection from "../../components/SearchAndContact";
import SearchResults from "./components/SearchResults";
import withScrollToHash from "../../hooks/useScrollToHash"; // Correctly import the HOC

function Link22() {
  return (
    <div className="flex flex-col gap-8">
      <div className="lg:px-[70px]">
        <CommonBanner
          className={"min-h-[60vh] md:min-h-[50vh]"}
          title={"Search Result"}
          isOnlinePanVerification
          desktopWidth="626px"
          shiftDotGrid="md:left-20"
        />
      </div>
      <Element name="search-and-contact-us">
        <SearchAndContactUsSection />
      </Element>
      <Element name="search-results">
        <SearchResults />
      </Element>
    </div>
  );
}

export default withScrollToHash(Link22); // Use the HOC
