
import React, { useState } from 'react';
import Header from './components/Header';
import { Outlet, useLocation } from "react-router-dom";
import Footer from './components/Footer';
import banner from "../src/assets/images/startBanner.jpg";

function App() {
  const location = useLocation();
  const [showBanner, setShowBanner] = useState(true);

  const handleBanner = () => {
    setShowBanner(false);
  }

  return (
    <div className="app">
      {location.pathname === '/' && showBanner && (
        <div className="bg-gray-500 flex absolute w-full h-full justify-center items-center z-50 bg-opacity-50">
          <div className="bg-white w-[50%] gap-4 flex flex-col justify-center items-center">
            <img src={banner} alt="banner" className="object-cover" />
            <button onClick={handleBanner} className="bg-orange-500 text-white px-4 py-2 rounded-md mb-4">
              Continue
            </button>
          </div>
        </div>
      )}
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}

export default App;




