// PAMLayout.js
import React from "react";
import Payment from "./Payment";
import Acknowledgment from "./Acknowledge";
import ModeForSubmission from "./ModeForSubmission";

function PAMLayout({ language }) {
  return (
    <div className="grid gap-4 lg:grid-cols-2">
      <Payment language={language} />
      {/* Right side - Two cards stacked vertically */}
      <div className="flex flex-col justify-between ">
        <div className="p-4 bg-[#EAF4FA] lg:min-h-[613px] relative">
          {/* Placeholder for ServiceDescription component */}
          <Acknowledgment language={language} />
        </div>
        {/* Placeholder for ResponseToUser component */}
        <ModeForSubmission language={language} />
      </div>
    </div>
  );
}

export default PAMLayout;
