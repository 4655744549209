// FourCards.jsx
import React from 'react';
import { CardOneSvg, CardTwoSvg, CardThreeSvg, CardFourSvg, LinkArrowSvg } from '../assets/svgs';

function FourCards({ className, data, maxWidthDesktop, minWidthDesktop, maxWidthMobile, minWidthMobile, bgColor }) {
    const isMobile = window.innerWidth <= 768;
    const getResponsiveStyles = (link) => {
        return {
            maxWidth: isMobile ? link.linkMaxWidthMobile : '100%',
            minWidth: isMobile ? link.linkMinWidthMobile : '100%',
            padding: link.padding || '12px 0px 12px 0px'
        };
    };

    const getCardStyles = () => {
        const isMobile = window.innerWidth <= 1200;
        return {
            maxWidth: isMobile ? maxWidthMobile : maxWidthDesktop,
            minWidth: isMobile ? minWidthMobile : minWidthDesktop
        };
    };

    return (
        <div className='md:px-[68px] pb-12 flex  flex-col gap-6'>
            <div className={`flex ${bgColor} flex-col md:flex-row gap-6`}>
                {data.slice(0, 2)?.map((card, index) => (
                    <div key={index} style={getCardStyles()} className={`md:w-1/2 bg-[#EEF7ED] relative  py-8 px-[45px] ${className}`}>
                        <h1 className='text-base font-semibold leading-[22px]'>{card?.title}</h1>  {/* heading */}
                        <p className='text-sm font-semibold leading-[24px]'>{card.subTitle}</p> {/* sub heading */}
                        <div className='relative flex flex-col justify-between md:flex-row pt-4 pb-28 md:pb-0 gap-6 md:gap-24'>
                            <div className='flex flex-col gap-6 grow'>
                                {card.firstColumn.map((link, linkIndex) => (
                                    <a key={linkIndex} style={getResponsiveStyles(link.style)} href={link.href} className='flex justify-between py-[12px] w-full items-center border-b-[1px] border-b-black flex-row cursor-pointer gap-4'>
                                        <p className='text-sm' style={{ maxWidth: isMobile ? '100%' : link.style.linkMaxWidth, color: link.style.textColor }}>
                                            {link.text}
                                        </p>
                                        <LinkArrowSvg strokeColor='black' className='h-6 w-6' />
                                    </a>
                                ))}
                            </div>
                            <div className='flex flex-col gap-6 grow'>
                                {card.secondColumn.map((link, linkIndex) => (
                                    <a key={linkIndex} style={getResponsiveStyles(link.style)} href={link.href} className='flex justify-between py-[12px] w-full items-center border-b-[1px] border-b-black flex-row cursor-pointer gap-4'>
                                        <p className='text-sm' style={{ maxWidth: isMobile ? '100%' : link.style.linkMaxWidth, color: link.style.textColor }}>
                                            {link.text}
                                        </p>
                                        <LinkArrowSvg strokeColor='black' className='h-6 w-6' />
                                    </a>
                                ))}
                            </div>
                        </div>
                        {index === 0 ? <CardOneSvg className='absolute bottom-0 right-0 w-[170px] h-[93px]' /> : <CardTwoSvg className='absolute bottom-0 right-0 w-[170px] h-[93px]' />}
                    </div>
                ))}
            </div>
            <div className={`flex ${bgColor} flex-col md:flex-row gap-6`}>
                {data.slice(2)?.map((card, index) => (
                    <div key={index} style={getCardStyles()} className={`md:w-1/2 bg-[#EEF7ED] relative py-8 px-[45px] ${className}`}>
                        <h1 className='text-base font-semibold leading-[22px]'>{card.title}</h1>  {/* heading */}
                        <p className='text-sm font-semibold leading-[24px]'>{card.subTitle}</p> {/* sub heading */}
                        <div className='relative flex flex-col justify-between md:flex-row pt-4 gap-6 pb-28 md:pb-0 md:gap-24'>
                            <div className='flex flex-col gap-6 grow'>
                                {card.firstColumn.map((link, linkIndex) => (
                                    <a key={linkIndex} style={getResponsiveStyles(link.style)} href={link.href} className='flex justify-between py-[12px] w-full items-center border-b-[1px] border-b-black flex-row cursor-pointer gap-4'>
                                        <p className='text-sm' style={{ maxWidth: isMobile ? '100%' : link.style.linkMaxWidth, color: link.style.textColor }}>
                                            {link.text}
                                        </p>
                                        <LinkArrowSvg strokeColor='black' className={'h-6 w-6'} />
                                    </a>
                                ))}
                            </div>
                            <div className='flex flex-col gap-6 grow'>
                                {card.secondColumn.map((link, linkIndex) => (
                                    <a key={linkIndex} style={getResponsiveStyles(link.style)} href={link.href} className='flex justify-between py-[12px] w-full items-center border-b-[1px] border-b-black flex-row cursor-pointer gap-4'>
                                        <p className='text-sm' style={{ maxWidth: isMobile ? '100%' : link.style.linkMaxWidth, color: link.style.textColor }}>
                                            {link.text}
                                        </p>
                                        <LinkArrowSvg strokeColor='black' className={'h-6 w-6'} />
                                    </a>
                                ))}
                            </div>
                        </div>
                        {index === 0 ? <CardThreeSvg className='absolute bottom-0 right-7 w-[170px] h-[93px]' /> : <CardFourSvg className='absolute bottom-0 right-0 w-[170px] h-[93px]' />}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default FourCards;
