import React from 'react';
import { Element } from 'react-scroll';
import TDSHeroSection from '../../components/TDSHeroSection';
import SecondSection from './components/SecondSection';
import Overview from './components/Overview';
import FourCards from '../../components/FourCards';
import FileValidationUtility from './components/FileValidationUtility';
import SoftwareProviderCards from './components/SoftwareProviderCards';
import TDSTCS from './components/TDSTCS';
import withScrollToHash from '../../hooks/useScrollToHash';
import { Helmet } from 'react-helmet';
function Link6() {
    const firstRowCard = [
        {
            title: 'Quarterly Returns',
            subTitle: 'For regular statements pertaining to FY 2010-11 onwards:',
            firstColumn: [
                { text: 'File Format for Form 24Q Q1 to Q3 Version 6.2', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_24Q_Regular_Q1_to_Q3_Version_6.2_01062023_201011.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
                { text: 'File Format for Form 24Q (4th Quarter) Version 7.2', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_24Q_Regular_Q4_Version_7.2_10042024_2210112.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
                { text: 'File Format for Form 26Q Q1 TO Q4 Version 7.4', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_26Q_Regular_Q1_to_Q4_Version_7.4_21092023_201011.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
            ],
            secondColumn: [
                { text: 'File Format for Form 27EQ Q1 to Q4 Version 6.7', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_27EQ_Regular_Q1_to_Q4_Version_6.7_%2002112023_201011.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
                { text: 'File Format for Form 27Q Q1 to Q4 Version 7.2', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_27Q_Regular_Q1_to_Q4_Version_7.2_02112023_201011.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
            ]
        },
        {
            subTitle: 'For regular statements up to FY 2009-10:',
            firstColumn: [
                { text: 'File Format for Form 24Q Q1 to Q3 Version 5.8', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_24Q_Regular_Q1_to_Q3_Version_5.8_01062023_200910.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
                { text: 'File Format for Form 24Q (4th Quarter) Version 6.2', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_24Q_Regular_Q4_Version_6.2_01062023_200910.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
                { text: 'File Format for Form 26Q Q1 TO Q4 Version 5.8', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_26Q_Regular_Q1_to_Q4_Version_5.8_01062023_200910.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
            ],
            secondColumn: [
                { text: 'File Format for Form 27EQ Q1 to Q4 Version 5.8', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_27EQ_Regular_Q1_to_Q4_Version_5.8_%2001062023_200910.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
                { text: 'File Format for Form 27Q Q1 to Q4 Version 5.8', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_27Q_Regular_Q1_to_Q4_Version_5.8_01062023_200910.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
            ]
        }
    ];

    const secondRowCard = [
        {
            subTitle: 'For correction statements up to FY 2009-10:',
            firstColumn: [
                { text: 'File Format for Form 24Q correction Q1 to Q3 Version 6.1', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_24Q_Correction_Q1_Q3_Version_6.1_01062023_200910.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
                { text: 'File Format for Form 24Q correction (4th Quarter) Version 6.3', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_24Q_Correction_Q4-Version_6.3_01062023_200910.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
                { text: 'File Format for Form 26Q correction Q1 to Q4 Version 6.1', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_26Q_Correction_Q1_Q4_Version_6.1_01062023_200910.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
            ],
            secondColumn: [
                { text: 'File Format for Form 27EQ correction Q1 to Q4 Version 6.1', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_27EQ_Correction_Q1_Q4_Version_6.1_01062023_200910.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
                { text: 'File Format for Form 27Q correction Q1 to Q4 Version 6.0', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_27Q_Correction_Q1_Q4_Version_6.0_01062023_200910.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
            ]
        },
        {
            subTitle: 'For correction statements pertaining to FY 2010-11 onwards:',
            firstColumn: [
                { text: 'File Format for Form 24Q correction Q1 to Q3 Version 7.2', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_24Q_Correction_Q1_to_Q3_Version_7.2_01062023_201011.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
                { text: 'File Format for Form 24Q correction (4th Quarter) Version 7.3', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_24Q_Correction_Q4_Version_7.3_10042024_201011.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
                { text: 'File Format for Form 26Q correction Q1 to Q4 Version 7.6', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_26Q_Correction_Q1_to_Q4_Version_7.6_21092023_201011.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
            ],
            secondColumn: [
                { text: 'File Format for Form 27EQ correction Q1 to Q4 Version 7.2', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_27EQ_Correction_Q1_to_Q4_Version_7.2_%2002112023_201011.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
                { text: 'File Format for Form 27Q correction Q1 to Q4 Version 7.4', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_27Q_Correction_Q1_to_Q4_Version_7.4_02112023_201011.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
            ]
        }
    ];

    const combinedCardData = [...firstRowCard, ...secondRowCard];

    return (
        <>
            <Helmet>
                <title>eTDS/eTCS Services - Compliance & Filing Solutions | Protean</title>
                <meta name="description" content="Explore Protean's eTDS/eTCS services for compliance and tax filing solutions. Access resources and support for accurate and timely tax submissions." />
                <meta name="keywords" content="tds online payment, income tax tds online payment, e tds return, e tds, e tds payment, e tds filing. e tds payment online, tds e payment online, online tds deposited, tds tcs online payment" />
            </Helmet>
            <div className="flex flex-col gap-8">
                <TDSHeroSection />
                <Element name="second-section">
                    <SecondSection />
                </Element>
                <Element name="overview">
                    <Overview />
                </Element>
                <Element name="four-cards">
                    <FourCards
                        data={combinedCardData}
                        className="custom-class "
                        maxWidthDesktop="600px"
                        minWidthDesktop="640px"
                    />
                </Element>
                <Element name="file-validation-utility">
                    <FileValidationUtility />
                </Element>
                <Element name="tds-tcs">
                    <TDSTCS />
                </Element>
                <Element name="software-provider-cards">
                    <SoftwareProviderCards />
                </Element>
            </div>
        </>
    );
}

export default withScrollToHash(Link6); // Use the HOC
