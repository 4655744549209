import React, { useState } from 'react'
import { ArrowUpSvg, DropDownArrowSvg, ThreeDotsBulletSvg } from '../../../assets/svgs'

function Fee({ language }) {
    const isMobile = window.innerWidth <= 768;
    const [isOverviewOpen, setIsOverviewOpen] = useState(isMobile || true)

    const toggleOverview = () => {
        setIsOverviewOpen(!isOverviewOpen)
    }

    const textContent = {
        en: {
            title: "Fee",
            subTitleOne: "A) If Physical PAN Card is required:",
            subTitleOneDescription: "While submitting PAN application form, applicant will have to indicate whether physical PAN card is required. If applicant opts for physical PAN Card, then physical PAN card will be printed & dispatched at communication address. The e-PAN card in PDF format will be dispatched at e-mail ID mentioned in PAN application form, if the same is provided. Fees applicable are as follows:-",
            subTitleTwo: "B) If e-PAN Card is required:",
            subTitleTwoDescription: "While submitting PAN application form, applicant will have to indicate whether e-PAN card is required. If applicant opts for e-PAN Card, then e-PAN card in PDF format will be dispatched at e-mail ID mentioned in PAN application form, if the same is provided. Fees applicable are as follows:-",
            tableRowOneColOne: "Sr. No",
            tableRowOneColTwo: "Particulars",
            tableRowOneColThree: "Fees (exclusive of applicable taxes)(₹)",
            tableRowOneColFour: "Fees (inclusive of applicable taxes)(₹)",
            tableDetail: "PAN applications submitted at TIN Facilitation Centres / PAN Centres",
            howSoonDispatched: "How Soon a PAN Card is Dispatched",
            howSoonDispatchedDescription: "Normally two weeks are required to process the application & dispatch the PAN card, provided application is in order in all respects."
        },
        hi: {
            title: "शुल्क",
            subTitleOne: "A) यदि भौतिक पैन कार्ड आवश्यक है:",
            subTitleOneDescription: "पैन आवेदन पत्र जमा करते समय, आवेदक को इस बात का निर्देश देना होगा कि क्या भौतिक पैन कार्ड आवश्यक है। यदि आवेदक भौतिक पैन कार्ड का चयन करता है, तो भौतिक पैन कार्ड को प्रिंट किया और संचार पते पर भेज दिया जाएगा। पीडीएफ प्रारूप में ई-पैन कार्ड को भी भेज दिया जाएगा, अगर पैन आवेदन पत्र में यह ईमेल आईडी दी गई है। लागू शुल्क निम्नलिखित हैं:-",
            subTitleTwo: "B) यदि ई-पैन कार्ड आवश्यक है:",
            subTitleTwoDescription: "पैन आवेदन पत्र जमा करते समय, आवेदक को इस बात का निर्देश देना होगा कि क्या ई-पैन कार्ड आवश्यक है। यदि आवेदक ई-पैन कार्ड का चयन करता है, तो ई-पैन कार्ड को पीडीएफ प्रारूप में ईमेल आईडी पर भेज दिया जाएगा, अगर पैन आवेदन पत्र में यह ईमेल आईडी दी गई है। लागू शुल्क निम्नलिखित हैं:-",
            tableRowOneColOne: "क्रमांक",
            tableRowOneColTwo: "विशेषताएँ",
            tableRowOneColThree: "शुल्क (लागू टैक्स को अलग किया गया)(₹)",
            tableRowOneColFour: "शुल्क (लागू टैक्स सम्मिलित किए गए)(₹)",
            tableDetail: "टीआईएन सुविधा केंद्रों / पैन केंद्रों पर जमा किए गए पैन आवेदन",
            howSoonDispatched: "पैन कार्ड कितनी जल्दी भेजा जाता है",
            howSoonDispatchedDescription: "सामान्यतः आवेदन को प्रोसेस करने और पैन कार्ड को भेजने के लिए दो सप्ताह की आवश्यकता होती है, यदि आवेदन सभी दृष्टिकोणों से ठीक है।"
        }
    };


    return (
        <section className="mb-8">
            <div className='px-4 md:px-0 flex items-center justify-between'>
                <h1 className='flex flex-row text-[26px] font-semibold gap-2 leading-5'>
                    <ThreeDotsBulletSvg fillColor={'#6F368B'} className={'w-[34px] h-[17px]'} />
                    Fee
                </h1>
                {isMobile && <button onClick={toggleOverview} className=''>
                    {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                </button>}
            </div>
            {isOverviewOpen &&
                <div>
                    <div className='md:max-w-[50vw] p-6 md:pb-8'>
                        <p className="text-lg font-semibold mb-2 md:px-8 md:pt-2">
                            A) If Physical PAN Card is required:
                        </p>
                        <p className='text-sm font-normal leading-6 md:px-8 md:pb-2'>
                            While submitting PAN application form, applicant will have to indicate whether physical PAN card is required. If applicant opts for physical PAN Card, then physical PAN card will be printed & dispatched at communication address. The e-PAN card in PDF format will be dispatched at e-mail ID mentioned in PAN application form, if the same is provided. Fees applicable are as follows:-
                        </p>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                        <table className="table-auto w-full">
                            <thead className='bg-[#6F368B] bg-opacity-20'>
                                <tr>
                                    <th className="px-4 py-2 text-sm font-semibold leading-6">Sr. No</th>
                                    <th className="px-4 py-2 text-sm font-semibold leading-6">Particulars</th>
                                    <th className="px-4 py-2 text-sm font-semibold leading-6">Fees (exclusive of applicable taxes)(₹)</th>
                                    <th className="px-4 py-2 text-sm font-semibold leading-6">Fees (inclusive of applicable taxes)(₹)</th>
                                </tr>
                            </thead>
                            <tbody className='bg-[#6F368B] bg-opacity-5'>
                                <tr>
                                    <td colSpan="4" className="px-4 py-2 text-sm font-semibold leading-6">PAN applications submitted at TIN Facilitation Centres / PAN Centres</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-2">1</td>
                                    <td className="px-4 py-2">Dispatch of physical PAN Card in India (Communication address is Indian address)</td>
                                    <td className="px-4 py-2">91</td>
                                    <td className="px-4 py-2">107</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-2">2</td>
                                    <td className="px-4 py-2">Dispatch of physical PAN Card outside India (where foreign address is provided as address for communication)</td>
                                    <td className="px-4 py-2">862</td>
                                    <td className="px-4 py-2">1,017</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="table-auto w-full">
                            <thead className='bg-[#6F368B] bg-opacity-20'>
                                <tr>
                                    <th className="px-4 py-2 text-sm font-semibold leading-6">Sr. No</th>
                                    <th className="px-4 py-2 text-sm font-semibold leading-6">Particulars</th>
                                    <th className="px-4 py-2 text-sm font-semibold leading-6">Fees (exclusive of applicable taxes)(₹)</th>
                                    <th className="px-4 py-2 text-sm font-semibold leading-6">Fees (inclusive of applicable taxes)(₹)</th>
                                </tr>
                            </thead>
                            <tbody className='bg-[#6F368B] bg-opacity-5'>
                                <tr>
                                    <td colSpan="4" className="px-4 py-2 text-sm font-semibold leading-6">PAN applications submitted Online using physical mode (i.e. Physical documents forwarded to Protean.)</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-2">1</td>
                                    <td className="px-4 py-2">Dispatch of physical PAN Card in India (Communication address is Indian address)</td>
                                    <td className="px-4 py-2">91</td>
                                    <td className="px-4 py-2">107</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-2">2</td>
                                    <td className="px-4 py-2">Dispatch of physical PAN Card outside India (where foreign address is provided as address for communication)</td>
                                    <td className="px-4 py-2">862</td>
                                    <td className="px-4 py-2">1,017</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>}
            {isOverviewOpen && <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <table className="table-auto w-full">
                    <thead className='bg-[#6F368B] bg-opacity-20'>
                        <tr>
                            <th className="px-4 py-2 text-sm font-semibold leading-6">Sr. No</th>
                            <th className="px-4 py-2 text-sm font-semibold leading-6">Particulars</th>
                            <th className="px-4 py-2 text-sm font-semibold leading-6">Fees (exclusive of applicable taxes)(₹)</th>
                            <th className="px-4 py-2 text-sm font-semibold leading-6">Fees (inclusive of applicable taxes)(₹)</th>
                        </tr>
                    </thead>
                    <tbody className='bg-[#6F368B] bg-opacity-5'>
                        <tr>
                            <td colSpan="4" className="px-4 py-2 text-sm font-semibold leading-6">PAN applications submitted Online through paperless modes (e-KYC & e-Sign / e-Sign scanned based / DSC scanned based):</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2">1</td>
                            <td className="px-4 py-2">Dispatch of physical PAN Card in India (Communication address is Indian address)</td>
                            <td className="px-4 py-2">86</td>
                            <td className="px-4 py-2">101</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2">2</td>
                            <td className="px-4 py-2">Dispatch of physical PAN Card outside India (where foreign address is provided as address for communication)</td>
                            <td className="px-4 py-2">857</td>
                            <td className="px-4 py-2">1,011</td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-auto w-full">
                    <thead className='bg-[#6F368B] bg-opacity-20'>
                        <tr>
                            <th className="px-4 py-2 text-sm font-semibold leading-6">Sr. No</th>
                            <th className="px-4 py-2 text-sm font-semibold leading-6">Particulars</th>
                            <th className="px-4 py-2 text-sm font-semibold leading-6">Fees (exclusive of applicable taxes)(₹)</th>
                            <th className="px-4 py-2 text-sm font-semibold leading-6">Fees (inclusive of applicable taxes)(₹)</th>
                        </tr>
                    </thead>
                    <tbody className='bg-[#6F368B] bg-opacity-5'>
                        <tr>
                            <td colSpan="4" className="px-4 py-2 text-sm font-semibold leading-6">Request for Reprint of PAN card submitted through separate online link</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2">1</td>
                            <td className="px-4 py-2">Dispatch of physical PAN Card in India (Communication address is Indian address)</td>
                            <td className="px-4 py-2">42</td>
                            <td className="px-4 py-2">50</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2">2</td>
                            <td className="px-4 py-2">Dispatch of physical PAN Card outside India (where foreign address is provided as address for communication)</td>
                            <td className="px-4 py-2">813</td>
                            <td className="px-4 py-2">959</td>
                        </tr>
                    </tbody>
                </table>
                <p style={{ color: 'red', whiteSpace: 'nowrap', fontSize: '0.8em' }}>
                    eSign charges Rs. 5.90 (including applicable taxes) is applicable if eKYC and/or e-Sign Service is selected
                </p>
            </div>}
            {isOverviewOpen &&
                <div>
                    <div className='md:max-w-[50vw] md:pb-8 p-6'>
                        <p className="text-lg font-semibold mb-2 md:px-8 md:pt-2">
                            B) If Physical PAN Card is not required:
                        </p>
                        <p className='text-sm font-normal leading-6 md:px-8 md:pb-2'>
                            PAN applicant will have to indicate at the time of submission of PAN application, if the physical PAN Card is not required. In such cases, email ID will be mandatory & e-PAN Card will be sent to the PAN applicant at the email ID. Physical PAN Card will not be dispatched in such cases. Fees applicable are as follows:-
                        </p>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                        <table className="table-auto w-full">
                            <thead className='bg-[#6F368B] bg-opacity-20'>
                                <tr>
                                    <th className="px-4 py-2 text-sm font-semibold leading-6">Sr. No</th>
                                    <th className="px-4 py-2 text-sm font-semibold leading-6">Particulars</th>
                                    <th className="px-4 py-2 text-sm font-semibold leading-6">Fees (exclusive of applicable taxes)(₹)</th>
                                    <th className="px-4 py-2 text-sm font-semibold leading-6">Fees (inclusive of applicable taxes)(₹)</th>
                                </tr>
                            </thead>
                            <tbody className='bg-[#6F368B] bg-opacity-5'>
                                <tr>
                                    <td colSpan="4" className="px-4 py-2 text-sm font-semibold leading-6">PAN applications submitted at TIN Facilitation Centres / PAN Centres</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-2">1</td>
                                    <td className="px-4 py-2">e-PAN Card will be dispatched at the email ID mentioned in the PAN application form</td>
                                    <td className="px-4 py-2">61</td>
                                    <td className="px-4 py-2">72</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="table-auto w-full">
                            <thead className='bg-[#6F368B] bg-opacity-20'>
                                <tr>
                                    <th className="px-4 py-2 text-sm font-semibold leading-6">Sr. No</th>
                                    <th className="px-4 py-2 text-sm font-semibold leading-6">Particulars</th>
                                    <th className="px-4 py-2 text-sm font-semibold leading-6">Fees (exclusive of applicable taxes)(₹)</th>
                                    <th className="px-4 py-2 text-sm font-semibold leading-6">Fees (inclusive of applicable taxes)(₹)</th>
                                </tr>
                            </thead>
                            <tbody className='bg-[#6F368B] bg-opacity-5'>
                                <tr>
                                    <td colSpan="4" className="px-4 py-2 text-sm font-semibold leading-6">PAN applications submitted Online using physical mode (i.e. Physical documents forwarded to Protean.)</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-2">1</td>
                                    <td className="px-4 py-2">e-PAN Card will be dispatched at the email ID mentioned in the PAN application form</td>
                                    <td className="px-4 py-2">61</td>
                                    <td className="px-4 py-2">72</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>}
            {isOverviewOpen && <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <table className="table-auto w-full">
                    <thead className='bg-[#6F368B] bg-opacity-20'>
                        <tr>
                            <th className="px-4 py-2 text-sm font-semibold leading-6">Sr. No</th>
                            <th className="px-4 py-2 text-sm font-semibold leading-6">Particulars</th>
                            <th className="px-4 py-2 text-sm font-semibold leading-6">Fees (exclusive of applicable taxes)(₹)</th>
                            <th className="px-4 py-2 text-sm font-semibold leading-6">Fees (inclusive of applicable taxes)(₹)</th>
                        </tr>
                    </thead>
                    <tbody className='bg-[#6F368B] bg-opacity-5'>
                        <tr>
                            <td colSpan="4" className="px-4 py-2 text-sm font-semibold leading-6">PAN applications submitted Online through paperless modes (e-KYC & e-Sign / e-Sign scanned based / DSC scanned based):</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2">1</td>
                            <td className="px-4 py-2">e-PAN Card will be dispatched at the email ID mentioned in the PAN application form</td>
                            <td className="px-4 py-2">56</td>
                            <td className="px-4 py-2">66</td>
                        </tr>
                    </tbody>
                </table>
                <div className="p-8 bg-[#54AD47] bg-opacity-10">
                    <div className='px-4 md:px-0 flex items-center justify-between pb-6'>
                        <h1 className='flex flex-row text-[26px] font-semibold gap-2 leading-5'>
                            <ThreeDotsBulletSvg fillColor={'#54AD47'} className={'w-[34px] h-[17px]'} />
                            How Soon a PAN Card is Dispatched
                        </h1>
                    </div>
                    <p className='text-sm leading-6 font-normal'>Normally two weeks are required to process the application & dispatch the PAN card, provided application is in order in all respects.</p>
                </div>

                <p style={{ color: 'red', whiteSpace: 'nowrap', fontSize: '0.8em' }}>
                    eSign charges Rs. 5.90 (including applicable taxes) is applicable if eKYC and/or e-Sign Service is selected
                </p>
            </div>}
        </section>
    )
}

export default Fee