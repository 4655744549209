import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import DesktopBackground from "../../../assets/images/MoreInfoBG.png";
import { useState } from "react";
import TypesOfPan from "./MorInfoContent/TypesOfPan";
import ProccessingFee from "./MorInfoContent/ProccessingFee";
import DocumentsRequired from "./MorInfoContent/Supporting";
import HowToApply from "./MorInfoContent/HowToApply";
import ProcessingTime from "./MorInfoContent/ProcessingTime";
import Communications from "./MorInfoContent/Communications";

const tabComponents = {
  "Types of PAN Application": TypesOfPan,
  "How to apply": HowToApply,
  "Supporting Documents": DocumentsRequired,
  "Communications": Communications,
  "Processing Fee": ProccessingFee,
  "Processing Time": ProcessingTime,
};

const MoreOnPan = () => {
  const { width } = useWindowDimensions();
  const isMobile = width <= 768;

  const [state, setState] = useState({
    activeTab: "Types of PAN Application",
    selectedLanguage: "English",
    isTabDropdownOpen: false,
    isLanguageDropdownOpen: false,
  });

  const handleLanguageChange = (language) => {
    setState({
      ...state,
      selectedLanguage: language,
      activeTab: "Types of PAN Application", // Reset to the first tab of the selected language
      isLanguageDropdownOpen: false,
    });
  };

  const handleTabChange = (tab) => {
    setState((prevState) => ({
      ...prevState,
      activeTab: tab,
      isTabDropdownOpen: false,
    }));
  };

  const toggleDropdown = (dropdown) => {
    setState((prevState) => ({
      ...prevState,
      [dropdown]: !prevState[dropdown],
    }));
  };

  const ActiveComponent = tabComponents[state.activeTab];

  return (
    <section
      className="w-full bg-no-repeat bg-cover py-10 px-4 lg:py-20 lg:px-[70px] flex flex-col relative"
      style={{
        backgroundImage: `url(${isMobile ? "none" : DesktopBackground})`,
      }}
    >
      {/* Header */}
      <div className="flex flex-col lg:flex-row lg:justify-between items-start lg:items-center mb-8">
        <h1 className="text-[26px] lg:text-[32px] font-semibold text-black">
          More info on PAN
        </h1>
        <div className="relative">
          <button
            onClick={() => toggleDropdown('isLanguageDropdownOpen')}
            className="bg-[#E43625] text-white py-2 px-4 rounded-[5px] text-[15px] lg:text-[16px] flex items-center gap-2"
          >
            {state.selectedLanguage}
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                state.isLanguageDropdownOpen ? "rotate-180" : ""
              } transition-transform`}
            >
              <path
                d="M4 6L8 10L12 6"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          {state.isLanguageDropdownOpen && (
            <ul className="absolute bg-white shadow-lg rounded-[5px] mt-2 right-0 z-10">
              {["English", "Hindi"].map((language, index) => (
                <li key={index}>
                  <button
                    onClick={() => handleLanguageChange(language)}
                    className="block px-4 py-2 text-black text-[15px] lg:text-[16px] w-full text-left hover:bg-gray-200"
                  >
                    {language}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {/* Tabs */}
      <div className="mb-8">
        {isMobile ? (
          <div className="relative">
            <button
              onClick={() => toggleDropdown('isTabDropdownOpen')}
              className="border py-2 px-4 rounded-[5px] text-[15px] lg:text-[16px] w-full text-left flex items-center justify-between"
            >
              {state.activeTab}
              <svg
                width="16"
                height="16"
                viewBox="0 16 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`${
                  state.isTabDropdownOpen ? "rotate-180" : ""
                } transition-transform`}
              >
                <path
                  d="M4 6L8 10L12 6"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            {state.isTabDropdownOpen && (
              <ul className="absolute bg-white shadow-lg rounded-[5px] mt-2 z-10 w-full">
                {Object.keys(tabComponents).map((tab, index) => (
                  <li key={index}>
                    <button
                      onClick={() => handleTabChange(tab)}
                      className={`block px-4 py-2 text-black text-[15px] lg:text-[16px] w-full text-left hover:bg-gray-200 ${
                        state.activeTab === tab ? "font-bold" : ""
                      }`}
                    >
                      {tab}
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ) : (
          <div className="flex border-b border-gray-400">
            {Object.keys(tabComponents).map((tab, index) => (
              <button
                key={index}
                onClick={() => handleTabChange(tab)}
                className={`py-2 px-4 lg:px-6 text-[15px] lg:text-[16px] ${
                  state.activeTab === tab
                    ? "border-b-2 border-[#E43625] text-[#E43625]"
                    : "text-black"
                }`}
              >
                {tab}
              </button>
            ))}
          </div>
        )}
      </div>

      {/* Content */}
      <div className="flex flex-col lg:flex-row">
        {/* Left Content */}
        <div className="lg:w-3/5 pr-0 lg:pr-10">
          {ActiveComponent !== "string" && <ActiveComponent language={state.selectedLanguage} />}
        </div>

        {/* Right Content */}
        <div className="lg:w-2/5 mt-8 lg:mt-0">
          {/* Add any other right-side content here */}
        </div>
      </div>
    </section>
  );
};

export default MoreOnPan;
