import React from 'react';

const DocumentsRequired = ({ language, isMobile }) => {
  const content = {
    English: (
      <section className="mb-8">
        <div className='px-4 md:px-0 flex items-center justify-between'>
          <h1 className='flex flex-row text-[26px] font-semibold gap-2 leading-5'>
            Documents Required
          </h1>
        </div>
        <div>
          <div className='md:max-w-[50vw] p-6 md:pb-8'>
            <p className='text-sm font-normal leading-6 md:px-8 md:pb-2'>
              Applicant should ensure that the necessary supporting documents (as specified under Rule 114 of Income Tax Department) are submitted along with the application. The details of the documents required are also provided in the application form. Name mentioned in Application form and Name in the Proof of Identity /Proof of Address should match exactly.
            </p>
          </div>
        </div>
      </section>
    ),
    Hindi: (
      <section className="mb-8">
        <div className='px-4 md:px-0 flex items-center justify-between'>
          <h1 className='flex flex-row text-[26px] font-semibold gap-2 leading-5'>
            आवश्यक दस्तावेज़
          </h1>
        </div>
        <div>
          <div className='md:max-w-[50vw] p-6 md:pb-8'>
            <p className='text-sm font-normal leading-6 md:px-8 md:pb-2'>
              आवेदक को सुनिश्चित करना चाहिए कि आवेदन के साथ आवश्यक समर्थन दस्तावेज़ (आयकर विभाग के नियम 114 के तहत निर्दिष्ट) जमा किए गए हैं। आवश्यक दस्तावेजों का विवरण आवेदन पत्र में भी प्रदान किया गया है। आवेदन पत्र में उल्लेखित नाम और पहचान प्रमाण/पता प्रमाण में नाम बिल्कुल मेल खाना चाहिए।
            </p>
          </div>
        </div>
      </section>
    )
  };

  return <div>{content[language]}</div>;
};

export default DocumentsRequired;
