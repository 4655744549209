// SearchHeroSection.jsx
import React from "react";
import { SearchSvg, SearchSvg2 } from "../assets/svgs";
import ImageDesk from "../assets/images/SearchBanner.png";
import ImageMob from "../assets/images/SearchBannerMob.png";
import Banner2 from "../assets/images/SearchBanner2.png";
import Banner2Mobile from "../assets/images/SearcBanenrMobile2.png"

const SearchHeroSection = ({ isPage2 }) => {
  const desktopImage = isPage2 ? Banner2 : ImageDesk;
  const mobileImage = isPage2 ? Banner2Mobile : ImageMob;

  return (
    <div className="bg-[#F7F7F7] md:min-h-[60vh] justify-between flex-col h-full flex md:flex-row relative z-10">
      <SearchSvg className="max-w-[200px] md:max-w-[400px] absolute bottom-0 hidden md:flex" />
      <div className="bg-[#F7F7F7] flex md:hidden justify-center">
        <img
          src={mobileImage}
          alt="Banner Mobile"
          className="max-w-[308px] flex md:hidden z-10"
        />
      </div>

      <div className="relative px-4 md:px-0 flex flex-col md:self-center">
        <div className="md:pl-[68px] flex flex-col pb-[10px] md:pb-[87px]">
          <h1 className="font-semibold text-[#1D1D1B] text-[32px] leading-[43.58px] md:text-[56px] md:leading-[68px] md:max-w-[550px] md:pl-10 pt-[30px] md:pt-0">
            {isPage2 ? "TIN Facilities Center Search" : "PAN Center Search"}
          </h1>
        </div>
        <div className="self-start mb-[66px] flex md:hidden md:mb-0 md:mt-0">
          <SearchSvg2 />
        </div>
      </div>
      <img

        width={isPage2 ? '519px' : '393px'}
        height={isPage2 ? '440px' : '517px'}
        src={desktopImage}
        alt="Banner Desktop"
        className="aspect-square hidden md:flex z-10"
      />
    </div>
  );
};

export default SearchHeroSection;
