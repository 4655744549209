import React from "react";

const branchData = [
  {
    title: "New Delhi",
    address:
      "409/410, Ashoka Estate Building, 4th floor, Barakhamba Road, Connaught Place, New Delhi 110 001.",
    telephone: "(011) 2370 5418 / 2335 3817",
    fax: "(011) 2335 3756",
    bgColor: "#E7F1FB", // Light Blue
  },
  {
    title: "Chennai",
    address:
      "6A, 6th Floor, Kences Towers, #1 Ramkrishna Street, North Usman Road, T. Nagar, Chennai - 600 017",
    telephone: "(044) 2814 3917/18",
    fax: "(044) 2814 4593",
    bgColor: "#F1EBF3", // Light Purple
  },
  {
    title: "Kolkata",
    address:
      "5th Floor, The Millennium, Flat No. 5W, 235/2A, Acharya Jagdish Chandra Bose Road, Kolkata - 700 020",
    telephone: "(033) 2281 4661 / 2290 1396",
    fax: "(033) 2289 1945",
    bgColor: "#FBE9DD", // Light Peach
  },
  {
    title: "Ahmedabad",
    address:
      "Unit No. 407, 4th floor, 3rd Eye One Commercial Complex Co-op. Soc. Ltd., C. G. Road, Near Panchvati Circle, Ahmedabad, Gujarat– 380006",
    telephone: "(079) 2646 1376",
    fax: "(079) 2646 1375",
    bgColor: "#F3F7F0", // Light Green
  },
];

const BranchOfficesGrid = () => {
  return (
    <section className="lg:px-[70px] w-full pb-8">
      <div className="flex flex-col w-full">
        <div className="w-full p-4">
          <div className="flex items-center  gap-2">
            <svg
              width="34"
              height="17"
              viewBox="0 0 34 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
                fill="#6F368B"
              />
              <path
                d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
                fill="#6F368B"
              />
              <circle cx="25.5" cy="8.5" r="8.5" fill="#6F368B" />
            </svg>
            <h2 className="text-2xl font-semibold">Branch Offices</h2>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
          {branchData.map((branch, index) => (
            <div
              key={index}
              className="p-4 text-[15px] "
              style={{ backgroundColor: branch.bgColor }}
            >
              <h3 className="font-semibold text-lg">{branch.title}</h3>

              <div className="flex flex-col gap-2 mt-4">
                <span className="font-semibold">Address:</span>
                <p> {branch.address}</p>
                <span className="font-semibold">Telephone:</span>
                <p> {branch.telephone}</p>
                <span className="font-semibold">Fax Number:</span>
                <p> {branch.fax}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BranchOfficesGrid;
