import React, { useState } from 'react';
import { ArrowUpSvg, DropDownArrowSvg, ThreeDotsBulletSvg } from '../../../../assets/svgs';

const TypesOfPan = ({ language, isMobile }) => {
  const [isOverviewOpen, setIsOverviewOpen] = useState(true);
  const toggleOverview = () => setIsOverviewOpen(!isOverviewOpen);

  const content = {
    English: (
      <div className="text-[15px] lg:text-[16px] text-black font-light mb-4">
        <h2 className="text-[18px] lg:text-[22px] font-semibold text-black mb-4">
          There are two types of PAN applications:
        </h2>
        <ol className="list-decimal pl-4">
          <li className="mb-4">
            <strong>Application for allotment of PAN:</strong>
            <p className="mt-2">
              This application form should be used when the applicant has never applied for a PAN or does not have PAN allotted to him. Applicant may visit ITD’s website.{" "}
              <a href="http://www.incometaxindia.gov.in" target="_blank" rel="noopener noreferrer" className="text-[#E43625]">
                www.incometaxindia.gov.in
              </a>{" "}
              to find whether a PAN has been allotted to him or not.
            </p>
            <p className="mt-2">
              Following forms have been notified by ITD (w.e.f. November 1, 2011) for submitting applications for allotment of new PAN:
            </p>
            <ul className="list-disc pl-4 mt-2">
              <li>
                <strong>FORM 49A:</strong> To be filled by Indian citizens including those who are located outside India.
              </li>
              <li>
                <strong>FORM 49AA:</strong> To be filled by foreign citizens.
              </li>
            </ul>
          </li>
          <li>
            <strong>Application for new PAN Card or/and Changes or Corrections in PAN Data:</strong>
            <p className="mt-2">
              Those who have already obtained the PAN and wish to obtain the new PAN card or want to make some changes / corrections in their PAN data, are required to submit their applications in the following form prescribed by ITD:
            </p>
            <p className="mt-2">
              <strong>REQUEST FOR NEW PAN CARD OR/AND CHANGES OR CORRECTION IN PAN DATA:</strong> The same form can be used by Indian as well as foreign citizens.
            </p>
            <p className="mt-2">
              A new PAN card bearing the same PAN but updated information is issued to applicant in such case.
            </p>
          </li>
        </ol>
    
      </div>
    ),
    Hindi: (
      <div className="text-[15px] lg:text-[16px] text-black font-light mb-4">
        <h2 className="text-[18px] lg:text-[22px] font-semibold text-black mb-4">
          पैन आवेदन के दो प्रकार हैं:
        </h2>
        <ol className="list-decimal pl-4">
          <li className="mb-4">
            <strong>पैन आवंटन के लिए आवेदन:</strong>
            <p className="mt-2">
              यह आवेदन फॉर्म तब उपयोग किया जाना चाहिए जब आवेदक ने पहले कभी पैन के लिए आवेदन नहीं किया हो या उसे पैन आवंटित नहीं किया गया हो। आवेदक आईटीडी की वेबसाइट पर जा सकता है।{" "}
              <a href="http://www.incometaxindia.gov.in" target="_blank" rel="noopener noreferrer" className="text-[#E43625]">
                www.incometaxindia.gov.in
              </a>{" "}
              यह जानने के लिए कि उसे पैन आवंटित किया गया है या नहीं।
            </p>
            <p className="mt-2">
              आईटीडी द्वारा नए पैन आवंटन के लिए आवेदन जमा करने के लिए निम्नलिखित फॉर्म अधिसूचित किए गए हैं (1 नवंबर, 2011 से प्रभावी):
            </p>
            <ul className="list-disc pl-4 mt-2">
              <li>
                <strong>फॉर्म 49A:</strong> भारतीय नागरिकों द्वारा भरा जाना चाहिए, जिसमें वे भी शामिल हैं जो भारत के बाहर स्थित हैं।
              </li>
              <li>
                <strong>फॉर्म 49AA:</strong> विदेशी नागरिकों द्वारा भरा जाना चाहिए।
              </li>
            </ul>
          </li>
          <li>
            <strong>नए पैन कार्ड या/और पैन डेटा में परिवर्तन या सुधार के लिए आवेदन:</strong>
            <p className="mt-2">
              वे जिन्होंने पहले ही पैन प्राप्त कर लिया है और नया पैन कार्ड प्राप्त करना चाहते हैं या अपने पैन डेटा में कुछ परिवर्तन / सुधार करना चाहते हैं, उन्हें आईटीडी द्वारा निर्धारित निम्नलिखित फॉर्म में अपने आवेदन जमा करने होंगे:
            </p>
            <p className="mt-2">
              <strong>नए पैन कार्ड या/और पैन डेटा में परिवर्तन या सुधार के लिए अनुरोध:</strong> भारतीय नागरिकों के साथ-साथ विदेशी नागरिकों द्वारा भी वही फॉर्म उपयोग किया जा सकता है।
            </p>
            <p className="mt-2">
              ऐसे मामले में आवेदक को एक नया पैन कार्ड जारी किया जाता है जिसमें समान पैन लेकिन अद्यतन जानकारी होती है।
            </p>
          </li>
        </ol>

      </div>
    )
  };

  return <div>{content[language]}</div>;
};

export default TypesOfPan;
