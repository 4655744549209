import React from "react";
import { Link14Graphic1 } from "../../../assets/svgs";

const Citizen = () => {
  return (
    <div className="bg-[#EAF4FA] max-w-[100vw] p-4 lg:px-4 ">
      <div className="flex gap-2 items-start flex-wrap md:flex-nowrap">
        <div className="mt-2">
          <svg
            width="34"
            height="17"
            viewBox="0 0 34 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
              fill="#3091D0"
            />
            <path
              d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
              fill="#3091D0"
            />
            <circle cx="25.5" cy="8.5" r="8.5" fill="#3091D0" />
          </svg>
        </div>

        <div>
          <p className="text-[20px] font-semibold text-black text-left flex items-center gap-2">
            For Categories other than Individuals & HUF i.e. Firm, BOI, AOP, AOP
            (Trust), Local Authority, Company, Limited Liability
            Partnership,Artificial Juridical Person
          </p>
        </div>
      </div>
      <div className=" bg-white   overflow-hidden mt-4">
        <div className="overflow-x-auto">
          <table className="min-w-full border-separate border-spacing-0 text-[12px] md:text-[14px]">
            <thead className="bg-[#D6E9F6]">
              <tr>
                <th className="p-2 sm:p-4 border text-left text-[14px]">
                  Proof of Identity
                </th>
                <th className="p-2 sm:p-4 border text-left text-[14px]">
                  Proof of Address (Proof should contain the address mentioned
                  in the PAN application form)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-2  border align-top lg:min-w-[176px]">
                  <p className="font-semibold"> Attestation not required -</p>
                  <ol className="list-decimal pl-5 space-y-6 lg:min-w-[176px] mt-4">
                    <li>Copy of passport, or</li>
                    <li>
                      Copy of Person of Indian Origin (PIO) card issued by
                      Government of India, or
                    </li>
                    <li>
                      Copy of Overseas Citizen of India (OCI) card issued by
                      Government of India, Or
                    </li>

                    <li className="flex flex-col gap-3">
                      <p className="font-semibold">Attestation required -</p>
                      4. Copy of other national or citizenship Identification
                      Number or Taxpayer Identification Number duly attested by
                      “Apostille” (in respect of countries which are signatories
                      to the Hague Convention of 1961) or by the Indian Embassy
                      or High Commission or Consulate in the country where the
                      applicant is located or authorised officials of overseas
                      branches of Scheduled Banks registered in India (in
                      prescribed format) .
                    </li>
                  </ol>
                </td>
                <td className="p-2  border align-top lg:min-w-[176px]">
                <p className="font-semibold"> Attestation not required -</p>
                  <ol className="list-decimal pl-5 space-y-6 lg:min-w-[176px] mt-4">
                    <li>Copy of Passport, or</li>
                    <li>
                      Copy of Person of Indian Origin (PIO) card issued by
                      Government of India, or
                    </li>
                    <li>
                      Copy of Overseas Citizen of India (OCI) card issued by
                      Government of India, orr
                    </li>
                    <li>
                      Copy of Bank account statement in the country of
                      residence, or
                    </li>
                    <li>
                      Copy of Non-resident External (NRE) bank account statement
                      in India, or
                    </li>
                    <li>
                      Copy of Certificate of Residence in India or Residential
                      permit issued by the State Police Authorities, or
                    </li>
                    <li>
                      Copy of Visa granted & Copy of appointment letter or
                      contract from Indian Company & Certificate (in original)
                      of Indian address issued by the employer.
                    </li>
                    <li className="flex flex-col gap-3">
                      <p className="font-semibold"> Attestation required -</p>
                      Copy of other national or citizenship Identification
                      Number or Taxpayer Identification Number duly attested by
                      “Apostille” (in respect of countries which are signatories
                      to the Hague Convention of 1961) or by the Indian Embassy
                      or High Commission or Consulate in the country where the
                      applicant is located or authorised officials of overseas
                      branches of Scheduled Banks registered in India (in
                      prescribed format).
                    </li>
                  </ol>
                  <p className="font-light mt-6 px-2">
                    Note: In case Office Address (of India) is mentioned in
                    application made by foreign citizens, then it is mandatory
                    to provide following documents as proof for office address
                    in addition to any of the above residence proof: I. Copy of
                    appointment letter/contract from Indian Company and II.
                    Certificate (in original) of address in India of applicant
                    issued by authorized signatory of employer on employer's
                    letter head mentioning the PAN of the employer and III. Copy
                    of PAN card for the PAN mentioned in the employer's
                    certificate.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Citizen;


