import React from "react";
import { LinkArrowSvg } from "../assets/svgs";

const DynamicList = ({ listData, className }) => {
  return (
    <div className={`flex gap-4 flex-col lg:flex-row ${className}`}>
      <ul className="dynamic-list">
        {listData.map((item, index) => (
          <li key={index} className="list-item ">
            <p className={`${className}`}>{item.text}</p>
            <LinkArrowSvg strokeColor='black' />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DynamicList;
