import React from "react";
import { Element } from "react-scroll";
import PANHeroSection from "../../components/PANHeroSection";
import AOCodes from "./components/AOCodes";
import ApplyOnline from "./components/ApplyOnline";
import Form49AA from "./components/Form49AA";
import Form49A from "./components/Form49A";
import ReprintPanCard from "./components/ReprintPanCard";
import CorrectionInPAN from "./components/CorrectionInPAN";
import DSCBases from "./components/DSCBases";
import DocumentsRequired from "./components/DocumentsRequired";
import MoreOnPan from "./components/MoreInfoOnPan";
import FAQComponent from "../../components/Faqs.jsx";
import SearchAndContactUsSection from "../../components/SearchAndContact";
import withScrollToHash from "../../hooks/useScrollToHash";
import { Helmet } from "react-helmet";

const Link2 = () => {
  const linkData = {
    "General": [
      {
        text: "What is PAN?",
        content: "Permanent Account Number (PAN) is a ten-digit alphanumeric identifier, issued by Income Tax Department. Each assessee (e.g. individual, firm, company etc.) is issued a unique PAN.",
      },
      {
        text: "Who can apply for PAN?",
        content: "All existing assessees or taxpayers or persons who are required to file a return of income, even on behalf of others, must have a PAN. Any person, who intends to enter into economic or financial transactions where quoting PAN is mandatory, must also have a PAN.",
      },
      {
        text: "Who can apply on behalf of minor, lunatic, idiot, mentally retarded, deceased and wards of court?",
        content: "Section 160 of IT Act, 1961 provides that a minor, lunatic, idiot, mentally retarded, deceased, wards of court and such other persons may be represented through a Representative Assessee. In such cases:\n\n- In the application for PAN, details of the minor, lunatic, idiot, mentally retarded, deceased, wards of court, etc. should be provided.\n- Details of representative assessee have to be provided in item 14 of the application for PAN.",
      },
      {
        text: "Is it compulsory to quote PAN on 'return of income'?",
        content: "Yes, it is compulsory to quote PAN on return of income.",
      },
      {
        text: "Do I need to apply for a new PAN when I move from one city to another?",
        content: "Permanent Account Number (PAN), as the name suggests, is a permanent number and does not change. Changing the address though, may change the Assessing Officer. Such changes must, therefore, be intimated to ITD so that the PAN database of ITD can be updated. One can intimate change in address by filling up the form for 'Request for New PAN Card or/and Changes or Correction in PAN data'. This form can be submitted at any TIN-FC or online at Protean e-Gov - TIN website.",
      },
      {
        text: "Can I have more than one PAN?",
        content: "No. Obtaining/possessing more than one PAN is against the law and may attract a penalty up to ₹ 10,000. Therefore, it is advisable not to obtain/possess more than one PAN.",
      },
      {
        text: "What should I do if I have more than one PAN?",
        content: "You may fill and submit PAN Change Request application form by mentioning the PAN which you are using currently on top of the form. All other PANs inadvertently allotted to you should be mentioned at item no. 11 of the form and the corresponding PAN card copies should be submitted for cancellation along with the form.",
      },
      {
        text: "Is there any third party verification conducted to verify identity and address of PAN applicants along with genuineness of documents submitted by them?",
        content: "Yes. As per procedure prescribed by Income Tax Department, third party verification may be conducted to verify identity and address of PAN applicants along with genuineness of documents submitting by them during PAN application. If found false, the Income Tax Department may take suitable action.",
      },
      {
        text: "What is e-PAN? Is e-PAN a valid proof of allotment of PAN?",
        content: "e-PAN is a digitally signed PAN card issued in electronic form and it is a valid proof of allotment of PAN.",
      },
      {
        text: "Is email ID mandatory for receiving e-PAN?",
        content: "Yes, it is mandatory to mention valid e-mail ID in PAN application form to receive e-PAN.",
      },
      {
        text: "Is it compulsory to link Aadhaar with PAN?",
        content: "Section 139AA of the Income Tax Act states that every person who has been allotted PAN as on July 1, 2017 is required to link his/her PAN with his/her Aadhaar number. Section 139AA also requires mandatory quoting of Aadhaar while applying for new PAN and also while filing return.",
      },
      {
        text: "What is the deadline to link Aadhaar with PAN?",
        content: "Aadhaar-PAN linking deadline has been extended till March 31, 2022.",
      },
      {
        text: "How do I link my Aadhaar with PAN?",
        content: "(A) You may visit e-filing website of Income Tax Department https://www.incometax.gov.in/iec/foportal or Protean e-Gov TIN website www.protean-tinpan.com and link your Aadhaar with PAN.\n\n(B) Alternatively, you can link your Aadhaar with PAN by sending SMS in the following format to 567678:\nUIDAIPAN<SPACE><12 digit Aadhaar><SPACE><10 digit PAN>\n\nFor example, if your Aadhaar number is 111122223333 and PAN is AAAPA9999Q, then you are required to send SMS to 567678 as UIDPAN 111122223333 AAAPA9999Q.\n\nThis is a free service provided by Protean e-Gov. However, SMS charges as levied by the mobile operator will be applicable.",
      },
      {
        text: "What should I do if I am unable to link my Aadhaar with PAN either online or through SMS facility as mentioned above?",
        content: "You can link your Aadhaar with PAN using Biometric Aadhaar Authentication services. Click here to download Aadhaar Seeding Request Form and visit designated PAN center for Biometric Aadhaar Authentication along with supporting documents i.e. copy of PAN card and Aadhaar card. Unlike abovementioned online services, linking of Aadhaar with PAN using Biometric Aadhaar Authentication is chargeable.",
      },
    ],
    "New PAN": [
      {
        text: "How to apply for PAN?",
        content: "Application for PAN is required to be made in Form 49A of the Income-tax Act, 1961. It can be obtained from any TIN-Facilitation Centres (TIN-FCs) or can be freely downloaded from the Protean e-Gov - TIN website. The application form can also be completed online at the same website.",
      },
      {
        text: "What documents are required to be submitted along with the application for PAN?",
        content: "The following documents are required to be submitted along with the application for PAN:\n\n- Proof of identity (POI)\n- Proof of address (POA)\n- Proof of date of birth (PODB)\n\nList of documents acceptable as POI, POA, and PODB are available on the Protean e-Gov - TIN website.",
      },
      {
        text: "Can an application for PAN be made on plain paper?",
        content: "No, application for PAN can be made only on Form 49A. This form can be obtained from any TIN-FC or can be freely downloaded from the Protean e-Gov - TIN website.",
      },
      {
        text: "Can Form 49A be filled on a typewriter?",
        content: "Yes, but typing should be in capital letters with good impression.",
      },
      {
        text: "What should I do if I am unable to submit my application for PAN online?",
        content: "You may visit the nearest TIN-FC and submit your application for PAN in paper form. List of TIN-FCs is available on the Protean e-Gov - TIN website.",
      },
      {
        text: "Is there any fee to be paid while submitting application for PAN?",
        content: "Yes, a fee of ₹ 107 (including GST) is to be paid while submitting application for PAN.",
      },
      {
        text: "What are the charges for correction in PAN data?",
        content: "The charges for correction in PAN data are ₹ 107 (including GST).",
      },
      {
        text: "How long does it take to receive PAN card?",
        content: "It takes about 15 working days to receive your PAN card after submitting a complete and correct application along with required documents.",
      },
      {
        text: "How to track the status of my PAN application?",
        content: "You can track the status of your PAN application by visiting the Protean e-Gov - TIN website and clicking on 'Track PAN Status'. You will need to provide your 15-digit acknowledgment number to check the status.",
      },
      {
        text: "What should I do if I do not receive my PAN card?",
        content: "If you do not receive your PAN card within the specified time, you should contact the Protean e-Gov - TIN call center or send an email to the contact details provided on the Protean e-Gov - TIN website.",
      },
      {
        text: "Can PAN be applied for a minor?",
        content: "Yes, PAN can be applied for a minor. The application form should be signed by the parent or guardian of the minor.",
      },
      {
        text: "What is the process to apply for PAN by an NRI?",
        content: "NRIs can apply for PAN by submitting the PAN application form along with required documents to any TIN-FC in India. They can also submit the application online on the Protean e-Gov - TIN website.",
      },
      {
        text: "Can PAN be applied for a foreign citizen?",
        content: "Yes, foreign citizens can apply for PAN by submitting the application form along with the required documents to any TIN-FC in India. They can also submit the application online on the Protean e-Gov - TIN website.",
      },
    ],
    "Reprint of PAN Card Change/Correction in PAN Data": [
      {
        text: "When should I submit the application for the form 'Request for New PAN Card or/and Changes or Correction in PAN data'?",
        content: "You can submit the application in the form 'Request for New PAN Card or/and Changes or Correction in PAN data' in the following cases:\n\nA) When you already have PAN but want a new PAN card.\nB) When you want to make some changes or corrections in your existing PAN details.",
      },
      {
        text: "Are there any charges to be paid while submitting the form 'Request for New PAN Card or/and Changes or Correction in PAN data'?",
        content: "Yes. The fee for processing PAN application is ₹ 107 (including GST) for Indian communication address and ₹ 989 (including GST) for foreign communication address.",
      },
      {
        text: "What should I do if there are any changes or correction in PAN data?",
        content: "The applicant is required to fill all the columns of the form and tick the box on the left margin of the appropriate item where change/correction is to be done.",
      },
      {
        text: "What are the documents required to be submitted while submitting the form?",
        content: "Following documents are required to be submitted along with the form:\n\n- Proof of PAN (the one currently in use - mentioned at the top of the application) (original)\n- Proof of Identity\n- Proof of Address\n- Proof of Date of Birth (for individual and Karta of HUF)\n- Proof in support of changes sought (original)\n- Proof of PAN(s) surrendered (original)",
      },
      {
        text: "What documents can be submitted as proof of PAN?",
        content: "Any one of the following documents can be submitted as proof of PAN:\n\nA. Copy of PAN card\nB. Copy of PAN allotment letter\n\nNo other documents are acceptable as proof of PAN. If any proof of PAN is not available, this application will be accepted on a 'good effort' basis.",
      },
      {
        text: "What documents can be submitted as proof of PAN surrendered?",
        content: "Any one of the following documents can be submitted as proof of PAN surrendered:\n\nA. Copy of PAN card\nB. Copy of PAN allotment letter\n\nNo other documents are acceptable as proof of PAN surrendered. If any proof of PAN surrendered is not available, this application will not be accepted.",
      },
      {
        text: "What documents can be submitted as proof of identity, proof of address & proof of date of birth (not applicable for foreign citizens) in case of individual applicants and HUF applicants?",
        content: "Any one of the following documents can be submitted as proof of identity, proof of address & proof of date of birth:\n\n- Aadhar card issued by the Unique Identification Authority of India\n- Elector's photo identity card\n- Driving license\n- Passport\n- Ration card having photograph of the applicant\n- Certificate of identity in original signed by Member of Parliament or Member of Legislative Assembly or Municipal Councilor or a Gazetted Officer\n- Bank certificate in original on letter head from the branch (along with name and stamp of the issuing officer) containing duly attested photograph and bank account number of the applicant\n\nFor proof of address, the above documents should be in the name of the applicant. For HUF applicants, an affidavit by the Karta of HUF stating the name, father's name and address of all the coparceners on the date of application is required.",
      },
      {
        text: "What documents can be submitted as proof in support of changes sought?",
        content: "For proof of change/correction in PAN data, valid documents that support the change/correction are required to be submitted.",
      },
      {
        text: "Is it necessary to affix two photographs on the form 'Request for New PAN Card or/and Changes or Correction in PAN data'?",
        content: "Yes, it is necessary to affix two recent colour photographs (size 3.5 cm X 2.5 cm) in the space provided on the form 'Request for New PAN Card or/and Changes or Correction in PAN data'.",
      },
      {
        text: "What is the procedure to be followed when the applicant wants to update only the address for communication?",
        content: "If applicant wants to update the address for communication:\n\nA. For changes in the existing PAN data, applicant is required to fill the form 'Request for New PAN Card or/and Changes or Correction in PAN data' and submit it along with the necessary supporting documents.\n\nB. For change of address only, without change in other PAN data, an applicant can update the new address through the Aadhar-based e-KYC service offered by Protean.",
      },
      {
        text: "While applying for reprint PAN card/correction in PAN data, should Karta of HUF submit affidavit along with prescribed POI, POA & POD documents?",
        content: "In case of PAN Change Request application, affidavit will be required along with prescribed POI, POA & POD documents only. No changes are sought in name, PAN & DOB in HUF data.",
      },
      {
        text: "Is it required to submit documentary proof in support of any change in 'Gender' field in the PAN Change Request application?",
        content: "No, it is not necessary to submit documentary proof in support of any change in 'Gender' field in the PAN Change Request application.",
      },
    ],
    "Cancellation of PAN": [
      {
        text: "I have been allotted more than one PAN, what is the procedure for cancellation/surrender of other PAN(s)?",
        content: "You may fill and submit PAN Change Request application form by mentioning the PAN which you are using currently on top of the form. All other PAN(s) inadvertently allotted to you should be mentioned at item no. 11 of the form and the corresponding PAN card copy/s should be submitted for cancellation along with the form.",
      },
      {
        text: "What is the procedure to cancel/surrender PAN which is being currently used?",
        content: "If you wish to cancel/surrender your PAN (which you are currently using), then you need to visit your local Income Tax Assessing Officer with a request letter to cancel/surrender your PAN.",
      },
    ],
    "PAN Online": [
      {
        text: "What is the procedure for applying for PAN online?",
        content: "Click here to select the type of PAN application.\nFill the appropriate online form and select payment mode to complete the form submission.\nOn successful submission of online application and payment (for online mode of payment), an Acknowledgement receipt is generated.\nSave and take a print out of the acknowledgement receipt.\nDuly sign in the space provided, affix recent color photographs and submit the PAN application with supporting documents as under:\n1. Visit any of the designated centre and submit the said documents\nOR;\n2. Forward the said documents to INCOME TAX PAN SERVICES UNIT\n(Managed by Protean e-Governance Infrastructure Limited)\n5th Floor, Mantri Sterling, Plot No. 341,\nSurvey No. 997/8, Model Colony,\nNear Deep Bungalow Chowk,\nPune - 411016\nThe 15 digit acknowledgement no. appearing on the acknowledgement receipt can be used for tracking status of application.",
      },
      {
        text: "Who can apply for PAN?",
        content: "All existing assessees or taxpayers or persons who are required to file a return of income, even on behalf of others, must have a PAN. Any person, who intends to enter into economic or financial transactions where quoting PAN is mandatory, must also have a PAN.",
      },
      {
        text: "Are there any additional charges for applying for PAN online?",
        content: "No. There are no additional charges for applying for PAN online.",
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>Apply for PAN Card, Track Status, Change, Correction, Reprint, Docs</title>
        <meta name="description" content="Apply for a PAN card, track status, change or correct details, reprint, and know required documents. Get PAN Services." />
      </Helmet>
      <div className="flex flex-col gap-8">
        <PANHeroSection title={"Permanent Account Number (PAN)"} isVectorVisible={true} />
        <SearchAndContactUsSection />
        <Element name="apply-online">
          <ApplyOnline />
        </Element>
        <Element name="form49a">
          <Form49A />
        </Element>
        <Element name="form49aa">
          <Form49AA />
        </Element>
        <Element name="reprint-pan-card">
          <ReprintPanCard />
        </Element>
        <Element name="correction-in-pan">
          <CorrectionInPAN />
        </Element>
        <Element name="dsc-bases">
          <DSCBases />
        </Element>
        <Element name="ao-codes">
          <AOCodes />
        </Element>
        <Element name="documents-required">
          <DocumentsRequired />
        </Element>
        <Element name="more-on-pan">
          <MoreOnPan />
        </Element>
        <Element name="faqs">
          <FAQComponent linkData={linkData} />
        </Element>
      </div>
    </>
  );
};

export default withScrollToHash(Link2); // Use the HOC
