import React from 'react';
import { BlueSemiCircle, DotGrid } from "../../../assets/svgs";
import tdsImage from "../../../assets/images/tdsHeroSection.png";
import tdsImageSm from "../../../assets/images/tdsHeroSectionSm.png";

const HeroSection = () => {
    return (
        <div className="bg-[#F7F7F7] max-h-[80vh] justify-between flex-col h-full flex md:flex-row">
            <img src={tdsImageSm} alt="PAN Hand" className="aspect-square flex md:hidden" />
            <div className="relative px-4 md:px-0 flex flex-col md:self-center">
                <div className='md:pl-[68px] flex flex-col pb-[10px] md:pb-[87px]'>
                    <h1 className="font-semibold text-[#1D1D1B] text-[32px] leading-[43.58px] md:text-[56px] md:leading-[68px] md:max-w-[550px] md:pl-10 pt-[30px] md:pt-0">
                    Privacy Policy
                    </h1>
                
                </div>
                <div className="self-start mb-[66px] md:mb-0 mt-8 md:mt-0">
                    <DotGrid fillColor="#6F368B" />
                    <BlueSemiCircle className="absolute h-[100px] md:h-[169px] bottom-[25%] md:bottom-4 right-[25%] md:-right-[50%] max-w-[195px] md:max-w-[280px]" />
                </div>
            </div>
            <img src={tdsImage} alt="PAN Hand" className=" aspect-square hidden md:flex" />
        </div>
    );
}

export default HeroSection;