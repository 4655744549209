import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Link1 from "../pages/Link1";
import Link2 from "../pages/Link2";
import Link3 from "../pages/Link3";
import Link4 from "../pages/Link4";
// import Link5 from "../pages/Link5";  No Link5 as of now
import Link6 from "../pages/Link6";
import Link7 from "../pages/Link7";
import Link8 from "../pages/Link8";
import Link9 from "../pages/Link9";
import Link10 from "../pages/Link10";
import Link11 from "../pages/Link11";
import Link12 from "../pages/Link12";
import Link13 from "../pages/Link13";
import Link14 from "../pages/Link14";
import Link15 from "../pages/Link15";
import Link16 from "../pages/Link16";
import Link17 from "../pages/Link17";
import Link18 from "../pages/Link18";
import Link19 from "../pages/Link19";
import Link20 from "../pages/Link20";
// import Link21 from "../pages/Link21"; No Link 21
import Link22 from "../pages/Link22";
import Link23 from "../pages/Link23";
import Link24 from "../pages/Link24";
import Link25 from "../pages/Link25";
import Link26 from "../pages/Link26";
import Link27 from "../pages/Link27";
import FacilationCenter from "../pages/FacilationCenter";

  // Correct path for your component

export const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Link1 />,
      },
      {
        path: "/services/pan/pan-index",
        element: <Link2 />,
      },
      {
        path: "/services/etds-etcs",
        element: <Link3 />,
      },
      {
        path: "/privacy-policy",
        element: <Link4 />,
      },
      {
        path: "/facilation-center",
        element: <FacilationCenter />,
      },
      {
        path: "/services/etds-etcs/etds-index",
        element: <Link6 />,
      },
      {
        path: "/services/pan/pan-introduction",
        element: <Link7 />,
      },
      {
        path: "/pan-center",
        element: <Link8 />,
      },
      {
        path: "/downloads/e-tds/eTDS-download-regular",
        element: <Link9 />,
      },
      {
        path: "/services/etds-etcs/etds-file-validation-utility",
        element: <Link10 />,
      },
      {
        path: "/services/pan/change-correction",
        element: <Link11 />,
      },
      {
        path: "/services/online-pan-verification/pan-verification-overview",
        element: <Link12 />,
      },
      {
        path: "/services/tan/tan-introduction",
        element: <Link13 />,
      },
      {
        path: "/services/pan/documents-req",
        element: <Link14 />,
      },
      {
        path: "/customerfeedback",
        element: <Link15 />,
      },
      {
        path: "/tin-facilities",
        element: <Link16 />,
      },
      {
        path: "/services/oltas/e-pay",
        element: <Link17 />,
      },
      {
        path: "/services/pan/change-correction",
        element: <Link18 />,
      },
      {
        path: "/link19",
        element: <Link19 />,
      },
      {
        path: "/nsdl-addresses",
        element: <Link20 />,
      },
      // {
      //   path: "/link21",
      //   element: <Link21 />,
      // },
      {
        path: "/search-result",
        element: <Link22 />,
      },
      {
        path: "/guided/guide-status-track",
        element: <Link23 />,
      },
      {
        path: "/services/tan/tan-downloads",
        element: <Link24 />,
      },
      {
        path: "/services/oltas/oltas-challan",
        element: <Link25 />,
      },
      {
        path: "/online-pan-verification",
        element: <Link26 />,
      },
      {
        path: "/about-us",
        element: <Link27 />,
      },
    ],
  },
]);
