import React from 'react';
import { Element } from 'react-scroll';
import EPayment from './components/EPayment';
import withScrollToHash from '../../hooks/useScrollToHash'; // Correctly import the HOC

function Link17() {
  return (
    <div className='flex flex-col gap-8'>
      <Element name="e-payment">
        <EPayment />
      </Element>
    </div>
  );
}

export default withScrollToHash(Link17); // Use the HOC
