import React from "react";
import Card from "../../../components/Card";
import { useState, useEffect } from "react";
import section2Graphic from "../../../assets/images/secondSectionGraphic.svg";

const cardDataArray = [
  {
    // cardBackgroundColor: "rgba(84, 173, 71, 0.1)",
    title: "PAN Services links ",
    svgColor: "#FFCE1B",
    linkData: [
      {
        text: "Apply for PAN",
        linkMinWidth: "279px",
        href: "https://www.onlineservices.nsdl.com/paam/endUserRegisterContact.html",
      },
      {
        text: "Re-print PAN Card",
        linkMinWidth: "279px",
        href: "https://www.onlineservices.nsdl.com/paam/ReprintEPan.html", // Include the hash
      },
      {
        text: "Address/Mobile number/Email ID update in PAN",
        linkMinWidth: "279px",
        href: "https://www.onlineservices.nsdl.com/paam/endUserAddressUpdate.html",
      },
      {
        text: "Request for e-PAN/ e-PAN XML (PANs allotted in last 30 days)",
        linkMinWidth: "279px",
        href: "https://www.onlineservices.nsdl.com/paam/requestAndDownloadEPAN.html",
      },
      {
        text: "Request for e-PAN/ e-PAN XML (PANs allotted prior to 30 days)",
        linkMinWidth: "279px",
        href: "https://www.onlineservices.nsdl.com/paam/requestAndDownloadEPAN.html",
      },
      {
        text: "Know your Status",
        linkMinWidth: "279px",
        href: 'https://tin.tin.nsdl.com/pantan/StatusTrack.html'
      },
      {
        text: "Online PAN verification",
        linkMinWidth: "279px",
        href: "/services/online-pan-verification/pan-verification-overview",
      },

      // Add more link items as needed
    ],
    defaultLinkMinWidth: window.innerWidth > 1024 ? "279px" : "300px", // Default min width for this card's links
  },
  {
    // cardBackgroundColor: "rgba(84, 173, 71, 0.1)",
    title: "PAN Info links",
    svgColor: "#E43625",
    linkData: [
      {
        text: "How to Apply – Video",
        linkMinWidth: "279px",
        href: "https://www.youtube.com/watch?v=ufv5flERhCo&list=PLbmT3BRzdeUmBbGnazDPSZcbvBWDt1RCV",
      },
      {
        text: "Documents Required",
        linkMinWidth: "279px",
        href: "/services/pan/pan-index#documents-required",
      },
      {
        text: "PAN Centres ",
        linkMinWidth: "279px",
        href: "/pan-center#search-tabs-forms",
      },
      // Add more link items as needed
    ],
    defaultLinkMinWidth: window.innerWidth > 1024 ? "279px" : "300px", // Default min width for this card's links
  },
  {
    // cardBackgroundColor: "rgba(84, 173, 71, 0.1)",
    title: "Other Services links",
    svgColor: "#54AD47",
    linkData: [
      {
        text: "Link PAN to Aadhar",
        linkMinWidth: "279px",
        href: 'https://www.incometax.gov.in/iec/foportal/'
      },
      {
        text: "ePAN XML Verification Utility",
        linkMinWidth: "279px",
        href: "https://www.protean-tinpan.com/downloads/pan/ePANxmlVerificationUtility_20sep2019.zip"
      },
      // Add more link items as needed
    ],
    defaultLinkMinWidth: window.innerWidth > 1024 ? "279px" : "300px", // Default min width for this card's links
  },

  // Add more card data objects as needed
];

const cardDataArray2 = [
  {
    linkData: [
      {
        text: "Login as TIN-FC",
        linkMinWidth: "279px",
        padding: "0px 0px 8px 0px",
        href: "https://onlineservices.tin.egov-nsdl.com/TIN/JSP/security/Login.jsp"
      },

      // Add more link items as needed
    ],
    defaultLinkMinWidth: window.innerWidth > 1024 ? "279px" : "300px", // Default min width for this card's links
  },
  {
    linkData: [
      {
        text: "Login as PAN Verification",
        linkMinWidth: "279px",
        padding: "0px 0px 8px 0px",
        href: 'https://onlineservices.tin.egov-nsdl.com/TIN/JSP/security/Login.jsp'
      },

      // Add more link items as needed
    ],
    defaultLinkMinWidth: window.innerWidth > 1024 ? "279px" : "300px", // Default min width for this card's links
  },
  {
    linkData: [
      {
        text: "Login as AO Account",
        linkMinWidth: "279px",
        padding: "0px 0px 8px 0px",
        href: 'https://onlineservices.tin.egov-nsdl.com/TIN/JSP/security/AinLogin.jsp'
      },

      // Add more link items as needed
    ],
    defaultLinkMinWidth: window.innerWidth > 1024 ? "279px" : "300px", // Default min width for this card's links
  },
];

function SecondSection() {
  const [minCardWidth, setMinCardWidth] = useState("300px");

  // Update the min width based on the window width
  useEffect(() => {
    const updateMinWidth = () => {
      if (window.innerWidth >= 1024) {
        setMinCardWidth("279px");
      } else {
        setMinCardWidth("340px");
      }
    };

    updateMinWidth(); // Set initial width
    window.addEventListener("resize", updateMinWidth); // Update on resize

    return () => {
      window.removeEventListener("resize", updateMinWidth);
    };
  }, []);

  return (
    <section id="SecondSection" className="bg-[#1F2A62] min-h-[546px] gap-[50px] py-10 flex flex-col  w-full justify-center relative">
      <div className="flex justify-between lg:py-[10px]">
        <div className="flex flex-wrap gap-[80px] lg:px-[70px] justify-center ">
          {cardDataArray.map((cardData, index) => (
            <Card key={index} {...cardData} minWidth={minCardWidth} />
          ))}
        </div>
      </div>
      <div className="absolute right-0 top-[20px] hidden lg:block">
        <img src={section2Graphic} className="" width={300} alt="" />
      </div>
      <div className="bg-blue-3  py-4   lg:max-w-[82%] flex flex-col lg:flex-row gap-4 lg:gap-[80px] items-center lg:px-[70px] relative">
        <svg
          className="absolute left-0"
          width="43"
          height="51"
          viewBox="0 0 43 51"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.60577 25.2246C8.60577 30.012 4.75682 33.903 0 33.903V50.4491C13.8165 50.4491 25.0135 39.1577 25.0135 25.2246C25.0135 11.2914 13.8165 0 0 0V16.5462C4.74736 16.5462 8.60577 20.4276 8.60577 25.2246V25.2246Z"
            fill="#3091D0"
          />
          <path
            d="M27.0273 30.69L36.3897 40.1313C44.5131 31.9393 44.5131 18.6451 36.3897 10.4531L27.0273 19.8945C29.9779 22.8699 29.9779 27.7146 27.0273 30.69Z"
            fill="#3091D0"
          />
        </svg>

        {cardDataArray2.map((cardData, index) => (
          <Card key={index} {...cardData} minWidth={minCardWidth} />
        ))}
      </div>
    </section>
  );
}

export default SecondSection;
