import React from "react";
import Card from "../../../components/Card";

const cardDataArray = [
  {
    title: "Search",
    svgColor: "#54AD47",
    linkData: [
      {
        text: "TIN Facilitation Centers cum PAN centers",
        linkMinWidth: "400px",
        linkMinWidthMobile: "247px",
        iconColor: "black",
        borderBottom: "1px solid black",
        textColor: "black",
        href: "link16",
      },
      {
        text: "PAN Centers",
        linkMinWidth: "400px",
        linkMinWidthMobile: "247px",
        iconColor: "black",
        borderBottom: "1px solid black",
        textColor: "black",
        href: "/pan-center",
      },
      {
        text: "PAN Service Agency Centers",
        linkMinWidth: "400px",
        linkMinWidthMobile: "247px",
        iconColor: "black",
        borderBottom: "1px solid black",
        textColor: "black",
        href: "https://www.protean-tinpan.com/downloads/PSA-Centre-list.xlsx",
      },
    ],
  },
];

const cardDataArray2 = [
  {
    title: "Contact us",
    svgColor: "#3091D0",
    linkData: [
      // {
      //   text: "Rights",
      //   linkMinWidth: "400px",
      //   linkMinWidthMobile: "247px",
      //   iconColor: "black",
      //   borderBottom: "1px solid black",
      //   textColor: "black",
      //   href: "https://www.protean-tinpan.com/customer-care.html",
      // },
      {
        text: "Complains / Queries",
        linkMinWidth: "400px",
        linkMinWidthMobile: "247px",
        iconColor: "black",
        borderBottom: "1px solid black",
        textColor: "black",
        href: "/customerfeedback",
      },
      {
        text: "Protean addresses",
        linkMinWidth: "400px",
        linkMinWidthMobile: "247px",
        iconColor: "black",
        borderBottom: "1px solid black",
        textColor: "black",
        href: "/nsdl-addresses",
      },
    ],
  },
];

function SecondSection() {
  return (
    <section className="md:px-[68px] pt-6 w-full">
      <div className=" flex flex-col md:flex-row gap-6 w-full">
        <div className="flex p-10 bg-[#54AD47] bg-opacity-10 flex-wrap gap-[80px] md:w-1/2 justify-center md:justify-start">
          {cardDataArray.map((cardData, index) => (
            <Card
              key={index}
              {...cardData}
              className={"text-black flex-col gap-6 w-full max-w-[412px]"}
              titleColor="black"
            />
          ))}
        </div>
        <div className="flex p-10 bg-[#3091D0] bg-opacity-10 flex-wrap gap-[80px] md:w-1/2 justify-center md:justify-start">
          {cardDataArray2.map((cardData, index) => (
            <Card
              key={index}
              {...cardData}
              className={"text-black flex-col gap-6 w-full max-w-[412px]"}
              titleColor="black"
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default SecondSection;
