import React from 'react';

const ProcessingTime = ({ language, isMobile }) => {
  const content = {
    English: (
      <div className="p-8 bg-opacity-10">
        <div className='px-4 md:px-0 flex items-center justify-between pb-6'>
          <h1 className='flex flex-row text-[26px] font-semibold gap-2 leading-5'>
            How Soon a PAN Card is Dispatched
          </h1>
        </div>
        <p className='text-sm leading-6 font-normal'>
          Normally two weeks are required to process the application & dispatch the PAN card, provided application is in order in all respects.
        </p>
      </div>
    ),
    Hindi: (
      <div className="p-8 bg-opacity-10">
        <div className='px-4 md:px-0 flex items-center justify-between pb-6'>
          <h1 className='flex flex-row text-[26px] font-semibold gap-2 leading-5'>
            PAN कार्ड कितनी जल्दी भेजा जाता है
          </h1>
        </div>
        <p className='text-sm leading-6 font-normal'>
          आवेदन की प्रक्रिया और PAN कार्ड के प्रेषण में सामान्यत: दो सप्ताह का समय लगता है, बशर्ते आवेदन सभी मामलों में क्रम में हो।
        </p>
      </div>
    )
  };

  return <div>{content[language]}</div>;
};

export default ProcessingTime;
