import React, { useState } from 'react'
import { ArrowUpSvg, DropDownArrowSvg, ThreeDotsBulletSvg } from '../../../assets/svgs'

function DocumentsRequired({ language }) {
    const isMobile = window.innerWidth <= 768;
    const [isOverviewOpen, setIsOverviewOpen] = useState(isMobile || true)

    const toggleOverview = () => {
        setIsOverviewOpen(!isOverviewOpen)
    }

    const textContent = {
        en: {
            overviewTitle: "Documents required",
            overviewDescription: "Protean forwards the application details to ITD after digitization of the form submitted.",
        },
        hi: {
            overviewTitle: "आवश्यक दस्तावेज़",
            overviewDescription: "आवेदक को यह सुनिश्चित करना चाहिए कि आवश्यक सहायक दस्तावेज (आयकर विभाग के नियम 114 के तहत निर्दिष्ट) आवेदन के साथ जमा किए जाएं। आवेदन पत्र में आवश्यक दस्तावेजों का विवरण भी दिया गया है। आवेदन पत्र में उल्लिखित नाम और पहचान प्रमाण/पते के प्रमाण में नाम बिल्कुल मेल खाना चाहिए",
        }
    };

    return (
        <section className="bg-[#E43625] md:w-[168vw] bg-opacity-10 p-8">
            <div className='px-4 md:px-0 flex items-center justify-between pb-6'>
                <h1 className='flex flex-row text-[26px] font-semibold gap-2 leading-5'>
                    <ThreeDotsBulletSvg fillColor={'#E43625'} className={'w-[34px] h-[17px]'} />
                    {textContent[language].overviewTitle}
                </h1>
                {isMobile && <button onClick={toggleOverview} className=''>
                    {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                </button>}
            </div>
            {isOverviewOpen && <p>
                {textContent[language].overviewDescription}
            </p>}
        </section>
    )
}

export default DocumentsRequired

