import React, { useState } from "react";
import { ArrowUpSvg, DropDownArrowSvg, ThreeDotsBulletSvg } from "../../../assets/svgs";
import DynamicLinks from "../../../components/DynamicLink";
import Card from "../../../components/Card";

function DocumentsRequired() {
    const isMobile = window.innerWidth <= 768;
    const [isOverviewOpen, setIsOverviewOpen] = useState(isMobile || true)

    const toggleOverview = () => {
        setIsOverviewOpen(!isOverviewOpen)
    }

    const cardDataArray = [
        {
            linkData: [
                {
                    text: "DSC change request cum Authorisation letter",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    href: 'https://www.protean-tinpan.com/downloads/online-pan-verification/DSC_updation_request_cum_Authorisation_letter_OPV_R.docx',
                },
            ],
        },
    ];

    const documentsRequired1 = [
        {
            linkData: [
                {
                    text: "Terms & Conditions",
                    linkMinWidth: "400px",
                    linkMinWidthMobile: "247px",
                    iconColor: "black",
                    borderBottom: "1px solid black",
                    textColor: "black",
                    href: 'https://www.protean-tinpan.com/downloads/online-pan-verification/Terms%20and%20conditions.pdf',
                },
                {
                    text: "Declaration by entities",
                    linkMinWidth: "400px",
                    linkMinWidthMobile: "247px",
                    iconColor: "black",
                    borderBottom: "1px solid black",
                    textColor: "black",
                    href: 'https://www.protean-tinpan.com/downloads/online-pan-verification/Declaration-by-Entities_R.docx',
                },
            ],
        },
    ];

    const documentsRequired2 = [
        {
            linkData: [
                {
                    text: "Authority Letter",
                    linkMinWidth: "400px",
                    linkMinWidthMobile: "247px",
                    iconColor: "black",
                    borderBottom: "1px solid black",
                    textColor: "black",
                    href: 'https://www.protean-tinpan.com/downloads/online-pan-verification/Authority%20letter_R.docx',
                },
                {
                    text: "Copy of ITR-V (Acknowledgment) of latest assessment Year",
                    linkMinWidth: "400px",
                    linkMinWidthMobile: "247px",
                    iconColor: "black",
                    borderBottom: "1px solid black",
                    textColor: "black",
                },
            ],
        },
    ];

    const dsaArray1 = [
        {
            linkData: [
                {
                    text: "Click here to download",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    href: '/File format_File based_Other than DCT.XLSX',
                },
            ],
        },
    ];

    const dsaArray2 = [
        {
            linkData: [
                {
                    text: "Click here to download",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    href: 'https://www.protean-tinpan.com/downloads/online-pan-verification/downloads/File_format_File%20based_DCT.XLSX',
                },
            ]
        }
    ]

    const dsaArray3 = [
        {
            linkData: [
                {
                    text: "Click here to download",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    href: 'https://www.protean-tinpan.com/downloads/online-pan-verification/downloads/PAN%20Verification%20API%20Integration%20document%20V1.2.pdf',
                },
            ]
        }
    ]

    const dsaArray4 = [
        {
            linkData: [
                {
                    text: "Click here to download",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    href: 'https://www.protean-tinpan.com/downloads/online-pan-verification/downloads/Sample_Input.txt',
                },
            ]
        }
    ]

    const dsaArray5 = [
        {
            linkData: [
                {
                    text: "Click here to download",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    href: 'https://www.protean-tinpan.com/downloads/online-pan-verification/downloads/Sample_Input_DCT.txt',
                },
            ]
        }
    ]

    const dsaArray6 = [
        {
            linkData: [
                {
                    text: "Click here to download",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    href: '/Sample_Output_DCT.rsp',
                },
            ]
        }
    ]

    const dsaArray7 = [
        {
            linkData: [
                {
                    text: "Click here to download",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    href: '/Sample_output.rsp',
                },
            ]
        }
    ]

    return (
        <section className="flex flex-col lg:px-[70px] pb-10 ">
            <div className='px-4 md:px-0 flex items-center justify-between pb-6'>
                {isMobile && <button onClick={toggleOverview} className=''>
                    {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                </button>}
            </div>
            <div className="flex flex-col md:flex-row gap-6 mt-6">
                <div className="flex flex-col gap-2">
                    <div className="flex-1 bg-[#FDF2E9] p-6 md:mb-4 max-w-[640px] ">
                        <h1 className='flex flex-row font-semibold gap-2 text-lg md:text-[32px] leading-5'>
                            <ThreeDotsBulletSvg fillColor={'#E43625'} className={'w-[34px] h-[17px]'} />
                            Documents required for registration
                        </h1>
                        <div className=" flex flex-col md:flex-row gap-6 w-full">
                            <div className="flex flex-wrap gap-[80px] md:w-1/2 justify-center md:justify-start">
                                {documentsRequired1.map((cardData, index) => (
                                    <Card
                                        key={index}
                                        {...cardData}
                                        className={"text-black flex-col gap-6 w-full max-w-[412px]"}
                                        titleColor="black"
                                    />
                                ))}
                            </div>
                            <div className="flex flex-wrap gap-[80px] md:w-1/2 justify-center md:justify-start">
                                {documentsRequired2.map((cardData, index) => (
                                    <Card
                                        key={index}
                                        {...cardData}
                                        className={"text-black flex-col gap-6 w-full max-w-[412px]"}
                                        titleColor="black"
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 bg-[#F1EBF3] p-6 max-w-[640px]">
                        <h1 className='flex flex-row font-semibold gap-2 text-lg md:text-[32px] leading-8'>
                            <ThreeDotsBulletSvg fillColor={'#6F368B'} className={'w-[34px] h-[17px]'} />
                            Documents required for Renewal/change of Digital Signature certificate (DSC)
                        </h1>
                        <ul className="text-sm font-normal list-disc list-inside md:pt-6">
                            <li>Below documents are to be sent along with screenshots of DSC</li>
                        </ul>
                        <div className="flex flex-col md:flex-row md:pt-6">
                            {cardDataArray.map((cardData, index) => (
                                <DynamicLinks key={index} linkData={cardData.linkData} listDir='flex-row' className={"text-black flex-col md:flex-row md:items-end gap-5 px-2 md:px-0 md:gap-[96px]"} titleColor='black' />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="flex-1 bg-[#EAF4FA] p-6 px-10">
                    <h1 className='flex flex-row text-lg md:text-[32px] leading-5 font-semibold gap-2'>
                        <ThreeDotsBulletSvg fillColor={'#3091D0'} className={'w-[34px] h-[17px]'} />
                        Data Structure
                    </h1>
                    <p className="text-sm font-bold pt-6">File based users</p>
                    <div className="flex flex-col md:flex-row md:items-center justify-between gap-3 md:gap-10">
                        <p className="text-sm font-normal md:w-[21vw] pt-6 md:pt-0">Other than ‘Department of Commercial Taxes’ category</p>
                        {dsaArray1.map((cardData, index) => (
                            <DynamicLinks key={index} linkData={cardData.linkData} listDir='flex-col' className={"text-black flex-col md:flex-row md:items-end gap-5 px-2 md:px-0 md:gap-[96px]"} titleColor='black' />
                        ))}

                    </div>
                    <div className="flex flex-col md:flex-row md:items-center justify-between gap-3 md:gap-10">
                        <p className="text-sm font-normal md:w-[21vw] pt-6 md:pt-0">Department of Commercial Taxes’ category</p>

                        {dsaArray2.map((cardData, index) => (
                            <DynamicLinks key={index} linkData={cardData.linkData} listDir='flex-row' className={"text-black flex-col md:flex-row md:items-end gap-5 px-2 md:px-0 md:gap-[96px]"} titleColor='black' />
                        ))}

                    </div>
                    <div className="md:items-center justify-between pt-6">
                        <p className="text-sm font-bold">API based users</p>
                        {dsaArray3.map((cardData, index) => (
                            <DynamicLinks key={index} linkData={cardData.linkData} listDir='flex-row' className={"text-black flex-col md:flex-row md:items-end gap-5 px-2 md:px-0 md:gap-[96px]"} titleColor='black' />
                        ))}
                    </div>
                    <p className="text-sm font-bold pt-6">Sample Input/output file for File Based users based on the above file format</p>
                    <div className="flex flex-row md:items-center gap-4 md:gap-10 pt-6">
                        <ul className="text-sm list-disc list-inside font-normal">
                            <li>Input file for File based users</li>
                        </ul>
                    </div>
                    <div className="flex flex-col md:flex-row md:items-center justify-between gap-3 md:gap-10">
                        <p className="text-sm font-normal md:w-[21vw] pt-6 md:pt-0">‘Other than ‘Department of Commercial Taxes’ category</p>

                        {dsaArray4.map((cardData, index) => (
                            <DynamicLinks key={index} linkData={cardData.linkData} listDir='flex-row' className={"text-black flex-col md:flex-row md:items-end gap-5 px-2 md:px-0 md:gap-[96px]"} titleColor='black' />
                        ))}

                    </div>
                    <div className="flex flex-col md:flex-row md:items-center justify-between gap-3 md:gap-10">
                        <p className="text-sm font-normal md:w-[21vw] pt-6 md:pt-0">Department of Commercial Taxes’ category</p>

                        {dsaArray5.map((cardData, index) => (
                            <DynamicLinks key={index} linkData={cardData.linkData} listDir='flex-row' className={"text-black flex-col md:flex-row md:items-end gap-5 px-2 md:px-0 md:gap-[96px]"} titleColor='black' />
                        ))}

                    </div>
                    <div className="flex flex-row md:items-center gap-4 md:gap-10 pt-6">
                        <ul className="text-sm list-disc list-inside font-normal">
                            <li>Output file for File based users</li>
                        </ul>
                    </div>
                    <div className="flex flex-col md:flex-row md:items-center justify-between gap-3 md:gap-10">
                        <p className="text-sm font-normal md:w-[21vw] pt-6 md:pt-0">Other than ‘Department of Commercial Taxes’ category</p>
                        {dsaArray6.map((cardData, index) => (
                            <DynamicLinks key={index} linkData={cardData.linkData} listDir='flex-row' className={"text-black flex-col md:flex-row md:items-end gap-5 px-2 md:px-0 md:gap-[96px]"} titleColor='black' />
                        ))}

                    </div>
                    <div className="flex flex-col md:flex-row md:items-center justify-between gap-3 md:gap-10">
                        <p className="text-sm font-normal md:w-[21vw] pt-6 md:pt-0">‘Department of Commercial Taxes’ category </p>

                        {dsaArray7.map((cardData, index) => (
                            <DynamicLinks key={index} linkData={cardData.linkData} listDir='flex-row' className={"text-black flex-col md:flex-row md:items-end gap-5 px-2 md:px-0 md:gap-[96px]"} titleColor='black' />
                        ))}

                    </div>
                </div>
            </div>
        </section>
    );
}

export default DocumentsRequired;
