import React, { useState } from 'react'
import { ArrowUpSvg, DropDownArrowSvg, ThreeDotsBulletSvg, TypesOfPanSvg } from '../../../assets/svgs'
import HowToApply from './HowToApply';
import WhereToGet from './WhereToGet';
import DocumentsRequired from './DocumentsRequired';
import StatusTrack from './StatusTrack';
import Communication from './Communication';
import Fee from './Fee';
import PanApplications from './PanApplications';

function TypesOfPan() {
    const isMobile = window.innerWidth <= 768;
    const [isOverviewOpen, setIsOverviewOpen] = useState(isMobile || true)
    const [language, setLanguage] = useState("en"); // Default language is English
    const [dropdownOpen, setDropdownOpen] = useState(false); // Dropdown visibility state

    const toggleOverview = () => {
        setIsOverviewOpen(!isOverviewOpen)
    }

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const changeLanguage = (lang) => {
        setLanguage(lang);
        setDropdownOpen(false); // Close dropdown after selection
    };

    const textContent = {
        en: {
            overviewTitle: "Types of PAN Applicants",
            overviewDescription: "Entities (both corporate and non-corporate deductors) making payments (specified under Income Tax Act) to third parties (deductees) are required to deduct tax at source (Tax Deducted at Source -TDS) from these payments and deposit the same at any of the designated branches of banks authorised to collect taxes on behalf of Government of India. They should also furnish TDS returns containing details of deductee(s) and challan details relating to deposit of tax to ITD.",
            typesOfPanTitle: "There are two types of PAN applications:",
            firstPoint: "1. Application for allotment of PAN: - ",
            applicationForAllotment: "This application form should be used when the applicant has never applied for a PAN or does not have PAN allotted to him. Applicant may visit ITD's website www.incometaxindia.gov.in to find whether a PAN has been allotted to him or not.",
            form49ABold: "FORM 49A: - ",
            form49A: "To be filled by Indian citizens including those who are located outside India.",
            form49AABold: "FORM 49AA: - ",
            form49AA: "To be filled by foreign citizens.",
            secondPoint: "2. Application for new PAN Card or/and Changes or Corrections in PAN Data: - ",
            applicationForNewPan: "Those who have already obtained the PAN and wish to obtain the new PAN card or want to make some changes / corrections in their PAN data, are required to submit their applications in the following form prescribed by ITD:",
            requestForNewPan: "REQUEST FOR NEW PAN CARD OR/AND CHANGES OR CORRECTION IN PAN DATA: -",
            requestForNewPanDescription1: "The same form can be used by Indian as well as foreign citizens.",
            requestForNewPanDescription2: "A new PAN card bearing the same PAN but updated information is issued to applicant in such case."
        },
        hi: {
            overviewTitle: "पैन आवेदकों के प्रकार",
            overviewDescription: "तृतीय पक्षों (कटौतीकर्ताओं) को भुगतान (आयकर अधिनियम के तहत निर्दिष्ट) करने वाली संस्थाओं (कॉर्पोरेट और गैर-कॉर्पोरेट कटौतीकर्ताओं दोनों) को इन भुगतानों से स्रोत पर कर (स्रोत पर कर कटौती - टीडीएस) की कटौती करने और इसे किसी भी समय जमा करने की आवश्यकता होती है। भारत सरकार की ओर से कर एकत्र करने के लिए अधिकृत बैंकों की नामित शाखाएँ। उन्हें टीडीएस रिटर्न भी प्रस्तुत करना चाहिए जिसमें कटौती प्राप्तकर्ताओं का विवरण और आईटीडी को कर जमा करने से संबंधित चालान विवरण शामिल हों।",
            typesOfPanTitle: "पैन आवेदन के दो प्रकार हैं:",
            firstPoint: "1. पैन आवंटन के लिए आवेदन: - ",
            applicationForAllotment: "इस आवेदन पत्र का उपयोग तब किया जाना चाहिए जब आवेदक ने कभी पैन के लिए आवेदन नहीं किया हो या उसे पैन आवंटित नहीं किया गया हो। आवेदक यह जानने के लिए आईटीडी की वेबसाइट www.incometaxindia.gov.in पर जा सकता है कि उसे पैन आवंटित किया गया है या नहीं।",
            form49ABold: "फॉर्म 49ए: - ",
            form49A: "भारतीय नागरिकों द्वारा भरा जाना है, जिनमें वे भी शामिल हैं जो भारत के बाहर स्थित हैं।",
            form49AABold: "फॉर्म 49एए: - ",
            form49AA: "विदेशी नागरिकों द्वारा भरा जाना है।",
            secondPoint: "2. नया पैन कार्ड या/और पैन डेटा में परिवर्तन या सुधार के लिए आवेदन: - ",
            applicationForNewPan: "जिनके पास पहले से ही पैन है और वे नया पैन कार्ड प्राप्त करना चाहते हैं या अपने पैन डेटा में कुछ परिवर्तन / सुधार करना चाहते हैं, उन्हें आईटीडी द्वारा निर्धारित निम्नलिखित फॉर्म में अपने आवेदन जमा करने की आवश्यकता है:",
            requestForNewPan: "नया पैन कार्ड या/और पैन डेटा में परिवर्तन या सुधार के लिए अनुरोध: -",
            requestForNewPanDescription1: " यह फॉर्म भारतीय और विदेशी नागरिकों दोनों द्वारा उपयोग किया जा सकता है।",
            requestForNewPanDescription2: " इस मामले में आवेदक को वही पैन लेकिन अद्यतन जानकारी वाला नया पैन कार्ड जारी किया जाता है।",
        }
    };

    return (
        <div className="md:px-[68px] pt-[30px] md:pt-12">
            <section>
                <div className='px-4 md:px-0 flex items-center justify-between pb-6'>
                    <div className='flex flex-col md:justify-between md:flex-row gap-3 w-full'>
                        <h1 className='flex flex-row text-[26px] font-semibold gap-2 leading-5'>
                            <ThreeDotsBulletSvg fillColor={'#6F368B'} className={'w-[34px] h-[17px]'} />
                            {textContent[language].overviewTitle}
                        </h1>
                        <button
                            onClick={toggleDropdown}
                            className="border p-2 gap-2 px-4 rounded self-start flex justify-between items-center bg-[#6F368B] text-white focus:outline-none max-w-[111px]"
                        >
                            {language === "en" ? "English" : "Hindi"}
                            <svg
                                width="14"
                                height="8"
                                viewBox="0 0 14 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1 1L7 7L13 1"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                    {dropdownOpen && (
                        <div className="absolute right-0 mt-2 w-32 bg-white border rounded shadow-lg">
                            <button
                                onClick={() => changeLanguage("en")}
                                className="block px-4 py-2 text-left w-full hover:bg-gray-100"
                            >
                                English
                            </button>
                            <button
                                onClick={() => changeLanguage("hi")}
                                className="block px-4 py-2 text-left w-full hover:bg-gray-100"
                            >
                                Hindi
                            </button>
                        </div>
                    )}
                    {isMobile && <button onClick={toggleOverview} className=''>
                        {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                    </button>}
                </div>
                <p className="mb-4 px-4 md:px-0 md:max-w-[720px]">
                    {textContent[language].overviewDescription}
                </p>
            </section>

            <section className="bg-[#6F368B] bg-opacity-10 p-8 mb-8 relative">
                <h2 className="text-lg font-semibold leading-6 mb-6">
                    {textContent[language].typesOfPanTitle}
                </h2>
                <div className="flex flex-col lg:flex-row gap-4 md:pb-10">
                    <div className="p-4 rounded-md flex-1">
                        <p className="text-sm font-normal">
                            <span className='font-semibold'>{textContent[language].firstPoint}</span> {textContent[language].applicationForAllotment}
                        </p>
                        <p className='text-sm font-normal'><span className='font-semibold'>{textContent[language].form49ABold}</span> {textContent[language].form49A}</p>
                        <p className='text-sm font-normal'><span className='font-semibold'>{textContent[language].form49AABold}</span> {textContent[language].form49AA}</p>
                    </div>
                    <div className="p-4 rounded-md flex-1">
                        <p className="text-sm font-normal">
                            <span className='font-semibold'>{textContent[language].secondPoint}</span> {textContent[language].applicationForNewPan}
                        </p>
                        <p className='text-sm font-normal'>{textContent[language].requestForNewPan}</p>
                        <p className='text-sm font-normal'>{textContent[language].requestForNewPanDescription1}</p>
                        <p className='text-sm font-normal'>{textContent[language].requestForNewPanDescription2}</p>
                    </div>
                </div>
                <TypesOfPanSvg className={"absolute bottom-0 right-0 md:left-[30vw]"} />
            </section>
            <div className='flex flex-col gap-6 md:pb-6'>
                <div className='flex flex-col md:flex-row gap-6'>
                    <HowToApply language={language} />
                    <WhereToGet language={language} />
                </div>
                <div className='flex flex-col md:flex-row gap-6'>
                    <DocumentsRequired language={language} />
                    <StatusTrack language={language} />
                </div>
            </div>
            <Communication language={language} />
            <Fee language={language} />
            <PanApplications language={language} />
        </div>
    )
}

export default TypesOfPan