import React from 'react'
import DynamicLinks from '../../../components/DynamicLink'
import { ProcedureSectionSvg } from '../../../assets/svgs';

function Procedure() {
    const cardDataArray = [
        {
            linkData: [
                {
                    text: "Deductors' Manual for Quarterly Regular Returns",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    href: "https://www.protean-tinpan.com/downloads/e-tds/download/deductorsmanualquarterlystatements.zip",
                },
                {
                    text: "Dos and Don’ts for Deductors/Collectors",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    href: "https://www.protean-tinpan.com/downloads/e-tds/download/DosandDontsdeductorscollectors.pdf",
                },
            ],
        },
    ];

    return (
        <div className='py-3 md:py-12 md:px-[68px]'>
            <div className='bg-[#EEF7ED] p-8 flex flex-col relative'>
                <h2 className='text-base font-semibold'>Procedure for Preparation of Return</h2>
                <div className="flex flex-col md:flex-row pb-20 md:pb-0 pt-4">
                    {cardDataArray.map((cardData, index) => (
                        <DynamicLinks key={index} linkData={cardData.linkData} listDir='flex-row' className={"text-black flex-col md:flex-row md:items-end gap-5 px-2 md:px-0 md:gap-[96px]"} titleColor='black' />
                    ))}
                </div>
                <ProcedureSectionSvg className={'absolute bottom-0 right-0 md:right-5 md:top-0'} />
            </div>
        </div>
    )
}

export default Procedure