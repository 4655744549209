import React from "react";
import { Element } from "react-scroll";
import PANHeroSection from "../../components/PANHeroSection";
import SearchAndContactUsSection from "../../components/SearchAndContact";
import CorrectionInPAN from "./components/CorrectionInPAN";
import ContactUs from "./components/Contact";
import withScrollToHash from "../../hooks/useScrollToHash"
function Link11() {
  return (
    <div className="flex flex-col gap-8">
      <section className="lg:px-[70px]">
        <PANHeroSection
          className={"mx-auto"}
          title={"Permanent Account Number (PAN)"}
        />
      </section>
      <Element name="search-and-contact-us">
        <SearchAndContactUsSection />
      </Element>
      <Element name="correction-in-pan">
        <CorrectionInPAN />
      </Element>
      <Element name="contact-us">
        <ContactUs />
      </Element>
    </div>
  );
}

export default withScrollToHash(Link11); // Use the HOC
