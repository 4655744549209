// src/components/ChargeDetails.js
import React from 'react';

const ChargeDetails = () => {
    const rangeText1 = "> 7.5 Lakhs <= 15 Lakhs"
    const rangeText2 = ">15 Lakhs <= 30 Lakhs"

    return (
        <div className="px-8 ">
            <h2 className="text-sm font-bold mb-4">Charges for File based PAN verification</h2>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white border">
                    <thead>
                        <tr>
                            <th className="py-2 px-4 bg-[#FAD9D5]" colSpan="5">Table No 1</th>
                        </tr>
                        <tr>
                            <th className="py-2 px-4 bg-[#FBE2DF] w-1/2" rowSpan="2">Count of PANs in the file</th>
                            <th className="py-2 px-4 bg-[#FAD9D5]" colSpan="4">Charges (₹)</th>
                        </tr>
                        <tr className='bg-[#F7F7F7]'>
                            <th className="py-2 px-4">Slab 1</th>
                            <th className="py-2 px-4">Slab 2</th>
                            <th className="py-2 px-4">Slab 3</th>
                            <th className="py-2 px-4">Slab 4</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        <tr>
                            <td className="py-2 px-4 font-normal"></td>
                            <td className="py-2 px-4 font-bold">@0.30</td>
                            <td className="py-2 px-4 font-bold">@0.25</td>
                            <td className="py-2 px-4 font-bold">@0.15</td>
                            <td className="py-2 px-4 font-bold">@0.10</td>
                        </tr>
                        <tr>
                            <td className="py-2 px-4">1-200</td>
                            <td className="py-2 px-4">60</td>
                            <td className="py-2 px-4">50</td>
                            <td className="py-2 px-4">30</td>
                            <td className="py-2 px-4">10</td>
                        </tr>
                        <tr>
                            <td className="py-2 px-4">201-400</td>
                            <td className="py-2 px-4">120</td>
                            <td className="py-2 px-4">100</td>
                            <td className="py-2 px-4">60</td>
                            <td className="py-2 px-4">20</td>
                        </tr>
                        <tr>
                            <td className="py-2 px-4">401-600</td>
                            <td className="py-2 px-4">180</td>
                            <td className="py-2 px-4">150</td>
                            <td className="py-2 px-4">90</td>
                            <td className="py-2 px-4">30</td>
                        </tr>
                        <tr>
                            <td className="py-2 px-4">601-800</td>
                            <td className="py-2 px-4">240</td>
                            <td className="py-2 px-4">200</td>
                            <td className="py-2 px-4">120</td>
                            <td className="py-2 px-4">40</td>
                        </tr>
                        <tr>
                            <td className="py-2 px-4">801-1000</td>
                            <td className="py-2 px-4">300</td>
                            <td className="py-2 px-4">250</td>
                            <td className="py-2 px-4">150</td>
                            <td className="py-2 px-4">50</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h1 className='pt-8 text-lg md:text-[22px] leading-5 font-medium'>Charges-Details</h1>
            <ul className='pt-4 list-disc list-inside text-sm md:text-[15px] font-normal leading-6' l>
                <li>The registration charges are ₹12,000 p.a. plus Goods and Services Tax (GST).</li>
                <li>This facility is provided for one year and the same needs to be renewed on yearly basis.</li>
                <li>Annual renewal charges are ₹12,000 p.a. plus Goods and Services Tax (GST).</li>
                <li>PANs verified through file upload under 'File and Screen Based' facility are chargeable.</li>
                <li>The entity availing 'File and Screen Based' or 'API Based' facility has to maintain sufficient advance against its user ID in order to verify PANs which are more than the free limit of PANs per day.</li>
                <li>The registration charges are non-refundable. Only in case where the registration is rejected by Income Tax Department, the registration charges will be refunded.</li>
            </ul>
            <div className="overflow-x-auto">
                <table className="mt-8 w-full">
                    <thead>
                        <tr>
                            <th className="py-2 px-4 bg-[#FAD9D5]" colSpan="5">Table No 2</th>
                        </tr>
                        <tr>
                            <th className="py-2 px-4 bg-[#FBE2DF]">Slabs (PANs verified during a financial year)</th>
                            <th className="py-2 px-4 bg-[#F7F7F7]">Rate (paisa)</th>
                            <th className="py-2 px-4 bg-[#F7F7F7]">Maximum Daily Free Limit</th>
                        </tr>
                    </thead>
                    <tbody className='text-center bg-white'>
                        <tr>
                            <td className="p-2">UPTO 7.5 Lakhs</td>
                            <td className="p-2">30</td>
                            <td className="p-2">750</td>
                        </tr>
                        <tr>
                            <td className="p-2">{rangeText1}</td>
                            <td className="p-2">25</td>
                            <td className="p-2">1,000</td>
                        </tr>
                        <tr>
                            <td className="p-2">{rangeText2}</td>
                            <td className="p-2">15</td>
                            <td className="p-2">1,500</td>
                        </tr>
                        <tr>
                            <td className="p-2">above 30 Lakhs</td>
                            <td className="p-2">5</td>
                            <td className="p-2">2,500</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ChargeDetails;
