import React from "react";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import Card from "../../../components/Card";
import correction from "../../../assets/images/correction.png";
import grp1 from "../../../assets/images/correctionGrp1Mobile.png";
import grp2 from "../../../assets/images/correctionGrp2Mobile.png";
const CorrectionInPAN = () => {
  const { width } = useWindowDimensions();
  const isMobile = width <= 700;

  const cardDataArray = [
    {
      linkData: [
        {
          text: "Read Guidelines",
          textColor: "black",
          iconColor: "black",
          borderBottom: "1px solid black",
          maxWidth: "207px",
          href: "https://www.protean-tinpan.com/services/pan/correction.html",
        },
        {
          text: "Read Instructions",
          textColor: "black",
          iconColor: "black",
          borderBottom: "1px solid black",
          maxWidth: "207px",
          href: "https://www.protean-tinpan.com/services/pan/instructionscorr.html",
        },
        {
          text: "Documents to be submitted",
          textColor: "black",
          iconColor: "black",
          borderBottom: "1px solid black",
          maxWidth: "207px",
          href: "https://www.protean-tinpan.com/services/pan/documents-req.html",
        },
        {
          text: "Do’s and Don’ts",
          textColor: "black",
          iconColor: "black",
          borderBottom: "1px solid black",
          maxWidth: "207px",
          href: "https://www.protean-tinpan.com/services/pan/cr-do-donts.html",
        },
      ],
      defaultLinkMinWidth: width > 1024 ? "279px" : "300px", // Default min width for this card's links
    },
  ];

  const cardDataArray2 = [
    {
      linkData: [
        {
          text: "NEW Designated centers accepting online PAN Documents",
          textColor: "black",
          iconColor: "black",
          borderBottom: "1px solid black",
          maxWidth: "207px",
          href: "https://www.protean-tinpan.com/downloads/List_Of_Centres_For_Submission_Of_Online_PAN_Documents.xlsx",
        },
        {
          text: "Regenerate ack receiptStatus of online payment (application made from July 16, 2016 onwards)",
          textColor: "black",
          iconColor: "black",
          borderBottom: "1px solid black",
          maxWidth: "239px",
          href: "https://www.onlineservices.nsdl.com/paam/endUserRegisterContact.html",
        },
        {
          text: "Regenerate ack receiptStatus of online payment (application on or before July 15, 2016)",
          textColor: "black",
          iconColor: "black",
          borderBottom: "1px solid black",
          maxWidth: "239px",
          href: "https://tin.tin.nsdl.com/pan/changemode.html",
        },
      ],
      defaultLinkMinWidth: width > 1024 ? "279px" : "300px", // Default min width for this card's links
    },
  ];

  return (
    <section className="w-full p  lg:px-[70px] bg-no-repeat flex flex-col lg:flex-row justify-between relative   ">
      <div className="flex bg-[#FDF2E9] relative bg-no-repeat bg-contain py-10 lg:py-0 flex-col md:min-h-[500px] md:flex-row justify-between ">
        {/* Left Section */}
        <img
          src={correction}
          alt="reprint"
          className="absolute right-0 bottom-0 hidden md:block"
          width={"1007px"}
        />
        <img
          src={grp2}
          alt="reprint"
          className="absolute right-0 bottom-0 block md:hidden"
          width={"50px"}
        />

        <div className="md:w-1/2 flex flex-col gap-6 lg:pr-10 px-4 lg:px-10 py-0 lg:py-10 relative">
          <img
            src={grp1}
            alt="reprint"
            className="absolute right-8 bottom-0 block md:hidden"
            width={"80px"}
          />

          <h2 className="text-[26px] lg:text-[26px] font-semibold text-black">
            Change / Correction in PAN Data
          </h2>
          <p className="text-black text-[15px] lg:text-[16px] font-light">
            This application should be used when PAN has already been allotted
            to the applicant but the applicant wants to make changes/corrections
            in PAN data. A new PAN card bearing the same PAN with updated
            details will be issued to the applicant. Appropriate check boxes in
            front of the relevant core details (i.e., name, father’s name, date
            of birth) should be selected to update the details.
          </p>
          <a
            href="https://www.onlineservices.nsdl.com/paam/endUserRegisterContact.html"
            className="w-max"
          >
            <button
              type="button"
              className="py-[12px] text-base font-medium text-white w-fit gap-4 flex items-center justify-between bg-black px-[24px] rounded-[8px] text-center"
            >
              Apply now
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_2429_15128)">
                  <path
                    d="M5 12H19"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15 16L19 12"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15 8L19 12"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2429_15128">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </a>
        </div>

        {/* Right Section */}
        <div className="flex flex-col lg:flex-row lg:gap-10 lg:pr-10 items-center lg:items-start px-4 lg:px-0">
          {cardDataArray.map((cardData, index) => (
            <Card
              key={index}
              {...cardData}
              className="min-w-full lg:min-w-[200px] justify-center items-center mb-4 lg:mb-0"
            />
          ))}
          {cardDataArray2.map((cardData, index) => (
            <Card
              key={index}
              {...cardData}
              className="min-w-full lg:min-w-[200px] justify-center items-center mb-4 lg:mb-0"
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default CorrectionInPAN;
