// src/components/Header.js
import React, { useState } from 'react';
import { ArrowUpSvg, DropDownArrowSvg, ThreeDotsBulletSvg } from '../../../assets/svgs';

const Header = () => {
    const isMobile = window.innerWidth <= 768;
    const [isOverviewOpen, setIsOverviewOpen] = useState(isMobile || true)

    const toggleOverview = () => {
        setIsOverviewOpen(!isOverviewOpen)
    }

    return (
        <header className="bg-[#FCEBE9] text-black text-center px-8 pt-8 mt-6">
            <div className='px-4 md:px-0 flex items-center justify-between'>
                <h1 className='flex flex-row text-lg md:text-[26px] font-semibold gap-2 leading-5'>
                    <ThreeDotsBulletSvg fillColor={'#E43625'} className={'w-[34px] h-[17px]'} />
                    Charges
                </h1>
                {isMobile && <button onClick={toggleOverview} className=''>
                    {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                </button>}
            </div>
        </header>
    );
};

export default Header;
