// ContactUs.jsx
import React from "react";

const ContactUs = () => {
  return (
    <section className="lg:px-[70px] pb-8 ">
      <div className="p-5 bg-pink-50 rounded font-sans  ">
        <h2 className="text-xl font-bold mb-2">5. Contact us.</h2>
        <div className="p-4">
          <p className="mb-4">
            <strong>a. For more information</strong>
          </p>
          <p className="mb-4">
            Call PAN/TDS Call Centre at 020 - 27218080
            <br />
            Fax: 020-27218081
            <br />
            E-mail us at:{" "}
            <a
              href="mailto:tininf@proteantech.in"
              className="text-red-600 no-underline"
            >
              tininf@proteantech.in
            </a>
            <br />
            SMS PTNPAN space 15 digit Acknowledgement No. & send to 57575 to
            obtain application status
            <br />
            Write to: INCOME TAX PAN SERVICES UNIT (Managed by Protean eGov
            Technologies Limited) 4th Floor, Sapphire Chambers, Baner Road,
            Baner, Pune - 411045
          </p>
          <p>
            Click here for detailed{" "}
            <a href="#" className="underline text-blue-600">
              Instructions for filling change request
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
