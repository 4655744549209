import React, { useState } from "react";
import Card3 from "../../../assets/images/softwareCard3.png"
import { ArrowUpSvg, DropDownArrowSvg, ThreeDotsBulletSvg } from "../../../assets/svgs";

export default function PanApplications({ language }) {
    const isMobile = window.innerWidth <= 768;
    const [isOverviewOpen, setIsOverviewOpen] = useState(isMobile || true)

    const toggleOverview = () => {
        setIsOverviewOpen(!isOverviewOpen)
    }

    const textContent = {
        en: {
            overviewTitle: "PAN Applications by Foreign Citizens",
            overviewDescription: "Guidelines for PAN Applicants by foreign citizens",
        },
        hi: {
            overviewTitle: "विदेशी नागरिकों द्वारा पैन आवेदन",
            overviewDescription: "विदेशी नागरिकों द्वारा पैन आवेदकों के लिए दिशानिर्देश",
        }
    };

    return (
        <section className="relative text-black pb-10 lg:pb-10">
            <div className='px-4 md:px-0 flex items-center justify-between pb-6'>
                <h1 className='flex flex-row text-[26px] font-semibold gap-2 leading-5'>
                    <ThreeDotsBulletSvg fillColor={'#3091D0'} className={'w-[34px] h-[17px]'} />
                    {textContent[language].overviewTitle}
                </h1>
                {isMobile && <button onClick={toggleOverview} className=''>
                    {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                </button>}
            </div>
            {isOverviewOpen && <div className="flex flex-wrap gap-[1.6rem] mt-10">
                <div
                    className="flex justify-between  bg-no-repeat bg-cover min-w-full lg:min-w-[412px] min-h-[203px]"
                    style={{ backgroundImage: `url(${Card3})` }}>
                    <a href="https://www.protean-tinpan.com/downloads/pan/Additionalguidelines_Revised_Oct_2019.pdf" className="cursor-pointer w-full md:w-[90%]">
                        <div class="flex justify-between  px-4  py-[8px]   ">
                            <p class=" max-w-[252px] text-white text-[23px] font-semibold">
                                {textContent[language].overviewDescription}
                            </p>

                            <div
                                className={`rounded-full w-[40px] h-[40px] flex justify-center items-center `}
                                style={{ backgroundColor: '#EE7F25' }}
                            >
                                <svg
                                    class="text-[#fefefe]"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M12.9495 3.05078L3.04997 12.9503"
                                        stroke="#fefefe"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M12.9495 8.70764V3.05078"
                                        stroke="#fefefe"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M7.29291 3.05108H12.9498"
                                        stroke="#fefefe"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>
                        </div>
                    </a>
                </div>
            </div>}
        </section>
    );
}
