import React from 'react'
import { ThreeDotsBulletSvg } from '../../../assets/svgs';

function ChallanSection() {
    return (
        <div className='flex md:px-[68px] py-6 md:flex-row w-full gap-6 justify-between'>
            <div className="bg-[#E43625] bg-opacity-10 p-8">
                <h1 className='flex flex-row text-[26px] font-semibold gap-2 pb-6 leading-5'>
                    <ThreeDotsBulletSvg fillColor={'#E43625'} className={'w-[34px] h-[17px]'} />
                    Single copy challans
                </h1>
                <p className='text-sm font-bold'>
                    Prior to June 1, 2004, there were seven different types of challans for depositing direct taxes into the government account.
                </p>
                <p className='text-sm pb-6 font-bold'>
                    These were replaced by three types of challans as follows:
                </p>
                <ul className="list-disc ml-6 mb-4 text-sm">
                    <li>A common single copy challan No. ITNS 280 for payment of Income tax, Corporation tax and Wealth tax;</li>
                    <li>A common single copy challan No. ITNS 281 for depositing Tax Deducted at Source from corporates or non-corporates;</li>
                    <li>A common single copy challan No. ITNS 282 for payment of Gift-tax, Estate Duty, Expenditure Tax and Other direct taxes.</li>
                </ul>
                <p className='text-sm pb-6'>
                    With effect from July 2005, the following challans should be used instead of the above mentioned challans:
                </p>
                <ul className="list-disc ml-6 text-sm pb-6">
                    <li>A common single copy challan No. ITNS 280 for payment of Income tax and Corporation tax;</li>
                    <li>A common single copy challan No. ITNS 281 for depositing Tax Deducted at Source / Tax Collected at Source (TDS/TCS) from     corporates or non-corporates;</li>
                    <li>A common single copy challan No. ITNS 282 for payment of Hotel Receipts Tax, Estate Duty, Wealth Tax, Gift-tax, Expenditure Tax and Other direct taxes;</li>
                    <li>A common single copy challan No. ITNS 283 for payment of Banking Cash Transaction Tax and Fringe Benefits Tax;</li>
                    <li>A common single copy challan No. ITNS 286 for payment under Income Declaration Scheme, 2016;</li>
                    <li>A common single copy challan No. ITNS 287 for payment under Pradhan Mantri Garib Kalyan Yojana, 2016 (PMGKY).</li>
                </ul>
                <p className='text-sm pb-6'>
                    The collecting bank branch will put a rubber stamp on the challan and its counterfoil indicating a unique Challan Identification Number (CIN) comprising of seven digit BSR Code allotted by RBI to that bank branch, the date of deposit (dd/ mm/ yy i.e. six digits), and the challan serial number in 5 digits. CIN will, therefore, be unique for each challan through out the country and will be used for identifying the challan in the OLTAS.
                </p>
                <p className='text-sm pb-6'>
                    The new single copy challans will have a main portion at the top and a 'taxpayer counterfoil' at the bottom. The bank will retain the main portion of the challan and return the 'taxpayer counterfoil' duly receipted to the taxpayer. Because there will be only one copy of challan it becomes extremely important that the <span className='font-bold'>challan is correctly filled and PAN of the taxpayer and PAN / TAN of the deductor as the case may be, is correctly indicated in the challan, and that the right columns are ticked/ filled in the challan, and that the taxpayer collects a proper stamped acknowledgement from the banks indicating the Challan Identification Number (CIN) as indicated above.</span>
                </p>
                <p className='text-sm pb-6'>
                    The collecting bank will capture the entire data of the challan and transmit it electronically to the Income-tax Department. The bank will send the paper copy of the challans alongwith printed scrolls to the Zonal Accounts Officers. The information received from banks will be used by the Department to give credit for the tax paid based on CIN.
                </p>
                <p className='text-sm pb-6'>
                    Since the taxpayer will have only a counterfoil, the requirement to enclose proof of payment of pre-paid taxes with the return of income as contained in 'Explanation' to Section 139 (9) shall stand fulfilled if CIN of the challans for payment of self-assessment tax and Advance Tax is indicated in the return of income.
                </p>
                <p className='text-sm pb-6 font-bold'>
                    Dos and Don'ts for Deductors/Collectors
                </p>
            </div>
        </div>
    )
}

export default ChallanSection;
