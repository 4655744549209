// src/components/Note.js
import React from 'react';

const Note = () => {
    return (
        <div className="p-8">
            <p className='text-[22px] leading-5 font-medium'>Note</p>
            <div className='md:w-3/4'>
                <ul className='list-inside list-decimal text-sm font-bold pt-4'>
                    <li>The adjusted amount on per PAN verification and free usage (PAN verified through screen based) shall be adjusted.</li>
                </ul>
                <p className="text-sm">
                    e.g.: If an entity verifies 13 Lakhs PANs during a financial year through 'Screen and File Based' type, it falls in the category of &gt; 7.5 Lakhs &le; 15 Lakhs as mentioned in the above table. It will get the benefit of 1000 free PANs per day and revised rate of 25 Paisa for PAN verification. Benefit of increase in the limit of free PANs per day is applicable only if the PANs are verified through Screen based in the category of "Screen and File based facility". The above charges are applicable for PANs verified through 'Screen and File Based' or 'API based' facility only. The annual registration fees would remain the same as before.
                </p>
            </div>
        </div>
    );
};

export default Note;
