// StepsForApplication.js
import React from "react";
import Graphic from "../../../assets/images/Link11Graphic3.png";
const stepsContent = [
  {
    en: "Applicant will fill PAN Change Request Form online and submit the form. The same form will be applicable for citizens as well as non-citizens of India.",
    hi: "आवेदक ऑनलाइन पैन परिवर्तन अनुरोध फॉर्म भरेंगे और फॉर्म जमा करेंगे। यह फॉर्म भारत के नागरिकों के साथ-साथ गैर-नागरिकों के लिए भी लागू होगा।",
  },
  {
    en: "If the data submitted fails in any format level validation, a response indicating the error(s) will be displayed on the screen. The applicant shall rectify the error(s) and re-submit the form.",
    hi: "यदि प्रस्तुत डेटा किसी भी प्रारूप स्तर सत्यापन में विफल रहता है, तो त्रुटि (त्रुटियों) का संकेत देने वाला एक प्रतिक्रिया स्क्रीन पर प्रदर्शित किया जाएगा। आवेदक त्रुटि (त्रुटियों) को सुधारें और फॉर्म को पुनः सबमिट करें।",
  },
  {
    en: "If there are no format level error(s), a confirmation screen with data filled by the applicant will be displayed. The applicant may either edit or confirm the same.",
    hi: "यदि कोई प्रारूप स्तर की त्रुटियाँ नहीं हैं, तो आवेदक द्वारा भरे गए डेटा के साथ एक पुष्टिकरण स्क्रीन प्रदर्शित की जाएगी। आवेदक इसे संपादित या पुष्टि कर सकते हैं।",
  },
  {
    en: "For Changes or Correction in PAN data, fill all mandatory fields (marked with *) of the Form and select the corresponding box on left margin of appropriate field where correction is required.",
    hi: "पैन डेटा में परिवर्तन या सुधार के लिए, फॉर्म के सभी अनिवार्य फ़ील्ड ( * से चिह्नित) भरें और जहाँ सुधार की आवश्यकता हो, उपयुक्त फ़ील्ड के बाएँ मार्जिन पर संबंधित बॉक्स चुनें।",
  },
  {
    en: "If the application is for re-issuance of a PAN card without any changes in PAN related data of the applicant, fill all fields in the Form but do not select any box on left margin.",
    hi: "यदि आवेदन आवेदक के पैन संबंधित डेटा में किसी भी बदलाव के बिना पैन कार्ड को पुनः जारी करने के लिए है, तो फॉर्म में सभी फ़ील्ड भरें लेकिन बाएँ मार्जिन पर किसी भी बॉक्स का चयन न करें।",
  },
  {
    en: "In case of either a request for Change or Correction in PAN data or request for re-issuance of a PAN Card without any changes in PAN data, the address for communication will be updated in the ITD database using address for communication provided in the application.",
    hi: "पैन डेटा में बदलाव या सुधार के अनुरोध के मामले में या पैन कार्ड के पुनः जारी करने के लिए, संचार के लिए पता आवेदन में प्रदान किए गए पते का उपयोग करके आईटीडी डेटाबेस में अपडेट किया जाएगा।",
  },
  {
    en: "For cancellation of PAN, fill all mandatory fields in the Form, enter PAN to be cancelled in Item No.10 of the Form and select the check box on left margin. PAN to be cancelled should not be same as PAN (the one currently used) mentioned at the top of the Form.",
    hi: "पैन रद्द करने के लिए, फॉर्म में सभी अनिवार्य फ़ील्ड भरें, फॉर्म के आइटम नंबर 10 में रद्द किए जाने वाले पैन को दर्ज करें और बाएँ मार्जिन पर चेक बॉक्स का चयन करें। रद्द किया जाने वाला पैन (वर्तमान में उपयोग किए जा रहे) फॉर्म के शीर्ष पर उल्लेखित पैन के समान नहीं होना चाहिए।",
  },
];

function StepsForApplication({ language }) {
  return (
    <div className="flex flex-col justify-center bg-[#F1EBF3] p-8  relative ">
      <img
        src={Graphic}
        alt=""
        className="absolute bottom-0 right-2 block lg:hidden"
      />
      <h2 className="text-[26px] font-semibold m-0 ">
        {language === "en"
          ? "1. Steps for Online Application"
          : "1. ऑनलाइन आवेदन के लिए चरण"}
      </h2>
      <ol className="list-decimal flex flex-col gap-2 text-[15px] mt-2 ml-4">
        {stepsContent.map((step, index) => (
          <li key={index}>{step[language]}</li>
        ))}
      </ol>
    </div>
  );
}

export default StepsForApplication;
