import React from "react";
import svgs from "../../../assets/svgs";
import "../../../index.css";

const MovingCards = () => {
  // Data for the cards
  const cardsData = [
    {
      title: "Caution - Online PAN applicants",
      url: "https://www.protean-tinpan.com/downloads/CAUTION-Online%20PAN_TAN_TDS_24G%20applicants.pdf",
    },
    {
      title: "Protean Cautions Public Against Spurious Calls",
      url: "https://www.protean-tinpan.com/downloads/Protean%20Cautions%20Public%20Against%20Spurious%20Calls.pdf",
    },
    {
      title: "Caution - Online PAN applicants",
      url: "https://www.protean-tinpan.com/downloads/CAUTION-Online%20PAN_TAN_TDS_24G%20applicants.pdf",
    },
    {
      title: "Protean Cautions Public Against Spurious Calls",
      url: "https://www.protean-tinpan.com/downloads/Protean%20Cautions%20Public%20Against%20Spurious%20Calls.pdf",
    },
    {
      title: "Caution - Online PAN applicants",
      url: "https://www.protean-tinpan.com/downloads/CAUTION-Online%20PAN_TAN_TDS_24G%20applicants.pdf",
    },
    {
      title: "Protean Cautions Public Against Spurious Calls",
      url: "https://www.protean-tinpan.com/downloads/Protean%20Cautions%20Public%20Against%20Spurious%20Calls.pdf",
    },
    {
      title: "Caution - Online PAN applicants",
      url: "https://www.protean-tinpan.com/downloads/CAUTION-Online%20PAN_TAN_TDS_24G%20applicants.pdf",
    },
  ];

  return (
    <section className="w-full flex flex-col relative lg:px-0 lg:min-h-[20vh]">
      <div className="lg:px-[68px] px-[20px] py-[20px]">
        {/* <p className="text-[26px] text-black text-left flex items-center justify-start gap-2">
          <svg
            width="34"
            height="17"
            viewBox="0 0 34 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
              fill="#E43625"
            />
            <path
              d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
              fill="#E43625"
            />
            <circle cx="25.5" cy="8.5" r="8.5" fill="#E43625" />
          </svg>
          Circulars
        </p> */}
      </div>
      <div className="py-[20px] marquee-container overflow-hidden whitespace-nowrap">
        <div className="marquee flex items-center gap-4">
          {/* Map through the data and render each card */}
          {cardsData.map((card, index) => (
            <React.Fragment key={index}>
              <svg
                width="101"
                height="72"
                viewBox="0 0 101 72"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.7"
                  d="M88.5921 36C88.5921 29.1675 94.1416 23.6144 101 23.6144L101 0C81.0792 1.74153e-06 64.9353 16.1149 64.9353 36C64.9353 55.8851 81.0792 72 101 72L101 48.3856C94.1552 48.3856 88.5921 42.8461 88.5921 36Z"
                  fill="#EBEBEB"
                />
                <path
                  opacity="0.4"
                  d="M49.5274 36C49.5274 29.1675 55.0769 23.6144 61.9353 23.6144L61.9353 0C42.0145 1.74153e-06 25.8706 16.1149 25.8706 36C25.8706 55.8851 42.0145 72 61.9353 72L61.9353 48.3856C55.0905 48.3856 49.5274 42.8461 49.5274 36Z"
                  fill="#EBEBEB"
                />
                <path
                  opacity="0.3"
                  d="M22.8706 28.2963L9.37191 14.8218C-2.3406 26.5133 -2.3406 45.4865 9.37191 57.178L22.8706 43.7035C18.6165 39.457 18.6165 32.5428 22.8706 28.2963Z"
                  fill="#EBEBEB"
                />
              </svg>
              <a href="#">
                <div className="flex justify-between py-[8px] px-10 bg-[#EBEBEB] min-h-[72px] items-center border-b-white">
                  <a className="text-black" href={card.url}>{card.title}</a>
                  <svg
                    className="text-black ml-2"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.9495 3.05078L3.04997 12.9503"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.9495 8.70764V3.05078"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.29291 3.05108H12.9498"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </a>

              <svg
                width="101"
                height="72"
                viewBox="0 0 101 72"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.7"
                  d="M12.4079 36C12.4079 42.8325 6.85843 48.3856 0 48.3856L0 72C19.9208 72 36.0647 55.8851 36.0647 36C36.0647 16.1149 19.9208 0 0 0L0 23.6144C6.8448 23.6144 12.4079 29.1539 12.4079 36V36Z"
                  fill="#EBEBEB"
                />
                <path
                  opacity="0.4"
                  d="M51.4723 36C51.4723 42.8325 45.9229 48.3856 39.0645 48.3856L39.0645 72C58.9853 72 75.1292 55.8851 75.1292 36C75.1292 16.1149 58.9853 0 39.0645 0V23.6144C45.9092 23.6144 51.4723 29.1539 51.4723 36V36Z"
                  fill="#EBEBEB"
                />
                <path
                  opacity="0.3"
                  d="M78.1289 43.7039L91.6276 57.1784C103.34 45.4869 103.34 26.5138 91.6276 14.8223L78.1289 28.2968C82.383 32.5433 82.383 39.4574 78.1289 43.7039Z"
                  fill="#EBEBEB"
                />
              </svg>
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="13" cy="13" r="13" fill="#EBEBEB" />
              </svg>
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MovingCards;
