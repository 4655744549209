import React from 'react';
import { Element } from 'react-scroll';
import PANHeroSection from '../../components/PANHeroSection';
import TypesOfPan from './components/TypesOfPan';
import SecondSection from '../Link6/components/SecondSection';
import withScrollToHash from '../../hooks/useScrollToHash';
import { Helmet } from 'react-helmet';

const Link7 = () => {
    return (
        <>
            <Helmet>
                <title>PAN Introduction: Overview and Importance of PAN Card | Protean</title>
                <meta name="description" content="Learn about PAN card, its importance, and how it works. Get an introduction to the PAN system and its benefits for taxpayers in India." />
            </Helmet>
            <div className="flex flex-col gap-8">
                <PANHeroSection
                    isVectorVisible={true}
                    title={"Permanent Account Number (PAN)"}
                    isPAN={true}
                    isFirstButton={true}
                    firstButtonTitle="Apply Now"
                    firstButtonLink="/services/pan/pan-index"
                />
                <Element name="second-section">
                    <SecondSection />
                </Element>
                <Element name="types-of-pan">
                    <TypesOfPan />
                </Element>
            </div>
        </>
    );
};

export default withScrollToHash(Link7); // Use the HOC
