import React from 'react';
import { Element } from 'react-scroll';
import SecondSection from './components/SecondSection';
import CommonBanner from '../../components/CommonBanner';
import PanApplication from './components/PanApplication';
import Overview from './components/Overview';
import TANApplication from './components/TANApplication';
import withScrollToHash from '../../hooks/useScrollToHash'; // Correctly import the HOC
import { Helmet } from 'react-helmet';

function Link23() {
    return (
        <>
            <Helmet>
                <title>Track PAN Application Status - Step-by-Step Guide | Protean</title>
                <meta name="description" content="Use Protean's guide to track the status of your PAN application. Follow our step-by-step instructions for real-time updates on your PAN card processing." />
                <meta name="keywords" content="tin call centre, nsdl pan card status, check pan card status, track pan card status, pan card application status, pan card status by name and date of birth, uti pan card status, track pan card" />
            </Helmet>
            <div className="flex flex-col gap-8">
                <CommonBanner title={"Guided Tours"} isGuidedTours={true} desktopWidth='40vw' />
                <Element name="second-section">
                    <SecondSection />
                </Element>
                <Element name="overview">
                    <Overview />
                </Element>
                <Element name="pan-application">
                    <PanApplication />
                </Element>
                <Element name="tan-application">
                    <TANApplication />
                </Element>
            </div>
        </>
    );
}

export default withScrollToHash(Link23); // Use the HOC
