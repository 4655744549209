// src/ETDSComponent.js
import React from 'react';
import { ExtractionTDSBlueSvg } from '../../../assets/svgs';

const ETDSComponent = () => {
    return (
        <div className="items-stretch w-full flex flex-col pt-4 md:pt-6 md:flex-row gap-4 md:gap-6">
            <div className="bg-[#EE7F25] relative bg-opacity-10 flex flex-col p-8 md:w-1/2">
                <h2 className="text-base leading-[22px] font-semibold mb-4">Extraction of e-TDS/TCS FVU</h2>
                <ul className="list-disc list-inside text-sm leading-6 font-normal">
                    <li>To extract these files, double-click on 'e-TDS FVU.exe'.</li>
                    <li>A 'WinZip Self-Extractor - e-TDS FVU.exe' will open.</li>
                    <li>By default, the path selected for extraction of the three files will be 'C:\e-TDS FVU'.</li>
                    <li>The files can also be extracted in any other location (other than C:\e-TDS FVU). In that case, the appropriate path has to be defined by clicking the 'Browse' button where the three files should be extracted.</li>
                    <li>Thereafter, click on 'Unzip' button.</li>
                    <li>On clicking the 'Unzip' button, the three files mentioned above will get extracted to the specified path (i.e. in folder 'C:\e-TDS FVU' by default or at the specified path).</li>
                </ul>
                <ExtractionTDSBlueSvg className='absolute top-0 right-16' />
            </div>

            <div className='flex flex-col gap-4 md:gap-6 items-stretch md:w-1/2'>
                <div className="bg-[#EE7F25] bg-opacity-10 p-8 grow">
                    <h2 className="text-base leading-[22px] font-semibold mb-4">Extraction of e-TDS/TCS FVU</h2>
                    <p className='text-sm leading-6 font-normal'>
                        The e-TDS/TCS FVU can be setup as per the procedure mentioned in the 'e-TDS FVU Readme.rtf' file (one of the three files extracted).
                    </p>
                </div>

                <div className='bg-[#EE7F25] bg-opacity-10 p-8 grow'>
                    <h2 className="text-base leading-[22px] font-semibold mb-4">Running the FVU</h2>
                    <p className='text-sm leading-6 font-normal'>
                        The procedure to run FVU is given in the Readme button on the window opened by clicking e-TDS/TCS FVU icon.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ETDSComponent;