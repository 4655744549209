import React from "react";
import { Element } from "react-scroll";
import CommonBanner from "../../components/CommonBanner";
import SearchAndContactUsSection from "../../components/SearchAndContact";
import BranchOfficesGrid from "./components/BranchOfficesGrid";
import PANCallCenter from "./components/PANCallCentre";
import withScrollToHash from "../../hooks/useScrollToHash"; // Correctly import the HOC
import { Helmet } from "react-helmet";

function Link20() {
  return (
    <>
      <Helmet>
        <title>Protean Addresses for PAN Centres - Protean Pan & Tan Services</title>
        <meta name="description" content="Locate Protean addresses for PAN card services. Get contact details and directions." />
        <meta name="keywords" content="pan call centre, tds call centre, tax information network, nsdl call centre, pan card call centre, tin nsdl call centre, pan call centre number, nsdl pan card call centre number, tin facilitation" />
      </Helmet>
      <div className="flex flex-col gap-8">
        <CommonBanner
          title={"Customer Care"}
          className={"min-h-[60vh] md:min-h-[50vh]"}
          isCustomerCare
          desktopWidth="466px"
          desktopHeight="466px"
        />
        <Element name="search-and-contact-us">
          <SearchAndContactUsSection />
        </Element>
        <Element name="pan-call-center">
          <PANCallCenter />
        </Element>
        <Element name="branch-offices-grid">
          <BranchOfficesGrid />
        </Element>
      </div>
    </>
  );
}

export default withScrollToHash(Link20); // Use the HOC
