import React, { useState } from 'react'
import { ArrowUpSvg, DropDownArrowSvg, ThreeDotsBulletSvg } from '../../../assets/svgs'
import DynamicLinks from '../../../components/DynamicLink';

function ChangeProcedure() {
    const isMobile = window.innerWidth <= 768;
    const [isOverviewOpen, setIsOverviewOpen] = useState(isMobile || true)

    const toggleOverview = () => {
        setIsOverviewOpen(!isOverviewOpen)
    }

    const cardDataArray = [
        {
            linkData: [
                {
                    text: "Status of Organisation Registration",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    href: 'https://onlineservices.tin.egov-nsdl.com/TIN/statusCheckForEntity.do',
                },
            ],
        },
    ];

    return (
        <div className='md:px-[68px] py-6 flex flex-col md:flex-row gap-6'>
            <div className='flex flex-col gap-6 md:w-1/2'>
                <div className='bg-[#6F368B] flex flex-col gap-6 bg-opacity-10 p-8'>
                    <div className='px-4 md:px-0 flex flex-row items-center justify-between'>
                        <h1 className='flex flex-row text-lg md:text-[26px] font-semibold gap-2 leading-5'>
                            <ThreeDotsBulletSvg fillColor={'#6F368B'} className={'w-[34px] h-[17px]'} />
                            Procedure for Change of DSC
                        </h1>
                        {isMobile && <button onClick={toggleOverview} className=''>
                            {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                        </button>}
                    </div>
                    <div>
                        <p className='text-sm font-semibold leading-6'>To change Digital Signature Certificate (DSC) details, the organization need to follow below procedure *.</p>
                        <ol className='text-sm leading-6 font-normal'>
                            <li>1) Login with user ID using existing Digital Signature Certificate.</li>
                            <li>2) Go to 'Change DSC details' option</li>
                            <li>3) Fill the required details of the DSC, which needs to be mapped with your user ID.</li>
                            <li>4) Select the renewed/Changed DSC.</li>
                        </ol>
                    </div>
                    <p className='text-sm font-semibold leaading-6'>*Note:- Existing DSC and the DSC which needs to be changed is required to be installed in the machine before following the above procedure.</p>
                    <div>
                        <p className='text-sm font-semibold leading-6'>If current DSC is expired or you are unable to login with registered DSC, the following documents are required for change of Digital Signature Certificate (DSC):</p>
                        <ol className='text-sm leading-6 font-normal list-decimal list-inside'>
                            <li><a href='https://www.protean-tinpan.com/downloads/online-pan-verification/DSC_updation_request_cum_Authorisation_letter_OPV_R.docx' className='underline'>DSC change request cum Authorisation Letter</a></li>
                            <li>Screen Shots of new DSC *</li>
                        </ol>
                    </div>
                    <div>
                        <p className='text-sm font-semibold leading-6'>*Note</p>
                        <p className='text-sm font-semibold leading-6'>Go to Internet explorer - Tools - Internet Options - Contents - Certificates - Personal Tab (and view certificate) then take below screen shots.</p>
                        <ol className='text-sm font-normal leading-6 list-inside list-decimal'>
                            <li>General Tab</li>
                            <li>Details Tab - Serial Number</li>
                            <li>Details Tab - Authority Key Identifier</li>
                        </ol>
                    </div>
                    <div>
                        <p className='text-sm font-normal leading-6'>Please share above mentioned details to change DSC to</p>
                        <p className='text-sm font-normal leading-6'><a href='mailto:PAN-verification@proteantech.in' className='underline'>PAN-verification@proteantech.in</a></p>
                    </div>
                </div>
                <div className='bg-[#54AD47] flex flex-col gap-6 bg-opacity-10 p-8'>
                    <div className='px-4 md:px-0 flex flex-row items-center justify-between'>
                        <h1 className='flex flex-row text-lg md:text-[26px] font-semibold gap-2 leading-5'>
                            <ThreeDotsBulletSvg fillColor={'#54AD47'} className={'w-[34px] h-[17px]'} />
                            Authorization of Registration
                        </h1>
                        {isMobile && <button onClick={toggleOverview} className=''>
                            {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                        </button>}
                    </div>
                    <p className='text-sm font-normal leading-6'>
                        Registration of an Entity is subject to approval by Income Tax department (ITD).

                        Upon receipt of requisite documents from entity, Protean will forward the registration request of the Entity to ITD for approval. Upon approval and post receipt of registration charges, an eight digit user ID will be created and shared with entity on its email ID mentioned in the application.

                        The registration charges will be refunded only if ITD rejects the registration request.
                    </p>
                </div>
                <div className='bg-[#E43625] flex grow flex-col gap-6 bg-opacity-10 p-8'>
                    <div className='px-4 md:px-0 flex flex-col items-center justify-between'>
                        <h1 className='flex flex-row text-lg md:text-[26px] font-semibold gap-2 leading-[30px]'>
                            <ThreeDotsBulletSvg fillColor={'#E43625'} className={'w-[34px] h-[17px]'} />
                            Advance addition to PAN Verification User ID
                        </h1>
                        {isMobile && <button onClick={toggleOverview} className=''>
                            {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                        </button>}
                    </div>
                    <p className='text-sm font-normal leading-6'>
                        The entity availing ‘File and Screen Based ‘or ‘API Based’ facility has to provide advance amount to Protean in order to verify PANs which are more than the free limit as applicable. An email along with E-Invoice of an advance amount addition done will be sent on registered email ID of entity.
                    </p>
                    <div>
                        <p className='text-sm leading-6 font-semibold'>A. Steps to be followed for addition of advance amount</p>
                        <p className='text-sm leading-6 fonnt-normal'>a) Raise addition of advance amount request online as per below steps:-</p>
                        <ul className='text-sm font-normal leading-6 list-disc list-inside'>
                            <li>Login with user ID using Digital Signature Certificate</li>
                            <li>Go to 'Payment' option</li>
                            <li>Select 'Advance Amount' option</li>
                            <li>Fill up payment details and click on 'Submit' button</li>
                        </ul>
                    </div>
                    <div>
                        <p className='text-sm font-semibold leading-6'>B. Email intimation for advance done</p>
                        <p className='text-sm font-normal leading-6'>A system-generated email for advance amount added for the facility will be sent on registered email ID of the entity once the online advance addition request is authorized by Protean.</p>
                    </div>
                </div>
            </div>
            {/* 2nd column */}
            <div className='flex flex-col gap-6 md:w-1/2'>
                <div className='bg-[#EE7F25] flex flex-col gap-6 bg-opacity-10 p-8'>
                    <div className='px-4 md:px-0 flex flex-row items-center justify-between'>
                        <h1 className='flex flex-row text-lg md:text-[26px] font-semibold gap-2 leading-[30px]'>
                            <ThreeDotsBulletSvg fillColor={'#EE7F25'} className={'w-[34px] h-[17px]'} />
                            Procedure for Renewal of PAN Verification facility
                        </h1>
                        {isMobile && <button onClick={toggleOverview} className=''>
                            {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                        </button>}
                    </div>
                    <p className='text-sm font-normal leading-6'>The facility for online PAN verification is provided for one year and same needs to be renewed on a yearly basis where the annual renewal charge is 12,000 (plus Goods and Services Tax (GST) as applicable).</p>
                    <div>
                        <p className='text-sm font-semibold leading-6'>A. Prior email intimation for renewal due</p>
                        <p className='text-sm font-normal leading-6'>Two months in advance, a system-generated email intimating the renewal due and the procedure to be followed to renew the PAN verification facility will be sent on registered email ID of the entity.</p>
                    </div>
                    <div>
                        <p className='text-sm font-semibold leading-6'>B. Time Period to renew facility</p>
                        <p className='text-sm font-normal leading-6'>The facility can be renewed sixty days in advance from the expiry date of PAN Verification facility.</p>
                    </div>
                    <div>
                        <p className='text-sm leading-6 font-semibold'>C. Steps to be followed for renewal</p>
                        <p className='text-sm font-normal leading-6'>a) Raise renewal request online as per below steps:-</p>
                        <ul className='text-sm font-normal leading-6 list-inside list-disc'>
                            <li>Login with user ID using Digital Signature Certificate</li>
                            <li>Go to 'Payment' option</li>
                            <li>Select 'Renewal of Facility' option</li>
                            <li>Fill up payment details and click on 'Submit' button</li>
                        </ul>
                    </div>
                    <div>
                        <p className='text-sm font-semibold leading-6'>D. You are required to make a payment in Virtual Account Number assigned against your Online PAN Verification user ID.</p>
                        <p className='text-sm font-semibold leading-6'>E. Email intimation for renewal done</p>
                        <p className='text-sm font-normal leading-6'>An email along with E-Invoice mentioning validity period of your user ID will be sent on registered email ID of entity.</p>
                    </div>
                </div>
                <div className='bg-[#FFCE1B] flex flex-col gap-6 bg-opacity-10 p-8'>
                    <div className='px-4 md:px-0 flex flex-row items-center justify-between'>
                        <h1 className='flex flex-row text-lg md:text-[26px] font-semibold gap-2 leading-5'>
                            <ThreeDotsBulletSvg fillColor={'#FFCE1B'} className={'w-[34px] h-[17px]'} />
                            Registration Status Track
                        </h1>
                        {isMobile && <button onClick={toggleOverview} className=''>
                            {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                        </button>}
                    </div>
                    <p className='text-sm font-normal leading-6'>The organisation can enquire the status of its registration on the basis of its acknowledgment number. On successful registration a screen displaying status as accepted. On creation of user ID for provided acknowledgement number, user ID will be displayed, if registration is rejected status displayed will be rejected.</p>
                    <div className="flex flex-col md:flex-row">
                        {cardDataArray.map((cardData, index) => (
                            <DynamicLinks key={index} linkData={cardData.linkData} listDir='flex-row' className={"text-black flex-col md:flex-row md:items-end gap-5 px-2 md:px-0"} titleColor='black' />
                        ))}
                    </div>
                </div>
                <div className='bg-[#3091D0] flex flex-col gap-6 bg-opacity-10 p-8'>
                    <div className='px-4 md:px-0 flex flex-row items-center justify-between'>
                        <h1 className='flex flex-row text-lg md:text-[26px] font-semibold gap-2 leading-5'>
                            <ThreeDotsBulletSvg fillColor={'#3091D0'} className={'w-[34px] h-[17px]'} />
                            Procedure to Upload File
                        </h1>
                        {isMobile && <button onClick={toggleOverview} className=''>
                            {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                        </button>}
                    </div>
                    <div>
                        <p className='text-sm leading-6 font-normal'>Online PAN verification facility is available only for those entities who have been duly authorised by Protean.</p>
                        <ul className='text-sm leading-6 font-normal list-disc list-inside'>
                            <li>The user will login to the TIN central system by signing with the DSC associated with it. On authentication of the DSC, the user will get access to the online upload system.</li>
                            <li>If DSC authentication fails the PAN details will not be shown.</li>
                        </ul>
                    </div>
                    <p className='text-sm leading-6 font-normal'>In online PAN verification facility two options have been provided to the user. User will have to take care of following points related to these options:</p>
                    <h2 className='text-lg md:text-[22px] leading-6 font-medium'>File Based Bulk Verification</h2>
                    <div>
                        <p className='text-sm leading-6 font-normal'>
                            Input file should be prepared as per the prescribed <a href='https://www.protean-tinpan.com/downloads/online-pan-verification/pan_inquiry_file_format-v1.3.9-Other-users.xls' className='underline'>file format</a>, for Department of Commercial Taxes separate file format.
                        </p>
                        <ul className='text-sm leading-6 font-normal list-inside list-disc'>
                            <li>The length of the filename should not be more than twelve characters (Including extension). The filename can be alphanumeric. No special characters are allowed in the file name (e.g. name of the file can be: PAN.txt).</li>
                            <li> After successful login, the user will select File Upload from the main menu and upload the input file online by digitally signing the upload.</li>
                            <li>The DSC will be authenticated by Protean. On successful authentication the file will be uploaded and a file reference number will be generated.</li>
                            <li>The status of the file uploaded and the output can be viewed / downloaded by selecting File Status from the main menu.</li>
                        </ul>
                    </div>
                    <p className='text-sm leading-6 font-normal'>
                        Contact PAN verification team at <a href='mailto:PAN-verification@proteantech.in' className='underline'>PAN-verification@proteantech.in</a> for operational queries and <a href='mailto:support_nsdl@proteantech.in' className='underline'>support_nsdl@proteantech.in</a> for technical queries.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ChangeProcedure