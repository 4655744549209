import React from "react";
import { DotGrid, YellowBottomVector } from "../assets/svgs";
import PANHandImage from "../assets/images/PaNVerification.png";
import PANHandImageMd from "../assets/images/panHeroSectionSm.png";

const PANVerification = ({ title, isVectorVisible, className }) => {
  return (
    <div
      className={`lg:max-h-[80vh]  bg-[#F7F7F7] justify-between flex-col h-full flex md:flex-row ${className}`}
    >
      <img
        src={PANHandImageMd}
        alt="PAN Hand"
        className="aspect-square md:hidden"
      />
      <div className="px-4 md:px-0 flex flex-col justify-center items-center gap-[10px] md:gap-[42px]">
        <h1 className="font-semibold text-[#1D1D1B] text-[32px] leading-[43.58px] md:text-[56px] md:leading-[68px] md:max-w-[550px] md:pl-10 pt-[30px] md:pt-0">
          {title}
        </h1>
        <div className="relative self-start mb-[66px] md:mb-0 mt-3 md:mt-0">
          <DotGrid fillColor="#3091D0" />
          {isVectorVisible && (
            <YellowBottomVector className="absolute bottom-0 right-0 h-[42px] max-w-[42px] md:h-[73px] md:max-w-[73px] translate-x-[50%] translate-y-[50%]" />
          )}
        </div>
      </div>
      <img
        src={PANHandImage}
        alt="PAN Hand"
        className=" md:max-w-[500px]    lg:max-w-[600px] aspect-square hidden md:flex"
      />
    </div>
  );
};

export default PANVerification;
