import React, { useState } from "react";
import { ArrowUpSvg, DropDownArrowSvg, ThreeDotsBulletSvg } from "../../../assets/svgs";
import DynamicLinks from "../../../components/DynamicLink";

const DSCSection = () => {
    const isMobile = window.innerWidth <= 768;
    const [isOverviewOpen, setIsOverviewOpen] = useState(isMobile || true)

    const toggleOverview = () => {
        setIsOverviewOpen(!isOverviewOpen)
    }

    const cardDataArray = [
        {
            linkData: [
                {
                    text: "Institute of Development & Research in Banking Technology (IDRBT)",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    gridRow: '1 / span 2',
                    href: "http://idrbtca.org.in/",
                },
                {
                    text: "SafeScrypt",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    href: "https://www.safescrypt.com/drupal/",
                },
                {
                    text: "e-Mudhra",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    href: "http://www.e-mudhra.com/",
                },
                {
                    text: "(n)Code Solutions",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    href: "https://www.ncodesolutions.com/",
                },
                {
                    text: "Capricorn Identity Services",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    href: "https://www.certificate.digital/",
                },
                {
                    text: "VSIGN",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    href: "https://www.vsign.in/",
                },
                {
                    text: "IDSign",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    href: "https://idsignca.com/",
                },
                {
                    text: "Pantasign",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    href: "https://www.pantasign.com/",
                },
                {
                    text: "XtraTrust",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    href: "https://xtratrust.com/",
                },
                {
                    text: "SignX",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    href: "https://signxca.com/",
                },
                {
                    text: "Prodisign",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    href: "https://prodigisign.com/",
                },
            ],
        },
    ];

    return (
        <section className="md:px-[68px]">
            <div className='bg-[#54AD47] flex flex-col gap-6 bg-opacity-10 p-8'>
                <div className='px-4 md:px-0 flex flex-row items-center justify-between'>
                    <h1 className='flex flex-row text-[26px] font-semibold gap-2 leading-5'>
                        <ThreeDotsBulletSvg fillColor={'#54AD47'} className={'w-[34px] h-[17px]'} />
                        System Pre-requisite - Digital Signature Certificate (DSC)
                    </h1>
                    {isMobile && <button onClick={toggleOverview} className=''>
                        {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                    </button>}
                </div>
                <ul className="text-sm leading-6 font-normal list-disc list-inside">
                    <li>
                        The entity must have a Digital Signature Certificate (Class II or
                        Class III) from any of the licensed Certifying Authorities specified
                        below for the purpose of digitally signing the application and the
                        files uploaded online.
                    </li>
                    <li>
                        The Digital Signature Certificate can be procured from the following
                        Certifying Authorities.{" "}
                        <a href="https://www.protean-tinpan.com/downloads/List_of_Valid_DSCs_empanelled_for_Online_PAN_Verification_090323.pdf" className="underline">
                            Click Here
                        </a>{" "}
                        to know list of DSC currently empanelled under Online PAN verification
                        facility.
                    </li>
                </ul>

                <div className="">
                    {cardDataArray.map((cardData, index) => (
                        <DynamicLinks key={index} linkData={cardData.linkData} listDir='flex-row' className={"text-black grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4"} titleColor='black' />
                    ))}
                </div>

                <div>
                    <p className="font-normal text-sm leading-6">
                        The entity desirous of opting for 'API' based facility, need to
                        procure Signature Certificates as described below:
                    </p>
                    <ul className="list-disc list-inside text-sm leading-6 font-normal">
                        <li>
                            1) Document Signer Certificate - Issued in soft format i.e. .pfx
                            format
                        </li>
                        <li>
                            2) Digital Signature Certificate - Issued in physical format i.e.
                            e-token (hardware device)
                        </li>
                    </ul>
                    <p className="font-normal text-sm leading-6">
                        Brief detail of e-Tokens tested using Java code & Dot net code on
                        different operating systems are placed in table below:
                    </p>
                </div>

                <div className="">
                    <table className="w-full">
                        <thead className="bg-[#54AD47] bg-opacity-10 text-sm font-normal leading-6">
                            <tr>
                                <th className="p-3">e-Token</th>
                                <th className="p-3">Java code</th>
                                <th className="p-3">DOT NET Code</th>
                            </tr>
                        </thead>
                        <tbody className="bg-[#F7F7F7] text-center text-sm font-normal leading-6">
                            {[
                                {
                                    token: "Aladdin",
                                    java: "Operating system : 32-bit Windows 7 and 64-bit SUSE Linux 11",
                                    dotNet: "Operating system - 32-bit Windows 7",
                                },
                                {
                                    token: "Gemalto/Moser Baer e-Pass",
                                    java: "Operating system : 32-bit Windows 7",
                                    dotNet: "Visual Studio version 2008 and 2010",
                                },
                                {
                                    token: "WatchData",
                                    java: "Operating system : 32-bit Windows 8 and Windows 7",
                                    dotNet: "IIS 6.0",
                                },
                            ].map((row, index) => (
                                <tr key={index}>
                                    <td className="p-3">{row.token}</td>
                                    <td className="p-3">{row.java}</td>
                                    <td className="p-3">{row.dotNet}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="flex flex-col gap-2">
                    <p className="font-bold text-sm leading-6">
                        Note: The compatibility of e-token is highly dependent on the hosted
                        client infrastructure setup.
                    </p>
                    <p className="text-sm font-normal leading-6 w-2/3">
                        The digital certificate must be in the name of the applicant. An{" "}
                        <a href="https://www.protean-tinpan.com/downloads/online-pan-verification/DSC.doc" className="underline">
                            authorisation letter
                        </a>{" "}
                        by the applicant should be provided on the letterhead of the applicant
                        to Protean.
                    </p>
                </div>

                <div className="flex flex-col gap-4">
                    <h2 className="font-medium text-[22px] leading-5">Notes</h2>
                    <ol className="list-decimal list-inside text-sm font-normal leading-6">
                        <li>
                            In order to use the new components for digital signing and
                            verification, Digital Signature Certificate (DSC) based users are
                            required to comply with the following pre-requisites with respect to
                            the system configuration of the client machines:
                        </li>
                        <li>
                            1. Windows XP Professional service Pack 3, Windows 7, Windows 8
                        </li>
                        <li>
                            2. Oracle JAVA (32-bit) 1.6 update 29 or above till java 1.8 update
                            66
                        </li>
                        <li>3. I.E. browser version 7, 8, 9, 10 and 11</li>
                        <li>
                            4. SafeNet e-Token drivers used should be the latest (if applicable)
                        </li>
                    </ol>
                </div>
            </div>
        </section>
    );
};

export default DSCSection;
