import React from "react";
import { Element } from "react-scroll";
import CommonBanner from "../../components/CommonBanner";
import DocumentsRequired from "./components/DocumentsRequired";
import OverviewAndService from "./components/OverViewAndService";
import ChargeSummary from "./components/ChargeSummary";
import Note from "./components/Note";
import ChargeDetails from "./components/ChargeDetails";
import Header from "./components/Header";
import Procedure from "./components/Procedure";
import withScrollToHash from "../../hooks/useScrollToHash"; // Correctly import the HOC
import ChangeProcedure from "./components/ChangeProcedure";
import DSCSection from "./components/DSCSection";
import FAQComponent from "../../components/Faqs";

function Link26() {
  const linkData = {
    General: [
      {
        text: "What is Online PAN Verification facility?",
        content: "To enable entities to verify Permanent Account Numbers (PANs), Income Tax Department (ITD) has authorized Protean eGov Technologies Limited (Protean) to provide an online PAN verification service for verification of PANs to eligible entities. Three types of facilities are available for PAN Verification facility. Based on the requirement, entities can avail any facility.",
      },
      {
        text: "Which three types of facilities are available for PAN Verification facility?",
        formattedData: true,
        para1: "This service has three types of facilities as mentioned below -",
        isOL1: true,
        olList1: [
          {
            isHeading: true,
            heading: "Screen based Verification:",
            text: "The users, after login, can furnish up to a maximum of five PANs in the screen provided. The PANs may be entered in the boxes provided in the screen. The response giving details of PAN will be displayed in the response screen."
          },
          {
            isHeading: true,
            heading: "File based Verification:",
            text: "The users, after login, can upload a file containing maximum of 1000 PANs (file structure as specified by Protean). In case, file format is incorrect, the system will convey the rejection. The entity can download response regarding acceptance/rejection of file at his convenience up to 15 days."
          },
          {
            isHeading: true,
            heading: "Software (API) based PAN verification:",
            text: "The facility is an interface that allows the user to make an online verification of PAN by accessing the verification-site through a software application."
          }
        ],
      },
      {
        text: "Who are eligible entities who can avail Online PAN Verification facility?",
        formattedData: true,
        para1: "The following entities can avail of online PAN verification facility:-",
        isOL1: true,
        olList1: [
          { text: "PFRDA approved Point of Presence (For Online Platform)" },
          { text: "Direct (Life & General) Brokers approved by IRDAI" },
          { text: "RBI approved Authorized Dealer – Category II" },
          { text: "Mutual Fund Advisor" },
          { text: "Register & Transfer agents approved by SEBI" },
          { text: "Investment Advisor approved by SEBI" },
          { text: "Banks" },
          { text: "Insurance Web Aggregators" },
          { text: "Non Banking Financial Companies approved by RBI" },
          { text: "Prepaid Payment Instrument Issuers approved by RBI" },
          { text: "Housing Finance Companies" },
          { text: "Insurance Company" },
          { text: "Insurance Repository" },
          { text: "Companies and Government deductor (Required to file TDS/TCS return)" },
          { text: "Mutual Funds" },
          { text: "Credit card Companies / Institutions" },
          { text: "Central Recordkeeping Agency of National Pension System" },
          { text: "DSC issuing Authorities" },
          { text: "Depository Participants" },
          { text: "Credit information companies approved by RBI" },
          { text: "KYC Registration Agency (KRA)/Central KYC Registry" },
          { text: "Educational Institutions established by Regulatory Bodies" },
          { text: "Companies (Required to furnish Annual Information Return (AIR)/Statement of Financial Transaction (SFT)" },
          { text: "Any other entity required to furnish Annual Information Return (AIR)/Statement of Financial Transaction (SFT)" },
          { text: "Stock Exchanges/Commodity Exchanges/Clearing Corporations" },
          { text: "Goods and Services Tax Network" },
          { text: "Depositories" },
          { text: "Government Agencies (Central/State)" },
          { text: "Income Tax Projects" },
          { text: "Reserve Bank of India" },
          { text: "Department of Commercial Taxes" },
          { text: "Stamp and Registration Department" },
          { text: "Central Vigilance Commission" },
          { text: "Payments Bank approved by RBI" },
          { text: "Payment And Settlement System Operators authorized by RBI" },
          { text: "Open Network for Digital Commerce(ONDC)" },
          { text: "PFMS" },
        ],
        bottomNote: "*Note: To register under the category “Companies and Government Deductor (Required to file TDS/TCS return)”, entities must have more than 50 deductee/collectee counts displayed on Provisional receipts in a quarter. Counts of deductee/collectee will be vetted at the time of applying for Online PAN verification facility.”",
      },
      {
        text: "How can I avail this facility?",
        formattedData: true,
        para1: "Entity desirous of availing the facility for online PAN verification has to “ register ” with Protean. For this, the entity should forward the following documents to Protean:",
        isUL1: true,
        ulList1: [
          { text: "Signed copy of the Terms & Conditions should be provided on the letterhead of the Entity", url: "https://www.tin-nsdl.com/downloads/online-pan-verification/Terms_and_conditions_R.pdf" },
          { text: "Non-Disclosure Agreement/ Confidentiality Agreements (NDA) on the letterhead of the Entity", url: "https://www.tin-nsdl.com/downloads/online-pan-verification/Terms_and_conditions_R.pdf" },
          { text: "Authorisation letter", url: "https://www.tin-nsdl.com/download/Authority%20letter_R.docx" },
          { text: "Screen Shots of DSC *" },
        ],
        note: "*Note",
        para2: " Go to Internet explorer - Tools - Internet Options - Contents - Certificates - Personal Tab(and view certificate) then take below screen shots.",
        isOL1: true,
        olList1: [
          { text: "General Tab" },
          { text: "Details Tab - Serial Number" },
          { text: "Details Tab - Authority Key Identifier" }
        ],
        isUL2: true,
        ulList2: [
          { text: "Incorporation Certificate (Two copies stamped & signed by authorized entity)" },
          { text: "PAN card copy of entity (Two copies stamped & signed by authorized entity)" },
          { text: "Copy of license/certificate issued by regulatory body (if applicable) (Two copies stamped & signed by authorized entity)" },
          { text: "Declaration by Entities (Two copies stamped & signed by authorized entity)", url: "https://www.tin-nsdl.com/download/Declaration-by-Entities_R.docx" },
          { text: "Copy of Balance sheet and Profit & Loss account statements for latest financial year" },
          { text: "Additional Information on letterhead mentioning below details –" }
        ],
        isOL2: true,
        olList2: [
          { text: "Authorized Capital" },
          { text: "Disbursed amount (Mention NA if not applicable)" },
          { text: "Existing number of customers as on ________(date)" },
          { text: "Expected number of customers __________per year" },
        ],
        isAddress: true,
        address: [
          { title: "Documents to be submitted at the following address:-", line1: "Protean eGov Technologies Limited", line2: "Online PAN Verification Team", line3: "1st floor Times Tower", line4: "Kamala Mill Compound", line5: "Senapati Bapat Marg", line6: "Lower Parel", line7: "Mumbai – 400 013" },
        ],
        para4: "After receipt of documents, the application will be forwarded to Income Tax Department(ITD) for approval.Upon receipt of approval from ITD and payment from entity, user ID will be created and communicated to an entity via email mentioned at the time of making online application.",
      },
      {
        text: "Can I make application to avail Online PAN Verification facility on plain paper?",
        content: "No. To avail Online PAN Verification facility, you have to register online.",
      },
      {
        text: "How much time is required to get approval from ITD?",
        content: "It is at the discretion of ITD.",
      },
      {
        text: "Will I get refund in case my application is rejected by ITD?",
        content: "Yes. In case the application is rejected by ITD, the registration charges will be refunded.",
      },
      {
        text: "Will I get refund in case my application is approved by ITD and I do not want to avail online PAN verification facility due to some reason?",
        content: "No. Once the approval is received from ITD and user ID is created, you will not get refund of fees paid by you.",
      },
      {
        text: "How long this facility is available?",
        content: "The facility for online PAN verification is provided for one year and same needs to be renewed on a yearly basis where the annual renewal charge is  12,000 (Plus Goods and Services Tax (GST) as applicable).",
      },
      {
        text: "Are there any charges to avail Online PAN Verification facility?",
        formattedData: true,
        isChargeSummary: true,
        isChargeDetails: true,
      },
      {
        text: "What documents are required to be submitted to avail Online PAN Verification facility?",
        formattedData: true,
        para1: "Entity desirous of availing the facility for online PAN verification has to “ register ” with Protean. For this, the entity should forward the following documents to Protean:",
        isUL1: true,
        ulList1: [
          { text: "Signed copy of the Terms & Conditions should be provided on the letterhead of the Entity", url: "https://www.tin-nsdl.com/downloads/online-pan-verification/Terms_and_conditions_R.pdf" },
          { text: "Non-Disclosure Agreement/ Confidentiality Agreements (NDA) on the letterhead of the Entity", url: "https://www.tin-nsdl.com/downloads/online-pan-verification/Terms_and_conditions_R.pdf" },
          { text: "Authorisation letter", url: "https://www.tin-nsdl.com/download/Authority%20letter_R.docx" },
          { text: "Screen Shots of DSC *" },
        ],
        note: "*Note",
        para2: " Go to Internet explorer - Tools - Internet Options - Contents - Certificates - Personal Tab(and view certificate) then take below screen shots.",
        isOL1: true,
        olList1: [
          { text: "General Tab" },
          { text: "Details Tab - Serial Number" },
          { text: "Details Tab - Authority Key Identifier" }
        ],
        isUL2: true,
        ulList2: [
          { text: "Incorporation Certificate (Two copies stamped & signed by authorized entity)" },
          { text: "PAN card copy of entity (Two copies stamped & signed by authorized entity)" },
          { text: "Copy of license/certificate issued by regulatory body (if applicable) (Two copies stamped & signed by authorized entity)" },
          { text: "Declaration by Entities (Two copies stamped & signed by authorized entity)", url: "https://www.tin-nsdl.com/download/Declaration-by-Entities_R.docx" },
          { text: "Copy of Balance sheet and Profit & Loss account statements for latest financial year" },
          { text: "Additional Information on letterhead mentioning below details –" }
        ],
        isOL2: true,
        olList2: [
          { text: "Authorized Capital" },
          { text: "Disbursed amount (Mention NA if not applicable)" },
          { text: "Existing number of customers as on ________(date)" },
          { text: "Expected number of customers __________per year" },
        ],
        isAddress: true,
        address: [
          { title: "Documents to be submitted at the following address:-", line1: "Protean eGov Technologies Limited", line2: "Online PAN Verification Team", line3: "1st floor Times Tower", line4: "Kamala Mill Compound", line5: "Senapati Bapat Marg", line6: "Lower Parel", line7: "Mumbai – 400 013" },
        ],
        para4: "After receipt of documents, the application will be forwarded to Income Tax Department(ITD) for approval.Upon receipt of approval from ITD and payment from entity, user ID will be created and communicated to an entity via email mentioned at the time of making online application.",
      },
      {
        text: "What are the details which will be displayed when I verify PANs?",
        formattedData: true,
        subPara1: "Below are the details:",
        isPanTable: true,
        panBottomPara: "For PANs being verified by Department of Commercial Taxes (DCT), an additional field Father’s name is required to be sent in Input along with all above input fields and ‘Matching status of Father’s name’ will be provided in the response along with all above output fields.",
      },
      {
        text: "Can I come to know contact number/address details of the PAN holder by availing online PAN Verification facility?",
        formattedData: true,
        para1: "No. Only the following fields are displayed after verification of PANs -",
        isOL1: true,
        olList1: [
          { text: "PAN Status" },
          { text: "Matching status of Name" },
          { text: "Matching status of DOB/I" },
          { text: "Aadhaar-PAN linking status" },
        ],
        para2: "For PANs being verified by Department of Commercial Taxes (DCT), an additional field ‘Matching status of Father’s name’ will be provided in the response along with all above output fields.",
      },
      {
        text: "I want to change type of facility (Screen/File/API based) of Online PAN Verification facility?",
        formattedData: true,
        para1: "For this, you need to forward a written request on the letterhead of your organization duly signed by the authorized signatory mentioning your user ID. You may send an email containing scan copy of letter at PAN-verification@proteantech.in.",
        para1Url: "mailto:PAN-verification@proteantech.in",
        isOL1: true,
        olList1: [
          { text: "In case you are opting from Screen based to API or File based facility, you need to maintain an advance balance." },
          { text: "In case you are opting from File based to API based facility or vice versa, the advance lying in your account will be carried forward to API based account." },
        ],
      },
      {
        text: "My organization name has changed. How do I inform you?",
        content: "For this, you need to forward a written request on the letterhead of your organization duly signed by the authorized signatory mentioning your user ID. You may send an email containing scan copy of letter at PAN-verification@proteantech.in. a) In case you are opting from Screen based to API or File based facility, you need to maintain an advance balance.  b) In case you are opting from File based to API based facility or vice versa, the advance lying in your account will be carried forward to API based account.",
      },
      {
        text: "Can I use Online PAN Verification facility for the purpose other than the purpose mentioned at the time of registration?",
        content: "No, you cannot use Online PAN Verification facility other than the purpose mentioned for availing Online PAN Verification facility. Income Tax Department carries out quarterly audit of PANs verified by the entities. If it is observed that the facility is being used other than the purpose for which it is availed, your facility may get discontinued.",
      },
      {
        text: "Authorized person/Contact details/Address/E-mail of our organization has been changed. What should I do?",
        formattedData: true,
        para1: "To update details, please follow the instructions given below:-",
        isOL1: true,
        olList1: [
          { text: "Login with your user ID using Digital Signature Certificate" },
          { text: "Go to ‘Update Contact Details’ menu" },
          { text: "Click on ‘Edit’ button" },
          { text: "Fill up desired details" },
          { text: "Click on ‘Submit’ button to update." },
        ],
      },
      {
        text: "Can I verify TAN using Online PAN Verification facility?",
        formattedData: true,
        para1: "No. At present this facility is not available. However, you can verify TAN at Income Tax website at",
        para2: "https://eportal.incometax.gov.in/iec/foservices/#/pre-login/knowYourTAN",
        para2Url: "https://eportal.incometax.gov.in/iec/foservices/#/pre-login/knowYourTAN",
      },
      {
        text: "Is multiple log-in allowed?",
        content: "No. Multiple log-in is not allowed.",
      },
      {
        text: "Do you provide any alerts to users who avail Online PAN Verification facility?",
        formattedData: true,
        para1: "Protean do provide alerts to its PAN verification users through email and SMS",
        subPara1: "Email alerts are provided on your registered email ID under the below scenarios:-",
        isOL1: true,
        olList1: [
          { text: "Acknowledgement email for registration made" },
          { text: "Intimation of creation of user ID and payment advice for payment made" },
          { text: "Intimation of rejection of registration with rejection reason" },
          { text: "Intimation of addition of advance amount in its PAN verification account along with payment advice" },
          { text: "Intimation for facility due for renewal two months in advance" },
          { text: "Intimation of renewal of facility along with payment advice for payment made" },
          { text: "Intimation for Due date of expiry of Digital Signature Certificate in advance" },
          { text: "Intimation for DSC update" },
          { text: "Email alert for insufficient balance in your PAN verification account" },
        ],
        subPara2: "SMS alert is also send on your mobile number registered for 'Quick Alert' facility for insufficient balance in your PAN verification account",
      },
      {
        text: "How is the communication sent on creation of user ID?",
        content: "You will receive an email regarding User ID and payment made by you towards registration on your registered email ID present with Protean.",
      },
      {
        text: "Where to contact in case of any problem / clarification required on PAN verification facility?",
        formattedData: true,
        para1: "The user can call on 022 - 4090 4163 / 4635 / 4182 or send their grievances/queries to the email ID at PAN-verification@proteantech.in. The user can also address their grievances/queries at the following address mentioning acknowledgment number or user ID (if applicable) for our reference :-",
        para1Url: "mailto:PAN-verification@proteantech.in",
        isAddress: true,
        address: [
          { title: "Documents to be submitted at the following address:-", line1: "Protean eGov Technologies Limited", line2: "Online PAN Verification Team", line3: "1st floor Times Tower", line4: "Kamala Mill Compound", line5: "Senapati Bapat Marg", line6: "Lower Parel", line7: "Mumbai – 400 013" },
        ],
      },
    ],
    "Digital Signature Certificate": [
      {
        text: "Is it compulsory to have Digital Signature Certificate (DSC)?",
        content: "Yes, to avail Online PAN Verification facility, Digital Signature Certificate is compulsory. DSC is required for making registration and verifying PANs online.",
      },
      {
        text: "Which Digital Signature Certificate (DSC) is required for making online registration?",
        formattedData: true,
        para1: "The list of DSC which can be used for making online registration can be referred fromSystem Pre-requisite - Digital Signature Certificate (DSC)",
        para1Url: "https://www.protean-tinpan.com/services/online-pan-verification/pan-verification-prerequisite.html",
      },
      {
        text: "Which class of Digital Signature Certificate (DSC) is required for Online PAN Verification facility?",
        formattedData: true,
        para1: "The entity must have a Digital Signature Certificate (Class II or Class III) from any of the licensed Certifying Authorities available at “System Pre-requisite - Digital Signature Certificate (DSC)”.",
        para1Url: "https://www.protean-tinpan.com/services/online-pan-verification/pan-verification-prerequisite.html"
      },
      {
        text: "Do you provide Digital Signature Certificate (DSC)?",
        formattedData: true,
        para1: "No. We do not provide Digital Signature Certificate. The Digital Signature Certificate can be procured from any of the Licensed Certifying Authorities available at “System Pre-requisite - Digital Signature Certificate (DSC)”.",
        para1Url: "https://www.protean-tinpan.com/services/online-pan-verification/pan-verification-prerequisite.html",
      },
      {
        text: "What if, if I do not have Digital Signature Certificate (DSC) which is not present in your list?",
        formattedData: true,
        para1: "In such a case, you need to procure DSC from any of the Licensed Certifying Authorities available at “System Pre-requisite - Digital Signature Certificate (DSC)”.",
        para1Url: "https://www.protean-tinpan.com/services/online-pan-verification/pan-verification-prerequisite.html",
      },
      {
        text: "Can I use another Digital Signature Certificate (DSC) for verifying PANs other than which was mentioned for making online registration?",
        formattedData: true,
        para1: "Yes, you can use another DSC for verifying PANs other than mentioned at the time of making online registration. But first new DSC details needs to be updated against user ID by forwarding us the following documents.",
        isOL1: true,
        olList1: [
          { text: "DSC change request cum Authorisation letter", url: "https://www.protean-tinpan.com/downloads/online-pan-verification/DSC_updation_request_cum_Authorisation_letter_OPV_020615.DOCX" },
          { text: "Screen shots of new DSC" },
        ],
        para2: "* Go to Internet explorer - Tools - Internet Options - Contents - Certificates - Personal Tab and view certificate by double clicking it. Then take below screen shots.",
        isOL2: true,
        olList2: [
          { text: "General Tab" },
          { text: "Details Tab - Serial Number" },
          { text: "Details Tab - Authority Key Identifier" },
        ],
        para3: "Please share above mentioned details for change of DSC to -",
        para4: "PAN-verification@proteantech.in",
        para4Url: "mailto:PAN-verification@proteantech.in",
      },
      {
        text: "Can I use any Digital Signature Certificate (DSC) for verifying PANs?",
        formattedData: true,
        para1: "No. Only the DSC which is registered with Protean by you can be used for verifying PANs. In case, your DSC has been changed or expired, you are required to raise a request with Protean. The following documents are required for renewal / updation of DSC:",
        isOL1: true,
        olList1: [
          { text: "DSC change request cum Authorisation letter", url: "https://www.protean-tinpan.com/downloads/online-pan-verification/DSC_updation_request_cum_Authorisation_letter_OPV_020615.DOCX" },
          { text: "Screen shots of new DSC" },
        ],
        para2: "* Go to Internet explorer - Tools - Internet Options - Contents - Certificates - Personal Tab and view certificate by double clicking it. Then take below screen shots.",
        isOL2: true,
        olList2: [
          { text: "General Tab" },
          { text: "Details Tab - Serial Number" },
          { text: "Details Tab - Authority Key Identifier" },
        ],
        para3: "Please share above mentioned details for change of DSC to -",
        para4: "PAN-verification@proteantech.in",
        para4Url: "mailto:PAN-verification@proteantech.in",
      },
      {
        text: "The person on whose name Digital Signature Certificate is registered has left the organization or he/she has been transferred to different department. What should we do?",
        formattedData: true,
        para1: "To change Digital Signature Certificate (DSC) details, the organization need to follow below procedure *.",
        isOL1: true,
        olList1: [
          { text: "Login with user ID using existing Digital Signature Certificate." },
          { text: "Go to 'Change DSC details' option" },
          { text: "Fill the required details of the DSC, which needs to be mapped with your user ID." },
          { text: "Select the renewed/Changed DSC." },
        ],
        subPara2: "* Note:- Existing DSC and the DSC which needs to be changed is required to be installed in the machine before following the above procedure.",
        extraSubPara2: "If current DSC is expired, the following documents are required for renewal / change of Digital Signature Certificate (DSC):",
        isOL2: true,
        olList2: [
          { text: "DSC change request cum Authorisation letter", url: "https://www.protean-tinpan.com/downloads/online-pan-verification/DSC_updation_request_cum_Authorisation_letter_OPV_020615.DOCX" },
          { text: "Screen shots of new DSC*" },
        ],
        bottomNote: "* Note:",
        para5: "* Go to Internet explorer – Tools - Internet Options – Contents – Certificates –Personal Tab (and view certificate) then take below screen shots.",
        isOL3: true,
        olList3: [
          { text: "General Tab" },
          { text: "Details Tab - Serial Number" },
          { text: "Details Tab - Authority Key Identifier" },
        ],
        para6: "Please share above mentioned details for change of DSC to",
        para7: "PAN-verification@proteantech.in",
        para7Url: "mailto:PAN-verification@proteantech.in",
      },
      {
        text: "My Digital Signature Certificate is changed. What should I do?",
        formattedData: true,
        para1: "To change Digital Signature Certificate (DSC) details, the organization need to follow below procedure * .",
        isOL1: true,
        olList1: [
          { text: "Login with user ID using existing Digital Signature Certificate." },
          { text: "Go to 'Change DSC details' option" },
          { text: "Fill the required details of the DSC, which needs to be mapped with your user ID." },
          { text: "Select the renewed/Changed DSC." },
        ],
        subPara2: "* Note:- Existing DSC and the DSC which needs to be changed is required to be installed in the machine before following the above procedure.",
        middleText: "My Digital Signature Certificate is expired. What should I do?",
        extraSubPara2: "If current DSC is expired, the following documents are required for renewal / change of Digital Signature Certificate (DSC):",
        isOL2: true,
        olList2: [
          { text: "DSC change request cum Authorisation letter", url: "https://www.protean-tinpan.com/downloads/online-pan-verification/DSC_updation_request_cum_Authorisation_letter_OPV_020615.DOCX" },
          { text: "Screen shots of new DSC*" },
        ],
        bottomNote: "* Note:",
        para5: "* Go to Internet explorer – Tools - Internet Options – Contents – Certificates –Personal Tab (and view certificate) then take below screen shots.",
        isOL3: true,
        olList3: [
          { text: "General Tab" },
          { text: "Details Tab - Serial Number" },
          { text: "Details Tab - Authority Key Identifier" },
        ],
        para6: "Please share above mentioned details for change of DSC to",
        para7: "PAN-verification@proteantech.in",
        para7Url: "mailto:PAN-verification@proteantech.in",
      },
      {
        text: "Are there any charges for renewal/updation of Digital Signature Certificate in Protean system?",
        content: "No charges are required to pay to update renewal/change of Digital Signature Certificate details to Protean.",
      },
      {
        text: "What are the documents required for renewal/updation of DSC?",
        formattedData: true,
        para1: "The following documents are required for renewal / updation of DSC:",
        isOL1: true,
        olList1: [
          { text: "DSC change request cum Authorisation letter", url: "https://www.protean-tinpan.com/downloads/online-pan-verification/DSC_updation_request_cum_Authorisation_letter_OPV_020615.DOCX" },
          { text: "Screen shots of new DSC*" },
        ],
        para5: "* Go to Internet explorer – Tools - Internet Options – Contents – Certificates –Personal Tab (and view certificate) then take below screen shots.",
        isOL3: true,
        olList3: [
          { text: "General Tab" },
          { text: "Details Tab - Serial Number" },
          { text: "Details Tab - Authority Key Identifier" },
        ],
        para6: "Please share above mentioned details for change of DSC to",
        para7: "PAN-verification@proteantech.in",
        para7Url: "mailto:PAN-verification@proteantech.in",
      },
      {
        text: "Will I receive any intimation for new DSC details updated in your system?",
        content: "Yes, you will receive system-generated email once the new DSC is updated at Protean end.",
      },
    ],
    "Advance Payment": [
      {
        text: "Who is eligible to make advance payment?",
        content: "Entity that has opted for File or API based facility is required to make advance payment.",
      },
      {
        text: "How can I make advance payment?",
        formattedData: true,
        para1: "To raise advance request online, please follow the instructions given below :-",
        isOL1: true,
        olList1: [
          { text: "Login with your user ID" },
          { text: "Go to 'Payment' option" },
          { text: "Select 'Advance Payment' option" },
          { text: "Fill up payment details and click on 'Submit' button" },
        ],
        para5: "You are required to make a payment in Virtual Account Number assigned against your Online PAN Verification user ID.",
      },
      {
        text: "Will my facility be stopped if I do not have funds in my advance account?",
        formattedData: true,
        para1: "In case you have no funds lying in your account, you will be able to log-in your account, but you will not be able to verify PANs. In case you have opted for File based facility, you will be able to verify 750 PANs per day per user ID free of cost through screen based option. In case, you have opted for API based facility, you will be able to verify 750 PANs per day free of cost. Beyond 750 PANs, you will not be able to verify PANs. To continue verifying PANs, you need to make payment in Virtual Account Number assigned against your Online PAN Verification user ID and raise online advance request under your login.",
        para2: "To continue verifying PANs, you need to raise online advance request and forward us Cheque or Demand Draft drawn in favour of ‘Protean eGov Technologies Limited’. Alternatively, you can also make online transfer of advance payment to the Protean account.",
      },
      {
        text: "Will I receive any intimation for advance amount added in my PAN verification account?",
        content: "Yes. An email along with an advance amount addition done will be sent on registered email ID of entity.",
      },
    ],
    "Renewal": [
      {
        text: "What are the renewal charges?",
        content: "The facility for online PAN verification is provided for one year and same needs to be renewed on a yearly basis where the annual renewal charge is  12,000 (plus Goods and Services Tax as applicable).",
      },
      {
        text: "My Online PAN Verification facility is expired. How do I renew it?",
        formattedData: true,
        para1: "You can renew the facility sixty days in advance from the expiry date of Online PAN Verification facility. For this, you need to raise renewal request online. Please follow the instructions given below :-",
        isOL1: true,
        olList1: [
          { text: "Login with your user ID using Digital Signature Certificate" },
          { text: "Go to 'Payment' option" },
          { text: "Select ‘Renewal of Facility’ option" },
          { text: "Fill up payment details and click on 'Submit' button" },
        ],
        para5: "You are required to make a payment in Virtual Account Number assigned against your Online PAN Verification user ID.",
      },
      {
        text: "Will I receive prior intimation for renewal due?",
        content: "Yes, you will receive system-generated email two months in advance intimating the renewal due and the procedure to be followed to renew the PAN verification facility.",
      },
      {
        text: "Will I receive any intimation that PAN verification facility has been renewed?",
        content: "An email mentioning validity period of your user ID will be sent on registered email ID of entity.",
      },
    ],
  };

  return (
    <div className="flex flex-col gap-8">
      <div className="md:px-[68px]">
        <CommonBanner
          title={"Online PAN Verification"}
          className={"min-h-[60vh] md:min-h-[50vh]"}
          isOnlinePanVerification
          desktopWidth="40vw"
        />
      </div>
      <Element name="overview-and-service">
        <OverviewAndService />
      </Element>
      <Element name="documents-required">
        <DocumentsRequired />
      </Element>
      <div className="bg-[#FCEBE9] md:mx-[68px]">
        <Header />
        <Element name="charge-summary">
          <ChargeSummary />
        </Element>
        <Element name="charge-details">
          <ChargeDetails />
        </Element>
        <Element name="note">
          <Note />
        </Element>
      </div>
      <Element name="dsc">
        <DSCSection />
      </Element>
      <Element name="procedure">
        <Procedure />
        <ChangeProcedure />
      </Element>
      <Element name="faqs">
        <FAQComponent linkData={linkData} />
      </Element>
    </div>
  );
}

export default withScrollToHash(Link26); // Use the HOC
