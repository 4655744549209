import React from "react";
import { Link14Graphic1 } from "../../../assets/svgs";

const SupportingDoc = () => {
  return (
    <section className="lg:px-[70px] pb-8">
      <div className="bg-[#EEF7ED] max-w-[100vw] p-4 lg:px-4 ">
        <div className="flex gap-2 items-start flex-wrap md:flex-nowrap">
          <div className="mt-2">
            <svg
              width="34"
              height="17"
              viewBox="0 0 34 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
                fill="#54AD47"
              />
              <path
                d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
                fill="#54AD47"
              />
              <circle cx="25.5" cy="8.5" r="8.5" fill="#54AD47" />
            </svg>
          </div>

          <div>
            <p className="text-[20px] font-semibold text-black text-left flex items-center gap-2">
              Supporting document required for changes in PAN data
            </p>
          </div>
        </div>
        <div className=" bg-white   overflow-hidden mt-4">
          <div className="overflow-x-auto">
            <table className="min-w-full border-separate border-spacing-0 text-[12px] md:text-[14px]">
              <thead className="bg-[#DDEFDA]">
                <tr>
                  <th className="p-2 sm:p-4 border text-left text-[14px]">
                    Case/Applicant type
                  </th>
                  <th className="p-2 sm:p-4 border text-left text-[14px]">
                    Document acceptable for change of name/father’s name
                  </th>
                </tr>
              </thead>
              <tbody>
    <tr>
      <td className="p-2 border align-top">
        Married ladies - change of name on account of marriage
      </td>
      <td className="p-2 border align-top">
        <ul>
          <li>Marriage certificate or</li>
          <li>Marriage invitation card or</li>
          <li>Copy of passport showing husband’s name or</li>
          <li>Publication of name change in official gazette or</li>
          <li>Certificate issued by a Gazetted officer (only for change in applicant’s name)</li>
        </ul>
      </td>
    </tr>
    <tr>
      <td className="p-2 border align-top">
        Individual applicants other than married ladies
      </td>
      <td className="p-2 border align-top">
        <ul>
          <li>Publication of name change in official gazette or</li>
          <li>Certificate issued by a Gazetted officer (only for change in applicant’s name)</li>
        </ul>
      </td>
    </tr>
    <tr>
      <td className="p-2 border align-top">
        Transgender applicants – Change in name/gender
      </td>
      <td className="p-2 border align-top">
        <ul>
          <li>Transgender Certificate issued by the District Magistrate</li>
        </ul>
      </td>
    </tr>
    <tr>
      <td className="p-2 border align-top">Companies</td>
      <td className="p-2 border align-top">
        <ul>
          <li>ROC’s certificate for name change</li>
        </ul>
      </td>
    </tr>
    <tr>
      <td className="p-2 border align-top">Firms / Limited Liability Partnerships</td>
      <td className="p-2 border align-top">
        <ul>
          <li>Revised partnership deed or</li>
          <li>Registrar of Firm/LLP’s certificate for name change</li>
        </ul>
      </td>
    </tr>
    <tr>
      <td className="p-2 border align-top">AOP/Trust/BOI/AJP/LOCAL authority</td>
      <td className="p-2 border align-top">
        <ul>
          <li>Revised Deed/Agreement or</li>
          <li>Revised registration certificate</li>
        </ul>
      </td>
    </tr>
  </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SupportingDoc;
