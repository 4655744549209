import React from 'react';

const HowToApply = ({ language }) => {
  const content = {
    English: (
      <div className="text-[15px] lg:text-[16px] text-black font-light mb-4">
        <h2 className="text-[18px] lg:text-[22px] font-semibold text-black mb-4">
          How to Apply
        </h2>
        <p>
          Applicant may either make an online application through this website or submit physical PAN Application to any TIN-FC or PAN centre of Protean.
        </p>
        <p className="mt-2">
          Applicants should go through the instructions and guidelines provided in the application form before filling the form.
        </p>
      </div>
    ),
    Hindi: (
      <div className="text-[15px] lg:text-[16px] text-black font-light mb-4">
        <h2 className="text-[18px] lg:text-[22px] font-semibold text-black mb-4">
          कैसे आवेदन करें
        </h2>
        <p>
          आवेदक या तो इस वेबसाइट के माध्यम से ऑनलाइन आवेदन कर सकते हैं या किसी भी TIN-FC या प्रोटीन के PAN केंद्र पर भौतिक PAN आवेदन जमा कर सकते हैं।
        </p>
        <p className="mt-2">
          आवेदकों को फॉर्म भरने से पहले आवेदन फॉर्म में प्रदान किए गए निर्देशों और दिशानिर्देशों को पढ़ना चाहिए।
        </p>
      </div>
    )
  };

  return <div>{content[language]}</div>;
};

export default HowToApply;
