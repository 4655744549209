import React from "react";
import graphic from "../../../assets/images/ApplyOnlineGraphic.jpg";
function ApplyOnline() {
  return (
    <section className="flex flex-col relative   ">
      <div className="flex px-[22px]  lg:px-[70px] gap-2 items-center w-full">
        <a href="#">
          <svg
            width="34"
            height="17"
            viewBox="0 0 34 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
              fill="#E43625"
            />
            <path
              d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
              fill="#E43625"
            />
            <circle cx="25.5" cy="8.5" r="8.5" fill="#E43625" />
          </svg>
        </a>
        <h3 className="font-semibold text-[26px]">Apply Online</h3>
      </div>
      <div className="flex justify-between flex-col px-[22px] lg:px-[70px]">
        <div className="flex flex-col py-10 justify-center items-center   lg:max-w-[60%]">
          <p className="text-[15px]">
            With effect from April 08, 2012, PAN applications are required to be
            furnished in the new forms prescribed by ITD. Indian citizens will
            have to submit their ‘Application for allotment of new PAN’ in
            revised Form 49A only. Foreign citizens will have to submit their
            ‘Application for allotment of new PAN’ in newly notified Form 49AA
            only.
          </p>
          <p className="mt-8 text-[15px]">
            For New PAN applications, in case of Individual and HUF applicants
            if Address for Communication is selected as Office, then Proof of
            Office Address along with Proof of residential address is to be
            submitted to Protean w.e.f. applications made on and after 1st
            November 2009.
          </p>

          <p className="mt-8 text-[15px]">
            As per RBI guidelines, the entities making e-commerce transactions
            are required to provide PIN (Personal Identification Number) while
            executing an online transaction. Accordingly, before making payment
            for online PAN/TAN applications using credit/debit card, please
            ensure that the PIN is obtained from your respective Banks.
          </p>
        </div>
        <div className="flex lg:hidden justify-center items-center py-8">
          <img src={graphic} alt="protean" width={220} />
        </div>
      </div>
      <img
        src={graphic}
        className="absolute right-[4.5rem] bottom-0 hidden lg:block"
        alt="protean"
        width={283}
        height={353}
      />
    </section>
  );
}

export default ApplyOnline;
