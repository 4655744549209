import React from "react";
import { Element } from "react-scroll";
import withScrollToHash from "../../hooks/useScrollToHash"; // Correctly import the HOC
import CommonBanner from "../../components/CommonBanner";
import SearchAndContactUsSection from "../../components/SearchAndContact";
import ThirdGrid from "./components/ThirdGrid";
import FAQComponent from "../../components/Faqs";
import { Helmet } from "react-helmet";

function Link13() {
  const linkData = {
    TAN: [
      {
        text: "What is TAN?",
        content:
          "TAN or Tax Deduction and Collection Account Number is a 10 digit alpha numeric number required to be obtained by all persons who are responsible for deducting or collecting tax. It is compulsory to quote TAN in all TDS/TCS returns (including e-TDS/TCS return), TDS/TCS payment challans and TDS/TCS certificates.",
      },
      {
        text: "Why is it necessary to have TAN?",
        content:
          "The provisions of Section 203A of the Income-tax Act, 1961 require all persons who deduct or collect tax at source to apply for the allotment of TAN. The section also makes it mandatory to quote TAN in all TDS/TCS/Annual Information Returns, payment challans and certificates to be issued. If TAN is not quoted, TDS/TCS returns will not be accepted by TIN-Facilitation Centres (TIN-FCs) and challans for TDS/TCS payments will not be accepted by banks. Failure to apply for TAN or not quoting the same in the specified documents attracts a penalty of Rs. 10,000.",
      },
      {
        text: "Who must apply for TAN?",
        content:
          "All those persons who are required to deduct/collect tax at source on behalf of Income Tax Department are required to apply for and obtain TAN.",
      },
    ],
    "NEW TAN": [
      {
        text: "What is the procedure to obtain a TAN?",
        content:
          "An application for allotment of TAN is to be filed in Form 49B and submitted to any TIN-FC. Addresses of TIN-FCs are available at Protean-TIN website. Alternatively, you can apply for TAN online at the Protean-TIN website.",
      },
      {
        text: "From where can I obtain Form 49B?",
        content:
          "Form 49B is freely downloadable from Protean e-Gov-TIN website. It is also available at TIN-FCs. Legible photocopies of Form 49B or forms legibly printed exactly as per the format prescribed by ITD are also allowed to be used.",
      },
      {
        text: "Who will allot TAN?",
        content:
          "TAN is allotted by the IncomeTax Department on the basis of the application submitted online at Protean e-Gov -TIN website or TIN-FCs managed by Protean e-Gov. Protean e-Gov will intimate the TAN to the applicant at the address provided in the application.",
      },
      {
        text: "Can an application for TAN be made on a plain paper?",
        content:
          "No. TAN Application can be made only on Form 49B. The application form can be downloaded from ITD website or Protean e-Gov -TIN website or may be obtained from any other source. The application is also available at TIN-FCs.",
      },
      {
        text: "Can Form 49B be filled on a typewriter?",
        content:
          "Yes. But typing should be in capital letters with good impression.",
      },
      {
        text: "What documents should be submitted alongwith the TAN application?",
        content:
          "No documents are required to be submitted along with the application for allotment of TAN. However, where the application is being made online, the signed acknowledgement which is generated after filling up the form should be forwarded to Protean e-Gov. Detailed guidelines for submission of application are available on Protean e-Gov -TIN website i.e. www.tin-nsdl.com.",
      },
      {
        text: "Is a separate TAN required to be obtained for the purpose of Tax Collection at Source (TCS)?",
        content:
          "No. TAN which was allotted for Tax Deduction at Source (TDS) can be used for the purpose of TCS. However, if no TAN has been allotted, a duly filled in Form 49B, alongwith the application fees should be submitted at any TIN-FC.",
      },
      {
        text: "What if incomplete form 49B is submitted?",
        content:
          "If you are submitting the form at a TIN-FC, the TIN-FC will assist you in correctly filling up Form 49B. Incomplete or deficient applications will not be accepted.",
      },
      {
        text: "Are there any charges to be paid while submitting application for TAN?",
        content:
          "A fee of ₹ 65 + Goods and Service tax (as applicable) should be paid as processing fee to the TIN-FC at the time of submitting Form 49B. If you are applying for TAN online, then the payment can be made vide cheque, demand draft or credit card as per the guidelines given in the Protean e-Gov -TIN website.",
      },
      {
        text: "How will the new TAN be intimated to the applicant?",
        content:
          "An allotment letter is dispatched by Protean e-Gov at the address provided in Form 49B.",
      },
      {
        text: "How can I enquire about the status of my application?",
        content:
          "You can inquire the status of your application by accessing Protean e-Gov -TIN website at the 'Status track' option and quoting your unique 14-digit acknowledgement number after three days of your application date.",
      },
    ],
    "Correction in TAN Data": [
      {
        text: "How can the change in address or details on the basis of which TAN was allotted be communicated to Income Tax Department?",
        content:
          "Any change or corrections in the data associated with the TAN, should be communicated to ITD by filing up 'Form for Changes or Correction in TAN data for TAN allotted' alongwith the necessary fees at any of the TIN-FCs, or at Protean e-Gov-TIN website.",
      },
      {
        text: "What are the charges I have to pay while submitting the 'Form for Changes or Correction in TAN data for TAN allotted'?",
        content:
          "You have to pay ₹ 65 + Goods and Service Tax, as applicable) to Protean e-Gov/TIN-FCs as processing fees while submitting your application at the TIN-FC or making online application.",
      },
      {
        text: "If I change my address, should I inform ITD or should I apply for a new TAN?",
        content:
          "Change of address within a jurisdiction does not change the TAN. However, it may involve a change in the Assessing Officer. Such changes must, therefore, be intimated to ITD so that the TAN database of ITD can be updated. In case of change of address from one jurisdiction to other (e.g. address changes from Bangalore to Mumbai) a new TAN needs to be applied for and previous TAN needs to be surrendered on allotment of fresh TAN.",
      },
      {
        text: "How can one find out Assessing Officer (AO) Details?",
        content:
          "Assessing Officer details are available at Protean e-Gov - TIN website.",
      },
      {
        text: "What can serve as proof of TAN in case one has lost the TAN allotment letter?",
        content:
          "Printout of the webpage showing details of TAN, from the Income Tax Department's website can serve as proof of TAN.",
      },
    ],
    "Duplicate TAN": [
      {
        text: "What is duplicate TAN?",
        content:
          "Duplicate TAN is a TAN which has been inadvertently obtained by a person who is responsible for deducting/collecting tax and who already has a TAN allotted to him. It is illegal to possess or use more than one TAN. Different branches/divisions of an entity may, however, have separate TANs.",
      },
      {
        text: "In case duplicate TAN has been allotted, which TAN should be used?",
        content:
          "In case duplicate TANs have been allotted, the TAN which has been used regularly should be continued to be used. The other TAN/s should be surrendered for cancellation using 'Form for Changes or Correction in TAN' which can be downloaded from Protean-TIN website or may be procured from TIN-FCs or other vendors.",
      },
      {
        text: "What should I do if I have been allotted a duplicate TAN by oversight?",
        content:
          "In case duplicate TAN has been allotted, an application may be made for cancellation of the TAN which has not been used in the ‘Form for Changes or Correction in TAN’ which can be downloaded from Protean-TIN website or may be procured from TIN-FCs or other vendors.",
      },
    ],
    "TAN Online": [
      {
        text: "What is the procedure to apply for TAN online?",
        content:
          "You can apply for TAN online at the Protean-TIN website. You can then fill and submit the form online. Once you click on button 'submit', an acknowledgment containing a unique 14 digit acknowledgment number is generated on the screen. You should print this acknowledgment, sign it and dispatch it to Protean at the address mentioned on Protean-TIN website along with the processing fee which is ₹ 65 + service tax (as applicable). Payment can be made by DD/cheque, payable at par in Mumbai, favouring Protean-TIN or by Credit card / Debit card or Net banking.",
      },
    ],
    Miscellaneous: [
      {
        text: "If a deductor/collector has a TAN as per the old format or if he has earlier applied, but has not been allotted TAN, what should he do?",
        content:
          "TIN-FC will help the deductors/collectors in ascertaining their correct TAN from the database available with them. TAN can also be verified from ITD’s website, wherein there is a facility to find your TAN against old TAN / name and address of the deductor/collector. In case no TAN has been allotted, the deductor will have to file an application in Form 49B along with application fee (₹ 65 + service tax as applicable) either at any TIN-FC or online at Protean e-Gov - TIN website.",
      },
      {
        text: "Where can I find the address of the nearest TIN-FC?",
        content:
          "Addresses of all TIN-FCs are available on Protean-TIN website.",
      },
      {
        text: "Is it necessary to apply for different TAN if a deductor has to deduct tax from different types of payments like salary, interest, dividend etc.?",
        content:
          "No. TAN once allotted can be used for all type of deductions/collections.",
      },
      {
        text: "Should Government deductors apply for TAN?",
        content:
          "Yes. All Government deductors are also required to quote TAN on their TDS / TCS / Annual Information Returns.",
      },
      {
        text: "In case of multiple DDOs, should all of them apply for TAN?",
        content:
          "Yes. In such case, the name of the Division; name and location of branch or the designation of the person responsible for deducting/collecting tax, whichever is applicable, should be clearly given in the application for allotment of TAN.",
      },
      {
        text: "Can branches of companies/banks have separate TANs?",
        content:
          "Yes. The name and location of branch or the designation of the person responsible for deducting/collecting tax, whichever is applicable, should be clearly given in the application for allotment of TAN.",
      },
      {
        text: "Can a TDS/TCS/Annual Information return be filed without TAN in case TAN has not been allotted?",
        content:
          "Quoting of TAN is mandatory in all TDS/TCS/Annual Information returns, whether filed in paper or electronic format. The return, whether in paper or electronic format, will not be received in case correct TAN is not quoted.",
      },
      {
        text: "Can I quote PAN in place of TAN?",
        content:
          "No. PAN should never be quoted in the field where TAN is required to be quoted. The purposes for which PAN and TAN are allotted are different. TAN is a unique identification number which is allotted to persons who are deducting or collecting tax at source on behalf of the Income Tax Department. PAN is a unique number allotted to assessees like individuals, companies etc.",
      },
    ],
  };
  return (
    <>
      <Helmet>
        <title>TAN Services Overview - Apply & Manage TAN | Protean</title>
        <meta name="description" content="Learn about TAN services with Protean. Apply for a TAN, manage your details, and ensure compliance with tax regulations. Comprehensive TAN service guide." />
        <meta name="keywords" content="tan application status, tan application, tan application online, online tan application, tan application form, tan application form download, tan application form online, tan application form pdf" />
      </Helmet>
      <div className="flex flex-col gap-8">
        <div className="lg:px-[70px]">
          <CommonBanner
            title={"TAN"}
            className={"min-h-[60vh] md:min-h-[50vh] mx-auto"}
            isTaxDeductions
            desktopWidth="466px"
            desktopHeight="466px"
            isFirstButton={true}
            firstButtonTitle="New TAN"
            firstButtonLink="https://tin.tin.nsdl.com/tan/form49B.html"
            isSecondButton={true}
            secondButtonTitle="Change / Correction"
            secondButtonLink="https://tin.tin.nsdl.com/tan/ChangeRequest.html"
          />
        </div>
        <Element name="search-and-contact-us">
          <SearchAndContactUsSection />
        </Element>
        <Element name="third-grid">
          <ThirdGrid />
        </Element>
        <Element name="faqs">
          <FAQComponent linkData={linkData} />
        </Element>
      </div>
    </>
  );
}

export default withScrollToHash(Link13); // Use the HOC
