// Payment.js
import React from "react";
import { Link11Svg1 } from "../../../assets/svgs";

function Payment({ language }) {
  const paymentContent = {
    en: [
      "If communication address is within India, then the fee for processing PAN application is ₹ 107 (inclusive of applicable taxes).",
      "Demand Draft/Credit Card / Debit Card/Net Banking",
      "If any of addresses i.e. office address or residential address is a foreign address, the payment can be made only through credit card/debit card or demand draft payable at Mumbai.",
      "If communication Address is outside India, then the fee for processing PAN application is ₹ 1020 [(Application fee ₹ 93 + Dispatch Charges ₹ 771.00) + 18% Goods And Service Tax].",
      "At present this facility is available for a select list of countries. Applicants from other countries may contact Protean at the contact details given in point (5) below.",
      "Demand draft shall be in favour of 'Protean - PAN'. Demand draft should be payable at Mumbai.",
      "The applicant shall rectify the error(s) and re-submit the form.",
      "Name of the applicant and the acknowledgment number should be mentioned on the reverse of the demand draft.",
      "Credit card / Debit card / Net banking payment - Persons authorised to make Credit card / Debit card / Net banking payment are as below:",
    ],
    hi: [
      "यदि संचार का पता भारत में है, तो पैन आवेदन प्रसंस्करण के लिए शुल्क ₹ 107 है (लागू करों सहित)।",
      "डिमांड ड्राफ्ट/क्रेडिट कार्ड / डेबिट कार्ड/नेट बैंकिंग",
      "यदि कोई भी पता जैसे कार्यालय का पता या आवासीय पता विदेशी पता है, तो भुगतान केवल क्रेडिट कार्ड/डेबिट कार्ड या मुंबई में देय डिमांड ड्राफ्ट के माध्यम से किया जा सकता है।",
      "यदि संचार का पता भारत के बाहर है, तो पैन आवेदन प्रसंस्करण के लिए शुल्क ₹ 1020 [(आवेदन शुल्क ₹ 93 + डिस्पैच शुल्क ₹ 771.00) + 18% वस्तु और सेवा कर] है।",
      "वर्तमान में यह सुविधा चयनित देशों की सूची के लिए उपलब्ध है। अन्य देशों के आवेदक नीचे दिए गए बिंदु (5) पर संपर्क विवरण पर प्रोटीन से संपर्क कर सकते हैं।",
      "'प्रोटीन - पैन' के पक्ष में डिमांड ड्राफ्ट होना चाहिए। डिमांड ड्राफ्ट मुंबई में देय होना चाहिए।",
      "आवेदक त्रुटि(यों) को सुधारें और फॉर्म को पुनः सबमिट करें।",
      "आवेदक का नाम और स्वीकृति संख्या डिमांड ड्राफ्ट के पीछे उल्लेखित होनी चाहिए।",
      "क्रेडिट कार्ड / डेबिट कार्ड / नेट बैंकिंग भुगतान - क्रेडिट कार्ड / डेबिट कार्ड / नेट बैंकिंग भुगतान करने के लिए अधिकृत व्यक्ति नीचे दिए गए हैं:",
    ],
  };

  const content = paymentContent[language];

  const tableContent = {
    en: [
      ["Individual", "Self, immediate family members"],
      ["HUF", "Karta of the HUF"],
      ["Company", "Any Director of the Company"],
      ["Firm/ Limited Liability Partnership", "Any Partner of the Firm"],
      [
        "Association of Person(s) / Body of Individuals / Artificial Juridical Person / Local Authority",
        "Authorised Signatory covered under Section 140 of Income Tax Act, 1961",
      ],
    ],
    hi: [
      ["व्यक्ति", "स्वयं, तत्काल परिवार के सदस्य"],
      ["हिन्दू अविभाजित परिवार (HUF)", "एचयूएफ का कर्ता"],
      ["कंपनी", "कंपनी का कोई भी निदेशक"],
      ["फर्म/ सीमित देयता साझेदारी", "फर्म का कोई भी साथी"],
      [
        "व्यक्तियों का संघ / व्यक्तियों का निकाय / कृत्रिम न्यायिक व्यक्ति / स्थानीय प्राधिकरण",
        "आयकर अधिनियम, 1961 की धारा 140 के अंतर्गत अधिकृत हस्ताक्षरकर्ता",
      ],
    ],
  };

  return (
    <div className="p-4 bg-[#EAF4FA] relative min-h-[1640px]">
      <Link11Svg1 className={"absolute right-0 top-0 hidden lg:block"} />

      <div className="flex items-center w-full gap-2">
        <h2 className="text-[26px] font-semibold flex items-center">
          {language === "en" ? " 2. Payment" : "2. भुगतान"}
        </h2>
      </div>
      <ul className="list-decimal ml-5 space-y-2 text-[15px] mt-4">
        {content.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <table className="mt-4 w-full text-[14px]">
        <thead className="bg-[#83BDE3]">
          <tr>
            <th className="p-2 border">Category of Applicant</th>
            <th className="p-2 border">
              Payment by Credit Card / Debit Card / Net Banking can be made by /
              for
            </th>
          </tr>
        </thead>
        <tbody>
          {tableContent[language].map(([category, details], index) => (
            <tr key={index} className="border-b">
              <td className="p-2 border">{category}</td>
              <td className="p-2 border">{details}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <p className="text-[14px] font-light mt-4">
        <span className="font-semibold">
          {" "}
          *{language === "en" ? "Note" : "नोट"}:
        </span>
        {language === "en"
          ? "To register under the category “Companies and Government Deductor (Required to file TDS/TCS return)”, entities must have more than 500 deductee/collectee counts displayed on Provisional receipts in a quarter. Counts of deductee/collectee will be vetted at the time of applying for Online PAN verification facility."
          : "“कंपनियों और सरकारी कटौतीकर्ता (टीडीएस/टीसीएस रिटर्न दाखिल करने की आवश्यकता)” श्रेणी के तहत पंजीकरण के लिए, इकाइयों को एक तिमाही में प्रोविजनल रसीदों पर प्रदर्शित 500 से अधिक डिडक्टी/कलेक्टी गणना होनी चाहिए। ऑनलाइन पैन सत्यापन सुविधा के लिए आवेदन करते समय डिडक्टी/कलेक्टी की गणना की जाएगी।"}
      </p>
    </div>
  );
}

export default Payment;
