import React from "react";
import img1 from "../../../assets/images/serviceImg1.png";
import img2 from "../../../assets/images/serviceImg2.png";
import img3 from "../../../assets/images/serviceImg3.png";
import img4 from "../../../assets/images/serviceImg4.png";
import img5 from "../../../assets/images/serviceImg5.png";
import img6 from "../../../assets/images/serviceImg5.png";

const serviceCards = [
  {
    imgSrc: img1,
    title: "Apply for new PAN",
    bgColor: "#54AD47",
    linkHref: "https://www.onlineservices.nsdl.com/paam/endUserRegisterContact.html",
  },
  {
    imgSrc: img2,
    title: "PAN",
    bgColor: "#3091D0",
    linkHref: "/services/pan/pan-index",
  },
  {
    imgSrc: img3,
    title: "TAN",
    bgColor: "#E43625",
    linkHref: "/services/tan/tan-introduction",
  },
  {
    imgSrc: img4,
    title: "e-TDS/TCS statement",
    bgColor: "#EE7F25",
    linkHref: "/services/etds-etcs",
  },
  {
    imgSrc: img5,
    title: "Annual Information Return",
    bgColor: "#6F368B",
    linkHref: "https://www.protean-tinpan.com/services/e-return/e-return-index.html",
  },
  {
    imgSrc: img6,
    title: "Challan Status Enquiry",
    bgColor: "#000000",
    linkHref: "https://tin.tin.nsdl.com/oltas/index.html",
  },
];

const ServicesSection = () => {
  return (
    <section className="w-full flex py-10 flex-col relative   lg:px-0 bg-[#F7F7F7]">
      <div className="flex flex-col items-center justify-center gap-10">
        <div className="flex px-[22px]  lg:px-[70px] gap-2 items-center w-full">
          <a className="text-red-500" href="#">
            <svg
              width="34"
              height="17"
              viewBox="0 0 34 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
                fill="#FFCE1B"
              />
              <path
                d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
                fill="#FFCE1B"
              />
              <circle cx="25.5" cy="8.5" r="8.5" fill="#FFCE1B" />
            </svg>
          </a>
          <h3 className="font-semibold text-[26px]">Our Services</h3>
        </div>
        <div className="flex gap-[24px] px-4 lg:px-0 flex-wrap justify-center">
          {serviceCards.map((card, index) => (
            <div key={index} className="max-w-[418px] flex flex-col">
              <img src={card.imgSrc} width="480px" height="270px" alt={card.title} />
              <a href={card.linkHref}>
                <div
                  className="flex justify-between py-[8px] px-4"
                  style={{ backgroundColor: card.bgColor }}
                >
                  <p className="text-white">{card.title}</p>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.9495 3.05078L3.04997 12.9503"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.9495 8.70764V3.05078"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.29291 3.05108H12.9498"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;