import React from "react";
import { ThreeDotsBulletSvg, TinSystemFlowChart } from "../../../assets/svgs";

function ThirdGrid() {
  const gridData = [
    {
      title: "Services through TIN-FC",
      fillColor: "#6F368B",
      bgColor: "#F1EBF3",
      content: [
        "Acceptance of electronic and physical TDS/TCS Returns.",
        "Processing of new PAN and PAN change request applications.",
        "Processing of new TAN and TAN change request applications.",
        "Acceptance of AIR / SFT",
        "Acceptance of Form 24G statement."
      ],
      isList: true,
    },
    {
      title: "Online Services",
      fillColor: "#F79A2E",
      bgColor: "#FBE9DD",
      content: "TIN provides the following Online Services through this website:",
      isList: false,
    },
    {
      title: "Quarterly Statement Status",
      fillColor: "#F07B5B",
      bgColor: "#FEECE6",
      content:
        "Facility for deductors to verify status of e-TDS/TCS statements submitted to TIN-FC by providing TAN and respective Provisional Receipt Number (PRN).",
      isList: false,
    },
    {
      title: "PAN",
      fillColor: "#4D96F2",
      bgColor: "#E7F1FB",
      content: [
        "Facility to apply for PAN online",
        "Reprint of PAN card with existing/revised details",
        "Status enquiry"
      ],
      isList: true,
    },
    {
      title: "OLTAS Challan Status Enquiry",
      fillColor: "#6F9AE3",
      bgColor: "#E7F1FB",
      content:
        "Facility for tax payers to enquire online about the status of challans deposited in banks.",
      isList: false,
    },
    {
      title: "TAN",
      fillColor: "#66B85B",
      bgColor: "#F3F7F0",
      content: [
        "Facility to apply for TAN online",
        "Reprint of TAN allotment letter with existing/revised details",
        "Status enquiry"
      ],
      isList: true,
    },
    {
      title: "Online PAN Verification",
      fillColor: "#FFCE1B",
      bgColor: "#FFFAE8",
      content: [
        "Facility to verify PANs online to authorized entities (Entities who are approved by ITD can verify PANs online).",
        "Status enquiry"
      ],
      isList: true,
    },
    {
      title: "Form 24G",
      fillColor: "#EE7F25",
      bgColor: "#FBE9DD",
      content: [
        "Receive Form 24G statements from Account Offices (AO/PAO/DTO) and upload them to the TIN central system"
      ],
      isList: true,
    },
  ];

  return (
    <section className="lg:px-[70px] w-full md:py-8">
      <div className="flex flex-col w-full gap-8">
        <div className="w-full p-4 bg-[#EEF7ED]">
          <div className="flex items-center gap-2 w-full mb-4">
            <svg
              width="34"
              height="17"
              viewBox="0 0 34 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
                fill={"#54AD47"}
              />
              <path
                d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
                fill={"#54AD47"}
              />
              <circle cx="25.5" cy="8.5" r="8.5" fill={"#54AD47"} />
            </svg>
            <h2 className="text-2xl font-semibold">Overview</h2>
          </div>
          <p className="mt-2 text-gray-600 text-[15px]">
            TAN or Tax Deduction and Collection Account Number is a 10 digit
            alphanumeric number required to be obtained by all persons who are
            responsible for deducting or collecting tax. Under Section 203A of
            the Income Tax Act, 1961, it is mandatory to quote Tax Deduction
            Account Number (TAN) allotted by the Income Tax Department (ITD) on
            all TDS returns. Since last few years ITD has revised the structure
            of TAN. It is a unique 10 digit alphanumeric code. Accordingly, they
            have issued TAN in this new format to all existing TAN holders. To
            facilitate deductors find their new TAN, ITD has now introduced a
            search facility in their website (www.incometaxindia.gov.in).
            Through this facility deductors can search on their name and old TAN
            to find the new TAN. Deductors are advised to find the new TAN from
            this site before it is incorporated in their e-TDS return file to
            avoid any inconvenience at the time of furnishing e-TDS return.
          </p>
        </div>
        <div className='px-4 bg-[#6F368B] bg-opacity-10 md:px-0 flex flex-col justify-between'>
          <div className="p-8 justify-center flex flex-col">
            <h1 className='flex flex-row text-[26px] font-semibold gap-2 leading-5'>
              <ThreeDotsBulletSvg fillColor={'#6F368B'} className={'w-[34px] h-[17px]'} />
              TIN System
            </h1>
            <div className="flex justify-center items-center">
              <TinSystemFlowChart className='' />
            </div>
            <div className='pt-6'>
              <p className="text-lg font-semibold mb-2 md:pt-2">
                TIN has two key sub-systems
              </p>
              <p className='text-sm font-normal leading-6 md:pb-6'>
                Electronic Return Acceptance and Consolidation System (ERACS) which consists of an infrastructure for interface with the taxpayers (a nation wide network of TIN-Facilitation Centres i.e. TIN-FC) and a web-based utility for upload of electronic returns of Tax Deduction at Source (TDS) & Tax Collection at Source (TCS) and Annual Information Return (AIR) / Statement of Financial Transactions (SFT) to the central system of TIN.
              </p>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 ">
          {gridData.map((card, index) => (
            <div
              key={index}
              className="p-4 relative z-40"
              style={{ backgroundColor: card.bgColor }}
            >
              <div className="flex items-center gap-2 w-full mb-4">
                <svg
                  width="34"
                  height="17"
                  viewBox="0 0 34 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
                    fill={card.fillColor}
                  />
                  <path
                    d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5
                    C12.3056 0 8.5 3.80558 8.5 8.5Z"
                    fill={card.fillColor}
                  />
                  <circle cx="25.5" cy="8.5" r="8.5" fill={card.fillColor} />
                </svg>
                <h3 className="text-xl font-semibold">{card.title}</h3>
              </div>
              {card.isList ? (
                <ul className="list-disc pl-5">
                  {card.content.map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </ul>
              ) : (
                <p>{card.content}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ThirdGrid;