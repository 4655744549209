// src/App.jsx
import React, { useState } from "react";
import Tabs from "../../../components/Tabs";
import TabsDropdown from "../../../components/TabsDropDown";
import withScrollToHash from "../../../hooks/useScrollToHash";
import { Element } from "react-scroll";
import Layout from "../../../components/Layout";
import TabContent from "../../../components/TabContent";
import CustomerSvg from "../../../assets/images/Customer.png"
import CustomerCareComplaint from "./CustomerCareComplaintForm";
import CustomerCareQuery from "./CustomerCareQueryForm";
import CustomerCareSuggestionsForm from "./CustomerCareSuggestionForm";

function CustomerCareForms() {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      label: "Query",
      component: () => <Element name="customercare-query"><CustomerCareQuery /></Element>,
    },
    {
      label: "Complaint",
      component: () => <Element name="customercare-complaint"><CustomerCareComplaint /></Element>
    },
    {
      label: "Suggestion for Improvement",
      component: () => <Element name="customercare-suggestions"><CustomerCareSuggestionsForm /></Element>
    },
  ];

  return (
    <Layout>
      <div className="flex flex-col gap-6 px-4">
        <div className="flex flex-col gap-4">
          <div className="flex     gap-2 items-center w-full">
            <svg
              width="34"
              height="17"
              viewBox="0 0 34 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
                fill="#6F368B"
              />
              <path
                d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
                fill="#6F368B"
              />
              <circle cx="25.5" cy="8.5" r="8.5" fill="#6F368B" />
            </svg>

            <h3 className="font-semibold text-[26px]"> Customer Care Form</h3>
          </div>
          <p className="text-[15px] font-light">
            To register your complaint / Query / Feedback , kindly fill this
            form and submit.
          </p>
          <p className="text-[15px] font-semibold mt-4">
            For instruction in Hindi language on how to send a
            feedback/query/complaint to Protean please click here.
          </p>
        </div>

        <div className="w-full max-w-full mt-4 bg-white border border-[#EBEBEB]">
          <div className="md:hidden p-4">
            <TabsDropdown
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              activeTabColor="#F1EBF3"
            />
          </div>
          <div className="hidden md:flex relative">
            <img src={CustomerSvg} alt="" className="absolute right-0 top-0" />
            <div className="w-1/4 border-r">
              <Tabs
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                activeTabColor="#F1EBF3"
              />
            </div>
            <div className="w-3/4 p-4">
              <TabContent tabs={tabs} activeTab={activeTab} />
            </div>
          </div>
          <div className="p-4 md:hidden">
            <TabContent tabs={tabs} activeTab={activeTab} />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default withScrollToHash(CustomerCareForms);
