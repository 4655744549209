import React, { useState, useEffect } from "react";
import Tabs from "../../../components/Tabs";
import TabsDropdown from "../../../components/TabsDropDown";
import FormStateCity from "../../../components/FormStateCity";
import FormPincode from "../../../components/FormPinCode"
import Layout from "../../../components/Layout";
import TabContent from "../../../components/TabContent";
import SearchSvg from "../../../assets/images/Search.svg";
import Table from "../../../components/Table"; // Ensure the correct import path

const SearchForms = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [resultsPincode, setResultsPincode] = useState([]);
  const [resultsStateCity, setResultsStateCity] = useState([]);
  const [centers, setCenters] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    // Load the JSON data
    fetch('/TINFC_WEB_UPDATION.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
      })
      .then(data => {
        setCenters(data);
      })
      .catch(error => console.error('Error loading data:', error));
  }, []);

  const handleSearchPincode = ({ pincode }) => {
    const results = centers.filter(center => {
      const pinCode = center.PINCODE || center.Pincode;
      return pinCode && pinCode.toString() === pincode.toString();
    });

    if (results.length === 0) {
      setError('No data found for the entered pincode.');
    } else {
      setError('');
    }
    setResultsPincode(results);
    setResultsStateCity(results); // Set the resultsStateCity with the same results
  };

  const handleSearchStateCity = ({ state, location, pincode }) => {
    const normalizedState = state.trim().toLowerCase();
    const normalizedCity = location.trim().toLowerCase();
    const normalizedPincode = pincode.toString().trim();

    const results = centers.filter(center => {
      const centerState = (center.STATE || center.STATE || '').trim().toLowerCase();
      const centerCity = (center.CITY || center.CITY || '').trim().toLowerCase();
      const centerPinCode = (center.PINCODE || center.PINCODE || '').toString().trim();

      if (!centerState || !centerCity || !centerPinCode) {
        return false;
      }

      const stateMatch = centerState === normalizedState;
      const cityMatch = centerCity === normalizedCity;
      const pincodeMatch = centerPinCode === normalizedPincode;

      return stateMatch && cityMatch && pincodeMatch;
    });

    if (results.length === 0) {
      setError('No data found for the entered state, city, and pincode.');
    } else {
      setError('');
    }

    setResultsStateCity(results);
  };

  const tabs = [
    {
      label: "By Pincode",
      component: () => (
        <FormPincode onSubmit={handleSearchPincode} results={resultsPincode} />
      ),
    },
    {
      label: "By State/City",
      component: () => (
        <FormStateCity
          onSubmit={handleSearchStateCity}
          results={resultsStateCity}
        />
      ),
    },
  ];

  return (
    <Layout>
      <div className="flex flex-col gap-8 px-4">
        <div className="flex flex-col gap-4">
          <div className="flex gap-2 items-center w-full">
            <svg
              width="34"
              height="17"
              viewBox="0 0 34 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
                fill="#6F368B"
              />
              <path
                d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
                fill="#6F368B"
              />
              <circle cx="25.5" cy="8.5" r="8.5" fill="#6F368B" />
            </svg>

            <h3 className="font-semibold text-[26px]">Overview</h3>
          </div>
          <p className="text-[15px] font-light">
            TIN Facilitation Centres available across the country.
          </p>
          <p className="text-[15px] md:py-4 font-bold">Activities carried out by these TIN Facilitation Centres are:</p>
          <ul className="list-disc list-inside text-[15px] font-light">
            <li>Receive e-TDS/TCS returns from deductors/collectors and upload them to the TIN central system.</li>
            <li>Receive TDS/TCS returns in paper format from non-corporate, non-government deductors/collectors and upload them to the TIN central system.</li>
            <li>Receive Annual Information Returns from filers and upload them to the TIN central system.</li>
            <li>Receive 'applications for allotment of new TAN(Form 49B)' and 'Request for Changes or Correction in TAN data for TAN allotted' from TAN applicants.</li>
            <li>Receive 'applications for allotment of new PAN(Form 49A,Form 49AA)' and 'Request for new PAN Card or/and changes or Correction in PAN data' from PAN applicants.</li>
            <li>Receive Form 24G statements from Account Offices (AO) and upload them to the TIN central system.</li>
          </ul>
        </div>
        <div className="flex flex-col md:pt-12">
          <div className="flex gap-2 items-center w-full">
            <svg
              width="34"
              height="17"
              viewBox="0 0 34 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
                fill="#54AD47"
              />
              <path
                d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
                fill="#54AD47"
              />
              <circle cx="25.5" cy="8.5" r="8.5" fill="#54AD47" />
            </svg>

            <h3 className="font-semibold text-[26px]">
              Search for TIN-Facilitation Centres Near Your Location
            </h3>
          </div>
          <div className="w-full max-w-full mt-4 bg-white border border-[#EBEBEB]">
            <div className="md:hidden p-4">
              <TabsDropdown
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
            <div className="hidden md:flex relative">
              <img src={SearchSvg} alt="" className="absolute right-0 top-0" />
              <div className="w-1/4 border-r">
                <Tabs
                  tabs={tabs}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </div>
              <div className="w-3/4 p-4">
                <TabContent tabs={tabs} activeTab={activeTab} />
              </div>
            </div>
            <div className="p-4 md:hidden">
              <TabContent tabs={tabs} activeTab={activeTab} />
            </div>
          </div>
        </div>
      </div>

      {error && (
        <div className="px-4 mt-4 text-red-500">
          {error}
        </div>
      )}

      {activeTab === 0 && resultsPincode.length > 0 && !error && (
        <div className="px-4 mt-4">
          <div className="flex gap-2 items-center w-full">
            <svg
              width="34"
              height="17"
              viewBox="0 0 34 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
                fill="#FFCE1B"
              />
              <path
                d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
                fill="#FFCE1B"
              />
              <circle cx="25.5" cy="8.5" r="8.5" fill="#FFCE1B" />
            </svg>

            <h3 className="font-semibold text-[26px]">PAN Centers</h3>
          </div>
          <Table data={resultsPincode} />
        </div>
      )}
      {activeTab === 1 && resultsStateCity.length > 0 && !error && (
        <div className="px-4 mt-4">
          <div className="flex gap-2 items-center w-full">
            <svg
              width="34"
              height="17"
              viewBox="0 0 34 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
                fill="#FFCE1B"
              />
              <path
                d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
                fill="#FFCE1B"
              />
              <circle cx="25.5" cy="8.5" r="8.5" fill="#FFCE1B" />
            </svg>

            <h3 className="font-semibold text-[26px]">PAN Centers</h3>
          </div>
          <Table data={resultsStateCity} />
        </div>
      )}
    </Layout>
  );
}

export default SearchForms;
