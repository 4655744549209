// ModeForSubmission.js
import React from "react";
import { Link11Svg3 } from "../../../assets/svgs";

function ModeForSubmission({ language }) {
  const modeForSubmissionContent = {
    en: [
      "The acknowledgment duly signed, affixed with photographs (in case of 'Individuals') along with Demand Draft, if any, and proof of identity (name in the application should be same as in the proof of identity) & proof of address (Individuals, HUFs, Body of Individuals, Association of Persons & Artificial Juridical Person should provide proof of address of residence stated in the application) as specified in the application form is to be sent to Protean at 'Income Tax Service Unit, Protean eGov Technologies Ltd. 4th Floor, Sapphire Chambers, Baner Road, Baner, Pune 411045.'",
      "For e-KYC & e-Sign based DSC based online PAN application there is no need to send physical documents to Protean as all these are paperless mode of PAN application.",
      "In case, AADHAAR is mentioned in the application (applicable for Individuals only), then the copy of AADHAAR allotment letter should also be enclosed along with the acknowledgment and supporting documents to be submitted to Protean. In case the applicant is a minor, Aadhaar of the minor should be mentioned in the application form. Aadhaar number (if provided) would be authenticated using applicant's details as mentioned in the application form.",
      "Superscribe the envelope with 'APPLICATION FOR PAN -- N-15 digit Acknowledgement Number' (e.g., 'APPLICATION FOR PAN -- N-881010200000097').",
      "Your acknowledgment, demand draft, if any, and proofs should reach Protean within 15 days from the date of online application.",
      "Applications received with demand draft as mode of payment shall be processed only on receipt of relevant proofs and realization of payment.",
      "Applications received with credit card / debit card / net banking as mode of payment shall be processed on receipt of relevant documents (acknowledgment and proofs).",
    ],
    hi: [
      "स्वीकृति विधिवत हस्ताक्षरित, तस्वीरों के साथ (यदि 'व्यक्तियों' के मामले में), किसी भी डिमांड ड्राफ्ट के साथ, पहचान का प्रमाण (आवेदन में नाम पहचान के प्रमाण के समान होना चाहिए) और पते का प्रमाण (व्यक्ति, एचयूएफ, व्यक्तियों का निकाय, व्यक्तियों का संघ और कृत्रिम न्यायिक व्यक्ति को आवेदन में बताए गए निवास के पते का प्रमाण प्रदान करना चाहिए) के साथ, जैसा कि आवेदन पत्र में निर्दिष्ट है, इसे 'इनकम टैक्स सर्विस यूनिट, प्रोटीन ईगॉव टेक्नोलॉजीज लिमिटेड, चौथी मंजिल, सफायर चैंबर्स, बानेर रोड, बानेर, पुणे 411045' पर प्रोटीन को भेजा जाना चाहिए।",
      "e-KYC और e-Sign आधारित DSC आधारित ऑनलाइन PAN आवेदन के लिए भौतिक दस्तावेज़ प्रोटीन को भेजने की आवश्यकता नहीं है क्योंकि ये सभी PAN आवेदन के कागज़ रहित तरीके हैं।",
      "यदि आवेदन में आधार का उल्लेख किया गया है (केवल व्यक्तियों के लिए लागू), तो आधार आवंटन पत्र की प्रति भी संलग्न की जानी चाहिए और स्वीकृति और सहायक दस्तावेज़ों के साथ प्रोटीन को प्रस्तुत की जानी चाहिए। यदि आवेदक नाबालिग है, तो आवेदन पत्र में नाबालिग का आधार उल्लेखित होना चाहिए। आधार नंबर (यदि प्रदान किया गया है) को आवेदन पत्र में उल्लिखित आवेदक के विवरण का उपयोग करके प्रमाणित किया जाएगा।",
      "लिफाफे के शीर्ष पर 'PAN के लिए आवेदन -- N-15 अंक की स्वीकृति संख्या' (जैसे, 'PAN के लिए आवेदन -- N-881010200000097') लिखें।",
      "आपकी स्वीकृति, डिमांड ड्राफ्ट, यदि कोई हो, और प्रमाण आवेदन की तारीख से 15 दिनों के भीतर प्रोटीन तक पहुंच जाना चाहिए।",
      "भुगतान के रूप में डिमांड ड्राफ्ट के साथ प्राप्त आवेदनों को केवल प्रासंगिक प्रमाण और भुगतान की प्राप्ति पर ही संसाधित किया जाएगा।",
      "भुगतान के रूप में क्रेडिट कार्ड / डेबिट कार्ड / नेट बैंकिंग के साथ प्राप्त आवेदनों को प्रासंगिक दस्तावेजों (स्वीकृति और प्रमाण) की प्राप्ति पर संसाधित किया जाएगा।",
    ],
  };

  const content = modeForSubmissionContent[language];

  return (
    <div className="p-4 bg-[#EEF7ED] relative min-h-[400px]">
      <Link11Svg3 className={"absolute top-0 right-4 hidden lg:block"} />
      <div className="flex items-center w-full gap-2">
        <h2 className="text-[26px] font-semibold flex items-center">
          {language === "en"
            ? "4. Mode for Submission"
            : "4. प्रस्तुति के तरीके"}
        </h2>
      </div>
      <ul className="list-decimal ml-5 space-y-2 text-[15px] mt-4">
        {content.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
}

export default ModeForSubmission;
