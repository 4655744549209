import React from "react";
import Card1 from "../../../assets/images/softwarecrd1.png"
import Card2 from "../../../assets/images/Softwarecrd2.png"
import Card3 from "../../../assets/images/softwareCard3.png"

export default function KnowStatus() {
    const cardData = [
        {
            text: "Know Status of Your Credit Card / debit card / net banking transaction for online application",
            href: "https://tin.tin.nsdl.com/tan/changemode.html",
            desktopbg: Card1,
            mobileBg: "",
            circleBg: "#3091D0",
        },
        {
            text: "Know Status of Your Application",
            href: "https://tin.tin.nsdl.com/pantan/StatusTrack.html",
            desktopbg: Card2,
            mobileBg: "",
            circleBg: "#EE7F25",
        },

    ];
    return (
        <section className="relative text-black pb-6 md:pb-12 lg:px-[70px]">
            {/* <div className="flex  p-8 flex-col bg-[#FCEBE9]">
                <div className="flex gap-2 items-center w-full">

                    <svg
                        width="34"
                        height="17"
                        viewBox="0 0 34 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
                            fill="#E43625"
                        />
                        <path
                            d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
                            fill="#E43625"
                        />
                        <circle cx="25.5" cy="8.5" r="8.5" fill="#E43625" />
                    </svg>

                    <h3 className="font-semibold text-[26px]">Software providers</h3>
                </div>
                <div className="flex justify-between items-center flex-col lg:flex-row ">
                    <div className="lg:max-w-[65%] flex flex-col mt-4">
                        <h3>Software providers</h3>
                        <p className="text-[15px] font-light mt-4">
                            TDS/TCS statements are furnished on quarterly basis, Now,
                            quarterly statements pertaining to Financial Year 2007-08 onwards
                            are accepted as per the guidelines of Income Tax Department.
                        </p>
                        <p className="text-[15px] font-light mt-4">
                            ITD has notified revised file formats for preparation of TDS (i.e.
                            Tax Deducted at source) and TCS (i.e. Tax collected at source)
                            statements . Deductors/collectors are required to prepare
                            e-TDS/TCS statements as per these file formats using, Protean
                            (formerly NSDL eGov) e-Gov. Return Preparation Utility or in-house
                            software or any other third party software and submit the same to
                            any of the TIN-FCs established by Protean (formerly NSDL eGov)
                            e-Gov.
                        </p>
                        <p className="text-[15px] font-light mt-4">
                            TDS/TCS statements are furnished on quarterly basis, Now,
                            quarterly statements pertaining to Financial Year 2007-08 onwards
                            are accepted as per the guidelines of Income Tax Department.
                        </p>
                    </div>
                    <div className="flex justify-between items-start border-b border-b-black py-2 max-h-[90px]  lg:max-w-[300px]">
                        <a
                            href=""
                            className="text-[#1D1D1B] cursor-pointer   lg:max-w-[210px]"
                        >
                            List of Software Providers for Annual Information Returns{" "}
                        </a>
                        <div>
                            <svg
                                className="text-[#1D1D1B]"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12.9495 3.05078L3.04997 12.9503"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M12.9495 8.70764V3.05078"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M7.29291 3.05108H12.9498"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>
                    </div>
                </div>{" "}
            </div> */}
            <div className="flex flex-wrap px-4 lg:px-0 gap-[1.6rem] mt-10">
                {cardData.map((card, index) => (
                    <div
                        className="flex justify-between  bg-no-repeat bg-cover min-w-full lg:min-w-[412px] min-h-[203px]"
                        style={{
                            backgroundImage: `url(${card.desktopbg
                                })`,
                        }}
                    >
                        <a className="cursor-pointer w-full md:w-[90%]" href={card.href}>
                            <div class="flex justify-between  px-4  py-[8px]   ">
                                <p class="  max-w-[252px] text-white text-[23px] font-semibold">
                                    {card.text}
                                </p>

                                <div
                                    className={`rounded-full w-[40px] h-[40px] flex justify-center items-center `}
                                    style={{ backgroundColor: card.circleBg }}
                                >
                                    <svg
                                        class="text-[#fefefe]"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M12.9495 3.05078L3.04997 12.9503"
                                            stroke="#fefefe"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M12.9495 8.70764V3.05078"
                                            stroke="#fefefe"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M7.29291 3.05108H12.9498"
                                            stroke="#fefefe"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </a>
                    </div>
                ))}
            </div>
        </section>
    );
}
