import React, { useState } from "react";
import {
  ArrowUpSvg,
  DropDownArrowSvg,
  ThreeDotsBulletSvg,
} from "../../../../assets/svgs";

const ProccessingFee = ({ language, isMobile }) => {
  const [isOverviewOpen, setIsOverviewOpen] = useState(true);
  const toggleOverview = () => setIsOverviewOpen(!isOverviewOpen);

  const content = {
    English: (
      <section className="mb-8 px-0">
        <div className="px-4 md:px-0 flex items-center justify-between">
          <h1 className="flex flex-row text-[26px] font-semibold gap-2 leading-5">
            Fee
          </h1>
          {isMobile && (
            <button onClick={toggleOverview} className="">
              {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
            </button>
          )}
        </div>
        {isOverviewOpen && (
          <div>
            <div className="md:max-w-[50vw] p-6 md:pb-8">
              <p className="text-lg font-semibold mb-2 md:px-8 md:pt-2">
                A) If Physical PAN Card is required:
              </p>
              <p className="text-sm font-normal leading-6 md:px-8 md:pb-2">
                While submitting PAN application form, applicant will have to
                indicate whether physical PAN card is required. If applicant
                opts for physical PAN Card, then physical PAN card will be
                printed & dispatched at communication address. The e-PAN card in
                PDF format will be dispatched at e-mail ID mentioned in PAN
                application form, if the same is provided. Fees applicable are
                as follows:-
              </p>
            </div>
            <div className="grid grid-cols-1 gap-4 mb-4 w-full ">
              <table className=" w-full  ml-[-1rem] lg:ml-0">
                <thead className="bg-[#6F368B] bg-opacity-20">
                  <tr>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      Sr. No
                    </th>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      Particulars
                    </th>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      Fees (exclusive of applicable taxes)(₹)
                    </th>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      Fees (inclusive of applicable taxes)(₹)
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-[#6F368B] bg-opacity-5 ">
                  <tr>
                    <td
                      colSpan="4"
                      className="px-4 py-2 text-sm font-semibold leading-6"
                    >
                      PAN applications submitted at TIN Facilitation Centres /
                      PAN Centres
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2">1</td>
                    <td className="px-4 py-2">
                      Dispatch of physical PAN Card in India (Communication
                      address is Indian address)
                    </td>
                    <td className="px-4 py-2">91</td>
                    <td className="px-4 py-2">107</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2">2</td>
                    <td className="px-4 py-2">
                      Dispatch of physical PAN Card outside India (where foreign
                      address is provided as address for communication)
                    </td>
                    <td className="px-4 py-2">862</td>
                    <td className="px-4 py-2">1,017</td>
                  </tr>
                </tbody>
              </table>
              <table className="table-auto w-full   ml-[-1rem] lg:ml-0 ">
                <thead className="bg-[#6F368B] bg-opacity-20">
                  <tr>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      Sr. No
                    </th>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      Particulars
                    </th>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      Fees (exclusive of applicable taxes)(₹)
                    </th>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      Fees (inclusive of applicable taxes)(₹)
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-[#6F368B] bg-opacity-5">
                  <tr>
                    <td
                      colSpan="4"
                      className="px-4 py-2 text-sm font-semibold leading-6"
                    >
                      PAN applications submitted Online using physical mode
                      (i.e. Physical documents forwarded to Protean.)
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2">1</td>
                    <td className="px-4 py-2">
                      Dispatch of physical PAN Card in India (Communication
                      address is Indian address)
                    </td>
                    <td className="px-4 py-2">91</td>
                    <td className="px-4 py-2">107</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2">2</td>
                    <td className="px-4 py-2">
                      Dispatch of physical PAN Card outside India (where foreign
                      address is provided as address for communication)
                    </td>
                    <td className="px-4 py-2">862</td>
                    <td className="px-4 py-2">1,017</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
        {isOverviewOpen && (
          <div className="grid grid-cols-1 gap-4">
            <table className="table-auto w-full  ml-[-1rem] lg:ml-0">
              <thead className="bg-[#6F368B] bg-opacity-20">
                <tr>
                  <th className="px-4 py-2 text-sm font-semibold leading-6">
                    Sr. No
                  </th>
                  <th className="px-4 py-2 text-sm font-semibold leading-6">
                    Particulars
                  </th>
                  <th className="px-4 py-2 text-sm font-semibold leading-6">
                    Fees (exclusive of applicable taxes)(₹)
                  </th>
                  <th className="px-4 py-2 text-sm font-semibold leading-6">
                    Fees (inclusive of applicable taxes)(₹)
                  </th>
                </tr>
              </thead>
              <tbody className="bg-[#6F368B] bg-opacity-5">
                <tr>
                  <td
                    colSpan="4"
                    className="px-4 py-2 text-sm font-semibold leading-6"
                  >
                    PAN applications submitted Online through paperless modes
                    (e-KYC & e-Sign / e-Sign scanned based / DSC scanned based):
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2">1</td>
                  <td className="px-4 py-2">
                    Dispatch of physical PAN Card in India (Communication
                    address is Indian address)
                  </td>
                  <td className="px-4 py-2">86</td>
                  <td className="px-4 py-2">101</td>
                </tr>
                <tr>
                  <td className="px-4 py-2">2</td>
                  <td className="px-4 py-2">
                    Dispatch of physical PAN Card outside India (where foreign
                    address is provided as address for communication)
                  </td>
                  <td className="px-4 py-2">857</td>
                  <td className="px-4 py-2">1,011</td>
                </tr>
              </tbody>
            </table>

            <p style={{ color: 'red', whiteSpace: 'nowrap', fontSize: '0.8em' }}>
              eSign charges Rs. 5.90 (including applicable taxes) is applicable if eKYC and/or e-Sign Service is selected
            </p>

            <table className="table-auto w-full  ml-[-1rem] lg:ml-0">
              <thead className="bg-[#6F368B] bg-opacity-20">
                <tr>
                  <th className="px-4 py-2 text-sm font-semibold leading-6">
                    Sr. No
                  </th>
                  <th className="px-4 py-2 text-sm font-semibold leading-6">
                    Particulars
                  </th>
                  <th className="px-4 py-2 text-sm font-semibold leading-6">
                    Fees (exclusive of applicable taxes)(₹)
                  </th>
                  <th className="px-4 py-2 text-sm font-semibold leading-6">
                    Fees (inclusive of applicable taxes)(₹)
                  </th>
                </tr>
              </thead>
              <tbody className="bg-[#6F368B] bg-opacity-5">
                <tr>
                  <td
                    colSpan="4"
                    className="px-4 py-2 text-sm font-semibold leading-6"
                  >
                    Request for Reprint of PAN card submitted through separate
                    online link
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2">1</td>
                  <td className="px-4 py-2">
                    Dispatch of physical PAN Card in India (Communication
                    address is Indian address)
                  </td>
                  <td className="px-4 py-2">42</td>
                  <td className="px-4 py-2">50</td>
                </tr>
                <tr>
                  <td className="px-4 py-2">2</td>
                  <td className="px-4 py-2">
                    Dispatch of physical PAN Card outside India (where foreign
                    address is provided as address for communication)
                  </td>
                  <td className="px-4 py-2">813</td>
                  <td className="px-4 py-2">959</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        {isOverviewOpen && (
          <div>
            <div className="md:max-w-[50vw] md:pb-8 p-6">
              <p className="text-lg font-semibold mb-2 md:px-8 md:pt-2">
                B) If Physical PAN Card is not required:
              </p>
              <p className="text-sm font-normal leading-6 md:px-8 md:pb-2">
                PAN applicant will have to indicate at the time of submission of
                PAN application, if the physical PAN Card is not required. In
                such cases, email ID will be mandatory & e-PAN Card will be sent
                to the PAN applicant at the email ID. Physical PAN Card will not
                be dispatched in such cases. Fees applicable are as follows:-
              </p>
            </div>
            <div className="grid grid-cols-1 gap-4 mb-4">
              <table className="table-auto w-full  ml-[-1rem] lg:ml-0">
                <thead className="bg-[#6F368B] bg-opacity-20">
                  <tr>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      Sr. No
                    </th>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      Particulars
                    </th>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      Fees (exclusive of applicable taxes)(₹)
                    </th>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      Fees (inclusive of applicable taxes)(₹)
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-[#6F368B] bg-opacity-5">
                  <tr>
                    <td
                      colSpan="4"
                      className="px-4 py-2 text-sm font-semibold leading-6"
                    >
                      PAN applications submitted at TIN Facilitation Centres /
                      PAN Centres
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2">1</td>
                    <td className="px-4 py-2">
                      e-PAN Card will be dispatched at the email ID mentioned in the PAN application form
                    </td>
                    <td className="px-4 py-2">61</td>
                    <td className="px-4 py-2">72</td>
                  </tr>
                </tbody>
              </table>
              <table className="table-auto w-full  ml-[-1rem] lg:ml-0">
                <thead className="bg-[#6F368B] bg-opacity-20">
                  <tr>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      Sr. No
                    </th>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      Particulars
                    </th>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      Fees (exclusive of applicable taxes)(₹)
                    </th>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      Fees (inclusive of applicable taxes)(₹)
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-[#6F368B] bg-opacity-5">
                  <tr>
                    <td
                      colSpan="4"
                      className="px-4 py-2 text-sm font-semibold leading-6"
                    >
                      PAN applications submitted Online using physical mode
                      (i.e. Physical documents forwarded to Protean.)
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2">1</td>
                    <td className="px-4 py-2">
                      e-PAN Card will be dispatched at the email ID mentioned in the PAN application form
                    </td>
                    <td className="px-4 py-2">61</td>
                    <td className="px-4 py-2">72</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
        {isOverviewOpen && (
          <div className="grid grid-cols-1 gap-4">
            <table className="table-auto w-full  ml-[-1rem] lg:ml-0">
              <thead className="bg-[#6F368B] bg-opacity-20">
                <tr>
                  <th className="px-4 py-2 text-sm font-semibold leading-6">
                    Sr. No
                  </th>
                  <th className="px-4 py-2 text-sm font-semibold leading-6">
                    Particulars
                  </th>
                  <th className="px-4 py-2 text-sm font-semibold leading-6">
                    Fees (exclusive of applicable taxes)(₹)
                  </th>
                  <th className="px-4 py-2 text-sm font-semibold leading-6">
                    Fees (inclusive of applicable taxes)(₹)
                  </th>
                </tr>
              </thead>
              <tbody className="bg-[#6F368B] bg-opacity-5">
                <tr>
                  <td
                    colSpan="4"
                    className="px-4 py-2 text-sm font-semibold leading-6"
                  >
                    PAN applications submitted Online through paperless modes
                    (e-KYC & e-Sign / e-Sign scanned based / DSC scanned based):
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2">1</td>
                  <td className="px-4 py-2">
                    e-PAN Card will be dispatched at the email ID mentioned in the PAN application form
                  </td>
                  <td className="px-4 py-2">56</td>
                  <td className="px-4 py-2">66</td>
                </tr>
              </tbody>
            </table>

            <p style={{ color: 'red', whiteSpace: 'nowrap', fontSize: '0.8em' }}>
              eSign charges Rs. 5.90 (including applicable taxes) is applicable if eKYC and/or e-Sign Service is selected
            </p>

          </div>
        )}
      </section>
    ),
    Hindi: (
      <section className="mb-8">
        <div className="px-4 md:px-0 flex items-center justify-between">
          <h1 className="flex flex-row text-[26px] font-semibold gap-2 leading-5">
            शुल्क
          </h1>
          {isMobile && (
            <button onClick={toggleOverview} className="">
              {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
            </button>
          )}
        </div>
        {isOverviewOpen && (
          <div>
            <div className="md:max-w-[50vw] p-6 md:pb-8">
              <p className="text-lg font-semibold mb-2 md:px-8 md:pt-2">
                A) यदि भौतिक PAN कार्ड की आवश्यकता है:
              </p>
              <p className="text-sm font-normal leading-6 md:px-8 md:pb-2">
                PAN आवेदन पत्र जमा करते समय, आवेदक को यह संकेत देना होगा कि क्या
                भौतिक PAN कार्ड की आवश्यकता है। यदि आवेदक भौतिक PAN कार्ड का
                विकल्प चुनता है, तो भौतिक PAN कार्ड प्रिंट और संचार पते पर भेजा
                जाएगा। यदि उपलब्ध कराया गया है तो PDF प्रारूप में e-PAN कार्ड
                PAN आवेदन पत्र में उल्लिखित ईमेल ID पर भेजा जाएगा। लागू शुल्क इस
                प्रकार हैं:-
              </p>
            </div>
            <div className="grid grid-cols-1 gap-4 mb-4">
              <table className="table-auto w-full  ml-[-1rem] lg:ml-0">
                <thead className="bg-[#6F368B] bg-opacity-20">
                  <tr>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      क्रम संख्या
                    </th>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      विवरण
                    </th>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      शुल्क (लागू करों को छोड़कर)(₹)
                    </th>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      शुल्क (लागू करों सहित)(₹)
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-[#6F368B] bg-opacity-5">
                  <tr>
                    <td
                      colSpan="4"
                      className="px-4 py-2 text-sm font-semibold leading-6"
                    >
                      PAN आवेदन TIN सुविधा केंद्रों / PAN केंद्रों पर जमा किए गए
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2">1</td>
                    <td className="px-4 py-2">
                      भारत में भौतिक PAN कार्ड का प्रेषण (संचार पता भारतीय पता
                      है)
                    </td>
                    <td className="px-4 py-2">91</td>
                    <td className="px-4 py-2">107</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2">2</td>
                    <td className="px-4 py-2">
                      भारत के बाहर भौतिक PAN कार्ड का प्रेषण (जहां विदेशी पता
                      संचार पते के रूप में प्रदान किया गया है)
                    </td>
                    <td className="px-4 py-2">862</td>
                    <td className="px-4 py-2">1,017</td>
                  </tr>
                </tbody>
              </table>
              <table className="table-auto w-full  ml-[-1rem] lg:ml-0">
                <thead className="bg-[#6F368B] bg-opacity-20">
                  <tr>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      क्रम संख्या
                    </th>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      विवरण
                    </th>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      शुल्क (लागू करों को छोड़कर)(₹)
                    </th>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      शुल्क (लागू करों सहित)(₹)
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-[#6F368B] bg-opacity-5">
                  <tr>
                    <td
                      colSpan="4"
                      className="px-4 py-2 text-sm font-semibold leading-6"
                    >
                      PAN आवेदन ऑनलाइन जमा किए गए भौतिक मोड का उपयोग करके (यानी
                      भौतिक दस्तावेज Protean को अग्रेषित किए गए।)
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2">1</td>
                    <td className="px-4 py-2">
                      भारत में भौतिक PAN कार्ड का प्रेषण (संचार पता भारतीय पता
                      है)
                    </td>
                    <td className="px-4 py-2">91</td>
                    <td className="px-4 py-2">107</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2">2</td>
                    <td className="px-4 py-2">
                      भारत के बाहर भौतिक PAN कार्ड का प्रेषण (जहां विदेशी पता
                      संचार पते के रूप में प्रदान किया गया है)
                    </td>
                    <td className="px-4 py-2">862</td>
                    <td className="px-4 py-2">1,017</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
        {isOverviewOpen && (
          <div className="grid grid-cols-1 gap-4">
            <table className="table-auto w-full  ml-[-1rem] lg:ml-0">
              <thead className="bg-[#6F368B] bg-opacity-20">
                <tr>
                  <th className="px-4 py-2 text-sm font-semibold leading-6">
                    क्रम संख्या
                  </th>
                  <th className="px-4 py-2 text-sm font-semibold leading-6">
                    विवरण
                  </th>
                  <th className="px-4 py-2 text-sm font-semibold leading-6">
                    शुल्क (लागू करों को छोड़कर)(₹)
                  </th>
                  <th className="px-4 py-2 text-sm font-semibold leading-6">
                    शुल्क (लागू करों सहित)(₹)
                  </th>
                </tr>
              </thead>
              <tbody className="bg-[#6F368B] bg-opacity-5">
                <tr>
                  <td
                    colSpan="4"
                    className="px-4 py-2 text-sm font-semibold leading-6"
                  >
                    PAN आवेदन ऑनलाइन पेपरलेस मोड के माध्यम से जमा किए गए (e-KYC
                    & e-Sign / e-Sign स्कैन आधारित / DSC स्कैन आधारित):
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2">1</td>
                  <td className="px-4 py-2">
                    भारत में भौतिक PAN कार्ड का प्रेषण (संचार पता भारतीय पता है)
                  </td>
                  <td className="px-4 py-2">91</td>
                  <td className="px-4 py-2">107</td>
                </tr>
                <tr>
                  <td className="px-4 py-2">2</td>
                  <td className="px-4 py-2">
                    भारत के बाहर भौतिक PAN कार्ड का प्रेषण (जहां विदेशी पता
                    संचार पते के रूप में प्रदान किया गया है)
                  </td>
                  <td className="px-4 py-2">862</td>
                  <td className="px-4 py-2">1,017</td>
                </tr>
              </tbody>
            </table>
            <table className="table-auto w-full  ml-[-1rem] lg:ml-0">
              <thead className="bg-[#6F368B] bg-opacity-20">
                <tr>
                  <th className="px-4 py-2 text-sm font-semibold leading-6">
                    क्रम संख्या
                  </th>
                  <th className="px-4 py-2 text-sm font-semibold leading-6">
                    विवरण
                  </th>
                  <th className="px-4 py-2 text-sm font-semibold leading-6">
                    शुल्क (लागू करों को छोड़कर)(₹)
                  </th>
                  <th className="px-4 py-2 text-sm font-semibold leading-6">
                    शुल्क (लागू करों सहित)(₹)
                  </th>
                </tr>
              </thead>
              <tbody className="bg-[#6F368B] bg-opacity-5">
                <tr>
                  <td
                    colSpan="4"
                    className="px-4 py-2 text-sm font-semibold leading-6"
                  >
                    Request for Reprint of PAN card submitted through separate
                    online link
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2">1</td>
                  <td className="px-4 py-2">
                    भारत में भौतिक PAN कार्ड का प्रेषण (संचार पता भारतीय पता है)
                  </td>
                  <td className="px-4 py-2">91</td>
                  <td className="px-4 py-2">107</td>
                </tr>
                <tr>
                  <td className="px-4 py-2">2</td>
                  <td className="px-4 py-2">
                    भारत के बाहर भौतिक PAN कार्ड का प्रेषण (जहां विदेशी पता
                    संचार पते के रूप में प्रदान किया गया है)
                  </td>
                  <td className="px-4 py-2">862</td>
                  <td className="px-4 py-2">1,017</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        {isOverviewOpen && (
          <div>
            <div className="md:max-w-[50vw] md:pb-8 p-6">
              <p className="text-lg font-semibold mb-2 md:px-8 md:pt-2">
                B) यदि भौतिक PAN कार्ड की आवश्यकता नहीं है:
              </p>
              <p className="text-sm font-normal leading-6 md:px-8 md:pb-2">
                PAN आवेदक को PAN आवेदन पत्र जमा करते समय यह संकेत देना होगा कि
                भौतिक PAN कार्ड की आवश्यकता नहीं है। ऐसे मामलों में, ईमेल ID
                अनिवार्य होगी और e-PAN कार्ड PAN आवेदक को ईमेल ID पर भेजा जाएगा।
                ऐसे मामलों में भौतिक PAN कार्ड नहीं भेजा जाएगा। लागू शुल्क इस
                प्रकार हैं:-
              </p>
            </div>
            <div className="grid grid-cols-1 gap-4 mb-4">
              <table className="table-auto w-full ml-[-1rem] lg:ml-0">
                <thead className="bg-[#6F368B] bg-opacity-20">
                  <tr>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      क्रम संख्या
                    </th>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      विवरण
                    </th>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      शुल्क (लागू करों को छोड़कर)(₹)
                    </th>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      शुल्क (लागू करों सहित)(₹)
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-[#6F368B] bg-opacity-5">
                  <tr>
                    <td
                      colSpan="4"
                      className="px-4 py-2 text-sm font-semibold leading-6"
                    >
                      PAN आवेदन TIN सुविधा केंद्रों / PAN केंद्रों पर जमा किए गए
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2">1</td>
                    <td className="px-4 py-2">
                      भारत में भौतिक PAN कार्ड का प्रेषण (संचार पता भारतीय पता
                      है)
                    </td>
                    <td className="px-4 py-2">91</td>
                    <td className="px-4 py-2">107</td>
                  </tr>
                </tbody>
              </table>
              <table className="table-auto w-full  ml-[-1rem] lg:ml-0">
                <thead className="bg-[#6F368B] bg-opacity-20">
                  <tr>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      क्रम संख्या
                    </th>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      विवरण
                    </th>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      शुल्क (लागू करों को छोड़कर)(₹)
                    </th>
                    <th className="px-4 py-2 text-sm font-semibold leading-6">
                      शुल्क (लागू करों सहित)(₹)
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-[#6F368B] bg-opacity-5">
                  <tr>
                    <td
                      colSpan="4"
                      className="px-4 py-2 text-sm font-semibold leading-6"
                    >
                      PAN आवेदन ऑनलाइन जमा किए गए भौतिक मोड का उपयोग करके (यानी
                      भौतिक दस्तावेज Protean को अग्रेषित किए गए।)
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2">1</td>
                    <td className="px-4 py-2">
                      भारत में भौतिक PAN कार्ड का प्रेषण (संचार पता भारतीय पता
                      है)
                    </td>
                    <td className="px-4 py-2">91</td>
                    <td className="px-4 py-2">107</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
        {isOverviewOpen && (
          <div className="grid grid-cols-1 gap-4">
            <table className="table-auto w-full  ml-[-1rem] lg:ml-0">
              <thead className="bg-[#6F368B] bg-opacity-20">
                <tr>
                  <th className="px-4 py-2 text-sm font-semibold leading-6">
                    क्रम संख्या
                  </th>
                  <th className="px-4 py-2 text-sm font-semibold leading-6">
                    विवरण
                  </th>
                  <th className="px-4 py-2 text-sm font-semibold leading-6">
                    शुल्क (लागू करों को छोड़कर)(₹)
                  </th>
                  <th className="px-4 py-2 text-sm font-semibold leading-6">
                    शुल्क (लागू करों सहित)(₹)
                  </th>
                </tr>
              </thead>
              <tbody className="bg-[#6F368B] bg-opacity-5">
                <tr>
                  <td
                    colSpan="4"
                    className="px-4 py-2 text-sm font-semibold leading-6"
                  >
                    PAN आवेदन ऑनलाइन पेपरलेस मोड के माध्यम से जमा किए गए (e-KYC
                    & e-Sign / e-Sign स्कैन आधारित / DSC स्कैन आधारित):
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2">1</td>
                  <td className="px-4 py-2">
                    भारत में भौतिक PAN कार्ड का प्रेषण (संचार पता भारतीय पता है)
                  </td>
                  <td className="px-4 py-2">91</td>
                  <td className="px-4 py-2">107</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </section>
    ),
  };

  return <div>{content[language]}</div>;
};

export default ProccessingFee;
