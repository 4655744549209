import React from "react";
import bgBanner from "../../../assets/images/bg5.jpg";

import mobileBg from "../../../assets/images/mobileBgSection5.svg"
const FifthSection = () => {
  return (
    <section
      className="w-full flex flex-col  relative lg:px-0 py-10 lg:py-0 lg:max-h-[220px] bg-[#EE7F25] bg-cover lg:bg-no-repeat"
      style={{
        backgroundImage: window.innerWidth >= 1024 ? `url(${bgBanner})` : `url(${mobileBg})`,
      }}
    >
      <div className="flex flex-col gap-4 lg:gap-0  relative lg:flex-row lg:py-[20px] ">
        <div className="lg:px-[68px] px-10">
          <p className="text-[26px] text-black text-left flex items-center justify-start gap-2">
            <svg
              width="34"
              height="17"
              viewBox="0 0 34 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
                fill="#6F368B"
              />
              <path
                d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
                fill="#6F368B"
              />
              <circle cx="25.5" cy="8.5" r="8.5" fill="#6F368B" />
            </svg>
            Search
          </p>
        </div>

        <div className="flex gap-4  px-10 lg:px-[70px] flex-1 flex-col lg:flex-row justify-center w-max lg:py-[40px] items-center z-40">
          <a href="/pan-center">
            <div className="flex justify-between py-[8px] px-10 border-l-8 border-l-[#6F368B] bg-[#EBEBEB] min-w-[320px] min-h-[72px] items-center border-b-white">
              <a href="/pan-center" className="text-black">PAN Centers</a>
              <svg
                className="text-black ml-2"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.9495 3.05078L3.04997 12.9503"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.9495 8.70764V3.05078"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.29291 3.05108H12.9498"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </a>
          <a href="/tin-facilities">
            <div className="flex justify-between py-[8px] px-10 bg-[#EBEBEB] border-l-8 border-l-[#6F368B] min-h-[72px] items-center border-b-white">
              <a className="text-black max-w-[210px]">
                TIN Facilitation Centers cum PAN centers
              </a>
              <svg
                className="text-black ml-2"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.9495 3.05078L3.04997 12.9503"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.9495 8.70764V3.05078"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.29291 3.05108H12.9498"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
};

export default FifthSection;