import React, { useState } from 'react'
import { ArrowUpSvg, DropDownArrowSvg, ThreeDotsBulletSvg } from '../../../assets/svgs'
import DynamicLinks from '../../../components/DynamicLink';

function AoCodes() {
    const isMobile = window.innerWidth <= 768;
    const [isOverviewOpen, setIsOverviewOpen] = useState(isMobile || true)

    const toggleOverview = () => {
        setIsOverviewOpen(!isOverviewOpen)
    }

    const cardDataArray = [
        {
            linkData: [
                {
                    text: "TAN AO Codes version 4.9",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    href:"https://www.protean-tinpan.com/services/tan/download/2020-019-Annexure-TAN_AO_code_master_v_4.9.xlsx"
                },
            ],
        },
    ];

    return (
        <section className="md:px-[68px] mb-8 w-full">
            <div className='bg-[#6F368B] bg-opacity-10 p-8'>
                <div className='px-4 md:px-0 flex items-center justify-between'>
                    <h1 className='flex flex-row text-[26px] font-semibold gap-2 leading-5'>
                        <ThreeDotsBulletSvg fillColor={'#3091D0'} className={'w-[34px] h-[17px]'} />
                        AO Codes
                    </h1>
                    {isMobile && <button onClick={toggleOverview} className=''>
                        {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                    </button>}
                </div>
                {isOverviewOpen && <div className='pt-6'>
                    <p className='text-sm font-normal leading-6 md:pb-6'>
                        The applicants may track the status of their TAN application using 14 digit unique Acknowledgment Number after three days of application using the status track facility. Alternatively, applicant may call TIN Call Centre on 020 – 2721 8080 to enquire about the status of application. The status of the TAN application can also be tracked by sending an SMS - NSDLTAN to 57575.
                    </p>
                    <div className="flex flex-col md:flex-row pb-20 md:pb-0">
                        {cardDataArray.map((cardData, index) => (
                            <DynamicLinks key={index} linkData={cardData.linkData} listDir='flex-row' className={"text-black flex-col md:flex-row md:items-end gap-5 px-2 md:px-0 md:gap-[96px]"} titleColor='black' />
                        ))}
                    </div>
                </div>}
            </div>
        </section>
    )
}

export default AoCodes