import React from "react";

import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import dscBase1 from "../../../assets/images/DSCBased1.png";
import dscBase2 from "../../../assets/images/DSCBased.png";
import dsc2Graphic from "../../../assets/images/DSC2Mobile.png";
const DSCBases = () => {
  const { width } = useWindowDimensions();
  const isMobile = width <= 700;

  const data = [
    {
      text: "Application for allotment of New PAN (Form 49A) – applicable for Citizens of India",
      href: "https://tin.tin.nsdl.com/pan/form49Adsc.html",
    },
    {
      text: "Application for allotment of New PAN (Form 49AA) – applicable for foreign citizens",
      href: "https://tin.tin.nsdl.com/pan/form49AAdsc.html",
    },
    {
      text: "Reprint of PAN Card",
      href: "https://tin.tin.nsdl.com/pan/correctiondsc.html",
    },
  ];
  const cardDataArray2 = [
    {
      text: "Change/Correction in PAN Data",
      href: "https://tin.tin.nsdl.com/pan/correctiondsc.html",
    },
    {
      text: "Click here for guidelines for Application of New PAN in Company category",
      href: "https://www.protean-tinpan.com/downloads/pan/Additional_Instructions_for_Corporate_applicants.pdf",
    },
  ];

  const cardDataArray3 = [
    {
      text: "Application for allotment of New PAN (Form 49A) – applicable for Citizens of India",
      href: "https://tin.tin.nsdl.com/pan/form49Adsc.html",
    },
  ];
  return (
    <section className="flex flex-col w-full  relative ">
      <div className="flex w-full flex-col lg:flex-row lg:justify-between justify-center items-center gap-8 lg:px-[72px]">
        <div
          className="w-full bg-[#FCEBE9] lg:min-w-[640px] px-10 py-4 gap-8 flex flex-col  relative min-h-[326px]"
          style={{
            backgroundImage: isMobile ? "none" : `url(${dscBase2})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div class="flex justify-between items-center w-full">
            <p class="text-[26px] font-semibold text-black text-left flex items-center justify-start gap-2">
              DSC Based PAN Application
            </p>
            <p class="p-2 bg-[#54AD47] text-black rounded-full">NEW</p>
          </div>
          <div className="flex flex-col md:flex-row w-full items-start py-4 gap-10">
            <ul class="flex flex-col gap-4">
              {data.map((cardData, index) => (
                <li>
                  <a className="cursor-pointer" href={cardData.href}>
                    <div class="flex justify-between py-[8px] border-b-[1px] border-b-[#1D1D1B] gap-4  ">
                      <p class="text-[#1D1D1B] lg:max-w-[279px] text-[15px] font-light lg:min-w-[279px] ">
                        {cardData.text}
                      </p>
                      <svg
                        class="text-[#1D1D1B]"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.9495 3.05078L3.04997 12.9503"
                          stroke="#1D1D1B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.9495 8.70764V3.05078"
                          stroke="#1D1D1B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.29291 3.05108H12.9498"
                          stroke="#1D1D1B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
            <ul class="flex flex-col gap-4">
              {cardDataArray2.map((cardData, index) => (
                <li>
                  <a className="cursor-pointer" href={cardData.href}>
                    <div class="flex justify-between py-[8px] border-b-[1px] border-b-[#1D1D1B] lg:max-w-[412px]">
                      <p class="text-[#1D1D1B] text-[15px]  font-light lg:max-w-[279px]">
                        {cardData.text}
                      </p>
                      <svg
                        class="text-[#1D1D1B]"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.9495 3.05078L3.04997 12.9503"
                          stroke="#1D1D1B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.9495 8.70764V3.05078"
                          stroke="#1D1D1B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.29291 3.05108H12.9498"
                          stroke="#1D1D1B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="flex flex-col relative     items-center lg:min-w-[620px] min-h-[380px] bg-[#FFFAE8]">
          <img
            src={dsc2Graphic}
            alt=""
            className="absolute bottom-0 right-0 md:w-[357px]"
          />
          <div class="flex justify-between items-center w-full px-8 py-4">
            <p class="text-[26px] font-semibold text-black text-left flex items-center justify-start gap-2">
              DSC Based Common PAN & TAN Application
            </p>
            <p class="p-2 bg-[#54AD47] text-black rounded-full">NEW</p>
          </div>
          <div className="flex w-full px-8">
            <ul class="flex flex-col gap-4">
              {cardDataArray3.map((cardData, index) => (
                <li>
                  <a className="cursor-pointer" href={cardData.href}>
                    <div class="flex justify-between py-[8px] border-b-[1px] border-b-[#1D1D1B] lg:max-w-[412px]">
                      <p class="text-[#1D1D1B] text-[15px]  font-light lg:max-w-[279px]">
                        {cardData.text}
                      </p>
                      <svg
                        class="text-[#1D1D1B]"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.9495 3.05078L3.04997 12.9503"
                          stroke="#1D1D1B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.9495 8.70764V3.05078"
                          stroke="#1D1D1B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.29291 3.05108H12.9498"
                          stroke="#1D1D1B"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DSCBases;
