import React, { useState } from 'react'
import { ArrowUpSvg, DropDownArrowSvg, ThreeDotsBulletSvg } from '../../../assets/svgs'
import DynamicLinks from '../../../components/DynamicLink';

function DownloadTan() {
    const isMobile = window.innerWidth <= 768;
    const [isOverviewOpen, setIsOverviewOpen] = useState(isMobile || true)

    const toggleOverview = () => {
        setIsOverviewOpen(!isOverviewOpen)
    }

    const cardDataArray = [
        {
            linkData: [
                {
                    text: "Form 49B",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    href:"https://www.protean-tinpan.com/downloads/tan/download/Form49b_24072023_1.pdf"
                },
            ],
        },
    ];

    const cardDataArray2 = [
        {
            linkData: [
                {
                    text: "Form for Change or Correction in TAN data",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    href:"https://www.protean-tinpan.com/downloads/tan/download/TAN_CRF_24072023_1.pdf",
                    textColor: 'black',
                },
            ],
        },
    ];

    return (
        <section className="md:px-[68px] mb-8 w-full">
            <div className='bg-[#FFCE1B] bg-opacity-10 p-8'>
                <div className='px-4 md:px-0 flex items-center justify-between'>
                    <h1 className='flex flex-row text-[26px] font-semibold gap-2 leading-5'>
                        <ThreeDotsBulletSvg fillColor={'#3091D0'} className={'w-[34px] h-[17px]'} />
                        Download TAN
                    </h1>
                    {isMobile && <button onClick={toggleOverview} className=''>
                        {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                    </button>}
                </div>
                {isOverviewOpen && <div className='pt-6'>
                    <p className='text-sm font-normal leading-6 md:pb-6'>
                        Deductors who do not have a valid TAN can apply for one by submitting a duly filled and signed Form 49B to any of the TIN-FCs managed by Protean.
                    </p>
                    <div className="flex flex-col md:flex-row pb-20 md:pb-0">
                        {cardDataArray.map((cardData, index) => (
                            <DynamicLinks key={index} linkData={cardData.linkData} listDir='flex-row' className={"text-black flex-col md:flex-row md:items-end gap-5 px-2 md:px-0 md:gap-[96px]"} titleColor='black' />
                        ))}
                    </div>
                    <p className='text-sm font-normal leading-6 md:py-6'>
                        In case of deductors who want Change or Correction in data associated with their reformatted 10 digit TAN may fill up the "Form for Change or Correction in TAN data".
                    </p>
                    <div className="flex flex-col md:flex-row pb-20 md:pb-0">
                        {cardDataArray2.map((cardData, index) => (
                            <DynamicLinks key={index} linkData={cardData.linkData} listDir='flex-row' className={"text-black flex-col md:flex-row md:items-end gap-5 px-2 md:px-0 md:gap-[96px]"} titleColor='black' />
                        ))}
                    </div>
                </div>}
            </div>
        </section>
    )
}

export default DownloadTan