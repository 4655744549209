import React, { useState } from 'react'
import { ArrowUpSvg, DropDownArrowSvg, ThreeDotsBulletSvg } from '../../../assets/svgs';

function WhereToGet({ language }) {
    const isMobile = window.innerWidth <= 768;
    const [isOverviewOpen, setIsOverviewOpen] = useState(isMobile || true)

    const toggleOverview = () => {
        setIsOverviewOpen(!isOverviewOpen)
    }

    const textContent = {
        en: {
            overviewTitle: "Where to get a physical application forms",
            overviewDescription: "Applicants may obtain the application forms from TIN-FCs, PAN centres, any other vendors providing such forms or can freely download the same from this website.",
        },
        hi: {
            overviewTitle: "भौतिक आवेदन पत्र कहां से प्राप्त करें",
            overviewDescription: "आवेदक टीआईएन-एफसी, पैन केंद्रों, ऐसे फॉर्म प्रदान करने वाले किसी भी अन्य विक्रेता से आवेदन पत्र प्राप्त कर सकते हैं या इस वेबसाइट से इसे स्वतंत्र रूप से डाउनलोड कर सकते हैं।",
        }
    };

    return (
        <section className="bg-[#EE7F25] bg-opacity-10 p-8">
            <div className='px-4 md:px-0 flex items-center justify-between pb-6'>
                <h1 className='flex flex-row text-[26px] font-semibold gap-2 leading-5'>
                    <ThreeDotsBulletSvg fillColor={'#3091D0'} className={'w-[34px] h-[17px]'} />
                    {textContent[language].overviewTitle}
                </h1>
                {isMobile && <button onClick={toggleOverview} className=''>
                    {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                </button>}
            </div>
            {isOverviewOpen && <p className='text-sm font-normal leading-6'>
                {textContent[language].overviewDescription}
            </p>}
        </section>
    )
}

export default WhereToGet
