import React from "react";
import { Element } from "react-scroll";
import CustomerCareForms from "./components/CustomerCare";
import SearchAndContactUsSection from "../../components/SearchAndContact";
import CommonBanner from "../../components/CommonBanner";
import withScrollToHash from "../../hooks/useScrollToHash"; // Correctly import the HOC
import { Helmet } from "react-helmet";

function Link15() {
  return (
    <>
      <Helmet>
        <title>Customer Feedback Form - Protean Pan & Tan Services</title>
        <meta name="description" content="Submit your feedback with our customer feedback form. Contact us now!" />
      </Helmet>
      <div className="flex flex-col gap-8">
        <CommonBanner
          title={"Customer Care"}
          className={'min-h-[60vh] md:min-h-[50vh]'}
          isCustomerCare
          desktopWidth="466px"
          desktopHeight="466px"
        />
        <Element name="search-and-contact-us">
          <SearchAndContactUsSection />
        </Element>
        <Element name="customer-care-forms">
          <CustomerCareForms />
        </Element>
      </div>
    </>
  );
}

export default withScrollToHash(Link15); // Use the HOC
