import React from "react";
import { useLocation } from "react-router-dom";

function SearchResults() {
  const location = useLocation();
  const { query, results } = location.state || { query: "", results: [] };

  const highlightMatch = (text, query) => {
    const parts = text.split(new RegExp(`(${query})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase()
        ? <mark key={index}>{part}</mark>
        : part
    );
  };

  return (
    <section className="lg:px-[70px] pb-8">
      <div className="w-full p-4 bg-[#EEF7ED] ">
        <div className="flex items-center gap-2 w-full mb-4">
          <svg
            width="34"
            height="17"
            viewBox="0 0 34 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
              fill={"#54AD47"}
            />
            <path
              d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
              fill={"#54AD47"}
            />
            <circle cx="25.5" cy="8.5" r="8.5" fill={"#54AD47"} />
          </svg>
          <h2 className="text-2xl font-semibold">Search Result</h2>
        </div>
        {/* Search Results Starts */}
        <div className="container mx-auto p-4">
          <h1 className="text-2xl font-bold mb-4">Search Results for "{query}"</h1>
          {results.length > 0 ? (
            <ul>
              {results.map((result, index) => (
                <li key={index} className="mb-4">
                  <a href={result.path} className="text-blue-500 hover:underline text-lg">
                    {highlightMatch(result.label, query)}
                  </a>
                  <p className="text-sm text-gray-600 mt-1">
                    {highlightMatch(result.description || '', query)}
                  </p>
                  {result.keywords && (
                    <p className="text-xs text-gray-500 mt-1">
                      Keywords: {result.keywords.map(keyword => highlightMatch(keyword, query)).join(', ')}
                    </p>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p>No results found.</p>
          )}
        </div>
        {/* Search Results Ends */}
      </div>
    </section>
  );
}

export default SearchResults;
