import React from "react";
import logo from "../../../assets/images/logoBlck.png";
import Card1 from "../../../assets/images/link1Card1.png";
import Card2 from "../../../assets/images/link1Card2.png";
import Card3 from "../../../assets/images/link1Card3.png";
import Card4 from "../../../assets/images/link1Card4.png";

const cards = [
  {
    imgSrc: Card1,
    title: "National Pension System (NPS)",
    description:
      "Strengthening India’s OLD AGE SECURITY as Central Record keeping Agency (CRA) for National Pension System (NPS)",
    linkColor: "#E43625",
    linkHref: "https://proteantech.in/services/national-pension-system/",
  },
  {
    imgSrc: Card2,
    title: "Atal Pension Yojana (APY)",
    description:
      "Helping the unorganized sector workers save for their retirement by investing in Atal Pension Yojana (APY)",
    linkColor: "#3091D0",
    linkHref: "https://proteantech.in/services/atal-pension-yojana/",
  },
  {
    imgSrc: Card3,
    title: "e-KYC",
    description:
      "Digital identity services to manage authentication of digital IDs like PAN, Driver License, Voter ID and Aadhaar",
    linkColor: "#EE7F25",
    linkHref: "https://proteantech.in/services/e-kyc/",
  },
  {
    imgSrc: Card4,
    title: "Online PAN Verification (OPV)",
    description:
      "Digital identity services to manage authentication of digital IDs like PAN, Driver License, Voter ID and Aadhaar",
    linkColor: "#54AD47",
    linkHref: "https://proteantech.in/services/pan-opv/",
  },
];

const SixthSection = () => {
  return (
    <section className="w-full flex flex-col relative lg:px-0 ">
      <div className="flex flex-col items-center justify-center gap-10 py-10">
        <div className="flex px-[22px] lg:px-[70px] gap-2 items-center w-full">
          <svg
            width="34"
            height="17"
            viewBox="0 0 34 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
              fill="#3091D0"
            />
            <path
              d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
              fill="#3091D0"
            />
            <circle cx="25.5" cy="8.5" r="8.5" fill="#3091D0" />
          </svg>
          <h3 className="font-semibold text-[26px]">More from</h3>
          <img src={logo} width="150px" alt="proe" />
        </div>
        <div className="flex flex-col lg:flex-row justify-center gap-8">
          {cards.map((card, index) => (
            <div
              key={index}
              className="max-w-[290px] flex flex-col gap-4 items-center justify-center"
            >
              <a href={card.linkHref}>
                <img
                  className="rounded-t-lg"
                  width="240px"
                  src={card.imgSrc}
                  alt={card.title}
                />
              </a>
              <div className="flex flex-col justify-between min-h-[182px]">
                <a href={card.linkHref}>
                  <h5 className="mb-2 text-[20px] font-semibold tracking-tight text-black">
                    {card.title}
                  </h5>
                </a>
                <p className="mb-3 font-normal text-[#1D1D1B]">
                  {card.description}
                </p>
                <a
                  href={card.linkHref}
                  className="inline-flex items-center py-2 text-sm font-medium text-center"
                  style={{ color: card.linkColor }}
                >
                  Read more
                  <svg
                    className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SixthSection;
