import React from 'react'
import DynamicLinks from '../../../components/DynamicLink'

function RBI() {
    const tinArray = [
        {
            linkData: [
                {
                    text: "Search TIN FC",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    href: "/tin-facilities",
                },
            ],
        },
    ];

    const panArray = [
        {
            linkData: [
                {
                    text: "Search PAN Center",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    href: "/pan-center",
                },
            ],
        },
    ];

    return (
        <div className='flex flex-col pt-6 w-full md:px-[68px]'>
            <div className="flex md:flex-row flex-col gap-6">
                <div className='bg-[#6F368B] bg-opacity-10 p-8 md:w-[49%]'>
                    <h1 className='flex flex-row text-lg font-semibold gap-2 pb-6'>
                        Search TIN FC near your location for filling TDS / TCS /
                        Form 24G / AIR statements as well as PAN application.
                    </h1>
                    <div className="flex flex-col md:flex-row pb-20 md:pb-0">
                        {tinArray.map((cardData, index) => (
                            <DynamicLinks key={index} linkData={cardData.linkData} listDir='flex-row' className={"text-black flex-col md:flex-row md:items-end gap-5 px-2 md:px-0 md:gap-6"} titleColor='black' />
                        ))}
                    </div>
                </div>
                <div className='bg-[#6F368B] bg-opacity-10 p-8 md:w-[49%]'>
                    <h1 className='flex flex-row text-lg font-semibold gap-2 pb-6'>
                        Search exclusive PAN center near your location to submit your PAN application.
                    </h1>
                    <div className="flex flex-col md:flex-row pb-20 md:pb-0">
                        {panArray.map((cardData, index) => (
                            <DynamicLinks key={index} linkData={cardData.linkData} listDir='flex-row' className={"text-black flex-col md:flex-row md:items-end gap-5 px-2 md:px-0 md:gap-6"} titleColor='black' />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RBI