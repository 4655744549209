import React from 'react';
import { ThreeDotsBulletSvg } from '../../../assets/svgs';

const Section = () => {
    return (
        <div className='flex flex-col pb-12 md:py-6 w-full'>
            <div className="bg-[#6F368B] bg-opacity-10 p-8">
                <h1 className='flex flex-row text-[26px] font-semibold gap-2 pb-6 leading-5'>
                    <ThreeDotsBulletSvg fillColor={'#6F368B'} className={'w-[34px] h-[17px]'} />
                    Bank branches
                </h1>
                <p className='text-sm'>
                    Protean receives tax collection data as uploaded by the bank. Protean is not authorized to carry out any changes in the data sent by the bank to TIN.
                </p>
                <p className='text-sm pb-6'>The fields that can be corrected by the Taxpayer through Bank are tabulated below</p>
                <table className="w-full text-left mb-6">
                    <thead className='bg-[#6F368B] bg-opacity-15'>
                        <tr className='text-sm'>
                            <th className="px-4 py-2">Sr. No</th>
                            <th className="px-4 py-2">Type of Correction on Challan</th>
                            <th className="px-4 py-2">Period for correction request (in days)</th>
                        </tr>
                    </thead>
                    <tbody className='bg-[#6F368B] bg-opacity-5 text-sm'>
                        <tr>
                            <td className="px-4 py-2">1</td>
                            <td className="px-4 py-2">PAN/TAN</td>
                            <td className="px-4 py-2">Within 7 days from challan deposit date</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2">2</td>
                            <td className="px-4 py-2">Assessment Year</td>
                            <td className="px-4 py-2">Within 7 days from challan deposit date</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2">3</td>
                            <td className="px-4 py-2">Total Amount</td>
                            <td className="px-4 py-2">Within 7 days from challan deposit date</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2">4</td>
                            <td className="px-4 py-2">Major Head</td>
                            <td className="px-4 py-2">Within 3 months from challan deposit date</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2">5</td>
                            <td className="px-4 py-2">Minor Head</td>
                            <td className="px-4 py-2">Within 3 months from challan deposit date</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2">6</td>
                            <td className="px-4 py-2">Nature of Payment</td>
                            <td className="px-4 py-2">Within 3 months from challan deposit date</td>
                        </tr>
                    </tbody>
                </table>
                <p className='text-sm font-normal leading-6'>Note:</p>
                <ol className='list-decimal list-inside text-sm leading-6 pb-6'>
                    <li>Above correction mechanism is applicable only for physical challans with deposit date greater than equal to September 1, 2011.</li>
                    <li>Any correction request initiated by the taxpayer after the time limit specified above shall be rejected by Bank.</li>
                    <li>For challans with challan deposit date from September 1, 2011 to September 30, 2011, the time limit for correction in TAN/PAN, Assessment Year and Amount will be within 45 days from challan deposit date.</li>
                    <li>The fields that can be corrected and the entity authorized to carry out corrections on challan with deposit date less than September 1, 2011 are as below:</li>
                </ol>
                <table className="w-full text-left mb-6">
                    <thead className='bg-[#6F368B] bg-opacity-15'>
                        <tr className='text-sm'>
                            <th className="px-4 py-2">Sr. No</th>
                            <th className="px-4 py-2">Type of Correction on Challan</th>
                            <th className="px-4 py-2">Period for correction request (in days)</th>
                        </tr>
                    </thead>
                    <tbody className='bg-[#6F368B] bg-opacity-5 text-sm'>
                        <tr>
                            <td className="px-4 py-2">1</td>
                            <td className="px-4 py-2">PAN/TAN</td>
                            <td className="px-4 py-2">Within 7 days from challan deposit date</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2">2</td>
                            <td className="px-4 py-2">Assessment Year</td>
                            <td className="px-4 py-2">Within 7 days from challan deposit date</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2">3</td>
                            <td className="px-4 py-2">Total Amount</td>
                            <td className="px-4 py-2">Within 7 days from challan deposit date</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2">4</td>
                            <td className="px-4 py-2">Major Head</td>
                            <td className="px-4 py-2">Within 3 months from challan deposit date</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2">5</td>
                            <td className="px-4 py-2">Minor Head</td>
                            <td className="px-4 py-2">Within 3 months from challan deposit date</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2">6</td>
                            <td className="px-4 py-2">Nature of Payment</td>
                            <td className="px-4 py-2">Within 3 months from challan deposit date</td>
                        </tr>
                    </tbody>
                </table>
                <ol className='list-decimal list-inside text-sm leading-6' start="5">
                    <li>For rectifications in challans paid through online mode (internet challan) , taxpayer may contact their concerned Assessing Officer (AO) of the Income Tax Department (ITD).</li>
                    <li>For more details on Challan Correction Mechanism, taxpayer may visit Income Tax Department website (https://www.incometaxindia.gov.in/) and contact Aaykar Sampark Kendra (ASK) on Toll Free No. 1800-180-1961.</li>
                </ol>
            </div>
        </div>
    );
};

export default Section;
