import React, { useState } from "react";
import { Logo } from "../assets/svgs";
import { useNavigate } from "react-router-dom";
import SearchData from "./SearchData";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleSearch = (event) => {
    event.preventDefault();
    const query = event.target.search.value.toLowerCase();
    if (query) {
      const results = SearchData.filter((item) =>
        item.label.toLowerCase().includes(query) ||
        (item.keywords && item.keywords.some(keyword => keyword.toLowerCase().includes(query))) ||
        (item.description && item.description.toLowerCase().includes(query))
      );
      navigate("/search-result", { state: { query, results } });
    }
  };

  const menuItems = [
    { label: "About us", href: "/about-us" },
    {
      label: "Services",
      dropdown: [
        {
          label: "PAN",
          dropdown: [
            { label: "Apply Now", href: "/services/pan/pan-index" },
            { label: "Introduction", href: "/services/pan/pan-introduction" }
          ]
        },
        { label: "Online PAN Verification", href: "/online-pan-verification" },
        { label: "e-TDS/e-TCS", href: "/services/etds-etcs" },
        { label: "TAN", href: "/services/tan/tan-downloads" },
        { label: "Form 24G", href: "https://www.protean-tinpan.com/services/form-24g/form24g-index.html" },
        { label: "Online AIN Registration", href: "https://www.protean-tinpan.com/services/ain/online_ain_registration_overview.html" },
        { label: "e-Return Intermediary", href: "https://www.protean-tinpan.com/services/e-return/ereturn-overview.html" },
        { label: "Annual Information Return (AIR)", href: "https://www.protean-tinpan.com/services/air/air-index.html" },
        { label: "e-payment: Pay Taxes Online", href: "https://onlineservices.tin.egov-nsdl.com/etaxnew/tdsnontds.jsp" },
        { label: "Status of Tax Refunds", href: "https://www.protean-tinpan.com/services/status-tax-refunds/refundstatuslogin.html" },
        { label: "Challan Status Inquiry", href: "https://tin.tin.nsdl.com/oltas/index.html" },
      ],
    },
    { label: "Facilitation Center", href: "/facilation-center" },
    {
      label: "Downloads",
      dropdown: [
        { label: "PAN", href: "https://www.protean-tinpan.com/downloads/pan/downloads-pan.html" },
        { label: "Online Pan Verification", href: "https://www.protean-tinpan.com/downloads/online-pan-verification/pan-verify-download.html" },
        {
          label: "e-TDS/e-TCS",
          href: "/services/etds-etcs",
          dropdown: [
            {
              label: "Quarterly Returns",
              dropdown: [
                { label: "Regular", href: "https://www.protean-tinpan.com/downloads/e-tds/eTDS-download-regular.html" },
                { label: "Correction", href: "https://www.protean-tinpan.com/downloads/e-tds/eTDS-download-corr.html" }
              ]
            },
            { label: "RPU_e-Tutorial_on_TDS_TCS", href: "/RPU e-Tutorial on TDS TCS.pdf" }
          ]
        },
        { label: "TAN", href: "https://www.protean-tinpan.com/downloads/tan/tan-downloads.html" },
        { label: "Form 24G", href: "https://www.protean-tinpan.com/downloads/form-24g/form24g-download.html" },
        { label: "AIR", href: "https://www.protean-tinpan.com/downloads/air/airdownloads.html" },
      ],
    },
    {
      label: "FAQs",
      dropdown: [
        { label: "PAN", href: "/services/pan/pan-index#faqs" },
        { label: "Online PAN Verification", href: "/online-pan-verification#faqs" },
        { label: "e-TDS", href: "/services/etds-etcs#faqs" },
        { label: "TAN", href: "/services/tan/tan-introduction#faqs" },
        { label: "Form 24G", href: "https://www.protean-tinpan.com/downloads/form-24g/form24g-download.html" },
        { label: "Form 24Q Q4", href: "https://www.protean-tinpan.com/faqs/form-24q-q4/faq-form-fourth.html" },
        { label: "Annual Information Return (AIR)", href: "https://www.protean-tinpan.com/faqs/air/faqairgeneral.html" },
        { label: "Statement of Financial Transaction", href: "https://www.protean-tinpan.com/faqs/SFT/faq-SFT.html" },
        { label: "View All", href: "https://www.protean-tinpan.com/faq.html" },
      ],
    },

    {
      label: "Customer Care",
      dropdown: [
        // {
        //   label: "Rights",
        //   href: "https://www.protean-tinpan.com/customer-care.html",
        // },
        { label: "Complaints/Queries", href: "/customerfeedback#customer-care-forms" },
        { label: "Protean Addresses", href: "/nsdl-addresses#branch-offices-grid" },
      ],
    },
  ];

  return (
    <header className="bg-white lg:bg-[#EBEBEB] shadow w-screen flex items-center justify-center">
      <div className="container flex items-center lg:justify-center flex-col w-full">
        <div className="flex items-center w-screen bg-white justify-between lg:justify-between px-4 lg:px-[50px] py-4">
          <div className="flex items-center lg:space-x-2">
            <div className="max-w-[120px] lg:max-w-full">
              <h3 className="font-semibold text-[10px] lg:text-[24px] tracking-tight">
                PAN & Tax Information Network Services
              </h3>
              <p className="text-[8px] lg:text-sm">For Income Tax Department</p>
            </div>
            <a href="/" className="border-l-2 border-l-gray-300 flex items-center px-4">
              <Logo className="w-[150px] md:w-[197px] h-[51px]" />
            </a>
          </div>

          <form onSubmit={handleSearch} className="relative block">
            <input
              type="text"
              name="search"
              placeholder="Search"
              className="border hidden lg:block rounded-full py-2 px-4 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-600"
            />
            <button
              type="submit"
              className="lg:absolute right-0 top-0 lg:mt-2 mr-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-gray-700"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-4.35-4.35M11 19a8 8 0 100-16 8 8 0 000 16z"
                />
              </svg>
            </button>
          </form>

          <div className="block lg:hidden">
            <button
              id="hamburger-button"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="flex items-center px-3 py-2 rounded text-gray-500 hover:text-gray-600"
            >
              <svg
                width="18"
                height="12"
                viewBox="0 0 18 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z"
                  fill="#1D1D1B"
                />
              </svg>
            </button>
          </div>
        </div>

        <nav
          id="nav-menu"
          className={`${isMenuOpen ? "block" : "hidden"
            } lg:flex lg:items-center w-screen bg-[#EBEBEB] justify-between lg:px-[50px] h-[100vh] lg:h-[40px]`}
        >
          <a href="/" className="flex flex-row gap-2 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M20 7.093v-5.093h-3v2.093l3 3zm4 5.907l-12-12-12 12h3v10h18v-10h3zm-5 8h-14v-10.26l7-6.912 7 6.99v10.182zm-5-1h-4v-6h4v6z" /></svg>
            Home
          </a>
          <ul className="text-sm relative lg:flex-grow bg-[#EBEBEB] h-full py-6 lg:py-0 flex flex-col lg:flex-row lg:justify-end lg:items-center px-4">
            {menuItems.map((item, index) => (
              <li
                key={index}
                className={`block ${index == 0 && 'pl-4' || index == 2 && 'pl-4'} mt-4 text-base font-medium lg:inline-block lg:mt-0 text-gray-700 hover:text-gray-900 ${!item.dropdown && ""
                  } group relative`}
              >
                {item.dropdown ? (
                  <div className="relative flex flex-row float-start w-full text-left">
                    <button
                      className="inline-flex justify-between w-full px-4 py-2 text-base font-medium text-gray-700"
                    >
                      {item.label}
                      <svg
                        className="-mr-1 ml-2 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 9.293a1 1 0 011.414 0L10 12.586l3.293-3.293a1 1 011.414 1.414l-4 4a1 1 01-1.414 0l-4-4a1 1 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                    <div className="dropdown-menu hidden right-0 bg-white group-hover:block absolute z-40 mt-10 w-56 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none transition ease-in-out duration-200 transform">
                      {item.dropdown.map((subItem, subIndex) => (
                        <div key={subIndex} className="relative group/sub">
                          {subItem.dropdown ? (
                            <>
                              <button className="w-full text-left text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 flex justify-between items-center">
                                {subItem.label}
                                <svg className="h-4 w-4 ml-2" fill="currentColor" viewBox="0 0 20 20">
                                  <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                </svg>
                              </button>
                              <div className="hidden group-hover/sub:block absolute left-auto z-20 md:left-full md:top-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                {subItem.dropdown.map((nestedItem, nestedIndex) => (
                                  <div key={nestedIndex} className="relative group/nested">
                                    {nestedItem.dropdown ? (
                                      <>
                                        <button className="w-full text-left text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 md:flex justify-between items-center">
                                          {nestedItem.label}
                                          <svg className="h-4 w-4 ml-2" fill="currentColor" viewBox="0 0 20 20">
                                            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                          </svg>
                                        </button>
                                        <div className="hidden group-hover/nested:block absolute left-full top-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                          {nestedItem.dropdown.map((deepNestedItem, deepNestedIndex) => (
                                            <a
                                              key={deepNestedIndex}
                                              href={deepNestedItem.href}
                                              className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                                            >
                                              {deepNestedItem.label}
                                            </a>
                                          ))}
                                        </div>
                                      </>
                                    ) : (
                                      <a
                                        href={nestedItem.href}
                                        className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                                      >
                                        {nestedItem.label}
                                      </a>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </>
                          ) : (
                            <a
                              href={subItem.href}
                              className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                            >
                              {subItem.label}
                            </a>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <a href={item.href}>{item.label}</a>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
