// src/components/FormPincode.jsx
import React, { useState, useEffect } from "react";
import { RefreshSvg } from "../assets/svgs";
import Table from "./Table"; // Ensure the correct import path
import Recaptcha from "./ReCaptcha";

const FormPincode = ({ onSubmit, results }) => {
  const [pincode, setPincode] = useState("");
  const [captchaCode, setCaptchaCode] = useState("");
  const [enterCaptchaCode, setEnterCaptchaCode] = useState("");

  const generateCaptcha = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    setCaptchaCode(result);
  };

  // Generate captcha on component mount
  useEffect(() => {
    generateCaptcha();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ pincode });
    // if (enterCaptchaCode.toLowerCase() === captchaCode.toLowerCase()) {
    //   onSubmit({ pincode, enterCaptchaCode });
    // } else {
    //   alert("Captcha does not match. Please try again.");
    //   setEnterCaptchaCode("");
    //   generateCaptcha();
    // }
  };

  return (
    <>
      <div>
        <form onSubmit={handleSubmit} className="flex flex-col p-2 justify-between">
          <div>
            <label className="block">Enter Pincode</label>
            <input
              type="text"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
              className="max-w-[308px] mt-2 p-2 border border-[#EBEBEB] rounded-[4px]"
              required
            />
          </div>
          {/* <div className="flex mt-4 flex-wrap gap-10 w-full">
            <div className="lg:min-w-[307px] ">
              <label className="block mb-2">Captcha Code</label>
              <div className="flex justify-between border">
                <Recaptcha onChange={setCaptchaCode} />
              </div>
            </div>
            <div className="lg:min-w-[307px]">
              <label className="block mb-2">Enter Captcha Code</label>
              <input
                type="text"
                value={enterCaptchaCode}
                onChange={(e) => setEnterCaptchaCode(e.target.value)}
                className="p-2 border lg:min-w-[307px] rounded"
                placeholder="Enter code"
              />
            </div>
          </div> */}
          <div className="border-t-[1px] border-t-[#EBEBEB] min-h-[90px] mt-10 w-full flex justify-end items-center">
            <button
              type="submit"
              className="p-2 mt-4 px-8 bg-green-500 text-white rounded"
            >
              Search
            </button>
          </div>
        </form>
      </div>

    </>
  );
};

export default FormPincode;
