import React, { useState } from 'react'
import { ArrowUpSvg, DropDownArrowSvg, ThreeDotsBulletSvg } from '../../../assets/svgs';

function HowToApply({ language }) {
    const isMobile = window.innerWidth <= 768;
    const [isOverviewOpen, setIsOverviewOpen] = useState(isMobile || true)

    const toggleOverview = () => {
        setIsOverviewOpen(!isOverviewOpen)
    }

    const textContent = {
        en: {
            overviewTitle: "How to apply",
            overviewDescription1: "Applicant may either make an online application through this website or submit physical PAN Application to any TIN-FC or PAN centre of Protean.",
            overviewDescription2: "Applicants should go through the instructions and guidelines provided in the application form before filling the form.",
        },
        hi: {
            overviewTitle: "आवेदन कैसे करें",
            overviewDescription1: "आवेदक या तो इस वेबसाइट के माध्यम से ऑनलाइन आवेदन कर सकता है या प्रोटीन के किसी भी टिन-एफसी या पैन केंद्र पर भौतिक पैन आवेदन जमा कर सकता है।",
            overviewDescription2: "आवेदकों को फॉर्म भरने से पहले आवेदन पत्र में दिए गए निर्देशों और दिशानिर्देशों को पढ़ लेना चाहिए।",
        }
    };

    return (
        <section className="bg-[#3091D0] bg-opacity-10 p-8">
            <div className='px-4 md:px-0 flex items-center justify-between pb-6'>
                <h1 className='flex flex-row text-[26px] font-semibold gap-2 leading-5'>
                    <ThreeDotsBulletSvg fillColor={'#3091D0'} className={'w-[34px] h-[17px]'} />
                    {textContent[language].overviewTitle}
                </h1>
                {isMobile && <button onClick={toggleOverview} className=''>
                    {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                </button>}
            </div>
            {isOverviewOpen && <p className='text-sm font-normal leading-6'>
                {textContent[language].overviewDescription1}
            </p>}
            {isOverviewOpen && <p className='text-sm font-normal leading-6'>
                {textContent[language].overviewDescription2}
            </p>}
        </section>
    )
}

export default HowToApply
