import React from 'react';

const Communications = ({ language }) => {
  const content = {
    English: (
      <div className="text-[15px] lg:text-[16px] text-black font-light mb-4">
        <h2 className="text-[18px] lg:text-[22px] font-semibold text-black mb-4">
          Communication
        </h2>
        <p>
          Protean forwards the application details to ITD after digitization of the form submitted.
        </p>
        <p className="mt-2">
          In case of application for allotment of PAN (Form 49A), a new PAN is allotted by ITD. Protean prints the PAN card after allotment of PAN by ITD and dispatches the same along with an allotment letter to the respective applicant.
        </p>
        <p className="mt-2">
          In case of 'Request for New PAN Card or/and Changes or Correction in PAN data' the application request is forwarded to ITD for update of the database and after confirmation from ITD, a new PAN card is printed and dispatched to the applicant.
        </p>
        <p className="mt-2">
          All the communications are sent at the address mentioned as 'address for communication' in the form. Applicants, specifying valid e-mail ID in the application, are informed about the new PAN through e-mail in addition to the PAN allotment letter. Applicants are advised to mention their telephone number (preferably a mobile number) in the application. This ensures faster communication in case of any discrepancy in the application.
        </p>
      </div>
    ),
    Hindi: (
      <div className="text-[15px] lg:text-[16px] text-black font-light mb-4">
        <h2 className="text-[18px] lg:text-[22px] font-semibold text-black mb-4">
          संचार
        </h2>
        <p>
          प्रस्तुत फॉर्म को डिजिटाइज करने के बाद प्रोटीन आवेदन विवरण को आईटीडी को अग्रेषित करता है।
        </p>
        <p className="mt-2">
          पैन (फॉर्म 49ए) आवंटन के लिए आवेदन के मामले में, आईटीडी द्वारा एक नया पैन आवंटित किया जाता है। आईटीडी द्वारा पैन आवंटन के बाद प्रोटीन पैन कार्ड प्रिंट करता है और उसे संबंधित आवेदक को आवंटन पत्र के साथ भेजता है।
        </p>
        <p className="mt-2">
          'नए पैन कार्ड या/और पैन डेटा में परिवर्तन या सुधार के लिए अनुरोध' के मामले में आवेदन अनुरोध को आईटीडी को डेटाबेस के अपडेट के लिए अग्रेषित किया जाता है और आईटीडी से पुष्टि के बाद, एक नया पैन कार्ड प्रिंट किया जाता है और आवेदक को भेजा जाता है।
        </p>
        <p className="mt-2">
          सभी संचार फॉर्म में 'संचार के लिए पता' के रूप में उल्लिखित पते पर भेजे जाते हैं। आवेदन में मान्य ई-मेल आईडी निर्दिष्ट करने वाले आवेदकों को पैन आवंटन पत्र के अतिरिक्त ई-मेल के माध्यम से नए पैन के बारे में सूचित किया जाता है। आवेदकों को आवेदन में अपना टेलीफोन नंबर (अधिमानतः मोबाइल नंबर) का उल्लेख करने की सलाह दी जाती है। इससे आवेदन में किसी भी विसंगति की स्थिति में तेजी से संचार सुनिश्चित होता है।
        </p>
      </div>
    )
  };

  return <div>{content[language]}</div>;
};

export default Communications;
