import React from "react";
import { Element } from "react-scroll";
import PANVerification from "../../components/PANVerification";
import SearchAndContactUsSection from "../../components/SearchAndContact";
import OverviewAndService from "./components/OverViewAndService";
import FAQComponent from "../../components/Faqs";
import withScrollToHash from "../../hooks/useScrollToHash"; // Correctly import the HOC
import { Helmet } from "react-helmet";

function Link12() {
  const linkData = {
    General: [
      {
        text: "What is quarterly e-TDS/TCS statement?",
        content:
          "TDS/TCS returns filed in electronic form as per section 200(3)/206C, as amended by Finance Act, 2005, are quarterly TDS/TCS statements. As per the Income Tax Act, these quarterly statements are required to be furnished from FY 2005-06 onwards; however, as advised by Income Tax Department, acceptance of e-TDS/TCS statements pertaining to Financial Years prior to 2007-08 has been discontinued at TIN. The forms used for quarterly e-TDS statements Forms are 24Q, 26Q and 27Q and for quarterly e-TCS statement is Form No. 27EQ. These statements filed in CD/Pen Drive should be accompanied by a signed verification in Form No. 27A in case of both, e-TDS/TCS statements.",
      },
      {
        text: "Who is required to file e-TDS/TCS statements?",
        content:
          "As per Income Tax Act, 1961, all corporate and government deductors/collectors are compulsorily required to file their TDS/TCS statements in electronic form i.e. e-TDS/TCS returns. However, deductors/collectors other than corporate/government can file either in physical or in electronic form.",
      },
      {
        text: "e-TDS/TCS statement have been made mandatory for Government deductors. How do I know whether I am a Government deductor or not?",
        content:
          "All Drawing and Disbursing Officers of Central and State Governments come under the category of Government deductors.",
      },
      {
        text: "Under what provision should e-TDS/TCS statement be filed?",
        content:
          "An e-TDS statement should be filed under Section 206 of the Income Tax Act in accordance with the scheme dated August 26, 2003 for electronic filing of TDS statement notified by the Central Board of Direct Taxes (CBDT) for this purpose. CBDT Circular No. 8 dated September 19, 2003 may also be referred. An e-TCS statement should be filed under Section 206C of the Income Tax Act in accordance with the scheme dated March 30, 2005 for electronic filing of TCS return notified by the CBDT for this purpose. As per section 200(3)/206C, as amended by Finance Act, 2005, deductors/collectors are required to file quarterly TDS/TCS statements from FY 2005-06 onwards.",
      },
      {
        text: "Who is the e-Filing Administrator?",
        content:
          "CBDT has appointed the Director General of Income Tax (Systems) as e-Filing Administrator for the purpose of electronic filing of TDS/TCS statement.",
      },
      {
        text: "Who is an e-TDS/TCS intermediary?",
        content:
          "CBDT has appointed Protean e-Gov Technologies Limited, (Protean), Mumbai, as e-TDS/TCS intermediary. Protean has established TIN Facilitation Centres (TIN-FCs) across the country to facilitate deductors/collectors file their e-TDS/TCS statement.",
      },
      {
        text: "Can we get details about foreign remittance (Form 15CA)? Can we get re-print of our Form 15CA?",
        content:
          "With effect from February 12, 2014, functionality of furnishing the foreign remittance details in Form 15CA and its related features have been discontinued from TIN and same are made available on the e-filing portal of Income Tax Department www.incometaxindiaefiling.gov.in",
      },
    ],
    "Preparation of e-TDS/TCS Return": [
      {
        text: "What is the process for preparing e-TDS/TCS return?",
        content:
          "To prepare e-TDS/TCS return, you need to use the Return Preparation Utility (RPU) provided by the Income Tax Department. The RPU allows you to enter the required details and generate the return file. This file must then be verified using the File Validation Utility (FVU) before submission.",
      },
      {
        text: "What is the due date for filing e-TDS/TCS return?",
        content:
          "The due dates for filing e-TDS/TCS returns are:\n\n- Q1: 31st July\n- Q2: 31st October\n- Q3: 31st January\n- Q4: 31st May",
      },
      {
        text: "What are the consequences of late filing of e-TDS/TCS return?",
        content:
          "Late filing of e-TDS/TCS returns attracts a penalty of ₹200 per day until the return is filed. However, the total penalty cannot exceed the amount of tax deducted/collected. In addition, there may be other penalties and interest charges for non-compliance.",
      },
      {
        text: "How to correct errors in e-TDS/TCS returns?",
        content:
          "To correct errors in e-TDS/TCS returns, you need to file a correction statement. The correction statement can be prepared using the RPU and verified using the FVU. The corrected return must be submitted to the TIN facilitation center.",
      },
      {
        text: "What is the process for verifying e-TDS/TCS return?",
        content:
          "The e-TDS/TCS return must be verified using the File Validation Utility (FVU) before submission. The FVU checks for errors in the return file and generates a .fvu file if there are no errors. This .fvu file must be submitted to the TIN facilitation center.",
      },
      {
        text: "What are the common errors in e-TDS/TCS returns and how to avoid them?",
        content:
          "Common errors in e-TDS/TCS returns include incorrect PAN details, incorrect deduction details, and mismatched challan details. To avoid these errors, ensure that all details are entered correctly and verify the data using the FVU before submission.",
      },
      {
        text: "What is the penalty for non-filing of e-TDS/TCS return?",
        content:
          "Non-filing of e-TDS/TCS returns attracts a penalty of ₹10,000 to ₹1,00,000 under section 271H of the Income Tax Act. In addition, interest charges may apply for non-payment or late payment of TDS/TCS.",
      },
      {
        text: "What are the requirements for filing e-TDS/TCS return?",
        content:
          "To file e-TDS/TCS return, you need to have the following:\n\n- TAN registration\n- Valid PAN of deductees/collectees\n- Details of tax deducted/collected\n- Details of challans and payments\n- Correct RPU and FVU versions",
      },
      {
        text: "How to file e-TDS/TCS return online?",
        content:
          "To file e-TDS/TCS return online, you need to register on the TIN NSDL website and follow the instructions for uploading the .fvu file. After successful upload, a provisional receipt is generated as an acknowledgment.",
      },
      {
        text: "What is the significance of Form 27A in e-TDS/TCS return filing?",
        content:
          "Form 27A is a control chart that is required to be submitted along with the e-TDS/TCS return. It summarizes the data provided in the return and includes details like the total amount paid and tax deducted at source.",
      },
      {
        text: "Can we file e-TDS/TCS return without using RPU and FVU?",
        content:
          "No, the use of RPU and FVU is mandatory for preparing and verifying e-TDS/TCS returns. The RPU helps in data entry and the FVU checks for errors in the return file before submission.",
      },
      {
        text: "What documents are required to be attached with e-TDS/TCS return?",
        content:
          "No documents are required to be attached with the e-TDS/TCS return. However, Form 27A must be submitted along with the return.",
      },
      {
        text: "How to handle corrections in PAN details in e-TDS/TCS return?",
        content:
          "For corrections in PAN details, you need to file a correction statement with the updated PAN information. The correction statement should be prepared using the RPU and verified using the FVU.",
      },
      {
        text: "What is the procedure for revising e-TDS/TCS return?",
        content:
          "To revise an e-TDS/TCS return, you need to file a correction statement with the updated details. The correction statement must be prepared using the RPU and verified using the FVU before submission.",
      },
      {
        text: "How to verify the status of e-TDS/TCS return?",
        content:
          "The status of the e-TDS/TCS return can be verified on the TIN NSDL website using the acknowledgment number provided in the provisional receipt.",
      },
    ],
    "Verification of e-TDS/TCS Return": [
      {
        text: "What is the current FVU version to validate e-TDS/TCS statements?",
        content:
          "- File Validation Utility (FVU) version 8.8 is to be used to validate e-TDS/TCS statements pertaining to FY 2010-11 onwards. File Validation Utility (FVU) version 2.184 is to be used to validate e-TDS/TCS statements pertaining up to FY 2009-10. To download the FVU, please visit the link: https://www.protean-tinpan.com/downloads-e-tde-TDS-download-regular.html",
      },
      {
        text: "After I prepare my e-TDS/TCS return, is there any way I can check/verify whether it conforms to the prescribed data structure (file format)?",
        content:
          "Yes, after you have prepared your e-TDS/TCS return you can check/verify the same by using the File Validation Utility (FVU). This utility is freely downloadable from the Protean - TIN website.",
      },
      {
        text: "What is File Validation Utility (FVU)?",
        content:
          "FVU is a program developed by Protean , which is used to ascertain whether the e-TDS/TCS return file contains any format level error(s). When you pass e-TDS/TCS return through FVU, it will either generate error file or no file. If there are errors, the error file will display the error location and error code along with the error code description. If there are no errors in the e-TDS/TCS return, an output file will get generated. In case you find any error, you can rectify the same and pass the e-TDS/TCS return file again through the FVU till you get an error-free file.",
      },
      {
        text: "What is the 'Upload File' in the new File Validation Utility?",
        content:
          "'Upload File' that is generated by the FVU when the return is validated using the FVU has to be filed with TIN-FC. This 'upload file' is a file with the same filename as the 'input file' but with extension .fvu. Example: 'Input file' name is 27EQGov.txt, the upload file generated will be 27EQGov.fvu.",
      },
      {
        text: "What are the platforms for execution of FVU?",
        content:
          "For Quarterly Returns, Java has to be installed to run FVU. Details are given in FVU section of Protean -TIN website.",
      },
      {
        text: "What are the Control Totals appearing in the Statement Statistic Report file generated by validating the text file through File Validation Utility (FVU) on TIN?",
        content:
          "The Control Totals in Statement Statistic Report File are generated only when a valid file is generated. Otherwise, the Error File shows the nature of error. The control totals are as under:\n\n- Number of deductee/party records: In case of Form 24Q, it is equal to the number of employees for which TDS return is being prepared. In case of Form 26Q/27Q, it is equal to the total number of records of tax deduction. 10 payments to 1 party would mean 10 deductee records.\n- Amount Paid: This is the Total Amount of all payments made on which tax was deducted. In case of Form 24Q, it is equal to the Total Taxable Income of all the employees. In case of Form 26Q/27Q, this is equal to the total of all the amounts on which tax has been deducted at source.\n- Tax Deducted: This is the Total Amount of tax actually deducted at source for all payments.\n- Tax Deposited: This is the total of all the deposit challans. This is normally the same as Tax Deducted but at times may be different due to interest or other amount.",
      },
      {
        text: "Are the control totals appearing in Form 27A same as that of Error/Response File?",
        content:
          "Yes, the control totals in Form 27A and in Error/Response File are same.",
      },
      {
        text: "What if any of the control totals mentioned in Form No. 27A do not match with that in the e-TDS/TCS return?",
        content:
          "In such a case the e-TDS/TCS return will not be accepted by the TIN-FC. You should ensure that the control totals generated by FVU and that mentioned on Form No. 27A match. In case of any difficulties/queries, you should contact the TIN-FC or TIN Call Centre at Protean .",
      },
    ],
    "Furnishing of e-TDS/TCS Return/Statement": [
      {
        text: "Where can I file my TDS/TCS return?",
        content:
          "You can file your TDS/TCS return at any of the TIN-FCs managed by Protean. TIN-FCs are set up at selected locations across the country. Details are given in the Protean - TIN website.",
      },
      {
        text: "For which Financial Years can I furnish TDS/TCS statements?",
        content:
          "TDS/TCS statements can be furnished from Financial Year 2007-08 onwards. Further, as advised by Income Tax Department, acceptance of TDS/TCS statements pertaining to Financial Year prior to 2007-08 has been discontinued.",
      },
      {
        text: "What are the basic details that should be included in the e-TDS/TCS return?",
        content:
          "Following information must be included in the e-TDS/TCS return for successful validation:\n\n- Correct and valid TAN of the deductor/collector, without which the return will not be accepted.\n- Challan details as mentioned in the statement, which should be correct and as per the challan counterfoil (receipt) issued by the bank.\n- Deductee details, which should match the deductee details mentioned in the deductee PAN master file.\n- Other details as required by the e-TDS/TCS return, as per the prescribed format.\n- If there is a discrepancy in any of these details, the e-TDS/TCS return will not be accepted.",
      },
      {
        text: "What are the charges for filing e-TDS/TCS return with TIN-FC?",
        content:
          "You have to pay charges as mentioned below:\n\n- Returns having up to 100 records: ₹ 35.00\n- Returns having 101 to 1000 records: ₹ 213.60\n- Returns having more than 1000 records: ₹ 764.20\n\n*GST as applicable",
      },
      {
        text: "What are the due dates for filing quarterly TDS/TCS Returns?",
        content:
          "The due dates for filing quarterly TDS/TCS returns, both electronic and paper are as under:\n\n- Q1: 31st July of the Financial Year\n- Q2: 31st October of the Financial Year\n- Q3: 31st January of the Financial Year\n- Q4: 31st May of the Financial Year immediately following the Financial Year in which the deduction is made.",
      },
      {
        text: "Is the procedure for filing of e-TCS different from that of filing e-TDS return?",
        content:
          "The procedure for filing of e-TCS return is the same as that of e-TDS return except the forms to be used are different. The relevant forms for filing the e-TCS return are:\n\n- Quarterly statement: Form No 27EQ, 27A Control Chart.\n\nThe e-TCS returns are also to be filed with Protean at the various TIN-FCs.",
      },
      {
        text: "Should I file TDS certificates and bank challans along with the e-TDS/TCS return?",
        content:
          "No, you need not file TDS certificates and bank challans for tax deposited along with the e-TDS/TCS return.",
      },
      {
        text: "Can more than one e-TDS/TCS return be filed in a single computer media (CD/Pen Drive)?",
        content:
          "Yes. More than one e-TDS/TCS statements can be furnished in a single computer media.",
      },
      {
        text: "Can a single e-TDS/TCS return be filed in two or more CDs/Pen Drives?",
        content: "No, one return cannot be furnished in two computer media.",
      },
      {
        text: "Can e-TDS/TCS return be filed in compressed form?",
        content:
          "Yes, if the e-TDS/TCS return file is filed in compressed form, it should be compressed using WinZip 8.1 or ZipItFast 3.0 or higher version compression utility only, so as to ensure quick and smooth acceptance of the file.",
      },
      {
        text: "Do I have to affix a label on the e-TDS/TCS return CD/Pen Drive? What do I mention on the label affixed on the e-TDS/TCS return CD/Pen Drive?",
        content: "No, there is no need to affix a label on computer media.",
      },
      {
        text: "What if I don't have the PANs of all my deductees?",
        content:
          "In case PANs of some of the deductees are not available with you, for quoting in the TDS statement, you need to deduct tax at a higher rate as prescribed by ITD and such deductee records to 'C' in the statement.\n\nIn case of TCS statement, PAN quoting compliance of 85% is mandatorily required for validation, i.e. out of the total collectee records in the TCS statement, the PAN should be present for 85% of the records failing which the statement will not be validated through the File Validation Utility. In view of this, it is recommended that TCS records comprising 85% of valid PAN be reported first and the records for the remaining collecteess may be reported as and when PAN cards are obtained.",
      },
      {
        text: "If a deductor faces any difficulty in filing of e-TDS return where can it approach for help?",
        content:
          "The details regarding the help required for filing of e-TDS are available on the Income Tax Department website and the Protean - TIN website. The TIN-FCs are also available for all related help in the e-filing of TDS returns.",
      },
      {
        text: "Will computer media be returned by TIN-FC after acceptance of e-TDS/TCS statement?",
        content:
          "Yes computer media will be returned to deductor after acceptance of the e-TDS/TCS statements.",
      },
    ],
    "Clarifications Regarding Form 24Q": [
      {
        text: "Form No. 24Q shows a column which requires explanation for lower deduction of tax. How can a DDO assess it? Please clarify.",
        content:
          "Certificate for lower deduction or no deduction of tax from salary is given by the Assessing Officer on the basis of an application made by the deductee. In cases where the Assessing Officer has issued such a certificate to an employee, deductor has to only mention whether no tax has been deducted or tax has been deducted at lower rate on the basis of such a certificate.",
      },
    ],
    "Provisional Receipt/Non-Acceptance Memo": [
      {
        text: "Will the TIN-FC give any acknowledgment/receipt after acceptance of e-TDS/TCS return?",
        content:
          "If the e-TDS/TCS return file is complete in all aspects, TIN-FC will issue a provisional receipt to you. The provisional receipt issued by TIN-FC is deemed to be the proof of e-TDS/TCS return filed by you.",
      },
      {
        text: "What if my e-TDS/TCS return is not accepted by TIN-FC, how will I know the reason for rejection?",
        content:
          "In case of non-acceptance of your e-TDS/TCS return file, TIN-FC will issue a Non-Acceptance Memo which will state reason for rejection.",
      },
    ],
    "Request for duplicate provisional receipt": [
      {
        text: "How do I obtain a duplicate copy of the provisional receipt for my TDS/TCS statement?",
        content:
          "Please click here [Request for duplicate provisional receipt](#) for requesting duplicate Provisional receipt. You can raise a request for the same online. Corresponding duplicate receipt will be sent to email ID as per the latest TDS/TCS statement submitted at TIN Facilitation Centres or Online filing at Protean.",
      },
      {
        text: "What details are required to raise the online request for duplicate copy of Provisional receipt?",
        content:
          "The details required to be entered are as under:\n\n- 15 digit token number\n- TAN of statement\n- Form Number\n- Financial Year\n- Period\n- Statement Type (Electronic/Paper)\n- Transaction type (Regular / Correction)\n- Correction type\n- 15 digit token number of Regular statement",
      },
      {
        text: "How can I get duplicate receipts for statements filed at e-filing portal of Income Tax Department (ITD)?",
        content:
          "Deductor needs to contact ITD to obtain duplicate copy of provisional receipt of statements filed at e-filing portal of ITD.",
      },
      {
        text: "How the duplicate receipt(s) will be provided to me?",
        content:
          "Password protected PDF copy of duplicate receipt(s) will be sent to the email ID mentioned in the latest TDS/TCS statement submitted at TIN Facilitation Centres or Online filing at Protean. The password to open the file and duplicate copy of provisional receipt is communicated through the email.",
      },
      {
        text: "Can I obtain duplicate copy of provisional receipt on the same day of submission of statement with TIN-FC?",
        content:
          "Yes, you can obtain the duplicate copy of provisional receipt of the statement on the same day or within five days from the date of submission of statement from the same TIN-FC. For all other cases, you may request for the duplicate receipt online from TIN website.",
      },
      {
        text: "Which Token number am I supposed to enter in the field '15 digit Token no. of Regular TDS/TCS statement in case I need provisional receipt of correction statement'?",
        content:
          "Token number of regular statement corresponding to the correction statement receipt being requested for needs to be entered in the given field.",
      },
      {
        text: "Can I obtain duplicate provisional receipt for cancelled statement(s)?",
        content:
          "No, you cannot obtain duplicate provisional receipt for cancelled statement.",
      },
      {
        text: "Can I obtain duplicate provisional receipt for the paper TDS/TCS statement(s)?",
        content:
          "Yes, you can obtain duplicate provisional receipt for the paper TDS/TCS statement.",
      },
      {
        text: "How many requests for duplicate provisional receipts can be generated at a time?",
        content:
          "At a time only one request for duplicate provisional receipt can be submitted.",
      },
      {
        text: "What if I am not able to successfully submit request for duplicate provisional receipt through online mode due to any reason?",
        content:
          "In case of any issue while submitting the request for duplicate copy provisional receipt you may write to us through the Customer Care form on [link](#).",
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>Online PAN Verification Service</title>
        <meta name="description" content="Quick and secure online PAN verification process. Ensure accurate and efficient PAN validation with our trusted service." />
        <meta name="keywords" content="online pan verification, pan number verification, verify pan card, pan verify online, online pan verification nsdl, online pan verification by pan no, pan card verification online, pan verification" />
      </Helmet>
      <div className="flex flex-col gap-8">
        <section className="lg:px-[70px] ">
          <PANVerification title={"Online PAN Verification"} />
        </section>
        <Element name="search-and-contact-us">
          <SearchAndContactUsSection />
        </Element>
        <Element name="overview-and-service">
          <OverviewAndService />
        </Element>
        <Element name="faqs">
          <FAQComponent linkData={linkData} />
        </Element>
      </div>
    </>
  );
}

export default withScrollToHash(Link12); // Use the HOC
