// src/components/Tabs.jsx
import React from "react";

const Tabs = ({ tabs, activeTab, setActiveTab, activeTabColor = "#EEF7ED" }) => {
  return (
    <div>
      {tabs.map((tab, index) => (
        <button
          key={index}
          onClick={() => setActiveTab(index)}
          className={`w-full flex justify-between items-center text-left p-2`}
          style={{
            backgroundColor: activeTab === index ? activeTabColor : "transparent",
          }}
        >
          {tab.label}
          <svg
            width="8"
            height="14"
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.99707 1L6.99707 7L0.99707 13"
              stroke="#1D1D1B"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      ))}
    </div>
  );
};

export default Tabs;
