import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { scroller } from "react-scroll";

const withScrollToHash = (WrappedComponent) => {
  return (props) => {
    const location = useLocation();

    useEffect(() => {
      const hash = location.hash.replace("#", "");
      if (hash) {
        scroller.scrollTo(hash, {
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart",
        });
      }
    }, [location]);

    return <WrappedComponent {...props} />;
  };
};

export default withScrollToHash;
