import React from 'react';
import { Element } from 'react-scroll';
import ThirdGrid from './components/ThirdGrid';
import CommonBanner from '../../components/CommonBanner';
import withScrollToHash from '../../hooks/useScrollToHash'; // Correctly import the HOC
import { Helmet } from 'react-helmet';

function Link27() {
    return (
        <>
            <Helmet>
                <title>About Us - Protean Pan & Tan Services</title>
                <meta name="description" content="Learn about Protean Tinpan, your trusted PAN and TAN service provider. Discover our mission and commitment. Read more!" />
                <meta name="keywords" content="online tan application, online pan card apply, online pan verification, tax information network pan card, tan application online, form 49a pan card, oltas tax payment, what is form 24g, oltas" />
            </Helmet>
            <div className="flex flex-col gap-8">
                <div className="lg:px-[70px]">
                    <CommonBanner
                        title={"About TIN"}
                        className={"min-h-[60vh] md:min-h-[50vh] mx-auto"}
                        isTaxDeductions
                        desktopWidth="466px"
                        desktopHeight="466px"
                    />
                </div>
                <Element name="third-grid">
                    <ThirdGrid />
                </Element>
            </div>
        </>
    );
}

export default withScrollToHash(Link27); // Use the HOC
