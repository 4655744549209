// src/data.js
// import statesLocationsPincodes from './states_locations_pincodes.json';

// export const getStates = () => Object.keys(statesLocationsPincodes);

// export const getCities = (state) => 
//   statesLocationsPincodes[state] ? Object.keys(statesLocationsPincodes[state]) : [];

// export const getPincodes = (state, city) => 
//   statesLocationsPincodes[state] && statesLocationsPincodes[state][city] ? statesLocationsPincodes[state][city] : [];


// src/data.js
// import statesLocationsPincodes1 from './states_locations_pincodes.json';

import panCenters from './psa_centres.json';
import tinCenters from './TINFC_WEB_UPDATION.json';

const jsonData = window.location.pathname == '/tin-facilities' ? tinCenters : panCenters;

export const getStates = () => {
  console.log(window.location.pathname);
  return [...new Set(jsonData.map(item => (item.STATE).toUpperCase()))].sort()
}


  export const getCities = (state) => {
    const filterData = jsonData.filter(item => (item.STATE).toUpperCase() === state.toUpperCase());
    return [...new Set(filterData.map(item => item.CITY))];
  }


  export const getPincodes = (state, city) => {
    const filterData = jsonData.filter(item => (item.STATE).toUpperCase() === state.toUpperCase());
    const filterDataCity = filterData.filter(item => (item.CITY).toUpperCase() === city.toUpperCase());

    return [...new Set(filterDataCity.map(item => item.PINCODE))];
  }