// src/components/ChargeSummary.js
import React, { useState } from 'react';
import { ArrowUpSvg, DropDownArrowSvg } from '../../../assets/svgs';

const ChargeSummary = () => {
    const isMobile = window.innerWidth <= 768;
    const [openSections, setOpenSections] = useState({
        annualRegistration: isMobile || true,
        gst: isMobile || true,
        totalCharges: isMobile || true,
        initialAdvance: isMobile || true,
        freePanVerification: isMobile || true,
        chargesAboveFree: isMobile || true
    });

    const toggleSection = (section) => {
        setOpenSections(prevState => ({
            ...prevState,
            [section]: !prevState[section]
        }));
    };

    return (
        <div className="p-8">
            <h2 className="text-2xl mb-4">Charges Summary</h2>
            {!isMobile && (
                <table className="min-w-full bg-white border overflow-x-scroll overflow-auto">
                    <thead>
                        <tr className='bg-[#FAD9D5]'>
                            <th className="py-2 px-4 text-left">Minimum payable charges</th>
                            <th className="py-2 px-4 text-right">Screen Based</th>
                            <th className="py-2 px-4 text-right" colSpan="2">File and Screen Based</th>
                            <th className="py-2 px-4 text-right">Software (API) Based</th>
                        </tr>
                    </thead>
                    <tbody className=''>
                        <tr>
                            <td className="py-2 px-4">Annual Registration Charges (₹)</td>
                            <td className="py-2 px-4 text-right">12,000</td>
                            <td className="py-2 px-4 text-right"></td>
                            <td className="py-2 px-4 text-right">12,000</td>
                            <td className="py-2 px-4 text-right">12,000 </td>
                        </tr>
                        <tr>
                            <td className="py-2 px-4">Goods and Services Tax (GST) @18% (₹)</td>
                            <td className="py-2 px-4 text-right">2,160</td>
                            <td className="py-2 px-4 text-right"></td>
                            <td className="py-2 px-4 text-right">2,160</td>
                            <td className="py-2 px-4 text-right">12,000</td>
                        </tr>
                        <tr>
                            <td className="py-2 px-4">Total Registrastion/Annual Charges(₹)</td>
                            <td className="py-2 px-4 text-right">14,160</td>
                            <td className="py-2 px-4 text-right"></td>
                            <td className="py-2 px-4 text-right">14,160</td>
                            <td className="py-2 px-4 text-right">12,000</td>
                        </tr>
                        <tr>
                            <td className="py-2 px-4">Initial Advance (₹) (Inclusive of GST as applicable)</td>
                            <td className="py-2 px-4 text-right">NA</td>
                            <td className="py-2 px-4 text-right"></td>
                            <td className="py-2 px-4 text-right">As desired</td>
                            <td className="py-2 px-4 text-right">As desired</td>
                        </tr>
                        <tr>
                            <td className="py-2 px-4 w-1/3">Free PAN verification per day (Subject to change based on cumulative number of PANs verified in F.Y.)</td>
                            <td className="py-2 px-4 text-right">Upto 750 Only</td>
                            <td className="py-2 px-4 text-right bg-[#FCEBE9]">Screen based</td>
                            <td className="py-2 px-4 text-right bg-[#FCEBE9]">File based</td>
                            <td className="py-2 px-4 text-right">750</td>
                        </tr>
                        <tr>
                            <td className="py-2 px-4"></td>
                            <td className="py-2 px-4 text-right"></td>
                            <td className="py-2 px-4 text-right bg-[#F7F7F7]">750</td>
                            <td className="py-2 px-4 text-right bg-[#F7F7F7]">NA</td>
                            <td className="py-2 px-4 text-right"></td>
                        </tr>
                        <tr>
                            <td className="py-2 px-4">Charges above free PANs verification (₹)</td>
                            <td className="py-2 px-4 text-right">NA</td>
                            <td className="py-2 px-4 text-right"></td>
                            <td className="py-2 px-4 text-right">Above 750 charges will applicable as per Table No 1</td>
                            <td className="py-2 px-4 text-right">Refer Table no. 2</td>
                        </tr>
                    </tbody>
                </table>
            )}
            <div className='bg-[#FAD9D5] flex md:hidden flex-col'>
                <h3 className='font-bold text-sm p-2 leading-6'>Minimum payable charges</h3>
                <div className='bg-white flex gap-2 flex-col'>
                    <div className='flex flex-row justify-between border-b p-2'>
                        <p>Annual Registration Charges (₹)</p>
                        <button onClick={() => toggleSection('annualRegistration')} className=''>
                            {!openSections.annualRegistration ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                        </button>
                    </div>
                    {openSections.annualRegistration && (
                        <div className='flex flex-col gap-2 border-b p-2'>
                            <div className='flex flex-col'>
                                <p className='font-bold text-sm leading-6'>Screen Based</p>
                                <p>12,000</p>
                            </div>
                            <div className='flex flex-col'>
                                <p className='font-bold text-sm leading-6'>File and Screen Based</p>
                                <p>12,000</p>
                            </div>
                            <div className='flex flex-col'>
                                <p className='font-bold text-sm leading-6'>Software (API) Based</p>
                                <p>12,000</p>
                            </div>
                        </div>
                    )}

                    <div className='flex flex-row justify-between border-b p-2'>
                        <p>Goods and Services Tax (GST) @18% (₹)</p>
                        <button onClick={() => toggleSection('gst')} className=''>
                            {!openSections.gst ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                        </button>
                    </div>
                    {openSections.gst && (
                        <div className='flex flex-col gap-2 border-b p-2'>
                            <div className='flex flex-col'>
                                <p className='font-bold text-sm leading-6'>Screen Based</p>
                                <p>2,160</p>
                            </div>
                            <div className='flex flex-col'>
                                <p className='font-bold text-sm leading-6'>File and Screen Based</p>
                                <p>2,160</p>
                            </div>
                            <div className='flex flex-col'>
                                <p className='font-bold text-sm leading-6'>Software (API) Based</p>
                                <p>12,000</p>
                            </div>
                        </div>
                    )}
                    <div className='flex flex-row justify-between border-b p-2'>
                        <p>Total Registrastion/Annual Charges(₹)</p>
                        <button onClick={() => toggleSection('totalCharges')} className=''>
                            {!openSections.totalCharges ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                        </button>
                    </div>
                    {openSections.totalCharges && (
                        <div className='flex flex-col gap-2 border-b p-2'>
                            <div className='flex flex-col'>
                                <p className='font-bold text-sm leading-6'>Screen Based</p>
                                <p>14,160</p>
                            </div>
                            <div className='flex flex-col'>
                                <p className='font-bold text-sm leading-6'>File and Screen Based</p>
                                <p>14,160</p>
                            </div>
                            <div className='flex flex-col'>
                                <p className='font-bold text-sm leading-6'>Software (API) Based</p>
                                <p>12,000</p>
                            </div>
                        </div>
                    )}
                    <div className='flex flex-row justify-between border-b p-2'>
                        <p>Initial Advance (₹) (Inclusive of GST as applicable)</p>
                        <button onClick={() => toggleSection('initialAdvance')} className=''>
                            {!openSections.initialAdvance ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                        </button>
                    </div>
                    {openSections.initialAdvance && (
                        <div className='flex flex-col gap-2 border-b p-2'>
                            <div className='flex flex-col'>
                                <p className='font-bold text-sm leading-6'>Screen Based</p>
                                <p>NA</p>
                            </div>
                            <div className='flex flex-col'>
                                <p className='font-bold text-sm leading-6'>File and Screen Based</p>
                                <p>As desired</p>
                            </div>
                            <div className='flex flex-col'>
                                <p className='font-bold text-sm leading-6'>Software (API) Based</p>
                                <p>As desired</p>
                            </div>
                        </div>
                    )}
                    <div className='flex flex-row justify-between border-b p-2'>
                        <p>Free PAN verification per day (Subject to change based on cumulative number of PANs verified in F.Y.)</p>
                        <button onClick={() => toggleSection('freePanVerification')} className=''>
                            {!openSections.freePanVerification ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                        </button>
                    </div>
                    {openSections.freePanVerification && (
                        <div className='flex flex-col gap-2 border-b p-2'>
                            <div className='flex flex-col'>
                                <p className='font-bold text-sm leading-6'>Screen Based</p>
                                <p>Upto 750 Only</p>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <p className='font-bold text-sm leading-6'>File and Screen Based</p>
                                <div className='flex flex-row gap-2'>
                                    <p className='bg-[#FCEBE9]'>Screen based</p>
                                    <p className='bg-[#F7F7F7]'>750</p>
                                </div>
                                <div className='flex flex-row gap-2'>
                                    <p className='bg-[#FCEBE9]'>File Based</p>
                                    <p className='bg-[#F7F7F7]'>NA</p>
                                </div>
                            </div>
                            <div className='flex flex-col'>
                                <p className='font-bold text-sm leading-6'>Software (API) Based</p>
                                <p>750</p>
                            </div>
                        </div>
                    )}
                    <div className='flex flex-row justify-between border-b p-2'>
                        <p>Charges above free PANs verification (₹)</p>
                        <button onClick={() => toggleSection('chargesAboveFree')} className=''>
                            {!openSections.chargesAboveFree ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                        </button>
                    </div>
                    {openSections.chargesAboveFree && (
                        <div className='flex flex-col gap-2 border-b p-2'>
                            <div className='flex flex-col'>
                                <p className='font-bold text-sm leading-6'>Screen Based</p>
                                <p>NA</p>
                            </div>
                            <div className='flex flex-col'>
                                <p className='font-bold text-sm leading-6'>File and Screen Based</p>
                                <p>Above 750 charges will applicable as per Table No 1</p>
                            </div>
                            <div className='flex flex-col'>
                                <p className='font-bold text-sm leading-6'>Software (API) Based</p>
                                <p>Refer Table no. 2</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ChargeSummary;
