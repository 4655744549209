// PAMLayout.js
import React from "react";

import HUF from "./HUF";
import Citizen from "./Citizen";
import Categories from "./Categories";

function PAMLayout() {
  return (
    <div className="grid gap-4 lg:grid-cols-2">
      <HUF />
      <div className="flex flex-col justify-between ">
        <div className="p-4 bg-[#EAF4FA] lg:min-h-[613px] relative">
          <Citizen />
        </div>
        <Categories />
      </div>
    </div>
  );
}

export default PAMLayout;
