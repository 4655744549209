import React from 'react'
import { ThreeDotsBulletSvg } from '../../../assets/svgs'

function Overview() {
    return (
        <div className='flex flex-col md:px-[68px] py-6 md:pt-12 md:flex-row w-full gap-6 justify-between'>
            {/* Overview Section */}
            <div style={{ minWidth: 'calc(50% - 12px)' }} className="bg-[#EAF4FA] p-8">
                <h1 className='flex flex-row text-[26px] font-semibold gap-2 pb-6 leading-5'>
                    <ThreeDotsBulletSvg fillColor={'#3091D0'} className={'w-[34px] h-[17px]'} />
                    Overview
                </h1>
                <p>
                    Income Tax Department's initiative to receive information and maintain records of tax paid through banks through online upload of challan details is named as OLTAS (Online Tax Accounting System).
                </p>
            </div>

            {/* Data upload by banks Section */}
            <div className="bg-[#EEF7ED] p-8">
                <h1 className='flex flex-row text-[26px] pb-6 font-semibold gap-2 leading-5'>
                    <ThreeDotsBulletSvg fillColor={'#54AD47'} className={'w-[34px] h-[17px]'} />
                    Data upload by banks
                </h1>
                <p>
                    Income Tax Department has devised the file formats for uploading data regarding tax payment. Banks are expected to generate and upload tax data as per these formats. Once the file has been prepared as per the file format, it can be verified for correctness of its structure using the File Validation Utility (FVU) provided by Protean.
                </p>
            </div>
        </div>
    )
}

export default Overview