import React from "react";
import DynamicLinks from "../../../components/DynamicLink";

import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import Card1 from "../../../assets/images/card1.png";
import Card2 from "../../../assets/images/card2.png";
import Card3 from "../../../assets/images/card3.png";
import Card4 from "../../../assets/images/card4.png";
import Card5 from "../../../assets/images/card5.png";
import Card6 from "../../../assets/images/card6.png";
import DynamicLinksTwo from "../../../components/DyanmicLinksTwo";
const DocumentsRequired = () => {
  const { width } = useWindowDimensions();
  const isMobile = width <= 700;
  const cardData = [
    {
      text: "Guidelines for Corporate PAN applicants",
      href: "https://www.protean-tinpan.com/downloads/pan/Additional_Instructions_for_Corporate_applicants.pdf",
      desktopbg: Card1,
      mobileBg: "",
      circleBg: "#3091D0",
    },
    {
      text: "Know Status of Your Application",
      href: "https://tin.tin.nsdl.com/pantan/StatusTrack.html",
      desktopbg: Card2,
      mobileBg: "",
      circleBg: "#EE7F25",
    },
    {
      text: "Guidelines for Foreign Citizens",
      href: "https://www.protean-tinpan.com/downloads/pan/Additionalguidelines_Revised_Oct_2019.pdf",
      desktopbg: Card3,
      mobileBg: "",
      circleBg: "#FFCE1B",
    },
    {
      text: "Know Status of Your Credit Card / debit card / net banking transaction for online application",
      href: "https://tin.tin.nsdl.com/pan/changemode.html",
      desktopbg: Card4,
      mobileBg: "",
      circleBg: "#3091D0",
    },
    {
      text: "Brief Process",
      href: "https://www.protean-tinpan.com/downloads/Online_PAN_Application_Final_Brief_process_March_14_2019.pdf",
      desktopbg: Card5,
      mobileBg: "",
      circleBg: "#EE7F25",
    },
    {
      text: "Download e-PAN XML Verification Utility",
      href: "https://www.protean-tinpan.com/downloads/pan/ePANxmlVerificationUtility_20sep2019.zip",
      desktopbg: Card6,
      mobileBg: "",
      circleBg: "#54AD47",
    },
  ];
  const linkData = [
    { text: "1. POI/POA Documents Required 1.1 Indian Citizen", href: "https://www.protean-tinpan.com/services/pan/documents49A.html", maxWidth: '85%' },
    {
      text: "2. POI/POA Documents Required 1.2 Foreign Citizen/ NRI",
      href: "https://www.protean-tinpan.com/services/pan/foreign-citizen.html",
      maxWidth: '85%'
    },
    { text: "3. Additional Documents for Change / Correction", href: "https://www.protean-tinpan.com/downloads/pan/Additional-Documents.pdf" },
    { text: "4. Format of affidavit for corporate PAN applicant.", href: "https://www.protean-tinpan.com/downloads/pan/Format_Of_Affidavit_For_Corporate_PAN_Applicants.pdf" },
    {
      text: "5. Prescribed format of certificate Issued by the employer on the letterhead of the organization/ Institution",
      href: "https://www.protean-tinpan.com/downloads/pan/Annexure%20B_03022014.pdf",
    },
    {
      text: "6. Prescribed format of certificate Identification Issued by the bank on the letterhead of bank.",
      href: "https://www.protean-tinpan.com/downloads/pan/Annexure%20C_03022014.pdf",
    },
    {
      text: "7. Prescribed format of certificate Identification Issued by overseas bank branch of scheduled bank registered in india.",
      href: "https://www.protean-tinpan.com/downloads/pan/Annexure%20D_03022014.pdf",
    },
    {
      text: "8. Prescribed format of certificate of identity/ addresses issued by MP/ MLA/ Municipal councillor/Gazetted officer",
      href: "https://www.protean-tinpan.com/downloads/pan/Format-of-Gazette-Certificate_03082012.pdf",
    },
  ];

  return (
    <section className=" py-10 lg:mt-0 relative w-full flex flex-col  px-[22px]  lg:px-[72px] ">
      <div>
        <div className="flex items-center mb-4">
          <svg
            width="34"
            height="17"
            viewBox="0 0 34 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
              fill="#54AD47"
            />
            <path
              d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
              fill="#54AD47"
            />
            <circle cx="25.5" cy="8.5" r="8.5" fill="#54AD47" />
          </svg>
          <h2 className="text-[24px] lg:text-[28px] font-semibold text-black ml-2">
            Documents Required
          </h2>
        </div>
        <p className="text-[15px] font-light m-0 lg:max-w-[800px] ">
          Online application form (with photo &/or signature affixed) and
          Prescribed supporting documents (except for e-Sign/DSC based
          applications) should be sent to Protean eGov Technologies Limited,
          Income Tax PAN Services Unit, 4th floor, Sapphire Chambers, Baner
          Road, Baner, Pune - 411045
        </p>
      </div>

      <div className="flex mt-8">
        <DynamicLinksTwo
          linkData={linkData}
          listDir="flex-row"
          defaultLinkMinWidth="250px"
          className="flex-row"
          defaultLinkTextColor="black"
          defaultLinkIconColor="black"
          defaultLinkBorderBottom="none"
          linkClassName="bg-[#F4F2F9] hover:bg-[#E5E3ED] transition-colors duration-200 flex-row"
          linkStyle={{
            borderLeft: "5px solid #54AD47",
            padding: "12px",
            maxWidth: isMobile ? "" : "307px", // Adjust width to fit 4 items in a row with spacing
            minHeight: "110px",
            background: "#EEF7ED",
          }}
        />
      </div>

      <div className="flex flex-wrap gap-[1.6rem] mt-10">
        {cardData.map((card, index) => (
          <div
            className="flex justify-between  bg-no-repeat bg-cover min-w-full lg:min-w-[412px] min-h-[203px]"
            style={{
              backgroundImage: `url(${isMobile ? card.desktopbg : card.desktopbg
                })`,
            }}
          >
            <a className="cursor-pointer w-full md:w-[90%]" href={card.href}>
              <div class="flex justify-between  px-4  py-[8px]   ">
                <p class="  max-w-[252px] text-white text-[23px] font-semibold">
                  {card.text}
                </p>

                <div
                  className={`rounded-full w-[40px] h-[40px] flex justify-center items-center `}
                  style={{ backgroundColor: card.circleBg }}
                >
                  <svg
                    class="text-[#fefefe]"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.9495 3.05078L3.04997 12.9503"
                      stroke="#fefefe"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.9495 8.70764V3.05078"
                      stroke="#fefefe"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.29291 3.05108H12.9498"
                      stroke="#fefefe"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default DocumentsRequired;
