import React from "react";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import desktopBackground from "../../../assets/images/Form49Bg.jpg"; // Replace with the actual background image path

import Card from "../../../components/Card";
const Form49A = () => {
  const { width } = useWindowDimensions();
  const isMobile = width <= 700;

  const cardDataArray = [
    {
      linkData: [
        {
          text: "Read Guidlines",
          textColor: "black",
          iconColor: "black",
          borderBottom: "1px solid black",
          maxWidth: "207px",
          href: "https://www.protean-tinpan.com/services/pan/form49A.html",
        },
        {
          text: "Read Instructions",
          textColor: "black",
          iconColor: "black",
          borderBottom: "1px solid black",
          maxWidth: "207px",
          href: "https://www.protean-tinpan.com/services/pan/instructions49A.html",
        },
        {
          text: "Documents to be submitted",
          textColor: "black",
          iconColor: "black",
          borderBottom: "1px solid black",

          maxWidth: "207px",
          href: "https://www.protean-tinpan.com/services/pan/documents49A.html",
        },
      ],
      defaultLinkMinWidth: window.innerWidth > 1024 ? "279px" : "300px", // Default min width for this card's links
    },
  ];
  const cardDataArray2 = [
    {
      linkData: [
        {
          text: "Do’s and Dont’s",
          textColor: "black",
          iconColor: "black",
          borderBottom: "1px solid black",
          maxWidth: "207px",
          href:'https://www.protean-tinpan.com/services/pan/new-do-donts-49AA.html'
        },
        {
          text: "NEW Designated centers accepting online PAN Documents",
          textColor: "black",
          iconColor: "black",
          borderBottom: "1px solid black",
          maxWidth: "207px",
            href:'https://www.protean-tinpan.com/downloads/List_Of_Centres_For_Submission_Of_Online_PAN_Documents.xlsx'
        },
      ],
      defaultLinkMinWidth: window.innerWidth > 1024 ? "279px" : "300px", // Default min width for this card's links
    },
  ];

  return (
    <section className="w-full  lg:px-[70px] flex flex-col lg:flex-row justify-between relative ">
      <div
        className="flex relative py-10 lg:py-0 bg-no-repeat bg-cover flex-col lg:flex-row justify-between  w-full  "
        style={{
          backgroundImage: isMobile
            ? `url(${desktopBackground})`
            : `url(${desktopBackground})`,
        }}
      >
        {/* Left Section */}
        <div className="lg:w-1/2 flex flex-col gap-6 lg:pr-10 px-4 lg:px-10 py-10">
          <h2 className="text-[26px] lg:text-[26px] font-semibold text-black">
            Application for allotment of New PAN (Form 49A) – applicable for
            Citizens of India
          </h2>
          <p className="text-black text-[15px] lg:text-[16px] font-light">
            This form should be used when the applicant has never applied for a
            PAN or does not have PAN allotted to him. An applicant can visit
            Income Tax Department (ITD) website to find whether a PAN has been
            allotted to him or not.
          </p>
          <a href="https://www.onlineservices.nsdl.com/paam/endUserRegisterContact.html" className="w-max">
            <button
              type="button"
              class="py-[12px] text-base font-medium text-white w-fit gap-4 flex items-center justify-between bg-black px-[24px] rounded-[8px] text-center"
            >
              Apply now
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2429_15128)">
                  <path
                    d="M5 12H19"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15 16L19 12"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15 8L19 12"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2429_15128">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </a>
        </div>

        {/* Right Section */}
        <div className="flex flex-col justify-center lg:justify-start items-center lg:items-start lg:flex-row lg:gap-10 lg:pr-10">
          {cardDataArray.map((cardData, index) => (
            <Card
              key={index}
              {...cardData}
              className="min-w-full justify-center items-center lg:min-w-[200px] "
            />
          ))}
          {cardDataArray2.map((cardData, index) => (
            <Card
              key={index}
              {...cardData}
              className="justify-center items-center lg:min-w-[200px] "
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Form49A;
