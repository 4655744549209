import React from "react";
import ServiceDescription from "./ServiceDescription";
import ResponseToUser from "./ResponseToUser";
import { OverViewSvg2, OverViewSvg3, } from "../../../assets/svgs";
import Graphic from "../../../assets/images/Link12Graphic.png"
import Graphic2 from "../../../assets/images/Link12A1.png"
import Graphic3 from "../../../assets/images/Link12A2.png"

const OverviewAndService = () => {
  const approvedEntities = [
    "PFRDA approved Point of Presence (For Online Platform)",
    "Direct (Life & General) Brokers approved by IRDAI",
    "RBI approved Authorised Dealer – Category II",
    "Mutual Fund Advisor",
    "Registrar & Transfer agents approved by SEBI",
    "Investment Advisor approved by SEBI",
    "Banks",
    "Insurance Web Aggregators",
    "Non Banking Financial Companies approved by RBI",
    "Prepaid Payment Instrument Issuers approved by RBI",
    "Housing Finance Companies",
    "Insurance Company",
    "Insurance Repository",
    "Companies and Government deductor (Required to file TDS/TCS return)*",
    "Mutual Funds",
    "Credit card Companies / Institutions",
    "Central Recordkeeping Agency of National Pension System",
    "DSC issuing Authorities",
    "Depository Participants",
    "Credit information companies approved by RBI",
    "KYC Registration Agency (KRA)/Central KYC Registry",
    "Educational Institutions established by Regulatory Bodies",
    "Companies (Required to furnish Annual Information Return (AIR)/Statement of Financial Transaction (SFT))",
    "Any other entity required to furnish Annual Information Return (AIR)/Statement of Financial Transaction (SFT)",
    "Stock Exchanges/Commodity Exchanges/Clearing Corporations",
    "Goods and Services Tax Network",
    "Depositories",
    "Government Agencies (Central/State)",
    "Income Tax Projects",
    "Reserve Bank of India",
    "Department of Commercial Taxes",
    "Stamp and Registration Department",
    "Central Vigilance Commission",
    "Payments Bank approved by RBI",
    "Payment And Settlement System Operators authorized by RBI",
    "Open Network for Digital Commerce (ONDC)",
    "PFMS",
  ];
  return (
    <div className="lg:px-[70px]  ">
      <div className="grid gap-4 lg:grid-cols-2">
        <div className="p-4 bg-[#EAF4FA] relative min-h-[1640px]">

          <img src={Graphic} alt="" className={"absolute bottom-0 right-0 max-w-[378px] hidden md:flex"} />
          <img src={Graphic2} alt="" className={"absolute bottom-0 right-0 max-w-[378px] flex md:hidden w-[60px]"} />
          <img src={Graphic3} alt="" className={"absolute bottom-0 left-0 max-w-[378px] flex md:hidden w-[20px]"} />

          <OverViewSvg2 className={"absolute top-[20%] right-0 max-w-[43px] hidden md:flex"} strokeColor={"#FFCE1B"} />
          <OverViewSvg3 className={"absolute bottom-[5%] left-0 max-w-[43px] hidden md:flex"} strokeColor={"#6F368B"} />
          <div className="flex items-center w-full gap-2">
            <svg
              width="34"
              height="17"
              viewBox="0 0 34 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
                fill={"#3091D0"}
              />
              <path
                d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
                fill={"#3091D0"}
              />
              <circle cx="25.5" cy="8.5" r="8.5" fill={"#3091D0"} />
            </svg>
            <h2 className="text-lg md:text-[26px] font-semibold flex items-center">
              Overview
            </h2>
          </div>
          <p className="text-sm md:text-[15px] mt-4">
            To enable eligible Entities verify Permanent Account Numbers (PANs),
            Income Tax Department (ITD) has authorized Protean eGov Technologies
            Limited (Protean ) to launch an online PAN verification service for
            verification of PANs by authorized entities.
          </p>
          <p className="text-sm md:text-[15px] mt-4">
            Entities who can avail of this facility:
          </p>
          <ul className="list-disc ml-5 space-y-2 text-sm md:text-[15px] mt-4">
            {approvedEntities.map((entity, index) => (
              <li className=" text-sm md:text-[15px]" key={index}>
                {entity}
              </li>
            ))}
          </ul>
          <p className="text-sm md:text-[14px] font-light mt-4">
            <span className="font-semibold"> *Note:</span>
            To register under the category “Companies and Government Deductor
            (Required to file TDS/TCS return)”, entities must have more than 500
            deductee/collectee counts displayed on Provisional receipts in a
            quarter. Counts of deductee/collectee will be vetted at the time of
            applying for Online PAN verification facility.
          </p>
        </div>
        {/* Right side - Two cards stacked vertically */}
        <div className="flex flex-col justify-between ">
          <div className="p-4 bg-[#FDF2E9] lg:min-h-[613px] relative">
            <ServiceDescription />
          </div>
          <ResponseToUser />
        </div>
      </div>
    </div>
  );
};

export default OverviewAndService;
