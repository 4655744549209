// src/App.jsx
import React, { useState } from "react";
import Tabs from "../../../components/Tabs";
import TabsDropdown from "../../../components/TabsDropDown";
import FormStateCity from "../../../components/FormStateCity";
import FormPincode from "../../../components/FormPinCode";
import Layout from "../../../components/Layout";
import TabContent from "../../../components/TabContent";
import SearchSvg from "../../../assets/images/Search.svg";
import Table from "../../../components/Table"; // Ensure the correct import path

function SearchTabForms() {
  const [activeTab, setActiveTab] = useState(0);
  const [resultsPincode, setResultsPincode] = useState([]);
  const [resultsStateCity, setResultsStateCity] = useState([]);

  const handleSearchPincode = (data) => {
    // Simulate fetching data based on pincode
    setResultsPincode([
      {
        code: "38026",
        facilitator: "Altruist Technologies Private Limited",
        contact: "Yogesh Ashok Kapre",
        address:
          "ONLINE E SEVAAT. NANDURVAIDYA POST, ASWALINEAR BHAIRAVNATH MANDIR, TAL.IGATPURI, NASHIK, MAHARASHTRA 422403",
        email: "Yogesh@mail.com",
      },
      // Add more entries here
    ]);
  };

  const handleSearchStateCity = (data) => {
    // Simulate fetching data based on state/city
    setResultsStateCity([
      {
        code: "12345",
        facilitator: "State City Facilitator Pvt. Ltd.",
        contact: "Amit Sharma",
        address:
          "OFFLINE E SEVAAT, MUMBAI ROAD, NEAR CENTRAL MALL, MUMBAI, MAHARASHTRA 400001",
        email: "Amit@mail.com",
      },
      // Add more entries here
    ]);
  };

  const tabs = [
    {
      label: "By Pincode",
      component: () => (
        <FormPincode onSubmit={handleSearchPincode} results={resultsPincode} />
      ),
    },
    {
      label: "By State/City",
      component: () => (
        <FormStateCity
          onSubmit={handleSearchStateCity}
          results={resultsStateCity}
        />
      ),
    },
  ];

  return (
    <Layout>
      <div className="flex flex-col gap-8 px-4">
        <div className="flex flex-col gap-4">
          <div className="flex     gap-2 items-center w-full">
            <svg
              width="34"
              height="17"
              viewBox="0 0 34 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
                fill="#6F368B"
              />
              <path
                d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
                fill="#6F368B"
              />
              <circle cx="25.5" cy="8.5" r="8.5" fill="#6F368B" />
            </svg>

            <h3 className="font-semibold text-[26px]">Exclusive PAN Centers</h3>
          </div>
          <p className="text-[15px] font-light">
            In addition to the TIN Facilitation Centres Cum PAN Centres,
            applications for PAN (new and change request) are accepted at the
            following locations:
          </p>
        </div>
        <div className="flex flex-col">
          <div className="flex gap-2 items-center w-full">
            <svg
              width="34"
              height="17"
              viewBox="0 0 34 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
                fill="#54AD47"
              />
              <path
                d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
                fill="#54AD47"
              />
              <circle cx="25.5" cy="8.5" r="8.5" fill="#54AD47" />
            </svg>

            <h3 className="font-semibold text-[26px]">
              Search PAN Centres Near Your Location
            </h3>
          </div>
          <div className="w-full max-w-full mt-4 bg-white border border-[#EBEBEB]">
            <div className="md:hidden p-4">
              <TabsDropdown
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
            <div className="hidden md:flex relative">
              <img src={SearchSvg} alt="" className="absolute right-0 top-0" />
              <div className="w-1/4 border-r">
                <Tabs
                  tabs={tabs}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </div>
              <div className="w-3/4 p-4">
                <TabContent tabs={tabs} activeTab={activeTab} />
              </div>
            </div>
            <div className="p-4 md:hidden">
              <TabContent tabs={tabs} activeTab={activeTab} />
            </div>
          </div>
        </div>
      </div>

      {activeTab === 0 && resultsPincode.length > 0 && (
        <div className="px-4 mt-4">
          <div className="flex     gap-2 items-center w-full">
            <svg
              width="34"
              height="17"
              viewBox="0 0 34 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
                fill="#FFCE1B"
              />
              <path
                d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
                fill="#FFCE1B"
              />
              <circle cx="25.5" cy="8.5" r="8.5" fill="#FFCE1B" />
            </svg>

            <h3 className="font-semibold text-[26px]">PAN Centers</h3>
          </div>
          <Table data={resultsPincode} />
        </div>
      )}
      {activeTab === 1 && resultsStateCity.length > 0 && (
        <div className="px-4 mt-4">
             <div className="flex     gap-2 items-center w-full">
            <svg
              width="34"
              height="17"
              viewBox="0 0 34 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
                fill="#FFCE1B"
              />
              <path
                d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
                fill="#FFCE1B"
              />
              <circle cx="25.5" cy="8.5" r="8.5" fill="#FFCE1B" />
            </svg>

            <h3 className="font-semibold text-[26px]">PAN Centers</h3>
          </div>
          <Table data={resultsStateCity} />
        </div>
      )}
    </Layout>
  );
}

export default SearchTabForms;
