import React, { useState } from 'react'
import { ThreeDotsBulletSvg } from '../../../assets/svgs';

function BankBranches() {
    const [formData, setFormData] = useState({
        state: "",
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form submitted:", formData);
    };

    return (
        <div className='flex flex-col w-full'>
            <div className="bg-[#3091D0] bg-opacity-10 p-8">
                <h1 className='flex flex-row text-[26px] font-semibold gap-2 pb-6 leading-5'>
                    <ThreeDotsBulletSvg fillColor={'#3091D0'} className={'w-[34px] h-[17px]'} />
                    Bank branches
                </h1>
                <p className='text-sm pb-4'>
                    Search for Authorised Bank branch for Payment of Direct Tax near your location
                    Tax payer can select a state and location to get the list of Authorised Bank branches for the payment of Direct tax in the location near to
                </p>
                <form onSubmit={handleSubmit}>
                    <label className="block text-base font-medium">
                        Choose State/Union Territory<span className="text-red-500">*</span>
                    </label>
                    <select
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                        className="mt-1 p-2 w-full border border-gray-300 rounded"
                        required
                    >
                        <option value="">Select State</option>
                        <option value="maharashtra">Maharashtra</option>
                        <option value="gujarat">Gujarat</option>
                    </select>
                    <div className="mt-6 flex justify-end gap-4">
                        <button type="button" className="p-2 px-4 bg-[#EBEBEB] text-black rounded">
                            Cancel
                        </button>
                        <button type="submit" className="p-2 px-4 bg-[#54AD47] text-white rounded">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default BankBranches