import React from "react";
import Layout from "../../../components/Layout";
import {
  PolicyVector10,
  PolicyVector11,
  PolicyVector12,
  PolicyVector2,
  PolicyVector3,
  PolicyVector4,
  PolicyVector5,
  PolicyVector6,
  PolicyVector7,
  PolicyVector8,
  PolicyVector9,
} from "../../../assets/svgs";

const Section3 = () => {
  return (
    <Layout>
      <div className="lg:min-h-[427px] py-14 lg:py-0   px-4 lg:px-0  bg-[#EAF4FA] flex flex-col w-full justify-center items-center relative">
        <div className="flex flex-col justify-center lg:max-w-[818px]">
          <div className="absolute bottom-4  right-4 lg:bottom-[30%] lg:right-4">
            <PolicyVector2  className={'max-w-[75px] max-h-[60px] lg:max-w-[203px] lg:max-h-[140px]'}/>
          </div>
          <div className="flex justify-start items-start">
            <h3 className="text-[26px] font-semibold">
              I. Personal Information we may collect and process
            </h3>
          </div>
          <p className="text-[15px] mt-4">
            You can visit our website{" "}
            <a className="text-blue-500" href="http://www.proteantech.in">
              www.proteantech.in
            </a>{" "}
            (“Website”) on the World Wide Web without declaring who you are or
            revealing any information about yourself. Our Web servers collect
            the domain names of visitors. We use this information to assemble
            aggregate information about the visitors to our Website, and assist
            with our systems administration and problem resolution activities.
            Your accessing our Website signifies your unconditional consent to
            allow the collection of your domain names.
          </p>
          <p className="text-[15px] mt-5">
            We would like to assure you that the Personal Information provided
            by you to us is usually used by us only to respond to your inquiry.
            We may collect the following Personal Information from you if you
            are transacting through our Website. We use tracking and/or
            analytics tools such as google analytics:
          </p>
          <p className="text-[15px] font-semibold mt-8">
            a. Name and Sex | b. Telephone Number | c. Address | d. Email
            address.
          </p>
          <p className="text-[15px] mt-4">
            Your contact details, including email address, may be added to our
            mailing list and your consent requested, for us to use it for direct
            marketing purposes.
          </p>
        </div>
      </div>
      <div className="lg:min-h-[427px] py-14 lg:py-0  px-4 lg:px-0  bg-[#EEF7ED] flex flex-col w-full justify-center items-center relative mt-4">
        <div className="flex flex-col justify-cente py-8 r lg:max-w-[818px]">
          <div className="absolute left-[35%]   bottom-4 lg:bottom-[30%] lg:left-4">
            <PolicyVector3 className={'max-w-[75px] max-h-[60px] lg:max-w-[203px] lg:max-h-[140px]'}/>
          </div>
          <div className="flex justify-start items-start">
            <h3 className="text-[26px] font-semibold">
              II. Data recipients, transfer, and disclosure of Personal
              Information
            </h3>
          </div>
          <p className="text-[15px] mt-4">
            We do not sell, trade, or otherwise transfer your Personal
            Information to outside parties. This does not include trusted third
            parties who assist us in conducting our business, or servicing you,
            so long as those parties agree to keep this information
            confidential. We may also release, use or disclose your Personal
            Information when we believe such release of information is necessary
            to comply with any applicable law, regulation, legal process or
            enforceable statutory requirement, to enforce our site policies, to
            protect ours or others rights, property or safety as required or
            permitted by law, or when we have reason to believe that disclosing
            the information is necessary to identify, contact or bring legal
            action against someone who may be causing interference with our
            rights or properties, whether intentionally or otherwise, or when
            anyone else could be harmed by such activities. In addition, if
            Protean eGov Technologies Ltd. or substantially all of its assets
            are acquired, our customer information will most likely also be
            transferred in connection with such acquisition.
          </p>
        </div>
      </div>
      <div className="lg:min-h-[427px]  px-4 lg:px-0  bg-[#FCEBE9] py-8 flex flex-col w-full justify-center items-center relative mt-4">
        <div className="flex flex-col justify-center lg:max-w-[818px] py-4">
          <div className="absolute right-8  top-4 lg:top-20 lg:left-20">
            <PolicyVector4 />
          </div>
          <div className="absolute  bottom-4 right-8 lg:bottom-20 lg:right-20">
            <PolicyVector5 />
          </div>
          <div className="flex justify-start items-start">
            <h3 className="text-[26px] font-semibold">III. Use of Cookies</h3>
          </div>
          <p className="text-[15px] mt-4">
            Some of our web pages use "cookies" so that we can better serve you
            with customized information when you return to our site. Cookies are
            identifiers which websites send to the browser on your computer to
            facilitate your next visit to our Website. You can set your browser
            to notify you when you are sent a cookie, giving you the option to
            decide whether or not to accept it. The information we collect and
            analyse (including the use of tracking tags) is used to improve our
            service to you. Cookies allow us to serve you better and more
            efficiently, and to personalize your experience at our Website. We
            use cookies to personalize your experience on the Website, and with
            respect to advertisements. As to the former, these types of cookies
            allow you to log in without having to type your log - in name each
            time (only your password is needed). We may also use such cookies to
            display an advertisement to you while you are on the Website. None
            of this information is passed to any third party, and is used solely
            by us to provide you with a better user experience on the Website.
            <br />A cookie may also be placed by our advertising server. Such
            cookies are used only for purposes of tracking the effectiveness of
            advertising served by us on our Website and no Personal Information
            is gathered from you by the use of these cookies, nor is this
            information shared with any third parties. Similarly, a cookie may
            be placed by our third - party advertising companies or
            advertisement providers or servers. These companies may use
            aggregated statistics about your visits to this and other websites
            in order to provide advertisements about goods and services that you
            may be interested in or any goods and services of potential interest
            to you. The information they collect does not include your Personal
            Information. The third - party advertising companies or
            advertisement providers may also employ technology that is used to
            measure the effectiveness of ads. Any such information is anonymous.
            They may use this anonymous information about your visits to this
            and other sites in order to provide advertisements about goods and
            services of potential interest to you. No Personal Information is
            collected during this process. The information is anonymous, and
            does not link online actions to an identifiable person. Most web
            browsers automatically accept cookies. Of course, by changing the
            options on your web browser or using certain software programs, you
            can control how and whether cookies will be accepted by your
            browser. We support your right to block any unwanted Internet
            activity, especially that of unscrupulous websites. However,
            blocking Protean eGov Technologies Ltd. cookies may disable certain
            features on the Website, and may make it impossible to purchase or
            use certain services available on the Website. Please note that it
            is possible to block cookie activity from certain websites while
            permitting cookies from websites you trust, like this Website.
          </p>
        </div>
      </div>

      <div className="lg:min-h-[427px]  px-4 lg:px-0  bg-[#FDF2E9] py-8 flex flex-col w-full justify-center items-center relative mt-4">
        <div className="flex flex-col justify-center lg:max-w-[818px]">
          <div className="absolute  bottom-5 right-4 lg:bottom-24 lg:left-20">
            <PolicyVector6 />
          </div>
          <div className="absolute  top-4 right-0 lg:bottom-20 lg:right-0">
            <PolicyVector7 />
          </div>
          <div className="flex justify-start items-start">
            <h3 className="text-[26px] font-semibold">
              IV. Use of Personal Information
            </h3>
          </div>
          <p className="text-[15px] mt-4 leading-6">
            At times we conduct on-line surveys to better understand the needs
            and profile of our visitors. When we conduct a survey, we will try
            (but are not obligated) to let you know how we will use the
            information at the time we collect information from you on the
            Internet.
            <br />
            You recognize and understand that there is no compulsion on you to
            provide us with your Personal Information and any and all Personal
            Information provided by you to us is with your full consent, own
            volition and desire to provide such Personal Information.
            <br />
            We will use your personal information where you give us consent to
            provide you with marketing information about products and services
            which we feel may interest you. You will have the choice to 'opt
            out' of such marketing or promotional communications at your will.
            <br />
            We will only use the information provided for the purposes for which
            it was collected unless you have consented to us using, or would
            reasonably expect us to use, the information for a different purpose
            or as permitted by law.
            <br />
            If you are a registered user of our service, you have various rights
            in relation to your personal information, and we respect those
            rights. With regards to marketing emails, you can request us not to
            send you these at any time. just follow the 'update your
            preferences' or 'unsubscribe' from this list' options at the end of
            the marketing email. You may still receive important account and
            service related information email notifications.
          </p>
        </div>
      </div>

      <div className=" bg-[#EEF7ED]  px-4 lg:px-0  py-8 flex flex-col w-full justify-center items-center relative mt-4">
        <div className="flex flex-col justify-center py-4 lg:max-w-[818px]">
          <div className="absolute  bottom-3 right-0 lg:bottom-0 lg:left-0">
            <PolicyVector8 className={'max-w-[75px] max-h-[40px] lg:max-w-[203px] lg:max-h-[140px]'} />
          </div>

          <div className="flex justify-start items-start">
            <h3 className="text-[26px] font-semibold">V. Linked Websites</h3>
          </div>
          <p className="text-[15px] mt-4 leading-6">
            Further, this Website may contain links to other sites such as
            business affiliates and software providers. We are not responsible
            for the content or the privacy practices employed by other sites. We
            are not responsible for the protection and privacy of any
            information which you provide whilst visiting other sites and such
            other sites are not governed by this Policy. We advise you to read
            their privacy policies before providing your Personal Information to
            such sites.
          </p>
        </div>
      </div>

      <div className=" bg-[#EAF4FA]  px-4 lg:px-0  py-8 flex flex-col w-full justify-center items-center relative mt-4">
        <div className="flex flex-col justify-center lg:max-w-[818px]">
          <div className="absolute  bottom-0 right-0 lg:bottom-0 lg:right-10">
            <PolicyVector9 className={'max-w-[75px] max-h-[40px] lg:max-w-[203px] lg:max-h-[140px]'}/>
          </div>

          <div className="flex justify-start items-start">
            <h3 className="text-[26px] font-semibold">VI. Payment</h3>
          </div>
          <p className="text-[15px] mt-4 leading-6">
            All payments are arranged to be executed by you through a secured
            Payment Gateway which is compliant with PCI-DSS (Payment Card
            Industry and IT Data Security Standards Compliance) requirements and
            using Verisign SSL Security Certificate. The data traffic to and
            from this website is protected through a SSL tunnel using the
            Entrust SSL Security Certificate. This means all Personal
            Information You provide on the Website is transmitted using strong
            encryption mechanism of 128 bits key. SSL is a proven coding system
            that lets your browser automatically encrypt, or scramble, data
            before you send it to us.
          </p>
        </div>
      </div>

      <div className=" bg-[#F1EBF3] px-4 lg:px-0 py-8 flex flex-col w-full justify-center items-center relative mt-4 ">
        <div className="flex flex-col justify-center lg:max-w-[818px]">
          <div className="absolute  top-0 right-0 lg:bottom-4 lg:right-10">
            <PolicyVector10 className={'max-w-[75px] max-h-[60px] lg:max-w-[203px] lg:max-h-[140px]'}/>
          </div>

          <div className="flex justify-start items-start">
            <h3 className="text-[26px] font-semibold">VII. Data security</h3>
          </div>
          <p className="text-[15px] mt-4 leading-6">
            We take appropriate steps to protect the information you share with
            us. We have implemented technology and security features and strict
            policy guidelines to safeguard the privacy of your Personal
            Information from unauthorized access and improper use or disclosure.
            Please contact us to obtain names and addresses of the specific
            entities that shall have access to your Personal Information in a
            given transaction.
            <br />
            We will continue to enhance its security procedures as new
            technology becomes available, and ensures that its security
            procedures are compliant with current applicable regulations.
            <br />
            Wherever applicable, Protean eGov Technologies Ltd. complies with
            the Rules, Regulations and Guidelines issued by UIDAI and all other
            regulators as applicable to the company’s various businesses from
            time to time and also adheres to the Information Technology
            (Reasonable security practices and procedures and sensitive personal
            data or information) Rules, 2011 and all the applicable laws as may
            from time to time be, amended, modified or re-enacted;
            <br />
            Protean eGov Technologies Ltd. is ISO 27001:13 compliant. We seek to
            use reasonable organizational, technical and administrative measures
            to protect Data within our organization. However, if you have any
            concerns regarding handling of your personal data or the privacy
            practises, please immediately notify to the designated Grievance
            Officer at the contact details provided in this policy.
          </p>
        </div>
      </div>

      <div className=" bg-[#FFFAE8] py-14 px-4 lg:py-8 flex flex-col w-full justify-center items-center relative mt-4">
        <div className="flex flex-col justify-center lg:max-w-[818px]">
          <div className="absolute  top-2 right-4 lg:top-10 lg:left-10">
            <PolicyVector12 />
          </div>
          <div className="absolute  bottom-4 right-0 lg:bottom-10 lg:right-10">
            <PolicyVector11 />
          </div>
          <div className="flex justify-start items-start">
            <h3 className="text-[26px] font-semibold">
              VIII. Changes to this Policy
            </h3>
          </div>
          <p className="text-[15px] mt-4 leading-6">
            If our Privacy Policy changes in the future, it will be posted here
            and a new effective date will be shown. You should access our
            Privacy Policy regularly to ensure you understand our current
            policies. Of course, you may always submit concerns / grievances
            regarding our Privacy Policy or our privacy practices to the
            designated Grievance Officer as follows:
          </p>
          <p className="font-semibold mt-4">
            Mr. Kapil Kapoor
            <br />
            Chief Risk & Compliance Officer
            <br />
            Email: writetous@proteantech.in
            <br />
            Phone: 022-24994200
          </p>
          <p className="text-[15px] mt-4 leading-6">
            Please reference the Privacy Policy in your subject line. We will
            attempt to respond to all reasonable concerns or inquiries within 10
            business days of receipt.This Privacy Policy shall be governed by
            the laws of India and any disputes arising out of or in relation to
            this Privacy Policy shall be subject to the jurisdiction of courts/
            tribunals of Mumbai, India.Thank you for using the Website!
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Section3;
