import React, { useState } from 'react'
import { ArrowUpSvg, DropDownArrowSvg, ThreeDotsBulletSvg } from '../../../assets/svgs';

function StatusTrack({ language }) {
    const isMobile = window.innerWidth <= 768;
    const [isOverviewOpen, setIsOverviewOpen] = useState(isMobile || true)

    const toggleOverview = () => {
        setIsOverviewOpen(!isOverviewOpen)
    }

    const textContent = {
        en: {
            overviewTitle: "Status Track",
            overviewDescription: "The applicants may track the status of their application using 15 digit unique Acknowledgment Number after three days of application using the status track facility. Alternatively, applicant may call TIN Call Centre on 020 – 2721 8080 to enquire about the status of application. The status of the PAN application can also be tracked by sending an SMS - PTNPAN  < space> 15 digit Acknowledgement No. to 57575.",
        },
        hi: {
            overviewTitle: "स्थिति ट्रैक",
            overviewDescription: "आवेदक स्टेटस ट्रैक सुविधा का उपयोग करके आवेदन के तीन दिनों के बाद 15 अंकों की अद्वितीय पावती संख्या का उपयोग करके अपने आवेदन की स्थिति को ट्रैक कर सकते हैं। वैकल्पिक रूप से, आवेदक आवेदन की स्थिति के बारे में पूछताछ करने के लिए 020 - 2721 8080 पर टिन कॉल सेंटर पर कॉल कर सकता है। पैन आवेदन की स्थिति को एक एसएमएस भेजकर भी ट्रैक किया जा सकता है - PTNPAN <स्पेस> 15 अंकों की पावती संख्या 57575 पर भेजें।",
        }
    };

    return (
        <section className="bg-[#54AD47] bg-opacity-10 p-8">
            <div className='px-4 md:px-0 flex items-center justify-between pb-6'>
                <h1 className='flex flex-row text-[26px] font-semibold gap-2 leading-5'>
                    <ThreeDotsBulletSvg fillColor={'#54AD47'} className={'w-[34px] h-[17px]'} />
                    {textContent[language].overviewTitle}
                </h1>
                {isMobile && <button onClick={toggleOverview} className=''>
                    {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                </button>}
            </div>
            {isOverviewOpen && <p className='text-sm font-normal leading-6'>
                {textContent[language].overviewDescription}
            </p>}
        </section>
    )
}

export default StatusTrack