import React from 'react';
import { OverViewSvg4 } from '../../../assets/svgs';
const ResponseToUser = () => {
  return (
    <div className="px-4 bg-[#f6f0f9] w-full  lg:min-h-[1000px] mt-4 md:mt-0">
      <div className="flex items-center w-full gap-2">
        <svg
          width="34"
          height="17"
          viewBox="0 0 34 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
            fill={'#6F368B'}
          />
          <path
            d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
            fill={'#6F368B'}
          />
          <circle cx="25.5" cy="8.5" r="8.5" fill={'#6F368B'} />
        </svg>
        <h2 className="text-lg md:text-[26px] font-semibold flex items-center">
          Response to the user
        </h2>
      </div>

      <table className="w-full mt-4 bg-[#fefefe] relative ">
        <OverViewSvg4 className={'absolute right-0 top-[40%] hidden md:block'} />
        <thead>
          <tr className="bg-[#fefefe]">
            <th className="text-left p-2 ">Input by PAN verification user</th>
            <th className="text-left p-2 ">Output</th>
            <th className="text-left p-2 ">Response by Protean</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="p-2">PAN</td>
            <td className="p-2">PAN Status</td>
            <td className="p-2 ">
              <ul className="list-disc pl-5">
                <li>Existing & Valid</li>
                <li>Existing & Valid marked with event</li>
                <li>Record (PAN) Not Found in ITD Database/Invalid PAN</li>
                <li>Deleted</li>
                <li>Deactivated PAN</li>
                <li>Fake PAN</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="p-2 ">Name of the PAN holder</td>
            <td className="p-2 ">Matching status of Name</td>
            <td className="p-2 ">
              <ul className="list-disc pl-5">
                <li>Yes (if matching)</li>
                <li>No (if not matching)</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="p-2 ">Date of Birth/Incorporation (DOB/I)</td>
            <td className="p-2 ">Matching status of DOB/I</td>
            <td className="p-2 ">
              <ul className="list-disc pl-5">
                <li>Yes (if matching)</li>
                <li>No (if not matching)</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="p-2"></td>
            <td className="p-2">Aadhaar-PAN linking status</td>
            <td className="p-2 ">
              <ul className="list-disc pl-5">
                <li>Operative</li>
                <li>Inoperative</li>
                <li>Not applicable</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <p className="text-sm mt-2">
        For PANs being verified by Department of Commercial Taxes (DCT), an additional field Father’s name is required to be sent in Input along with all above input fields and ‘Matching status of Father’s name’ will be provided in the response along with all above output fields.
      </p>
    </div>
  );
};

export default ResponseToUser;