import React from "react";
import { Link13Graphic1, Link13Graphic2 } from "../../../assets/svgs";

function ThirdGrid() {
  const gridData = [
    {
      title: "Types of TAN Application",
      fillColor: "#6F368B",
      bgColor: "#F1EBF3",
      content: [
        "Application for issuance of new TAN (Form 49B) This application form should be used when the deductor has never applied for a TAN or does not have a TAN.)",
        "Form for Change or Correction in TAN data for TAN Allotted",
      ],
      isList: true,
    },
    {
      title: "How to Apply",
      fillColor: "#F79A2E",
      bgColor: "#FBE9DD",
      content:
        "A deductor may either make an online application through this website or submit physical TAN Application to any TIN-Facilitation Center (TIN-FC) of Protean. Applicants should go through the instructions and guidelines provided in the application form before filling the form.",
      isList: false,
    },
    {
      title: "Where to get the Physical Application Forms",
      fillColor: "#F07B5B",
      bgColor: "#FEECE6",
      content:
        "Applicants may obtain the application forms from TIN-FCs, any other vendors providing such forms or can freely download the same from this website.",
      isList: false,
    },
    {
      title: "Communication",
      fillColor: "#4D96F2",
      bgColor: "#E7F1FB",
      content:
        "These applications are digitised by Protean and forwarded to ITD. ITD will issue the TAN which will be intimated to Protean online. On the basis of this Protean will issue the TAN letter to the applicant.",
      isList: false,
    },
    {
      title: "Status Track",
      fillColor: "#6F9AE3",
      bgColor: "#E7F1FB",
      content:
        "The applicants may track the status of their TAN application using 14 digit unique Acknowledgment Number after three days of application using the status track facility. Alternatively, applicant may call TIN Call Centre on 020 – 2721 8080 to enquire about the status of application. The status of the TAN application can also be tracked by sending an SMS - NSDLTAN to 57575.",
      isList: false,
    },
    {
      title: "Fee",
      fillColor: "#66B85B",
      bgColor: "#F3F7F0",
      content:
        "The processing fee for both the applications (new TAN and change request) is ₹77 (including Goods and Service Tax).",
      isList: false,
    },
  ];

  return (
    <section className="lg:px-[70px] w-full md:py-8">
      <div className="flex flex-col w-full gap-8">
        <div className="w-full p-4 bg-[#EEF7ED]">
          <div className="flex items-center gap-2 w-full mb-4">
            <svg
              width="34"
              height="17"
              viewBox="0 0 34 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
                fill={"#54AD47"}
              />
              <path
                d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
                fill={"#54AD47"}
              />
              <circle cx="25.5" cy="8.5" r="8.5" fill={"#54AD47"} />
            </svg>
            <h2 className="text-2xl font-semibold">Overview</h2>
          </div>
          <p className="mt-2 text-gray-600 text-[15px]">
            TAN or Tax Deduction and Collection Account Number is a 10 digit
            alphanumeric number required to be obtained by all persons who are
            responsible for deducting or collecting tax. Under Section 203A of
            the Income Tax Act, 1961, it is mandatory to quote Tax Deduction
            Account Number (TAN) allotted by the Income Tax Department (ITD) on
            all TDS returns. Since last few years ITD has revised the structure
            of TAN. It is a unique 10 digit alphanumeric code. Accordingly, they
            have issued TAN in this new format to all existing TAN holders. To
            facilitate deductors find their new TAN, ITD has now introduced a
            search facility in their website (www.incometaxindia.gov.in).
            Through this facility deductors can search on their name and old TAN
            to find the new TAN. Deductors are advised to find the new TAN from
            this site before it is incorporated in their e-TDS return file to
            avoid any inconvenience at the time of furnishing e-TDS return.
          </p>
        </div>

        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 ">
          {gridData.map((card, index) => (
            <div
              key={index}
              className="p-4 relative z-40"
              style={{ backgroundColor: card.bgColor }}
            >
              <div className="flex items-center gap-2 w-full mb-4">
                <svg
                  width="34"
                  height="17"
                  viewBox="0 0 34 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
                    fill={card.fillColor}
                  />
                  <path
                    d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
                    fill={card.fillColor}
                  />
                  <circle cx="25.5" cy="8.5" r="8.5" fill={card.fillColor} />
                </svg>
                <h3 className="font-semibold text-xl">{card.title}</h3>
              </div>
              {card.isList ? (
                <>
                  <p className="text-[15px] font-light">
                    There are two types of TAN applications:
                  </p>
                  <ul className="list-disc pl-5 text-gray-700 text-[15px]">
                    {card.content.map((item, idx) => (
                      <li key={idx} className="mb-1 text-[15px]">
                        {item}
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <p className="text-gray-700 text-[15px] z-40">{card.content}</p>
              )}
              {index === gridData.length - 1 && (
                <>
                  <Link13Graphic1 className="absolute bottom-0 right-0 z-10 " />
                  <Link13Graphic2 className="absolute top-0 right-0 " />
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ThirdGrid;
