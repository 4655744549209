import React from "react";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";

import Card from "../../../components/Card";
import Reprint from "../../../assets/images/Reprint.png";
import { ReprintGraphic } from "../../../assets/svgs";
const ReprintPanCard = () => {
  const { width } = useWindowDimensions();
  const isMobile = width <= 700;

  const cardDataArray = [
    {
      linkData: [
        {
          text: "Read Guidlines",
          textColor: "black",
          iconColor: "black",
          borderBottom: "1px solid black",
          maxWidth: "207px",
          href: "https://www.protean-tinpan.com/services/pan/correction.html",
        },
        {
          text: "Read Instructions",
          textColor: "black",
          iconColor: "black",
          borderBottom: "1px solid black",
          maxWidth: "207px",
          href: "https://www.protean-tinpan.com/services/pan/instructionscorr.html",
        },
        {
          text: "Documents to be submitted",
          textColor: "black",
          iconColor: "black",
          borderBottom: "1px solid black",
          maxWidth: "207px",
          href: "https://www.protean-tinpan.com/services/pan/documents-req.html",
        },
        {
          text: "Do’s and Dont’s",
          textColor: "black",
          iconColor: "black",
          borderBottom: "1px solid black",
          maxWidth: "207px",
          href: "https://www.protean-tinpan.com/services/pan/cr-do-donts.html"
        },
        {
          text: "NEW Designated centers accepting online PAN Documents",
          textColor: "black",
          iconColor: "black",
          borderBottom: "1px solid black",
          maxWidth: "207px",
          href: "https://www.protean-tinpan.com/downloads/List_Of_Centres_For_Submission_Of_Online_PAN_Documents.xlsx"
        },
      ],
      defaultLinkMinWidth: window.innerWidth > 1024 ? "279px" : "300px", // Default min width for this card's links
    },
  ];

  return (
    <section className="w-full  lg:px-[70px] flex flex-col lg:flex-row justify-between relative ">
      <div className="flex relative bg-[#F1EBF3] bg-no-repeat bg-cover py-10 lg:py-0 flex-col lg:flex-row justify-between  w-full  ">
        <img
          src={Reprint}
          className="absolute bottom-0 right-0 hidden md:block"
          width={"714px"}
        />

        <div className="lg:w-1/2 flex flex-col gap-6 lg:pr-10 px-4 lg:px-10 py-0 lg:py-10  relative">
          <div className="block lg:hidden absolute bottom-[-3rem] right-0">
            <ReprintGraphic />
          </div>
          <h2 className="text-[26px] lg:text-[26px] font-semibold text-black">
            Reprint of PAN Card
          </h2>
          <p className="text-black text-[15px] lg:text-[16px] font-light">
            This application should be used when PAN has already been allotted
            to the applicant but applicant requires a PAN card. A new PAN card
            bearing the same PAN details is issued to the applicant.
          </p>
          <p className="mt-2 text-[14px] font-light">
            <span className="font-semibold">(A)</span> PAN applicants can now
            apply online for Reprint of PAN card (only when there is no change
            required in data) by clicking here. This facility is only available
            for those PAN holders whose latest PAN application was processed
            through Protean and / or e-Filing portal of Income Tax Department.
            Option for update of PAN details will not be available through this
            facility.
          </p>
          <p className="mt-2 text-[15px] font-light">
            <span className="font-semibold">(B)</span> If the communication
            address of an applicant as per latest records available with Protean
            is an Indian address, then applicant needs to make an online fee
            payment of ₹50 (inclusive of taxes) and if it is a foreign address,
            then payment of ₹959 (inclusive of taxes) has to be made by the
            applicant.
          </p>

          <p className="mt-2 text-[15px] font-light">
            <span className="font-semibold">(C)</span>
            There is no requirement of submitting any application form along
            with supporting documents for processing this PAN card reprint
            request. Kindly click on guidelines to know more. .
          </p>
          <p className="mt-2 text-[15px] font-light">
            Alternatively, an applicant can apply for a reprinted PAN card
            through normal online PAN application mode whose details are given
            below.
          </p>
          <p className="mt-2 text-[15px]  font-light">
            <span className="font-semibold">(D)</span> While filling this form,
            applicant should not select any of the check boxes on the left
            margin of the form. However, the check box for Item no. 7 Address
            for communication will be selected by default as this address will
            be updated in the records of ITD
          </p>
          <a href="https://www.onlineservices.nsdl.com/paam/endUserRegisterContact.html" className="w-max mt-4 ">
            <button
              type="button"
              class="py-[12px] text-base font-medium text-white w-fit gap-4 flex items-center justify-between bg-black px-[24px] rounded-[8px] text-center"
            >
              Apply now
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2429_15128)">
                  <path
                    d="M5 12H19"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15 16L19 12"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15 8L19 12"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2429_15128">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </a>
        </div>

        {/* Right Section */}
        <div className="flex flex-col justify-center mt-8 lg:mt-0   lg:py-0 lg:justify-start items-center lg:items-start lg:flex-row lg:gap-10 lg:pr-10">
          {cardDataArray.map((cardData, index) => (
            <Card
              key={index}
              {...cardData}
              className="min-w-full justify-center items-center lg:min-w-[200px] "
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ReprintPanCard;
