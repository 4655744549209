import React, { useState } from 'react'
import { ArrowUpSvg, DropDownArrowSvg, ThreeDotsBulletSvg } from '../../../assets/svgs';

function Protean() {
    const isMobile = window.innerWidth <= 768;
    const [isOverviewOpen, setIsOverviewOpen] = useState(isMobile || true)

    const toggleOverview = () => {
        setIsOverviewOpen(!isOverviewOpen)
    }

    return (
        <section className="bg-[#3091D0] bg-opacity-10 p-8">
            <div className='px-4 md:px-0 flex items-center justify-between pb-6'>
                <h1 className='flex flex-row text-[26px] font-semibold gap-2 leading-5'>
                    <ThreeDotsBulletSvg fillColor={'#3091D0'} className={'w-[34px] h-[17px]'} />
                    Protean Return Preparation Utility
                </h1>
                {isMobile && <button onClick={toggleOverview} className=''>
                    {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                </button>}
            </div>
            {isOverviewOpen && <p className='text-sm font-normal leading-6'>
            New e-TDS/TCS Return Preparation Utility Ver. 5.3 for Regular & Correction Statement(s) from FY 2007-08 onwards.
            </p>}
            {isOverviewOpen && <p className='text-sm font-normal leading-6'>
            Features of RPU 5.3
            </p>}
            {isOverviewOpen && <p className='text-sm font-normal leading-6'>
            Protean RPU_e-Tutorial_on_TDS_TCS
            </p>}
            {isOverviewOpen && <p className='text-sm font-normal leading-6'>
            e-TDS/TCS Regular & Correction Statement(s) for FY 2007-08 and onwards (i.e. Forms 24Q, 26Q, 27Q and 27EQ) can be prepared using this utility.
            </p>}
            {isOverviewOpen && <p className='text-sm font-normal leading-6'>
            The e-TDS/TCS RPU is a Java based utility. JRE (Java Run-time Environment) [versions: SUN JRE: 1.6 onwards] should be installed on the computer where the e-TDS/TCS FVU is being installed. Java is freely downloadable from http://java.sun.com and http://www.ibm.com/developerworks/java/jdk or you can ask your vendor providing computer facilities (hardware) to install the same for you.
            </p>}
            {isOverviewOpen && <p className='text-sm font-normal leading-6'>
            The e-TDS/TCS RPU setup file (e-TDS/TCS RPU.zip) comprises of three files and rest are supporting jar files. Main jar files are as listed below and supporting jar files are mentioned in Readme.txt:
            </p>}
            {isOverviewOpen && <ul className='list-disc list-inside text-sm font-normal leading-6'>
                <li>TDS RPU Readme.txt This file contains instructions for setup of the e-TDS RPU.</li>
                <li>TDS_RPU.jar: This is RPU program file. This is executable file.</li>
                <li>TDS_FVU_STANDALONE_8.8.jar: This is the FVU program file (for financial year greater than 2009-10).</li>
                <li>TDS_FVU_STANDALONE_2.184.jar: This is the FVU program file (for financial year less than equal to 2009-10).</li>
            </ul>}
            {isOverviewOpen && <p className='text-sm font-normal leading-6'>
                These files are in an executable zip file. These files are required for installing the e-TDS/TCS FVU.
            </p>}
        </section>
    )
}

export default Protean
