import React, { useState } from 'react'
import { ArrowUpSvg, DropDownArrowSvg, ThreeDotsBulletSvg } from '../../../assets/svgs'
import DynamicLinks from '../../../components/DynamicLink';

function ApplyOnline() {
    const isMobile = window.innerWidth <= 768;
    const [isOverviewOpen, setIsOverviewOpen] = useState(isMobile || true)

    const toggleOverview = () => {
        setIsOverviewOpen(!isOverviewOpen)
    }

    const cardDataArray = [
        {
            linkData: [
                {
                    text: "New TAN",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    href:"https://tin.tin.nsdl.com/tan/form49B.html"
                },
                {
                    text: "Change / Correction",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    href:"https://tin.tin.nsdl.com/tan/ChangeRequest.html"
                },
            ],
        },
    ];

    return (
        <section className="md:px-[68px] mb-8 w-full">
            <div className='bg-[#EE7F25] bg-opacity-10 p-8'>
                <div className='px-4 md:px-0 flex items-center justify-between'>
                    <h1 className='flex flex-row text-[26px] font-semibold gap-2 leading-5'>
                        <ThreeDotsBulletSvg fillColor={'#3091D0'} className={'w-[34px] h-[17px]'} />
                        Apply Online
                    </h1>
                    {isMobile && <button onClick={toggleOverview} className=''>
                        {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                    </button>}
                </div>
                {isOverviewOpen && <div className='pt-6'>
                    <div className="flex flex-col md:flex-row pb-20 md:pb-0">
                        {cardDataArray.map((cardData, index) => (
                            <DynamicLinks key={index} linkData={cardData.linkData} listDir='flex-row' className={"text-black flex-col md:flex-row md:items-end gap-5 px-2 md:px-0 md:gap-6"} titleColor='black' />
                        ))}
                    </div>
                </div>}
            </div>
        </section>
    )
}

export default ApplyOnline