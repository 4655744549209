import React from 'react'
import TDSHeroSection from '../../components/TDSHeroSection'
import SecondSection from '../Link6/components/SecondSection'
import Procedure from './components/Procedure'
import FormSection from './components/FormSection'
import FourCards from '../../components/FourCards'
import Protean from './components/Protean'
import FVU from './components/FVU'
import withScrollToHash from '../../hooks/useScrollToHash'
import { Element } from "react-scroll";
import { Helmet } from 'react-helmet'

function Link9() {
    const firstRowCard = [
        {
            title: 'Data Structure',
            subTitle: 'For regular statements pertaining to FY 2010-11 onwards:',
            firstColumn: [
                { text: 'File Format for Form 24Q Q1 to Q3 Version 6.2', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_24Q_Regular_Q1_to_Q3_Version_6.2_01062023_201011.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
                { text: 'File Format for Form 24Q (4th Quarter) Version 7.2', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_24Q_Regular_Q4_Version_7.2_10042024_2210112.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
                { text: 'File Format for Form 26Q Q1 TO Q4 Version 7.4', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_26Q_Regular_Q1_to_Q4_Version_7.4_21092023_201011.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
            ],
            secondColumn: [
                { text: 'File Format for Form 27EQ Q1 to Q4 Version 6.7', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_27EQ_Regular_Q1_to_Q4_Version_6.7_%2002112023_201011.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
                { text: 'File Format for Form 27Q Q1 to Q4 Version 7.2', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_27Q_Regular_Q1_to_Q4_Version_7.2_02112023_201011.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
            ]
        },
        {
            subTitle: 'For regular statements up to FY 2009-10:',
            firstColumn: [
                { text: 'File Format for Form 24Q Q1 to Q3 Version 5.8', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_24Q_Regular_Q1_to_Q3_Version_5.8_01062023_200910.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
                { text: 'File Format for Form 24Q (4th Quarter) Version 6.2', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_24Q_Regular_Q4_Version_6.2_01062023_200910.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
                { text: 'File Format for Form 26Q Q1 TO Q4 Version 5.8', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_26Q_Regular_Q1_to_Q4_Version_5.8_01062023_200910.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
            ],
            secondColumn: [
                { text: 'File Format for Form 27EQ Q1 to Q4 Version 5.8', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_27EQ_Regular_Q1_to_Q4_Version_5.8_%2001062023_200910.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
                { text: 'File Format for Form 27Q Q1 to Q4 Version 5.8', href: 'https://www.protean-tinpan.com/downloads/e-tds/File_Format_27Q_Regular_Q1_to_Q4_Version_5.8_01062023_200910.xls', style: { linkMaxWidth: '187px', textColor: 'black' } },
            ]
        }
    ];

    const secondRowCard = [
        {
            title: "",
            subTitle: 'For statement pertaining to FY 2010-11 onwards',
            firstColumn: [
                { text: 'Sample file for Form 24Q (1st, 2nd & 3rd Quarter)', href: 'https://www.protean-tinpan.com/downloads/e-tds/download/uat/24QQ3_Sample.zip', style: { linkMaxWidth: '187px', textColor: 'black' } },
                { text: 'Sample file for Form 24Q (4th Quarter)', href: 'https://www.protean-tinpan.com/downloads/e-tds/24QQ4.zip', style: { linkMaxWidth: '187px', textColor: 'black' } },
                { text: 'Sample file for Form 26Q', href: 'https://www.protean-tinpan.com/downloads/e-tds/download/uat/26QQ1.zip', style: { linkMaxWidth: '187px', textColor: 'black' } },
            ],
            secondColumn: [
                { text: 'Sample file for Form 27Q', href: 'https://www.protean-tinpan.com/downloads/e-tds/download/uat/27QQ3.zip', style: { linkMaxWidth: '187px', textColor: 'black' } },
                { text: 'Sample file for Form 27EQ', href: 'https://www.protean-tinpan.com/downloads/e-tds/download/uat/27EQRQ3.zip', style: { linkMaxWidth: '187px', textColor: 'black' } },
            ]
        },
        {
            subTitle: 'For statement upto FY 2009-10',
            firstColumn: [
                { text: 'Sample file for Form 24Q (1st, 2nd & 3rd Quarter)', href: 'https://www.protean-tinpan.com/downloads/e-tds/download/uat/24QQ1.zip', style: { linkMaxWidth: '187px', textColor: 'black' } },
                { text: 'Sample file for Form 24Q (4th Quarter)', href: 'https://www.protean-tinpan.com/downloads/e-tds/24QRQ4.zip', style: { linkMaxWidth: '187px', textColor: 'black' } },
                { text: 'Sample file for Form 26Q', href: 'https://www.protean-tinpan.com/downloads/e-tds/download/uat/26QQ2.zip', style: { linkMaxWidth: '187px', textColor: 'black' } },
            ],
            secondColumn: [
                { text: 'Sample file for Form 27Q', href: 'https://www.protean-tinpan.com/downloads/e-tds/download/uat/27QQ1_Sample.zip', style: { linkMaxWidth: '187px', textColor: 'black' } },
                { text: 'Sample file for Form 27EQ', href: 'https://www.protean-tinpan.com/downloads/e-tds/download/uat/27EQQ1_Sample.zip', style: { linkMaxWidth: '187px', textColor: 'black' } },
            ]
        }
    ];

    const combinedCardData = [...firstRowCard, ...secondRowCard];

    return (
        <>
            <Helmet>
                <title>eTDS Download Regular - Protean Pan & Tan Services</title>
                <meta name="description" content="Download eTDS software and forms. Get eTDS filing resources from Protean Tinpan. Start your eTDS download now!" />
            </Helmet>
            <div className="flex flex-col gap-8">
                <TDSHeroSection />
                <Element name="second-section">
                    <SecondSection />
                </Element>
                <Element name="procedure">
                    <Procedure />
                </Element>
                <Element name="form-section">
                    <FormSection />
                </Element>
                <Element name="four-cards">
                    <FourCards
                        data={combinedCardData}
                        className="custom-class "
                        maxWidthDesktop="600px"
                        minWidthDesktop="640px"
                        bgColor={'bg-[#EEF7ED]'}
                    />
                </Element>
                <Element name="protean-fvu">
                    <div className='flex flex-col md:flex-row md:px-[68px] gap-6 pb-7 md:pb-10'>
                        <Protean />
                        <FVU />
                    </div>
                </Element>
            </div>
        </>
    )
}

export default withScrollToHash(Link9)