import React, { useState } from "react";

const FAQComponent = ({ linkData }) => {
  const [activeTab, setActiveTab] = useState(Object.keys(linkData)[0]);
  const [openFAQ, setOpenFAQ] = useState(null);
  const rangeText1 = "> 7.5 Lakhs <= 15 Lakhs"
  const rangeText2 = ">15 Lakhs <= 30 Lakhs"

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setOpenFAQ(null);
  };

  const handleFAQClick = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  const renderFAQ = (data, startIndex) => {
    return data.map((item, index) => (
      <div key={index} className="mt-4">
        <button
          onClick={() => handleFAQClick(startIndex + index)}
          className="flex justify-between items-center text-left w-full p-2 border-b border-gray-600 text-white"
        >
          {item.text}
          <span>
            {openFAQ === startIndex + index ? (
              <svg
                width="24"
                height="24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-chevron-up"
              >
                <polyline points="18 15 12 9 6 15"></polyline>
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-chevron-down"
              >
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            )}
          </span>
        </button>
        {openFAQ === startIndex + index && (
          <div className="p-2 text-white flex flex-col gap-2 bg-black whitespace-pre-line">
            {item.content}
            {item.formattedData === true &&
              <>
                {item.para1 && <a href={item.para1Url}>{item.para1}</a>}
                {item.subPara1 && <a href={item.subPara1Url} className="font-semibold">{item.subPara1}</a>}
                {item.isUL1 &&
                  <ul className="list-disc list-inside">
                    {item.ulList1.map((listItem, index) => (
                      <li key={index} className="pb-2">
                        {listItem.isHeading &&
                          <>
                            <a href={listItem.headingUrl}>{listItem.heading}</a>
                            <br />
                          </>
                        }
                        <a href={listItem.url}>{listItem.text}</a>
                      </li>
                    ))}
                  </ul>}
                {item.note && <p className="font-semibold">{item.note}</p>}
                {item.para2 && <a href={item.para2Url}>{item.para2}</a>}
                {item.isOL1 &&
                  <ol className="list-decimal list-inside">
                    {item.olList1.map((listItem, index) => (
                      <li key={index} className="pb-2">
                        {listItem.isHeading &&
                          <>
                            <a href={listItem.headingUrl}>{listItem.heading}</a>
                            <br />
                          </>
                        }
                        <a href={listItem.url}>{listItem.text}</a>
                      </li>
                    ))}
                  </ol>}
                {item.subPara2 && <a href={item.subPara2Url} className="font-semibold">{item.subPara2}</a>}
                {item.middleText && <p>{item.middleText}</p>}
                {item.extraSubPara2 && <a href={item.extraSubPara2Url}>{item.extraSubPara2}</a>}
                {item.isUL2 &&
                  <ul className="list-disc list-inside">
                    {item.ulList2.map((listItem, index) => (
                      <li key={index} className="pb-2">
                        {listItem.isHeading &&
                          <>
                            <a href={listItem.headingUrl}>{listItem.heading}</a>
                            <br />
                          </>
                        }
                        <a href={listItem.url}>{listItem.text}</a>
                      </li>
                    ))}
                  </ul>}
                {item.isOL2 &&
                  <ol className="list-decimal list-inside">
                    {item.olList2.map((listItem, index) => (
                      <li key={index} className="pb-2">
                        {listItem.isHeading &&
                          <>
                            <a href={listItem.headingUrl}>{listItem.heading}</a>
                            <br />
                          </>
                        }
                        <a href={listItem.url}>{listItem.text}</a>
                      </li>
                    ))}
                  </ol>}
                {item.bottomNote && <p className="font-semibold">{item.bottomNote}</p>}
                {item.para5 && <a href={item.para5Url}>{item.para5}</a>}
                {item.isAddress &&
                  <>
                    {item.address.map((address, index) => (
                      <ul key={index} className="gap-1">
                        <li>{address.title}</li>
                        <li>{address.line1}</li>
                        <li>{address.line2}</li>
                        <li>{address.line3}</li>
                        <li>{address.line4}</li>
                        <li>{address.line5}</li>
                        <li>{address.line6}</li>
                        <li>{address.line7}</li>
                      </ul>
                    ))}
                  </>
                }
                {item.isUL3 &&
                  <ul className="list-disc list-inside">
                    {item.ulList3.map((listItem, index) => (
                      <li key={index} className="pb-2">
                        {listItem.isHeading &&
                          <>
                            <a href={listItem.headingUrl}>{listItem.heading}</a>
                            <br />
                          </>
                        }
                        <a href={listItem.url}>{listItem.text}</a>
                      </li>
                    ))}
                  </ul>}
                {item.isOL3 &&
                  <ol className="list-decimal list-inside">
                    {item.olList3.map((listItem, index) => (
                      <li key={index} className="pb-2">
                        {listItem.isHeading &&
                          <>
                            <a href={listItem.headingUrl}>{listItem.heading}</a>
                            <br />
                          </>
                        }
                        <a href={listItem.url}>{listItem.text}</a>
                      </li>
                    ))}
                  </ol>}
                {item.para3 && <a href={item.para3Url}>{item.para3}</a>}
                {item.para4 && <a href={item.para4Url}>{item.para4}</a>}
                {item.para6 && <a href={item.para6Url}>{item.para6}</a>}
                {item.para7 && <a href={item.para7Url}>{item.para7}</a>}
                {item.isPanTable &&
                  <table className="w-full mt-4 text-black bg-[#fefefe] overflow-x-scroll overflow-auto">
                    <thead>
                      <tr className="bg-[#fefefe] text-black">
                        <th className="text-left p-2 ">Input by PAN verification user</th>
                        <th className="text-left p-2 ">Output</th>
                        <th className="text-left p-2 ">Response by Protean</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="p-2">PAN</td>
                        <td className="p-2">PAN Status</td>
                        <td className="p-2 ">
                          <ul className="list-disc pl-5">
                            <li>Existing & Valid</li>
                            <li>Existing & Valid marked with event</li>
                            <li>Record (PAN) Not Found in ITD Database/Invalid PAN</li>
                            <li>Deleted</li>
                            <li>Deactivated PAN</li>
                            <li>Fake PAN</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-2 ">Name of the PAN holder</td>
                        <td className="p-2 ">Matching status of Name</td>
                        <td className="p-2 ">
                          <ul className="list-disc pl-5">
                            <li>Yes (if matching)</li>
                            <li>No (if not matching)</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-2 ">Date of Birth/Incorporation (DOB/I)</td>
                        <td className="p-2 ">Matching status of DOB/I</td>
                        <td className="p-2 ">
                          <ul className="list-disc pl-5">
                            <li>Yes (if matching)</li>
                            <li>No (if not matching)</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-2"></td>
                        <td className="p-2">Aadhaar-PAN linking status</td>
                        <td className="p-2 ">
                          <ul className="list-disc pl-5">
                            <li>Operative</li>
                            <li>Inoperative</li>
                            <li>Not applicable</li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                }
                {item.panBottomPara && <a className="font-semibold" href={item.panBottomParaUrl}>{item.panBottomPara}</a>}
                {item.isChargeSummary &&
                  <div className="">
                    <h2 className="text-lg mb-4">Charges Summary</h2>
                    <table className="min-w-full border overflow-x-scroll overflow-auto">
                      <thead>
                        <tr className='bg-[#FAD9D5] text-black'>
                          <th className="py-2 px-4 text-left">Minimum payable charges</th>
                          <th className="py-2 px-4 text-right">Screen Based</th>
                          <th className="py-2 px-4 text-right" colSpan="2">File and Screen Based</th>
                          <th className="py-2 px-4 text-right">Software (API) Based</th>
                        </tr>
                      </thead>
                      <tbody className=''>
                        <tr>
                          <td className="py-2 px-4">Annual Registration Charges (₹)</td>
                          <td className="py-2 px-4 text-right">12,000</td>
                          <td className="py-2 px-4 text-right"></td>
                          <td className="py-2 px-4 text-right">12,000</td>
                          <td className="py-2 px-4 text-right">12,000 </td>
                        </tr>
                        <tr>
                          <td className="py-2 px-4">Goods and Services Tax (GST) @18% (₹)</td>
                          <td className="py-2 px-4 text-right">2,160</td>
                          <td className="py-2 px-4 text-right"></td>
                          <td className="py-2 px-4 text-right">2,160</td>
                          <td className="py-2 px-4 text-right">12,000</td>
                        </tr>
                        <tr>
                          <td className="py-2 px-4">Total Registrastion/Annual Charges(₹)</td>
                          <td className="py-2 px-4 text-right">14,160</td>
                          <td className="py-2 px-4 text-right"></td>
                          <td className="py-2 px-4 text-right">14,160</td>
                          <td className="py-2 px-4 text-right">12,000</td>
                        </tr>
                        <tr>
                          <td className="py-2 px-4">Initial Advance (₹) (Inclusive of GST as applicable)</td>
                          <td className="py-2 px-4 text-right">NA</td>
                          <td className="py-2 px-4 text-right"></td>
                          <td className="py-2 px-4 text-right">As desired</td>
                          <td className="py-2 px-4 text-right">As desired</td>
                        </tr>
                        <tr>
                          <td className="py-2 px-4 w-1/3">Free PAN verification per day (Subject to change based on cumulative number of PANs verified in F.Y.)</td>
                          <td className="py-2 px-4 text-right">Upto 750 Only</td>
                          <td className="py-2 px-4 text-black text-right bg-[#FCEBE9]">Screen based</td>
                          <td className="py-2 px-4 text-black text-right bg-[#FCEBE9]">File based</td>
                          <td className="py-2 px-4 text-right">750</td>
                        </tr>
                        <tr>
                          <td className="py-2 px-4"></td>
                          <td className="py-2 px-4 text-right"></td>
                          <td className="py-2 px-4 text-black text-right bg-[#F7F7F7]">750</td>
                          <td className="py-2 px-4 text-black text-right bg-[#F7F7F7]">NA</td>
                          <td className="py-2 px-4 text-right"></td>
                        </tr>
                        <tr>
                          <td className="py-2 px-4">Charges above free PANs verification (₹)</td>
                          <td className="py-2 px-4 text-right">NA</td>
                          <td className="py-2 px-4 text-right"></td>
                          <td className="py-2 px-4 text-right">Above 750 charges will applicable as per Table No 1</td>
                          <td className="py-2 px-4 text-right">Refer Table no. 2</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                }
                {item.isChargeDetails &&
                  <div className="mt-3">
                    <h2 className="text-sm font-bold mb-4">Charges for File based PAN verification</h2>
                    <div className="overflow-x-auto">
                      <table className="min-w-full border">
                        <thead>
                          <tr>
                            <th className="py-2 px-4 text-black bg-[#FAD9D5]" colSpan="5">Table No 1</th>
                          </tr>
                          <tr>
                            <th className="py-2 px-4 text-black bg-[#FBE2DF] w-1/2" rowSpan="2">Count of PANs in the file</th>
                            <th className="py-2 px-4 text-black bg-[#FAD9D5]" colSpan="4">Charges (₹)</th>
                          </tr>
                          <tr className='bg-[#F7F7F7] text-black'>
                            <th className="py-2 px-4">Slab 1</th>
                            <th className="py-2 px-4">Slab 2</th>
                            <th className="py-2 px-4">Slab 3</th>
                            <th className="py-2 px-4">Slab 4</th>
                          </tr>
                        </thead>
                        <tbody className='text-center'>
                          <tr>
                            <td className="py-2 px-4 font-normal"></td>
                            <td className="py-2 px-4 font-bold">@0.30</td>
                            <td className="py-2 px-4 font-bold">@0.25</td>
                            <td className="py-2 px-4 font-bold">@0.15</td>
                            <td className="py-2 px-4 font-bold">@0.10</td>
                          </tr>
                          <tr>
                            <td className="py-2 px-4">1-200</td>
                            <td className="py-2 px-4">60</td>
                            <td className="py-2 px-4">50</td>
                            <td className="py-2 px-4">30</td>
                            <td className="py-2 px-4">10</td>
                          </tr>
                          <tr>
                            <td className="py-2 px-4">201-400</td>
                            <td className="py-2 px-4">120</td>
                            <td className="py-2 px-4">100</td>
                            <td className="py-2 px-4">60</td>
                            <td className="py-2 px-4">20</td>
                          </tr>
                          <tr>
                            <td className="py-2 px-4">401-600</td>
                            <td className="py-2 px-4">180</td>
                            <td className="py-2 px-4">150</td>
                            <td className="py-2 px-4">90</td>
                            <td className="py-2 px-4">30</td>
                          </tr>
                          <tr>
                            <td className="py-2 px-4">601-800</td>
                            <td className="py-2 px-4">240</td>
                            <td className="py-2 px-4">200</td>
                            <td className="py-2 px-4">120</td>
                            <td className="py-2 px-4">40</td>
                          </tr>
                          <tr>
                            <td className="py-2 px-4">801-1000</td>
                            <td className="py-2 px-4">300</td>
                            <td className="py-2 px-4">250</td>
                            <td className="py-2 px-4">150</td>
                            <td className="py-2 px-4">50</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <h1 className='pt-8 text-[22px] leading-5 font-medium'>Charges-Details</h1>
                    <ul className='pt-4 list-disc list-inside text-sm font-normal leading-6' l>
                      <li>The registration charges are ₹12,000 p.a. plus Goods and Services Tax (GST).</li>
                      <li>This facility is provided for one year and the same needs to be renewed on yearly basis.</li>
                      <li>Annual renewal charges are ₹12,000 p.a. plus Goods and Services Tax (GST).</li>
                      <li>PANs verified through file upload under 'File and Screen Based' facility are chargeable.</li>
                      <li>The entity availing 'File and Screen Based' or 'API Based' facility has to maintain sufficient advance against its user ID in order to verify PANs which are more than the free limit of PANs per day.</li>
                      <li>The registration charges are non-refundable. Only in case where the registration is rejected by Income Tax Department, the registration charges will be refunded.</li>
                    </ul>
                    <div className="overflow-x-auto">
                      <table className="mt-8 w-full">
                        <thead>
                          <tr>
                            <th className="py-2 px-4 text-black bg-[#FAD9D5]" colSpan="5">Table No 2</th>
                          </tr>
                          <tr>
                            <th className="py-2 px-4 text-black bg-[#FBE2DF]">Slabs (PANs verified during a financial year)</th>
                            <th className="py-2 px-4 text-black bg-[#F7F7F7]">Rate (paisa)</th>
                            <th className="py-2 px-4 text-black bg-[#F7F7F7]">Maximum Daily Free Limit</th>
                          </tr>
                        </thead>
                        <tbody className='text-center text-black bg-white'>
                          <tr>
                            <td className="p-2">UPTO 7.5 Lakhs</td>
                            <td className="p-2">30</td>
                            <td className="p-2">750</td>
                          </tr>
                          <tr>
                            <td className="p-2">{rangeText1}</td>
                            <td className="p-2">25</td>
                            <td className="p-2">1,000</td>
                          </tr>
                          <tr>
                            <td className="p-2">{rangeText2}</td>
                            <td className="p-2">15</td>
                            <td className="p-2">1,500</td>
                          </tr>
                          <tr>
                            <td className="p-2">above 30 Lakhs</td>
                            <td className="p-2">5</td>
                            <td className="p-2">2,500</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                }
              </>
            }
          </div>
        )}
      </div>
    ));
  };

  const half = Math.ceil(linkData[activeTab].length / 2);
  const leftColumn = linkData[activeTab].slice(0, half);
  const rightColumn = linkData[activeTab].slice(half);

  return (
    <section className="w-full flex flex-col py-4 relative lg:px-0 bg-black ">
      <div className="flex px-[22px] lg:px-[70px] gap-2 items-center w-full">
        <svg
          width="34"
          height="17"
          viewBox="0 0 34 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
            fill="#54AD47"
          />
          <path
            d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
            fill="#54AD47"
          />
          <circle cx="25.5" cy="8.5" r="8.5" fill="#54AD47" />
        </svg>
        <h3 className="font-semibold text-white text-[26px]">FAQ’s</h3>
      </div>

      <div className="lg:px-[70px] flex justify-center flex-col mt-8">
        {/* Mobile Dropdown */}
        <div className="block lg:hidden my-4 px-[22px]">
          <select
            value={activeTab}
            onChange={(e) => handleTabClick(e.target.value)}
            className="w-full p-2 border-[1px] border-[#514B4B] bg-black text-white rounded-md"
          >
            {Object.keys(linkData).map((tab) => (
              <option key={tab} value={tab}>
                {tab}
              </option>
            ))}
          </select>
        </div>

        {/* Desktop Tabs */}
        <div className="hidden lg:flex justify-evenly items-center border-b border-gray-600 mb-4">
          {Object.keys(linkData).map((tab) => (
            <button
              key={tab}
              onClick={() => handleTabClick(tab)}
              className={`text-white py-2 px-2 focus:outline-none ${activeTab === tab ? "border-b-2 border-[#54AD47]" : ""
                }`}
            >
              {tab}
            </button>
          ))}
        </div>

        {/* Content */}
        <div className="flex flex-col lg:flex-row px-[22px] lg:px-[70px] py-4 gap-10">
          <div className="flex-1 ">
            {renderFAQ(leftColumn, 0)}
          </div>
          <div className="flex-1">
            {renderFAQ(rightColumn, half)}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQComponent;
