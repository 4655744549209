import React from "react";
import bannerSvg1 from "../../../assets/images/bannerTopRight.jpg";
import bannerSemi1 from "../../../assets/images/bannerLeftSemi.jpg";

import bannerBg from "../../../assets/images/bgBanner.svg";
import mobileBg from "../../../assets/images/mobileBg.svg";
export default function FirstSection() {
  return (
    <section className="w-full flex flex-col py-10 justify-center items-center md:py-6 relative">
      <div className="absolute bottom-[-1rem] md:bottom-[0px] left-[45%] z-40">
        <a href="/#SecondSection" className="scroll-smooth">
          <svg
            width="66"
            height="67"
            viewBox="0 0 66 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M50.3333 41.3335L35.3817 50.3045C34.326 50.9379 33.0073 50.9379 31.9517 50.3045L17 41.3335"
              stroke="black"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M50.3333 28L35.3817 36.971C34.326 37.6044 33.0073 37.6044 31.9517 36.971L17 28"
              stroke="black"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </a>
      </div>
      <div className="absolute right-0 top-0">
        <img
          src={bannerSvg1}
          className="lg:w-[177px] w-[50px] z-10"
          alt="protean"
        />
      </div>
      <div className="absolute left-0 top-[40%] hidden lg:block">
        <img src={bannerSemi1} className="w-[60px] h-[201px]" alt="protean" />
      </div>
      <div className="w-full justify-center items-center flex flex-col z-20 relative">
        <p>We have Upgraded!</p>
        <p className="font-semibold px-10 lg:px-0 text-center text-[16px] text-[#E43625]">
          Welcome to the new look of the PAN Services Page.
        </p>
      </div>
      <div className="block lg:hidden py-[12px]">
        <img src={mobileBg} alt="protean" />
        <div className="px-[20px] py-[12px] flex flex-col gap-[30px]">
          <h1 className="lg:text-h1-lg text-h1-mobile max-w-[80%] font-semibold">
            Get your PAN in just four simple steps!
          </h1>
          <a
            href="https://www.protean-tinpan.com/services/pan/pan-index.html"
            className="py-[12px] text-base font-medium text-white max-w-[50%] flex items-center justify-between  px-[24px] rounded-[8px] text-center"
          >
            Apply now
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_2429_15128)">
                <path
                  d="M5 12H19"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15 16L19 12"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15 8L19 12"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_2429_15128">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
      </div>
      <div
        className="hidden img-cont min-h-[500px] min-w-full lg:flex flex-col justify-center"
        style={{
          backgroundImage: `url(${bannerBg})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="flex flex-col lg:max-w-[700px] px-[70px] items-start gap-6">
          <h1 className="text-[56px] font-semibold">
            Get your PAN in just four simple steps!
          </h1>
          <a
            href="https://www.onlineservices.nsdl.com/paam/endUserRegisterContact.html"
            type="button"
            className="py-[12px] text-base font-medium text-white min-w-[200px] flex items-center justify-between bg-black px-[24px] rounded-[8px] text-center"
          >
            Apply now
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_2429_15128)">
                <path
                  d="M5 12H19"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15 16L19 12"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15 8L19 12"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_2429_15128">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
      </div>
    </section>
  );
}
