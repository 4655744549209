import React from "react";

import svgBackground1 from "../../../assets/images/TDSSvg1.png";
import svgBackground2 from "../../../assets/images/TDSSvg2.png";

// Assuming svgBackground1 and svgBackground2 are placeholders for actual SVG files

const TDSOverview = () => {
  return (
    <section className="relative  text-black  lg:px-[70px]">
      <div className="flex px-4 lg:px-0    gap-2 items-center w-full">
        <a href="#">
          <svg
            width="34"
            height="17"
            viewBox="0 0 34 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
              fill="#E43625"
            />
            <path
              d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
              fill="#E43625"
            />
            <circle cx="25.5" cy="8.5" r="8.5" fill="#E43625" />
          </svg>
        </a>
        <h3 className="font-semibold text-[26px]">Apply Online</h3>
      </div>
      <div className="relative z-10 mt-8">
        <div className="flex flex-col lg:flex-row gap-10 lg:gap-20">
          {/* e-TDS Section */}
          <div className="lg:w-1/2 bg-[#F1EBF3] py-10 px-10">
            <h2 className="text-2xl font-semibold mb-4">e-TDS</h2>
            <p className="mb-4 text-[15px]">
              Entities (both corporate and non-corporate deductors) making
              payments (specified under Income Tax Act) to third parties
              (deductees) are required to deduct tax at source (Tax Deducted at
              Source - TDS) from these payments and deposit the same at any of
              the designated branches of banks authorized to collect taxes on
              behalf of Government of India. They should also furnish TDS
              returns containing details of deductee(s) and challan details
              relating to deposit of tax to ITD.
            </p>
            <p className="mb-4 text-[15px]">
              As part of automation of collection, compilation and processing of
              TDS returns ITD has notified an "Electronic Filing of Returns of
              Tax Deducted at Source Scheme, 2003". It is applicable to all
              deductors furnishing their TDS return in electronic form. As per
              this scheme:
            </p>
            <ul className="list-disc pl-5 mb-4 text-[15px]">
              <li>
                It is mandatory (w.e.f. June 1, 2003) for corporate deductors to
                furnish their TDS returns in electronic form (e-TDS return).
              </li>
              <li>
                From F.Y. 2004-2005 onwards furnishing TDS returns in electronic
                form is also mandatory for government deductors in addition to
                corporate deductors.
              </li>
              <li>
                Deductors (other than government and corporates) may file TDS
                return in electronic or physical form.
              </li>
              <li>
                Protean e-Governance Infrastructure Limited (Protean) as the
                e-TDS Intermediary (appointed by ITD) receives, on behalf of
                ITD, the e-TDS returns from the deductors.
              </li>
            </ul>
          </div>

          {/* e-TCS Section */}
          <div className="lg:w-1/2 bg-[#F1EBF3] py-10 px-10">
            <h2 className="text-2xl font-semibold mb-4">e-TCS</h2>
            <p className="mb-4 text-[15px]">
              TCS means collection of tax at source by the seller (collector)
              from the buyer (collectee/payer) of the goods (specified u/s 206C
              of Income-tax Act, 1961, like timber obtained under forest lease,
              scrap, any other forest produce not being timber or tendu leaves
              etc.). For e.g., if purchase value of goods is Rs.10,000/-, the
              buyer will pay an amount of Rs.10,000/- + X% (X being the value of
              TCS as prescribed under Income-tax Act, 1961) to the seller. The
              seller will deposit the tax collected at source (TCS) at any of
              the designated branches of the authorised banks.
            </p>
            <p className="mb-4 text-[15px]">
              Following the automation of TCS returns in 2003, ITD has now
              notified an "Electronic Filing of Returns of Tax Collected at
              Source Scheme, 2005". It is applicable to all deductors furnishing
              their TDS return in electronic form. As per this scheme:
            </p>
            <ul className="list-disc pl-5 mb-4 text-[15px]">
              <li>
                It is mandatory for corporate and government deductors to
                furnish their TCS returns in electronic form (e-TCS return) from
                F.Y. 2004-2005 onwards.
              </li>
              <li>
                Deductors (other than government and corporates) may file TCS
                return in electronic or physical form.
              </li>
              <li>
                Protean as the e-TCS Intermediary (appointed by ITD) receives,
                on behalf of ITD, the e-TCS returns from the deductors.
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* Revised Forms Section */}
      <div className="mt-10 px-4 md:px-0">
        <h3 className="text-xl font-semibold mb-4">
          Revised forms and file formats for e-TDS/e-TCS Returns
        </h3>
        <table className="w-full ">
          <thead className="bg-[#B090BF] hidden  lg:table-header-group">
            <tr>
              <th className="px-4 py-2 bg-[#B090BF]">Financial Year</th>
              <th className="px-4 py-2 ">Forms</th>
              <th className="px-4 py-2 bg-[#B090BF]">Periodicity</th>
              <th className="px-4 py-2 bg-[#B090BF]">Remarks</th>
            </tr>
          </thead>
          <tbody className="block lg:table-row-group">
            <tr className="block lg:table-row border border-gray-300 p-4 flex flex-col lg:table-row">
              <td className="px-4 py-2 font-semibold lg:align-top" rowSpan="4">
                <span className="lg:hidden font-semibold">
                  Financial Year:{" "}
                </span>
                2006-07
              </td>
              <td className="px-4 py-2">
                <span className="lg:hidden font-semibold">Forms: </span>
                <a
                  href="https://www.protean-tinpan.com/downloads/e-tds/24Q_04012018.pdf"
                  className="text-[#634B83]"
                >
                  24Q-TDS
                </a>
              </td>
              <td className="px-4 py-2">
                <span className="lg:hidden font-semibold">Periodicity: </span>
                Quarterly
              </td>
              <td className="px-4 py-2">
                <span className="lg:hidden font-semibold">Remarks: </span>
                Corporates, Government
              </td>
            </tr>
            <tr className="block  border border-gray-300 p-4 flex flex-col lg:table-row">
              <td className="px-4 py-2">
                <span className="lg:hidden font-semibold">Forms: </span>
                <a
                  href="https://www.protean-tinpan.com/downloads/e-tds/26Q_04012018.pdf"
                  className="text-[#634B83]"
                >
                  26Q-TDS
                </a>
              </td>
              <td className="px-4 py-2">
                <span className="lg:hidden font-semibold">Periodicity: </span>
                Quarterly
              </td>
              <td className="px-4 py-2">
                <span className="lg:hidden font-semibold">Remarks: </span>
                Departments, Organisations
              </td>
            </tr>
            <tr className="block lg:table-row border border-gray-300 p-4 flex flex-col lg:table-row">
              <td className="px-4 py-2">
                <span className="lg:hidden font-semibold">Forms: </span>
                <a
                  href="https://www.protean-tinpan.com/downloads/e-tds/27Q_04012018.pdf"
                  className="text-[#634B83]"
                >
                  27Q-TDS
                </a>
              </td>
              <td className="px-4 py-2">
                <span className="lg:hidden font-semibold">Periodicity: </span>
                Quarterly
              </td>
              <td className="px-4 py-2">
                <span className="lg:hidden font-semibold">Remarks: </span>
                Mandatory to file in electronic
              </td>
            </tr>
            <tr className="block lg:table-row border border-gray-300 p-4 flex flex-col lg:table-row">
              <td className="px-4 py-2">
                <span className="lg:hidden font-semibold">Forms: </span>
                <a
                  href="https://www.protean-tinpan.com/downloads/e-tds/27EQ_04012018.pdf"
                  className="text-[#634B83]"
                >
                  27EQ-TDS
                </a>
              </td>
              <td className="px-4 py-2">
                <span className="lg:hidden font-semibold">Periodicity: </span>
                Quarterly
              </td>
              <td className="px-4 py-2">
                <span className="lg:hidden font-semibold">Remarks: </span>
                Other deductors - may file in electronic form or in physical
                form
              </td>
            </tr>
            <tr className="block lg:table-row border border-gray-300 p-4">
              <td className="px-4 py-2 font-semibold lg:align-top" colSpan="4">
                <span className="lg:hidden font-semibold">Form 27A: </span>
                <a
                  href="https://www.protean-tinpan.com/downloads/e-tds/Form%2027A_01042015.pdf"
                  className="text-[#634B83]"
                >
                  Form 27A
                </a>{" "}
                to be filed in physical form with each e-TDS/e-TCS return
                submitted through TIN-FC
              </td>
            </tr>
          </tbody>
        </table>

        <p className="text-[14px] font-extralight py-8">
          **e-TDS/e-TCS returns will be filed quarterly for F.Y. 2005-2006.
          However, filing of quarterly e-TDS/TCS returns facility upto FY
          2006-07 has been discontinued from TIN as advised by Income Tax
          Department (ITD). The file formats for quarterly returns (Forms 24Q,
          26Q, 27Q and 27EQ) have been notified by the ITD and have been made
          available on Protean web-site. Deductors furnishing e-TDS/TCS returns
          are required to furnish a control chart - Form 27A in physical form
          along with the e-TDS/TCS return furnished in CD/Pen Drive. Form 27A is
          a summary of e-TDS/TCS return (Form 24Q, 26Q, 27Q & 27EQ), which
          contains control totals of 'Amount Paid', 'Income tax deducted at
          source'& ‘Tax Deposited (Total challan amount)’. The control totals
          mentioned on Form 27A should match with the corresponding control
          totals in e-TDS/TCS return file. Form 27A is required to be furnished
          separately for each e-TDS/TCS return (Form 24Q, 26Q, 27Q & 27EQ).
          Procedures and guidelines for preparing and furnishing e-TDS/e-TCS
          return are given in Deductors Manual available at downloads section.
          With effect from February 1, 2014, it is mandatory to submit Form 27A
          generated by TDS/TCS FVU (File Validation Utility) duly signed, along
          with the TDS/TCS statement(s). Any other Form 27A submitted will be
          treated as invalid submission and the same will be rejected by TIN-FC
          branches. Protean has developed a freely downloadable utility called
          File Validation Utility to verify whether the e-TDS/e-TCS return files
          prepared by the deductors/ collectors conform to the prescribed
          format.
        </p>
      </div>
    </section>
  );
};

export default TDSOverview;
