import React, { useState } from 'react'
import { ArrowUpSvg, DropDownArrowSvg, ThreeDotsBulletSvg } from '../../../assets/svgs'
import DynamicLinks from '../../../components/DynamicLink';

function PanApplication() {
    const isMobile = window.innerWidth <= 768;
    const [isOverviewOpen, setIsOverviewOpen] = useState(isMobile || false)

    const toggleOverview = () => {
        setIsOverviewOpen(!isOverviewOpen)
    }

    const cardDataArray = [
        {
            linkData: [
                {
                    text: "Track Status of your PAN / TAN application online",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                    href: 'https://tin.tin.nsdl.com/pantan/StatusTrack.html'
                },
            ],
        },
    ];

    return (
        <section className="md:px-[68px] py-6 md:py-12">
            <div className='bg-[#6F368B] bg-opacity-10'>
                <div className='bg-[#6F368B] bg-opacity-10 p-8'>
                    <div className='px-4 md:px-0 flex items-center justify-between'>
                        <h1 className='flex flex-row text-[26px] font-semibold gap-2 leading-5'>
                            <ThreeDotsBulletSvg fillColor={'#6F368B'} className={'w-[34px] h-[17px]'} />
                            PAN Application
                        </h1>
                        <button onClick={toggleOverview} className=''>
                            {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                        </button>
                    </div>
                </div>
                {isOverviewOpen && <div className='px-6 pt-6'>
                    <p className="text-lg font-semibold mb-2 md:px-8 md:pt-2">
                        Introduction
                    </p>
                    <p className='text-sm font-normal leading-6 md:px-8 md:pb-6'>
                        Protean dispenses PAN services through a chain of TIN-Facilitation Centres (TIN-FCs) and PAN centres established by Protean across the country. Applications can also be made over internet through an online facility.
                    </p>
                    <p className="text-lg font-semibold mb-2 md:px-8 pt-6 md:pt-2">
                        Types of PAN Application
                    </p>
                    <ul className='text-sm font-normal leading-6 list-inside list-decimal md:px-8'>
                        <li>
                            <span className='font-bold'>Application for allotment of PAN (Form 49 A)</span> This application should be used when the applicant has never applied for a PAN or does not have PAN allotted to him.
                        </li>
                        <li>
                            <span className='font-bold'>Request for New PAN Card or/and Changes or Correction in PAN data</span> This application should be used when PAN has been allotted already to applicant but a new PAN card is required or when data associated with the PAN (e.g. name of applicant / date of birth / address) is required to be updated in the records of Income Tax Department.
                        </li>
                        <li>
                            <span className='font-bold'>Application for allotment of PAN (Form 49AA)</span> - applicable for foreign citizens. This form should be used when the applicant has never applied for a PAN or does not have PAN allotted to him. An applicant can visit Income Tax Department (ITD) website to find whether a PAN has been allotted to him or not.
                        </li>
                    </ul>
                </div>
                }
                {isOverviewOpen &&
                    <div>
                        <div className='md:max-w-[50vw] md:pb-8 p-6'>
                            <p className="text-lg font-semibold mb-2 md:px-8 md:pt-2">
                                A) If Physical PAN Card is required:
                            </p>
                            <p className='text-sm font-normal leading-6 md:px-8 md:pb-2'>
                                While submitting PAN application form, applicant will have to indicate whether physical PAN card is required. If applicant opts for physical PAN Card, then physical PAN card will be printed & dispatched at communication address. The e-PAN card in PDF format will be dispatched at e-mail ID mentioned in PAN application form, if the same is provided. Fees applicable are as follows:-
                            </p>
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                            <table className="table-auto w-full">
                                <thead className='bg-[#6F368B] bg-opacity-20'>
                                    <tr>
                                        <th className="px-4 py-2 text-sm font-semibold leading-6">Sr. No</th>
                                        <th className="px-4 py-2 text-sm font-semibold leading-6">Particulars</th>
                                        <th className="px-4 py-2 text-sm font-semibold leading-6">Fees (exclusive of applicable taxes)(₹)</th>
                                        <th className="px-4 py-2 text-sm font-semibold leading-6">Fees (inclusive of applicable taxes)(₹)</th>
                                    </tr>
                                </thead>
                                <tbody className='bg-[#6F368B] bg-opacity-5'>
                                    <tr>
                                        <td colSpan="4" className="px-4 py-2 text-sm font-semibold leading-6">PAN applications submitted at TIN Facilitation Centres / PAN Centres</td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 py-2">1</td>
                                        <td className="px-4 py-2">Dispatch of physical PAN Card in India (Communication address is Indian address)</td>
                                        <td className="px-4 py-2">91</td>
                                        <td className="px-4 py-2">107</td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 py-2">2</td>
                                        <td className="px-4 py-2">Dispatch of physical PAN Card outside India (where foreign address is provided as address for communication)</td>
                                        <td className="px-4 py-2">862</td>
                                        <td className="px-4 py-2">1,017</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table-auto w-full">
                                <thead className='bg-[#6F368B] bg-opacity-20'>
                                    <tr>
                                        <th className="px-4 py-2 text-sm font-semibold leading-6">Sr. No</th>
                                        <th className="px-4 py-2 text-sm font-semibold leading-6">Particulars</th>
                                        <th className="px-4 py-2 text-sm font-semibold leading-6">Fees (exclusive of applicable taxes)(₹)</th>
                                        <th className="px-4 py-2 text-sm font-semibold leading-6">Fees (inclusive of applicable taxes)(₹)</th>
                                    </tr>
                                </thead>
                                <tbody className='bg-[#6F368B] bg-opacity-5'>
                                    <tr>
                                        <td colSpan="4" className="px-4 py-2 text-sm font-semibold leading-6">PAN applications submitted Online using physical mode (i.e. Physical documents forwarded to Protean.)</td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 py-2">1</td>
                                        <td className="px-4 py-2">Dispatch of physical PAN Card in India (Communication address is Indian address)</td>
                                        <td className="px-4 py-2">91</td>
                                        <td className="px-4 py-2">107</td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 py-2">2</td>
                                        <td className="px-4 py-2">Dispatch of physical PAN Card outside India (where foreign address is provided as address for communication)</td>
                                        <td className="px-4 py-2">862</td>
                                        <td className="px-4 py-2">1,017</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>}
                {isOverviewOpen && <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <table className="table-auto w-full">
                        <thead className='bg-[#6F368B] bg-opacity-20'>
                            <tr>
                                <th className="px-4 py-2 text-sm font-semibold leading-6">Sr. No</th>
                                <th className="px-4 py-2 text-sm font-semibold leading-6">Particulars</th>
                                <th className="px-4 py-2 text-sm font-semibold leading-6">Fees (exclusive of applicable taxes)(₹)</th>
                                <th className="px-4 py-2 text-sm font-semibold leading-6">Fees (inclusive of applicable taxes)(₹)</th>
                            </tr>
                        </thead>
                        <tbody className='bg-[#6F368B] bg-opacity-5'>
                            <tr>
                                <td colSpan="4" className="px-4 py-2 text-sm font-semibold leading-6">PAN applications submitted Online through paperless modes (e-KYC & e-Sign / e-Sign scanned based / DSC scanned based):</td>
                            </tr>
                            <tr>
                                <td className="px-4 py-2">1</td>
                                <td className="px-4 py-2">Dispatch of physical PAN Card in India (Communication address is Indian address)</td>
                                <td className="px-4 py-2">91</td>
                                <td className="px-4 py-2">107</td>
                            </tr>
                            <tr>
                                <td className="px-4 py-2">2</td>
                                <td className="px-4 py-2">Dispatch of physical PAN Card outside India (where foreign address is provided as address for communication)</td>
                                <td className="px-4 py-2">862</td>
                                <td className="px-4 py-2">1,017</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="table-auto w-full">
                        <thead className='bg-[#6F368B] bg-opacity-20'>
                            <tr>
                                <th className="px-4 py-2 text-sm font-semibold leading-6">Sr. No</th>
                                <th className="px-4 py-2 text-sm font-semibold leading-6">Particulars</th>
                                <th className="px-4 py-2 text-sm font-semibold leading-6">Fees (exclusive of applicable taxes)(₹)</th>
                                <th className="px-4 py-2 text-sm font-semibold leading-6">Fees (inclusive of applicable taxes)(₹)</th>
                            </tr>
                        </thead>
                        <tbody className='bg-[#6F368B] bg-opacity-5'>
                            <tr>
                                <td colSpan="4" className="px-4 py-2 text-sm font-semibold leading-6">Request for Reprint of PAN card submitted through separate online link</td>
                            </tr>
                            <tr>
                                <td className="px-4 py-2">1</td>
                                <td className="px-4 py-2">Dispatch of physical PAN Card in India (Communication address is Indian address)</td>
                                <td className="px-4 py-2">42</td>
                                <td className="px-4 py-2">50</td>
                            </tr>
                            <tr>
                                <td className="px-4 py-2">2</td>
                                <td className="px-4 py-2">Dispatch of physical PAN Card outside India (where foreign address is provided as address for communication)</td>
                                <td className="px-4 py-2">813</td>
                                <td className="px-4 py-2">959</td>
                            </tr>
                        </tbody>
                    </table>
                </div>}
                {isOverviewOpen &&
                    <div>
                        <div className='md:max-w-[50vw] md:pb-8 p-6'>
                            <p className="text-lg font-semibold mb-2 md:px-8 md:pt-2">
                                B) If Physical PAN Card is not required:
                            </p>
                            <p className='text-sm font-normal leading-6 md:px-8 md:pb-2'>
                                PAN applicant will have to indicate at the time of submission of PAN application, if the physical PAN Card is not required. In such cases, email ID will be mandatory & e-PAN Card will be sent to the PAN applicant at the email ID. Physical PAN Card will not be dispatched in such cases. Fees applicable are as follows:-
                            </p>
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                            <table className="table-auto w-full">
                                <thead className='bg-[#6F368B] bg-opacity-20'>
                                    <tr>
                                        <th className="px-4 py-2 text-sm font-semibold leading-6">Sr. No</th>
                                        <th className="px-4 py-2 text-sm font-semibold leading-6">Particulars</th>
                                        <th className="px-4 py-2 text-sm font-semibold leading-6">Fees (exclusive of applicable taxes)(₹)</th>
                                        <th className="px-4 py-2 text-sm font-semibold leading-6">Fees (inclusive of applicable taxes)(₹)</th>
                                    </tr>
                                </thead>
                                <tbody className='bg-[#6F368B] bg-opacity-5'>
                                    <tr>
                                        <td colSpan="4" className="px-4 py-2 text-sm font-semibold leading-6">PAN applications submitted at TIN Facilitation Centres / PAN Centres</td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 py-2">1</td>
                                        <td className="px-4 py-2">Dispatch of physical PAN Card in India (Communication address is Indian address)</td>
                                        <td className="px-4 py-2">91</td>
                                        <td className="px-4 py-2">107</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table-auto w-full">
                                <thead className='bg-[#6F368B] bg-opacity-20'>
                                    <tr>
                                        <th className="px-4 py-2 text-sm font-semibold leading-6">Sr. No</th>
                                        <th className="px-4 py-2 text-sm font-semibold leading-6">Particulars</th>
                                        <th className="px-4 py-2 text-sm font-semibold leading-6">Fees (exclusive of applicable taxes)(₹)</th>
                                        <th className="px-4 py-2 text-sm font-semibold leading-6">Fees (inclusive of applicable taxes)(₹)</th>
                                    </tr>
                                </thead>
                                <tbody className='bg-[#6F368B] bg-opacity-5'>
                                    <tr>
                                        <td colSpan="4" className="px-4 py-2 text-sm font-semibold leading-6">PAN applications submitted Online using physical mode (i.e. Physical documents forwarded to Protean.)</td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 py-2">1</td>
                                        <td className="px-4 py-2">Dispatch of physical PAN Card in India (Communication address is Indian address)</td>
                                        <td className="px-4 py-2">91</td>
                                        <td className="px-4 py-2">107</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>}
                {isOverviewOpen && <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <table className="table-auto w-full">
                        <thead className='bg-[#6F368B] bg-opacity-20'>
                            <tr>
                                <th className="px-4 py-2 text-sm font-semibold leading-6">Sr. No</th>
                                <th className="px-4 py-2 text-sm font-semibold leading-6">Particulars</th>
                                <th className="px-4 py-2 text-sm font-semibold leading-6">Fees (exclusive of applicable taxes)(₹)</th>
                                <th className="px-4 py-2 text-sm font-semibold leading-6">Fees (inclusive of applicable taxes)(₹)</th>
                            </tr>
                        </thead>
                        <tbody className='bg-[#6F368B] bg-opacity-5'>
                            <tr>
                                <td colSpan="4" className="px-4 py-2 text-sm font-semibold leading-6">PAN applications submitted Online through paperless modes (e-KYC & e-Sign / e-Sign scanned based / DSC scanned based):</td>
                            </tr>
                            <tr>
                                <td className="px-4 py-2">1</td>
                                <td className="px-4 py-2">Dispatch of physical PAN Card in India (Communication address is Indian address)</td>
                                <td className="px-4 py-2">91</td>
                                <td className="px-4 py-2">107</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="p-8 bg-[#54AD47] bg-opacity-10">
                        <div className='px-4 md:px-0 flex items-center justify-between pb-6'>
                            <h1 className='flex flex-row text-[26px] font-semibold gap-2 leading-5'>
                                <ThreeDotsBulletSvg fillColor={'#54AD47'} className={'w-[34px] h-[17px]'} />
                                How Soon a PAN Card is Dispatched
                            </h1>
                        </div>
                        <p className='text-sm leading-6 font-normal'>Normally two weeks are required to process the application & dispatch the PAN card, provided application is in order in all respects.</p>
                    </div>
                </div>}
                {isOverviewOpen && <div className='bg-[#EAE2EE] p-8 md:mx-8 m-6'>
                    <p className="text-lg font-semibold mb-6">
                        Introduction
                    </p>
                    <p className='text-sm font-normal leading-6'>
                        The applicants may track the status of their TAN application using 14 digit unique Acknowledgment Number after three days of application using the status track facility. Alternatively, applicant may call TIN Call Centre on 020 – 2721 8080 to enquire about the status of application. The status of the TAN application can also be tracked by sending an SMS - NSDLTAN to 57575.
                    </p>
                    <div className="flex flex-col md:flex-row pb-20 md:pb-0 pt-4">
                        {cardDataArray.map((cardData, index) => (
                            <DynamicLinks key={index} linkData={cardData.linkData} listDir='flex-row' className={"text-black flex-col md:flex-row md:items-end gap-5 px-2 md:px-0 md:gap-[96px]"} titleColor='black' />
                        ))}
                    </div>
                </div>}
            </div>
        </section>
    )
}

export default PanApplication