import React from 'react';
import { Element } from 'react-scroll';
import SearchHeroSection from '../../components/SearchHerosection';
import SearchTabsForms from './components/SearchTabForms';
import withScrollToHash from '../../hooks/useScrollToHash';
import { Helmet } from 'react-helmet';
const Link8 = () => {
  return (
    <>
      <Helmet>
        <title>Find PAN Center: PAN Service Centers</title>
        <meta name="description" content="Locate your nearest PAN center for application, change or correction, and other PAN services. Access PAN service centers nationwide." />
      </Helmet>
      <div className='flex flex-col gap-8'>
        <SearchHeroSection />
        <Element name="search-tabs-forms">
          <SearchTabsForms />
        </Element>
      </div>
    </>
  );
}

export default withScrollToHash(Link8); // Use the HOC
