// src/components/FormStateCity.jsx
import React, { useState, useEffect } from "react";
import { RefreshSvg } from "../assets/svgs";
import { getStates, getCities, getPincodes } from "../data/data";
import Recaptcha from "./ReCaptcha";

const FormStateCity = ({ onSubmit }) => {
  const [state, setState] = useState("");
  const [location, setLocation] = useState("");
  const [pincode, setPincode] = useState("");
  const [captchaCode, setCaptchaCode] = useState("");
  const [enterCaptchaCode, setEnterCaptchaCode] = useState("");

  const states = getStates();
  const locations = state ? getCities(state) : [];
  const pincodes = state && location ? getPincodes(state, location) : [];

  const generateCaptcha = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    setCaptchaCode(result);
  };

  useEffect(() => {
    generateCaptcha();
  }, []);

  const refreshCaptcha = () => {
    setCaptchaCode(generateCaptcha());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ state, location, pincode });
    // if (captchaCode === enterCaptchaCode) {
    //   onSubmit({ state, location, pincode, enterCaptchaCode });
    // } else {
    //   alert("Captcha code does not match. Please try again.");
    //   setEnterCaptchaCode("");
    //   refreshCaptcha();
    // }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="space-y-4 flex flex-col justify-between p-4"
      >
        <div className="flex flex-col gap-8 justify-between">
          <div className="flex flex-col gap-6">
            <div className="flex flex-wrap gap-4">
              <div className="w-full md:w-1/3">
                <label className="block mb-2">
                  Choose State/Union Territory
                </label>
                <select
                  value={state}
                  onChange={(e) => {
                    setState(e.target.value);
                    setLocation("");
                    setPincode("");
                  }}
                  className="w-full p-2 border rounded"
                  required
                >
                  <option value="">Select State</option>
                  {states.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-full md:w-1/3">
                <label className="block mb-2">Choose Location</label>
                <select
                  value={location}
                  onChange={(e) => {
                    setLocation(e.target.value);
                    setPincode("");
                  }}
                  className="w-full p-2 border rounded"
                  required
                  disabled={!state}
                >
                  <option value="">Select Location</option>
                  {locations.map((location, index) => (
                    <option key={index} value={location}>
                      {location}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-full md:w-1/3">
                <label className="block mb-2">Choose Pincode</label>
                <select
                  value={pincode}
                  onChange={(e) => setPincode(e.target.value)}
                  className="w-full p-2 border rounded"
                  required
                  disabled={!location}
                >
                  <option value="">Select Pincode</option>
                  {pincodes.map((pincode, index) => (
                    <option key={index} value={pincode}>
                      {pincode}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* <div className="flex flex-wrap gap-10 w-full">
              <div className="lg:min-w-[307px]">
                <label className="block mb-2">Captcha Code</label>
                <div className="flex justify-between border">
                  <p className="py-2 px-4 bg-gray-300">{captchaCode}</p>
                  <button
                    type="button"
                    className="flex justify-center items-center"
                    onClick={refreshCaptcha}
                  >
                    <RefreshSvg />
                  </button>
                  <Recaptcha onChange={setCaptchaCode} />
                </div>
              </div>
              <div className="lg:min-w-[307px]">
                <label className="block mb-2">Enter Captcha Code</label>
                <input
                  type="text"
                  value={enterCaptchaCode}
                  onChange={(e) => setEnterCaptchaCode(e.target.value)}
                  className="p-2 border lg:min-w-[307px] rounded"
                  placeholder="Enter code"
                />
              </div>
            </div> */}
          </div>
          <div className="border-t-[1px] border-t-[#EBEBEB] w-full flex justify-end items-center min-h-[80px]">
            <button
              type="submit"
              className="p-2 px-8 bg-green-500 text-white rounded mt-4"
            >
              Search
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormStateCity;
