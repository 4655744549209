import React from 'react';
import { BlueSemiCircle, DotGrid } from "../assets/svgs";
import tdsImage from "../assets/images/tdsHeroSection.png";
import tdsImageSm from "../assets/images/tdsHeroSectionSm.png";

const TDSHeroSection = ({ isDotGrid = true, isBlueSemiCircle = true }) => {
    return (
        <div className="bg-[#F7F7F7] lg:max-h-[80vh] justify-between flex-col h-full flex md:flex-row">
            <img src={tdsImageSm} alt="PAN Hand" className="flex md:hidden" />
            <div className="relative px-4 md:px-0 flex flex-col md:self-center">
                <div className='md:pl-[68px] flex flex-col pb-[10px] md:pb-[87px]'>
                    <h1 className="font-semibold text-[#1D1D1B] text-[32px] leading-[43.58px] md:text-[56px] md:leading-[68px] md:max-w-[550px] md:pl-10 pt-[30px] md:pt-0">
                        e-TDS / e-TCS
                    </h1>
                    <p className="text-[#1D1D1B] font-normal text-[14px] leading-[18.2px] md:text-sm md:leading-[24px] md:max-w-[550px] md:pl-10">
                        e-Tax deducted at source/ e-Tax collected at source
                    </p>
                </div>
                <div className="self-start mb-[66px] md:mb-0 mt-8 md:mt-0">
                    {isDotGrid && <DotGrid fillColor="#6F368B" />}
                    {isBlueSemiCircle && <BlueSemiCircle className="absolute h-[100px] md:h-[169px] bottom-[25%] md:bottom-4 right-[10%] md:-right-[10%] max-w-[195px] md:max-w-[280px]" />}
                </div>
            </div>
            <img src={tdsImage} alt="PAN Hand" className="  md:max-w-[500px]    lg:max-w-[600px] aspect-square hidden md:flex" />
        </div>
    );
}

export default TDSHeroSection;