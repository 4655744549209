import React from 'react'
import { ThreeDotsBulletSvg } from '../../../assets/svgs'

function Overview() {
    return (
        <div className='flex gap-6 md:px-[68px] pt-12'>
            <div className='flex flex-col p-8 bg-[#54AD47] bg-opacity-10 w-full'>
                <div className='px-4 md:px-0 flex items-center justify-between pb-6'>
                    <h1 className='flex flex-row text-[26px] font-semibold gap-2 leading-5'>
                        <ThreeDotsBulletSvg fillColor={'#54AD47'} className={'w-[34px] h-[17px]'} />
                        Overview
                    </h1>
                </div>
                <p className='text-sm leading-[24px]'>
                    This simple step by step guided tour will help you to avail various services viz. PAN application, TAN application and e-Tax payment, under Tax Information Network.
                </p>
            </div>
        </div>
    )
}

export default Overview