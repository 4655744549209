import React from "react";
import DynamicList from "./DynamicLists";
import DynamicLinks from "./DynamicLink";

const Card = ({
  cardBackgroundColor,
  linkData,
  svgColor,
  title,
  titleColor,
  listData,
  maxWidth,
  minWidth,
  maxHeight,
  minHeight,
  defaultLinkMinWidth = "auto", // Default values for links
  defaultLinkTextColor = "white",
  defaultLinkIconColor = "white",
  defaultLinkBorderBottom = "1px solid white",
  className = "",
  style = {}, // Additional style prop
}) => {
  const cardStyle = {
    backgroundColor: cardBackgroundColor,
    maxWidth: maxWidth,
    minWidth: minWidth,
    maxHeight: maxHeight,
    minHeight: minHeight,
    ...style, // Merging additional styles
  };

  return (
    <div style={cardStyle} className={`card flex flex-col ${className || 'flex-col gap-4'}`}>
      <div className="title flex items-center gap-2">
        <svg
          width="34"
          height="17"
          viewBox="0 0 34 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
            fill={svgColor}
          />
          <path
            d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
            fill={svgColor}
          />
          <circle cx="25.5" cy="8.5" r="8.5" fill={svgColor} />
        </svg>
        <h2 className="text-[26px]" style={{ color: titleColor || "white" }}>
          {title}
        </h2>
      </div>
      <div className="flex flex-col lg:flex-row">
        {listData?.length > 0 && <DynamicList listData={listData} />}
        {linkData?.length > 0 && (
          <DynamicLinks
            linkData={linkData}
            defaultLinkMinWidth={defaultLinkMinWidth}
            defaultLinkTextColor={defaultLinkTextColor}
            defaultLinkIconColor={defaultLinkIconColor}
            defaultLinkBorderBottom={defaultLinkBorderBottom}
            className={className}
          />
        )}
      </div>
    </div>
  );
};

export default Card;