import React from "react";

const DynamicLinks = ({
  linkData,
  defaultLinkMinWidth = "auto",
  defaultLinkTextColor = "white",
  defaultLinkIconColor = "white",
  defaultLinkBorderBottom = "1px solid white",
  listDir = "flex-col",
  className,
  linkStyle = {}, // Additional style for each link
  linkClassName = "", // Additional className for each link
}) => {
  const getResponsiveStyles = (link) => {
    const isMobile = window.innerWidth <= 768;
    return {
      borderLeft: link.borderLeft,
      borderBottom: link.borderLeft
        ? ""
        : link.borderBottom || defaultLinkBorderBottom,
      background: link.bg,
      maxWidth: isMobile ? link.linkMaxWidthMobile : link.maxWidth || "auto",
      minWidth: isMobile
        ? link.linkMinWidthMobile
        : link.minWidth || defaultLinkMinWidth,
      padding: link.padding || "12px 0px 12px 0px",
      ...linkStyle,
    };
  };

  return (
    <ul className={`flex flex-col lg:${listDir} ${className || 'gap-5'}`}>
      {linkData.map((link, index) => (
        <li key={index} style={{ gridRow: link.gridRow }}>
          <a href={link.href} className="block cursor-pointer">
            <div
              style={getResponsiveStyles(link)}
              className={`flex justify-between items-center py-[12px] px-[10px] ${linkClassName}`}
            >
              <p
                className="text-[15px] flex-1"
                style={{
                  maxWidth: link.maxWidth || "100%",
                  color: link.textColor || defaultLinkTextColor,
                }}
              >
                {link.text}
              </p>
              <svg
                className="ml-2"
                width={link.iconWidth || 16}
                height={link.iconHeight || 16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ color: link.iconColor || defaultLinkIconColor }}
              >
                <path
                  d="M12.9495 3.05078L3.04997 12.9503"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.9495 8.70764V3.05078"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.29291 3.05108H12.9498"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </a>
        </li>
      ))}
    </ul>
  );
};

export default DynamicLinks;
