import React from 'react';
import { OverViewSvg2 } from '../../../assets/svgs';

const ServiceDescription = () => {
  return (
    <div className=" relative">
      <OverViewSvg2 className={"absolute top-[15%] right-[-1rem] max-w-[43px] hidden md:flex"} strokeColor={"green"} />
      <div className="flex items-center w-full gap-2">
        <svg
          width="34"
          height="17"
          viewBox="0 0 34 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
            fill={'#EE7F25'}
          />
          <path
            d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
            fill={'#EE7F25'}
          />
          <circle cx="25.5" cy="8.5" r="8.5" fill={'#EE7F25'} />
        </svg>
        <h2 className="text-lg md:text-[26px] font-semibold flex items-center">
          Service
        </h2>
      </div>
      <p className="mb-4 font-semibold text-sm md:text-[15px]">This service has three modes of verification:</p>
      <ul className="list-disc pl-5 mb-4">
        <li className='text-sm md:text-[15px]'>Screen based verification</li>
        <li className='text-sm md:text-[15px]'>File and Screen based Verification</li>
        <li className='text-sm md:text-[15px]'>Software (API) based verification</li>
      </ul>
      <div className="mb-4">
        <h3 className="font-semibold mb-2">(1) Screen based verification:</h3>
        <p className='text-sm md:text-[15px]'>
          The users, after login, can furnish up to a maximum of five PANs in the screen provided.
          The PANs may be entered in the boxes provided in the screen and then submitted. The
          response giving details of PAN will be displayed in the response screen.
        </p>
      </div>
      <div className="mb-4">
        <h3 className="font-semibold mb-2">(2) File and Screen based Verification:</h3>
        <p className='text-sm md:text-[15px]'>
          The users, after login, can upload a file containing maximum of 1000 PANs (file structure
          as specified by Protean). The site will make available, within 24 hours, a response file
          containing details of the PANs. In case, file format is incorrect, the system will convey
          the rejection within 24 hours. The entity can download the response at his convenience up
          to 15 days.
        </p>
      </div>
      <div>
        <h3 className="font-semibold mb-2">(3) Software (API) based PAN verification:</h3>
        <p className='text-sm md:text-[15px]'>
          The facility is an interface that allows the user to make an online verification of PAN by
          accessing the verification-site through a software application.
        </p>
      </div>
    </div>
  );
};

export default ServiceDescription;