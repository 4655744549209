import React from 'react';
import { Element } from 'react-scroll';
import QuarterlyReturns from './components/QuarterlyReturns';
import SecondSection from '../Link6/components/SecondSection';
import TDSHeroSection from '../../components/TDSHeroSection';
import withScrollToHash from '../../hooks/useScrollToHash';
import { Helmet } from 'react-helmet';

function Link10() {
    return (
        <>
            <Helmet>
                <title>e-TDS/TCS File Validation Utility</title>
                <meta name="description" content="Detail information on e-TDS/TCS services for file validation utility for quarterly returns" />
                <meta name="keywords" content="E tds, e tds return, e tds filling, e tds return, e tds payment, online, income tax tds online payment, tds payment online payment, tds tcs online payment, tds e payment online, tds e payment" />
            </Helmet>
            <div className="flex flex-col gap-8">
                <TDSHeroSection />
                <Element name="second-section">
                    <SecondSection />
                </Element>
                <Element name="quarterly-returns">
                    <QuarterlyReturns />
                </Element>
            </div>
        </>
    );
}

export default withScrollToHash(Link10); // Use the HOC
