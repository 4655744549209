import React from "react";
import { GreenVector } from "../../../assets/svgs";
const EPayment = () => {
  return (
    <section className="py-[30px] lg:px-[70px] md:py-12 flex flex-col gap-8">
      <div className="bg-[#E7F1FB] p-4 relative">
        <GreenVector className={"absolute right-0 top-0"} />
        <div className="flex items-center gap-2 w-full mb-4 ">
          <svg
            width="34"
            height="17"
            viewBox="0 0 34 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
              fill="#4D96F2"
            />
            <path
              d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
              fill="#4D96F2"
            />
            <circle cx="25.5" cy="8.5" r="8.5" fill="#4D96F2" />
          </svg>
          <h3 className="font-semibold text-xl">
            e-payment : Pay Taxes Online
          </h3>
        </div>
        <p className="font-medium">
          Direct tax payments facility have been migrated from OLTAS 'e-payment:
          Pay Taxes Online' to e-Pay Tax facility of E-Filing portal. Users are
          advised to navigate to 'e-Pay Tax' portal of Income Tax Department on{" "}
          <a
            href="https://www.incometax.gov.in/"
            className="underline text-blue-500"
          >
            https://www.incometax.gov.in/
          </a>{" "}
          to make direct tax payments.
        </p>
      </div>
    </section>
  );
};

export default EPayment;
