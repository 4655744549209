import React from "react";
import { PolicyVector } from "../../../assets/svgs";

const Policy = () => {
  return (
    <section className="flex flex-col w-full  px-4   lg:px-[60px]  justify-center relative">
      <div className="absolute top-20  hidden lg:block">
        <PolicyVector />
      </div>

      <div className="flex flex-col w-full justify-center lg:items-center">
        <div className="flex justify-start items-start lg:min-w-[818px]">
          <p className="text-[26px] text-black text-left flex items-center justify-start gap-2">
            <svg
              width="34"
              height="17"
              viewBox="0 0 34 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
                fill="#54AD47"
              />
              <path
                d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
                fill="#54AD47"
              />
              <circle cx="25.5" cy="8.5" r="8.5" fill="#54AD47" />
            </svg>
            Privacy Policy
          </p>
        </div>

        <div className="lg:max-w-[818px]  mt-4">
          <p className="text-[15px]">
            Protean eGov Technologies Ltd. is sensitive to privacy issues on the
            Internet. It is therefore important for you to know how the
            information we receive from you on the Internet is treated.
            <br /> Protean eGov Technologies Ltd. respects your privacy and
            recognizes the need to protect the personally identifiable
            information i.e. any information by which you can be identified,
            such as, name, address, telephone number, etc.) (“Personal
            Information”) you share with us. We would like to assure you that we
            follow appropriate standards when it comes to protecting your
            privacy on our website.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Policy;
