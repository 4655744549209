import React from 'react';
import { OverViewSvg4 } from '../../../assets/svgs';
const ResponseToUser = () => {
  return (
    <div className="px-4 bg-[#f6f0f9] w-full  lg:min-h-[1000px] mt-4 md:mt-0">
        <div className="flex items-center w-full gap-2">
        <svg
          width="34"
          height="17"
          viewBox="0 0 34 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 8.5C0 13.1944 3.80558 17 8.5 17V0C3.80558 0 0 3.80558 0 8.5Z"
            fill={'#6F368B'}
          />
          <path
            d="M8.5 8.5C8.5 13.1944 12.3056 17 17 17V0C12.3056 0 8.5 3.80558 8.5 8.5Z"
            fill={'#6F368B'}
          />
          <circle cx="25.5" cy="8.5" r="8.5" fill={'#6F368B'} />
        </svg>
        <h2 className="text-[26px] font-semibold flex items-center">
   Response to the user
      </h2>
        </div>
    
      <table className="w-full mt-4 bg-[#fefefe] relative ">
        <OverViewSvg4  className={'absolute right-0 top-[40%] hidden md:block' }/>
        <thead>
          <tr className="bg-[#fefefe]">
            <th className="text-left p-2 ">PAN Being verified</th>
            <th className="text-left p-2 ">Details/response provided in PAN Verification</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="p-2">Valid pan</td>
            <td className="p-2 ">
              <ul className="list-disc pl-5">
                <li>PAN status</li>
                <li>Name of the PAN holder</li>
                <li>Last date of PAN update</li>
                <li>*Name printed on PAN card</li>
                <li>*Aadhaar PAN linking Status</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="p-2 ">Valid PAN with events such as merger, acquisition etc.</td>
            <td className="p-2 ">
              <ul className="list-disc pl-5">
                <li>PAN status along with event</li>
                <li>Name of the PAN holder</li>
                <li>Last date of PAN update</li>
                <li>*Name printed on PAN card</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="p-2 ">Deleted or Deactivated</td>
            <td className="p-2 ">
              Only status will be shown as "Deleted" or "Marked as Deactivated" and no other details will be displayed
            </td>
          </tr>
          <tr>
            <td className="p-2">Fake PAN</td>
            <td className="p-2 ">
              Only status will be shown as "Marked as Fake" and no other details will be displayed
            </td>
          </tr>
          <tr>
            <td className="p-2 ">PAN not found in database</td>
            <td className="p-2 ">
              The message "not present in ITD database" will be displayed. In such cases, if the user is able to provide a proof of this PAN (copy of PAN card or PAN allotment letter), Protean will forward the same to ITD for investigation.
            </td>
          </tr>
        </tbody>
      </table>
      <p className="text-sm mt-2">
        For PANs being verified by Department of Commercial Taxes (DCT), additional fields such as Father’s name and Date of Birth will also be provided in the response.
      </p>
      <p className="text-sm mt-2">
        * Name on PAN card field will reflect as per details present in ITD database. In case name on PAN card field is blank it means the name on PAN card field is not available at ITD database.
      </p>
      <p className="text-sm mt-2">
        * Aadhaar PAN linking Status will be displayed only for PANs having status as “Existing and Valid”.
      </p>
    </div>
  );
};

export default ResponseToUser;