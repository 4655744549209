// Acknowledgment.js
import React from "react";
import Link12 from "../../Link12";
import { Link11Svg2 } from "../../../assets/svgs";

function Acknowledgment({ language }) {
  const acknowledgmentContent = {
    en: [
      "A 15-digit unique acknowledgment number",
      "Category of applicant",
      "Name of applicant",
      "Father's Name (in case of 'Individual')",
      "Date of Birth/Incorporation/Agreement/Partnership or Trust Deed/Formation of Body of Individuals/Association of Persons",
      "Address for Communication",
      "Representative Assessee’s name and address",
      "Space for Photograph (in case of 'Individual')",
      "Payment Details",
      "Space for Signature",
      "Aadhaar No.",
      "Details of Proof of Identity and Address",
      "Applicant shall save and print this acknowledgment.",
      "'Individual' applicants making 'Application for allotment of new PAN' should affix two recent colour photographs (3.5 cm x 2.5 cm) in the space provided in the acknowledgment. The photograph should not be stapled or clipped to the acknowledgment. (The clarity of the image on PAN card will depend on the quality and clarity of the photograph affixed on the form).",
      "Signature / Left hand thumb impression should be provided across the photo affixed on the left side of the acknowledgment in such a manner that portion of signature/impression is on photo as well as on acknowledgment receipt.",
      "The signature should not be on the photograph affixed on right side of the acknowledgment. If there is any mark on this photograph such that it hinders the clear visibility of the face of the applicant, the application will not be accepted.",
      "Signature / left thumb impression should only be within the box provided in the acknowledgment. The signature should not be on the photograph. If there is any mark on the photograph such that it hinders the clear visibility of the face of the applicant, the application will not be accepted.",
      "Thumb impression, if used, should be attested by a Magistrate or a Notary Public or Gazetted Officer, under official seal and stamp.",
    ],
    hi: [
      "15-अंकों की एक अनूठी स्वीकृति संख्या",
      "आवेदक की श्रेणी",
      "आवेदक का नाम",
      "पिता का नाम (यदि 'व्यक्ति' के मामले में)",
      "जन्म तिथि / निगमन / समझौता / साझेदारी या ट्रस्ट डीड / व्यक्तियों की संस्था / व्यक्तियों के संघ का गठन",
      "संचार के लिए पता",
      "प्रतिनिधि निर्धारक का नाम और पता",
      "फोटो के लिए स्थान (यदि 'व्यक्ति' के मामले में)",
      "भुगतान विवरण",
      "हस्ताक्षर के लिए स्थान",
      "आधार नंबर",
      "पहचान और पते के प्रमाण का विवरण",
      "आवेदक इस स्वीकृति को सहेजें और प्रिंट करें।",
      "'व्यक्ति' आवेदक जो 'नए पैन के आवंटन के लिए आवेदन' कर रहे हैं, उन्हें स्वीकृति में प्रदान किए गए स्थान में दो हालिया रंगीन तस्वीरें (3.5 सेमी x 2.5 सेमी) चिपकानी चाहिए। तस्वीर को स्वीकृति पर स्टेपल या क्लिप नहीं करना चाहिए। (पैन कार्ड पर छवि की स्पष्टता फॉर्म पर चिपकाई गई तस्वीर की गुणवत्ता और स्पष्टता पर निर्भर करेगी)।",
      "हस्ताक्षर / बाएं हाथ का अंगूठे का निशान स्वीकृति की बाईं ओर चिपकाई गई तस्वीर पर इस तरह प्रदान किया जाना चाहिए कि हस्ताक्षर/निशान का एक हिस्सा तस्वीर पर और स्वीकृति रसीद पर भी हो।",
      "हस्ताक्षर स्वीकृति की दाईं ओर चिपकाई गई तस्वीर पर नहीं होना चाहिए। यदि इस तस्वीर पर ऐसा कोई निशान है जिससे आवेदक के चेहरे की स्पष्टता बाधित होती है, तो आवेदन स्वीकार नहीं किया जाएगा।",
      "हस्ताक्षर / बाएं हाथ का अंगूठे का निशान केवल स्वीकृति में प्रदान किए गए बॉक्स के भीतर होना चाहिए। हस्ताक्षर तस्वीर पर नहीं होना चाहिए। यदि तस्वीर पर ऐसा कोई निशान है जिससे आवेदक के चेहरे की स्पष्टता बाधित होती है, तो आवेदन स्वीकार नहीं किया जाएगा।",
      "यदि अंगूठे का निशान का उपयोग किया जाता है, तो इसे मजिस्ट्रेट या नोटरी पब्लिक या राजपत्रित अधिकारी द्वारा, आधिकारिक सील और मुहर के तहत सत्यापित किया जाना चाहिए।",
    ],
  };

  const content = acknowledgmentContent[language];

  return (
    <div className="p-4 bg-[#EAF4FA] relative min-h-[400px]">
      <Link11Svg2 className={"absolute top-[40%] right-0"} />
      <div className="flex items-center w-full gap-2">
        <h2 className="text-[26px] font-semibold flex items-center">
          {language === "en" ? "2. Acknowledgment" : "2. स्वीकृति"}
        </h2>
      </div>
      <ul className="list-decimal ml-5 space-y-2 text-[15px] mt-4">
        {content.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
}

export default Acknowledgment;
