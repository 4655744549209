import React from 'react'
import DynamicLinks from '../../../components/DynamicLink';
import { FormSectionSvg } from '../../../assets/svgs';

function FormSection() {
    const cardDataArray = [
        {
            linkData: [
                {
                    text: "Form 24Q'",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    textColor: 'black',
                    href: "https://www.protean-tinpan.com/downloads/e-tds/download/24Q_04012018.pdf",
                },
                {
                    text: "Form 26Q",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    textColor: 'black',
                    href: "https://www.protean-tinpan.com/downloads/e-tds/download/26Q_04012018.pdf",
                },
                {
                    text: "Form 27Q",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    textColor: 'black',
                    href: "https://www.protean-tinpan.com/downloads/e-tds/download/27Q_04012018.pdf",
                },
            ],
        },
    ];

    const cardDataArray2 = [
        {
            linkData: [
                {
                    text: "Form 27EQ",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    textColor: 'black',
                    href: "https://www.protean-tinpan.com/downloads/e-tds/download/27EQ_04012018.pdf",
                },
                {
                    text: "Form 27A",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    textColor: 'black',
                    href: "https://www.protean-tinpan.com/downloads/e-tds/download/Form%2027A_01042015.pdf",
                },
            ],
        },
    ];

    return (
        <div className='pb-3 md:pb-12 md:px-[68px]'>
            <div className='bg-[#EEF7ED] p-8 flex flex-col relative'>
                <h2 className='text-base font-semibold'>Forms</h2>
                <div className='flex flex-col'>
                    <div className="md:pb-6 pt-4">
                        {cardDataArray.map((cardData, index) => (
                            <DynamicLinks key={index} linkData={cardData.linkData} listDir='flex-row' className={"text-black flex-col md:flex-row md:items-end gap-5 px-2 md:px-0 md:gap-6"} titleColor='black' />
                        ))}
                    </div>
                    <div className="pb-20 md:pb-0 pt-4">
                        {cardDataArray2.map((cardData, index) => (
                            <DynamicLinks key={index} linkData={cardData.linkData} listDir='flex-row' className={"text-black flex-col md:flex-row md:items-end gap-5 px-2 md:px-0 md:gap-6 w-full md:w-[60%]"} titleColor='black' />
                        ))}
                    </div>
                </div>
                <FormSectionSvg className={'absolute bottom-0 right-0'} />
            </div>
        </div>
    )
}

export default FormSection