import React, { useState } from 'react'
import { ArrowUpSvg, BlueVectorSvg, DropDownArrowSvg, RedVectorSvg, ThreeDotsBulletSvg } from '../../../assets/svgs'

function Overview() {
    const isMobile = window.innerWidth <= 768;
    const [isOverviewOpen, setIsOverviewOpen] = useState(isMobile || true)

    const toggleOverview = () => {
        setIsOverviewOpen(!isOverviewOpen)
    }

    return (
        <div className='md:px-[68px] py-12 flex flex-col gap-6'>
            <div className='px-4 md:px-0 flex items-center justify-between pb-6'>
                <h1 className='flex flex-row text-[26px] font-semibold gap-2 leading-5'>
                    <ThreeDotsBulletSvg fillColor={'#6F368B'} className={'w-[34px] h-[17px]'} />
                    TDS/e-TCS Overview
                </h1>
                {isMobile && <button onClick={toggleOverview} className=''>
                    {!isOverviewOpen ? <ArrowUpSvg /> : <DropDownArrowSvg />}
                </button>}
            </div>
            {isOverviewOpen && <div className='flex flex-col md:flex-row items-stretch gap-6 justify-between'>
                <div className='bg-[#6F368B1A] p-8 bg-opacity-10'>
                    <h1 className='font-semibold text-lg pb-4'>e-TDS</h1>
                    <div className='pb-4 text-sm leading-6'>
                        <p>Entities (both corporate and non-corporate deductors) making payments (specified under Income Tax Act) to third parties (deductees) are required to deduct tax at source (Tax Deducted at Source -TDS) from these payments and deposit the same at any of the designated branches of banks authorised to collect taxes on behalf of Government of India. They should also furnish TDS returns containing details of deductee(s) and challan details relating to deposit of tax to ITD.</p>
                        <p>As a part of automation of collection, compilation and processing of TDS returns ITD has notified an <a href='#' className='text-[#6F368B]'>"Electronic Filing of Returns of Tax Deducted at Source Scheme, 2003".</a> It is applicable to all deductors furnishing their TDS return in electronic form. As per this scheme:</p>
                    </div>
                    <ul className='text-sm leading-8 list-disc list-inside'>
                        <li>It is mandatory (w.e.f. June 1, 2003) for corporate deductors to furnish their TDS returns in electronic form (e-TDS return).</li>
                        <li>From F.Y. 2004-2005 onwards furnishing TDS returns in electronic form is also mandatory for government deductors in addition to corporate deductors.</li>
                        <li>Deductors (other than government and corporates) may file TDS return in electronic or physical form.</li>
                        <li>Protean e-Governance Infrastructure Limited (Protean) as the e- TDS Intermediary (appointed by ITD) receives, on behalf of ITD, the e-TDS returns from the deductors.</li>
                    </ul>
                </div>
                <div className='bg-[#6F368B1A] p-8 bg-opacity-10 relative h-[100vh] md:h-auto'>
                    <h1 className='font-semibold text-lg pb-4'>e-TCS</h1>
                    <div className='pb-4 text-sm leading-6'>
                        <p>TCS means collection of tax at source by the seller (collector) from the buyer (collectee/payee) of the goods (specified u/s 206C of Income-tax Act, 1961, like timber obtained under forest lease, scrap, any other forest produce not being timber or tendu leaves etc.,). For e.g. if purchase value of goods is Rs.10,000/-, the buyer will pay an amount of Rs.10,000/- + X (X being the value of TCS as prescribed under Income-tax Act, 1961) to the seller. The seller will deposit the tax collected at source (TCS) at any of the designated branches of the authorised banks.</p>
                        <p>Following the automation of TDS returns in 2003, ITD has now notified an <a href='#' className='text-[#6F368B]'>"Electronic Filing of Returns of Tax Collected at Source Scheme, 2005".</a> It is applicable to all deductors furnishing their TDS return in electronic form. As per this scheme:</p>
                    </div>
                    <ul className='text-sm leading-8 list-disc list-inside'>
                        <li>It is mandatory for corporate and government deductors to furnish their TCS returns in electronic form (e-TCS return) from F.Y. 2004-2005 onwards.</li>
                        <li>Deductors (other than government and corporates) may file TCS return in electronic or physical form.</li>
                        <li>Protean as the e-TCS Intermediary (appointed by ITD) receives, on behalf of ITD, the e-TCS returns from the deductors.</li>
                    </ul>
                    <BlueVectorSvg className='absolute bottom-0 left-0 w-[71px] h-[71px]' />
                    <RedVectorSvg className='absolute bottom-0 right-0 w-[63px] h-[126px]' />
                </div>
            </div>}
            {isOverviewOpen && <div className='bg-[#6F368B1A] bg-opacity-10 p-8'>
                <div className="">
                    <h1 className="text-sm font-normal mb-4">
                        Deductors/Collectors can submit e-TDS/TCS returns through TIN-Facilitation Centres (TIN-FC) established by Protean or may directly file at e-Filing portal.
                    </h1>
                    <h2 className="text-base font-semibold mb-2">
                        Revised forms and file formats for e-TDS/e-TCS Returns
                    </h2>
                    <table className="min-w-full text-[#0F0F0F]">
                        <thead>
                            <tr className="bg-[#6F368B80] bg-opacity-50">
                                <th className="w-1/4 p-3 text-left">Financial Year</th>
                                <th className="w-1/4 p-3 text-left">Forms</th>
                                <th className="w-1/4 p-3 text-left">Periodicity</th>
                                <th className="w-1/4 p-3 text-left">Remarks</th>
                            </tr>
                        </thead>
                        <tbody className='bg-white'>
                            <tr>
                                <td className="p-3 content-start font-bold">2006-07</td>
                                <td className="p-3 content-start font-normal text-sm">
                                    <ul className='text-[#6F368B]'>
                                        <li>24Q-TDS</li>
                                        <li>26Q-TDS</li>
                                        <li>27Q-TDS</li>
                                        <li>27EQ-TDS</li>
                                    </ul>
                                </td>
                                <td className="p-3 content-start font-normal text-sm">
                                    <ul>
                                        <li>Quarterly</li>
                                        <li>Quarterly</li>
                                        <li>Quarterly</li>
                                        <li>Quarterly</li>
                                    </ul>
                                </td>
                                <td className="p-3 content-start font-normal text-sm">
                                    <ul>
                                        <li>Corporates, Government</li>
                                        <li>Departments, Organisations</li>
                                        <li>Mandatory to file in electronic </li>
                                        <li>Other deductors - may file in electronic form or in physical form</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="4" className="p-3 text-center font-normal text-sm">
                                    <a href='https://www.protean-tinpan.com/downloads/e-tds/Form%2027A_01042015.pdf' className='text-[#6F368B]'>Form 27A</a> to be filed in physical form with each e-TDS/TCS return submitted through TIN-FC
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p className="mt-4 text-sm">
                        **e-TDS/e-TCS returns will be filed quarterly for F.Y. 2005-2006. However, filing of quarterly e-TDS/TCS returns facility upto FY 2006-07 has been discontinued from TIN as advised by Income Tax Department (ITD). The file formats for quarterly returns (Forms 24Q, 26Q, 27Q and 27EQ) have been notified by the ITD and have been made available on Protean web-site.
                        Deductors furnishing e-TDS/TCS returns are required to furnish a control chart - Form 27A in physical form along with the e-TDS/TCS return furnished in CD/Pen Drive.
                        Form 27A is a summary of e-TDS/TCS return (Form 24Q, 26Q, 27Q, 27EQ), which contains control totals of 'Amount Paid', 'Income tax deducted at source'& 'Tax Deposited (Total challan amount)'. The control totals mentioned on Form 27A should match with the corresponding control totals in e-TDS/TCS return file. Form 27A is required to be furnished separately for each e-TDS/TCS return (Form 24Q, 26Q, 27Q & 27EQ).
                    </p>
                    <p className="text-sm">Procedures and guidelines for preparing and furnishing e-TDS/e-TCS return are given in Deductors Manual available at downloads section.
                        With effect from February 1, 2014, it is mandatory to submit Form 27A generated by TDS/TCS FVU (File Validation Utility) duly signed, along with the TDS/TCS statement(s). Any other Form 27A submitted will be treated as invalid submission and the same will be rejected by TIN-FC branches.
                        Protean has developed a freely downloadable utility called File Validation Utility to verify whether the e-TDS/e-TCS return files prepared by the deductors/ collectors conform to the prescribed format.
                    </p>
                </div>
            </div>}
        </div>
    )
}

export default Overview