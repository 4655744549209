import React from 'react';
import { Element } from 'react-scroll';
import Section from './components/Section';
import Overview from './components/Overview';
import CommonBanner from '../../components/CommonBanner';
import SecondSection from './components/SecondSection';
import ChallanSection from './components/ChallanSection';
import FileValidation from './components/FileValidation';
import BankBranches from './components/BankBranches';
import RBI from './components/RBI';
import withScrollToHash from '../../hooks/useScrollToHash'; // Correctly import the HOC

function Link25() {
    return (
        <div className="flex flex-col gap-8">
            <CommonBanner
                title={"Online Tax Accounting System (OLTAS)"}
                className={"min-h-[60vh] md:min-h-[50vh]"}
                isOnlineTax
                desktopWidth='43vw'
            />
            <Element name="second-section">
                <SecondSection />
            </Element>
            <Element name="overview">
                <Overview />
            </Element>
            <Element name="challan-section">
                <ChallanSection />
            </Element>
            <div className='flex flex-col gap-6 md:px-[68px] md:flex-row justify-between'>
                <div className='flex flex-col'>
                    <Element name="file-validation">
                        <FileValidation />
                    </Element>
                    <Element name="bank-branches">
                        <BankBranches />
                    </Element>
                    <Element name="rbi">
                        <RBI />
                    </Element>
                </div>
                <Element name="section">
                    <Section />
                </Element>
            </div>
        </div>
    );
}

export default withScrollToHash(Link25); // Use the HOC
