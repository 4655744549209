import React from "react";

function Layout({ className, children }) {
  return (
    <section className={`lg:px-[70px] py-10 ${className}`}>
      {children}
    </section>
  );
}

export default Layout;
