import React from 'react'
import DynamicLinks from '../../../components/DynamicLink'
import { ThreeDotsBulletSvg } from '../../../assets/svgs';

function FileValidation() {
    const cardDataArray = [
        {
            linkData: [
                {
                    text: "Download File Validation Utility (Ver. 2.2.3) for Link Cells (effective from February 10, 2023).",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                },
                {
                    text: "Download File Validation Utility (Ver. 1.8.0) for Collecting/Nodal Branches (effective from February 10, 2023).",
                    linkMinWidth: "227px",
                    linkMinWidthMobile: '247px',
                    iconColor: 'black',
                    borderBottom: '1px solid black',
                    maxWidth: '227px',
                    textColor: 'black',
                },
            ],
        },
    ];

    return (
        <div className='flex flex-col py-6 w-full'>
            <div className="bg-[#FFCE1B] bg-opacity-10 p-8">
                <h1 className='flex flex-row text-[26px] font-semibold gap-2 pb-6 leading-5'>
                    <ThreeDotsBulletSvg fillColor={'#FFCE1B'} className={'w-[34px] h-[17px]'} />
                    File Validation Utility
                </h1>
                <p className='text-sm pb-4'>File Validation Utilities for validations in TIN-OLTAS effective from February 10, 2023</p>
                <div className="flex flex-col md:flex-row pb-20 md:pb-0">
                    {cardDataArray.map((cardData, index) => (
                        <DynamicLinks key={index} linkData={cardData.linkData} listDir='flex-row' className={"text-black flex-col md:flex-row md:items-end gap-5 px-2 md:px-0 md:gap-6"} titleColor='black' />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default FileValidation